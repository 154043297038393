export default function Kursister() {
    return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" style={{fill: "none", strokeWidth: 5, strokeMiterLimit: 10}}>
        <path d="M62.18,25.73L4.61,56.44c-2.73,1.45-2.73,5.36,0,6.82l57.57,30.7c1.14,0.61,2.5,0.61,3.64,0l57.57-30.7
            c2.73-1.45,2.73-5.36,0-6.82l-57.57-30.7C64.68,25.13,63.32,25.13,62.18,25.73z"/>
        <path d="M24.53,73.88v29.82c0,9.65,17.54,21.93,39.47,21.93s39.47-12.28,39.47-21.93V73.88"/>
        <line x1="125.43" y1="59.84" x2="125.43" y2="116.85"/>
    </svg>

    )
}