import { Client } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser'
import { InteractionType } from '@azure/msal-browser';
import { msalInstance } from './msal';

const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msalInstance, {
    account: msalInstance.getActiveAccount(),
    interactionType: InteractionType.Silent,
    scopes: ['User.ReadWrite.All', 'Sites.ReadWrite.All'],
});



export const graphClient = Client.initWithMiddleware({ authProvider: authProvider });