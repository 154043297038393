export default function Cross(props) {

    const event = {
        onClick: (e) => {
            if ("onClick" in props)
                props.onClick(e)
        }
    }
    return (
        <svg onClick={(e) => event.onClick(e)} className={props.className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style={{fill: 'none', strokeWidth:9, strokeLinecap:'round', strokeMiterlimit:10}}>
            <line x1="5.33" y1="5.33" x2="58.67" y2="58.67"/>
            <line x1="58.67" y1="5.33" x2="5.33" y2="58.67"/>
        </svg>
    )
}