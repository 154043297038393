import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

// TYPES
import { UserHandler } from "./functions/UserHandler";
import { ThemeHandler } from "./functions/ThemeHandler";
import { UserData } from "./hooks/useUserData";

// CONTEXT
import { AppContext, ContextType } from "./context/AppContext";

// FUNCTIONS
import { PopupHandler } from "./functions/PopupHandler";
import { AutoFillHandler } from "./functions/AutoFillHandler";
import { FormatHandler } from "./functions/FormatHandler";
import { MsgHandler } from "./functions/MsgHandler";
import { SelectMenuHandler } from "./functions/SelectMenuHandler";
import { AktiveHoldHandler } from "./functions/AktiveHoldHandler";
import { DropDownMenuHandler } from "./functions/DropDownMenuHandler";

// PAGES
import AktiveHoldPage from "./pages/AktiveHoldPage/AktiveHoldPage";
import KursisterPage from "./pages/KursisterPage/KursisterPage";
import HoldPage from "./pages/HoldPage/HoldPage";
import StatistikPage from "./pages/StatistikPage/StatistikPage"

// COMPONENTS
import Navigation from "./components/Navigation/Navigation";
import PageHeaderBar from "./components/PageHeaderBar/PageHeaderBar";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";

// STYLE
import style from "./App.module.scss";
import { EmailHandler } from "./functions/EmailHandler";

interface AppType {
  userData: UserData;
  userHandler: UserHandler;
  userDataNeedsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  themeHandler: ThemeHandler;
}

const App: React.FC<AppType> = ({
  userData,
  userHandler,
  userDataNeedsUpdate,
  themeHandler,
}) => {
  const dropDownMenuHandler = new DropDownMenuHandler();
  const formatHandler = new FormatHandler();
  const activeHoldHandler = new AktiveHoldHandler();
  const popupHandler = new PopupHandler();
  const msgHandler = new MsgHandler();
  const selectMenuHandler = new SelectMenuHandler();
  const autoFillHandler = new AutoFillHandler();
  const mailHandler = new EmailHandler();


  const context: ContextType = {
    popupHandler,
    formatHandler,
    msgHandler,
    selectMenuHandler,
    autoFillHandler,
    userHandler,
    userDataNeedsUpdate,
    activeHoldHandler,
    themeHandler,
    dropDownMenuHandler,
    userData,
    mailHandler
  };

  const KeydownEvents = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      if (selectMenuHandler.isOpen()) selectMenuHandler.clear();
      else if (autoFillHandler.isOpen()) autoFillHandler.clear();
      else popupHandler.close();
    }
  };

  useEffect(() => {
    activeHoldHandler.update(["hold", "eLearn"]);
  },[])

  useEffect(() => {
    window.addEventListener("keydown", KeydownEvents);
    return () => window.removeEventListener("keydown", KeydownEvents);
  },[popupHandler]);

  return (
    <AppContext.Provider value={context}>
      <BrowserRouter>
        {process.env.REACT_APP_NODE_ENV === "development" && !process.env.REACT_APP_LOCAL_ENV ? 
          <div className={style.watermark}><p>TEST</p></div>
        : null}
        {selectMenuHandler.display()}
        {autoFillHandler.display()}
        <Navigation />
        <div className={style.layout__content}>
          <ErrorBoundary>
            <PageHeaderBar />
            {dropDownMenuHandler.display()}
            {popupHandler.layer(0)}
            {msgHandler.display()}
            <Routes>
              <Route path="/aktive" element={<AktiveHoldPage />} />
              <Route path="/kursister" element={<KursisterPage />} />
              <Route path="/hold" element={<HoldPage />} />
              <Route path="/statistik" element={<StatistikPage />} />
              <Route path="/" element={<AktiveHoldPage />} />
            </Routes>
          </ErrorBoundary>
        </div>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export default App;
