import { useMutation } from "@apollo/client"
import { useContext, useEffect, useState } from "react"

// QUERIES
import { GET_KURSISTER_BY_HOLD } from '../../../../queries/kursister.gql'
import { UPDATE_HOLD, GET_HOLD, DELETE_HOLD } from '../../../../queries/hold.gql'

// CONTEXT
import { AppContext } from "../../../../context/AppContext"
import client from "../../../../config/apolloClient"

// COMPONENTS
import Button from "../../../Button/Button"
import { HoldInterfaceTemplate } from "../HoldInterfaceTemplate/HoldInterfaceTemplate"

// STYLE
import style from './HoldInterfaceUpdate.module.scss'
import Trash from "../../../../assets/icons/indicators/Trash"

export default function HoldInterfaceUpdate(props) {
    const [isELearning, setIsELearning] = useState(false)
    const {popupHandler} = useContext(AppContext)
    const [hold, setHold] = useState(props.hold)
    const [newHold, setNewHold] = useState(hold)
    const [disable, setDisable] = useState(true)
    const [inputErrors, setInputErrors] = useState({})

    const [update_hold, updateQuery] = useMutation(UPDATE_HOLD, {
        refetchQueries: [
            GET_HOLD,
            GET_KURSISTER_BY_HOLD,
        ],
    });

    const [delete_hold, deleteQuery] = useMutation(DELETE_HOLD, {
        refetchQueries: [
            GET_HOLD
        ],
    });

    useEffect(() => {
        if (updateQuery.loading || updateQuery.error)
            return
        if (!updateQuery.data)
            return
        setHold(updateQuery.data.update_hold)
        setNewHold(updateQuery.data.update_hold)
        setDisable(true)
    },[updateQuery.data])

    const event = {
        input: (name, val, kursus) => {
            let updateObj = {...newHold}
            if (kursus && kursus.E_learning) {
                setIsELearning(true)
                updateObj["Online"] = true
            } else {
                setIsELearning(false)
            }
            updateObj[name] = val
            setNewHold(updateObj)
        },
        OnlineAlertUpdate: (updateObj) => {
            popupHandler.add(
                <div className={style.delete_card}>
                    <p>Du er ved at lave et hold online... dette vil også ændre alle kursisterne til online, er du sikker?</p>
                    <div>
                        <Button onClick={() => {
                            client.clearStore();
                            update_hold({variables: {id: hold.Hold_id, input: updateObj}})
                            popupHandler.remove(1)
                        }}>ja</Button>
                        <Button outlined onClick={() => popupHandler.remove(1)}>nej</Button>
                    </div>
                </div>
            )
        },
        update: () => {
            let validateObj = HoldInterfaceTemplate.functions.validate(newHold)
            if (!validateObj.valid) {
                setInputErrors(validateObj.errors)
                return;
            }

            setInputErrors({})
            const updateObj = {
                Kursus_id: newHold.Kursus_id,
                Holdnavn: newHold.Holdnavn,
                Aktivt: newHold.Aktivt,
                Online: newHold.Online,
                Sharepoint: newHold.Sharepoint,
                Startdato: new Date(newHold.Startdato)
            }

            if (newHold.Online === true && (hold.Online === false || hold.Online === null)) {
                event.OnlineAlertUpdate(updateObj)
                return
            }

            client.clearStore();
            update_hold({variables: {id: hold.Hold_id, input: updateObj}})
        },
        delete: () => {
            const htmlRow = document.querySelector(`[data-primary-key="Hold_id${hold.Hold_id}"]`)
            htmlRow.classList.add("inactive_load")
            popupHandler.close()
            delete_hold({variables: {id: hold.Hold_id}})
        },
        reset: () => {
            setDisable(true)
            setNewHold(hold)
        },
    }

    return (
        <HoldInterfaceTemplate.component error={updateQuery.error} loading={updateQuery.loading}>
            <HoldInterfaceTemplate.content>
                <HoldInterfaceTemplate.baseInputs 
                    hold={newHold} 
                    onInput={event.input}
                    disabledFields={disable}
                    inputErrors={inputErrors}
                    isELearning={isELearning}
                    deleteButton={
                        <div className={style.Trash} title="Slet hold?" onClick={() => popupHandler.add(
                            <div className={style.delete_card}>
                                <p>Er du sikker på at du vil slette <span>{hold.Holdnavn}</span>?</p>
                                <div>
                                    <Button onClick={() => event.delete()}>ja</Button>
                                    <Button outlined onClick={() => popupHandler.remove(1)}>nej</Button>
                                </div>
                            </div>
                        )}>
                            <Trash />
                        </div>
                    }
                >
                    <HoldInterfaceTemplate.footer>
                        {disable ?
                                <Button onClick={() => setDisable(false)}>Rediger</Button>
                            :
                                [
                                    <Button outlined onClick={() => event.reset(false)}>Fortryd</Button>,
                                    <Button onClick={() => event.update()}>Gem</Button>
                                ]
                            }
                    </HoldInterfaceTemplate.footer>
                </HoldInterfaceTemplate.baseInputs>
                <HoldInterfaceTemplate.kursister 
                    hold={newHold} 
                    onInput={event.input}
                    disabledFields={disable}
                />
            </HoldInterfaceTemplate.content>
        </HoldInterfaceTemplate.component>
    )
}