import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";

// Queries
import { CHECK_CONNECTION } from "./queries/base.gql";

// Hooks
import { usePreloadCatalog } from "./hooks/useCatalog";
import { useUserData } from "./hooks/useUserData";

// Functions
import { ThemeHandler } from "./functions/ThemeHandler";
import { FormatHandler } from "./functions/FormatHandler";
import { UserHandler } from "./functions/UserHandler";

// Assets
import Loading from "./assets/icons/loaders/Loading";

// Components
import App from "./App";
import Button from "./components/Button/Button";

// Style
import style from "./App.module.scss";

type AppInitProps = {
  userHandler: UserHandler;
  themeHandler: ThemeHandler;
};

const ErrorCard: React.FC<{
  title: string;
  message: string;
  userHandler: UserHandler;
}> = ({ title, message, userHandler }) => (
  <div className={style.error}>
    <div className={style.error_card}>
      <p className={style.error_card__text}>{title}</p>
      <p className={style.error_card__text_alt}>{message}</p>
      <div className={style.error_card_actions}>
        <Button onClick={() => userHandler.logout()}>Log ud</Button>
        <Button outlined onClick={() => window.location.reload()}>
          Prøv igen
        </Button>
      </div>
    </div>
  </div>
);

const AppInit: React.FC<AppInitProps> = ({ userHandler, themeHandler }) => {
  const format = new FormatHandler();
  const [userDataNeedsUpdateState, userDataNeedsUpdate] =
    useState<boolean>(true);
  const {
    loading: userLoading,
    data: userData,
    error: userError,
  } = useUserData(userDataNeedsUpdateState, userDataNeedsUpdate);

  const { loading, error, data } = useQuery(CHECK_CONNECTION);

  const {
    loading: loadCatalog,
    error: errorCatalog,
  } = usePreloadCatalog();

  if (userError) {
    return (
      <ErrorCard
        title="Der er sket en fejl"
        message={!userError ? "" : "message" in userError ? userError.message : ""}
        userHandler={userHandler}
      />
    );
  }

  if (error || errorCatalog) {
    const [firstError] = format.apiError(error);
    const title = firstError.code ? "Adgang nægtet" : "Service er nede";
    return (
      <ErrorCard
        title={title}
        message={!userError ? "" : "message" in error ? error.message : ""}
        userHandler={userHandler}
      />
    );
  }

  if (loading || userLoading || loadCatalog) {
    return (
      <div className={style.load}>
        <div className={style.load_card}>
          <Loading className={style.load_card_loading} />
          <p className={style.load_card__text}>
            {userLoading
              ? "Henter Bruger"
              : loading
              ? "Tjekker forbindelse"
              : "Henter Nødvendige Cataloger"}
          </p>
        </div>
      </div>
    );
  }

  if (!userData.Roles) {
    return (
      <ErrorCard
        title="Adgang nægtet"
        message="Din bruger har ikke adgang til applikationen"
        userHandler={userHandler}
      />
    );
  }
  
  return (
    <div className={style.layout}>
      <App
        userData={userData}
        userHandler={userHandler}
        userDataNeedsUpdate={userDataNeedsUpdate}
        themeHandler={themeHandler}
      />
    </div>
  );
};

export default AppInit;
