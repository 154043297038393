import AreaInput from "../../../../Inputs/AreaInput/AreaInput"
import Input from "../../../../Inputs/Input"
import CustomSelectInput from "../../../../Inputs/SelectInput/CustomSelectInput"
import { styling } from "../content/content"


import style from './Options.module.scss'

export default function Options({editComponent, temp, updateTemp, setHover, setFocus}) {
    if (!editComponent)
        return null

    const getTempValue = () => {
        let baseKey = editComponent[editComponent.length-1]
        let path = editComponent
        let obj = {...temp}
    
        if (!Array.isArray(path))
        return
        let currentObj = obj;
    
        for (const key of path.slice(0, -1)) {
        if (!currentObj.hasOwnProperty(key)) {
            console.error(`Key "${key}" not found in the object`);
            return obj;
        }
        currentObj = currentObj[key];
        }
    
        const lastKey = path[path.length - 1];
        return {...currentObj[lastKey], baseKey: baseKey }
    }
          
    return (
        <>
            <AreaInput 
                onMouseOut={() => setHover([])}
                onMouseOver={() => setHover(editComponent)} 
                onBlur={() => setFocus([])}
                onFocus={() => setFocus(editComponent)} 
                label="Tekst"
                value={getTempValue().value}
                autoSize={true}
                onInput={(name, val, e) => updateTemp(val, "value")}
            />
            <div className={style.flex}>
                <Input 
                    label="Top"
                    value={getTempValue().style.marginTop}
                    onInput={(name, val, e) => updateTemp(val, "marginTop")}
                />
                <Input 
                    label="Bredde"
                    value={getTempValue().style.width}
                    onInput={(name, val, e) => updateTemp(val, "width")}
                />
                <Input 
                    label="Tekst størrelse"
                    value={getTempValue().style.fontSize}
                    onInput={(name, val, e) => updateTemp(val, "fontSize")}
                />
                <CustomSelectInput 
                    label="Tekst tykkelse"
                    value={getTempValue().style.fontWeight}
                    onInput={(name, val, e) => updateTemp(val, "fontWeight")}
                    sort={{type: "default"}}
                    options={[
                        {title: "tynd", value: "100" },
                        {title: "normal", value: "300" },
                        {title: "tyk", value: "600" },
                        {title: "tykkere", value: "900" }
                    ]}
                />
            </div>
        </>
    )
}