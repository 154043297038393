export const getEnvVariables = () => {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const tenantId = process.env.REACT_APP_TENANT_ID

    if (typeof clientId !== "string") {
        throw new Error("REACT_APP_CLIENT_ID is not defined or is not a valid string.");
    }

    if (typeof tenantId !== "string") {
        throw new Error("REACT_APP_TENANT_ID is not defined or is not a valid string.");
    }

    const environment = process.env.REACT_APP_LOCAL_ENV ? "local" : process.env.NODE_ENV
    if (environment === "local") {
        return ({
            redirectUri: "/",
            clientId: clientId,
            tenantId: tenantId,
            apiUri: "http://localhost:7071/api/graphql"
        })
    }
    return ({
        redirectUri: window.location.origin,
        clientId: clientId,
        tenantId: tenantId,
        apiUri: "/api/graphql"
    })
}