import React, { ReactElement, useEffect, useRef } from "react";
import Cross from "../../assets/icons/indicators/Cross";

// STYLE
import style from "./Popup.module.scss";

export interface PopupProps {
  content: ReactElement;
  close: () => void;
  layer: number;
  modifier?: "big" | "small";
}

const Popup: React.FC<PopupProps> = ({ content, close, layer, modifier }) => {
  const ref = useRef(null);
  const popbgRef = useRef(null);

  const event = {
    close: (e) => {
      if (e.target === popbgRef.current) close();
    },
  };

  useEffect(() => {
    if (!ref.current || !ref.current.parentElement) return;
    ref.current.focus();
  }, [ref]);

  const classNames = [style.cardTest];
  if (modifier === "big") classNames.push(style.cardTest_big);
  else if (layer === 0) classNames.push(style.cardTest_adjusted);

  return (
    <div
      ref={popbgRef}
      className={style.componentTest}
      onMouseDown={(e) => event.close(e)}
    >
      <div className={classNames.join(" ")}>
        <input
          ref={ref}
          style={{ opacity: 0, height: 0, width: 0, position: "absolute" }}
          id="autoFocusInput"
        />
        <Cross onClick={() => close()} className={style.close_btn} />
        {React.cloneElement(content, { layer: layer })}
      </div>
    </div>
  );
};

export default Popup;
