export default function Burger(props) {
    return (
        <svg onClick={(e) => props.onClick(e)} className={props.className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.56 47.81" style={{fill: 'none', strokeWidth:5, strokeLinecap:'round', strokeMiterlimit:10}}>
            <line x1="5.42" y1="5.9" x2="55.46" y2="5.9"/>
            <line x1="5.28" y1="23.94" x2="55.32" y2="23.94"/>
            <line x1="5.28" y1="41.99" x2="55.32" y2="41.99"/>
        </svg>
    )
}



