import { styling } from "../content/content"
import style from './PdfEdit.module.scss'

import logo from '../assets/itucation.png'
import bg from '../assets/bg.jpg'
import underskrift from '../assets/underskrift.jpg'
import Cross from "../../../../../assets/icons/indicators/Cross"

export default function PdfEdit(props) {
    const {
        addPage,
        removePage,
        updateContent,
        content,
        editComponent,
        setEditComponet,
        focus,
        hover
    } = props


    const isSelected = (path) => {
      return JSON.stringify(editComponent) === JSON.stringify(path)
    }
  
    const getContent = (path) => {
      let obj = {...content}
  
      if (!Array.isArray(path))
        return
      let currentObj = obj;
  
      for (const key of path.slice(0, -1)) {
        if (!currentObj.hasOwnProperty(key)) {
          console.error(`Key "${key}" not found in the object`);
          return null;
        }
        currentObj = currentObj[key];
      }
  
      const lastKey = path[path.length - 1];
      return currentObj[lastKey]
    }
  
    const formatStyle = (styleObj, capitalize) => {
      let baseStyleObj = {
        ...styleObj,
        marginTop: styleObj.marginTop+"px",
        fontSize: styleObj.fontSize+"px",
        width: (eval(styleObj.width)+10)+"px",
      }

      if (capitalize)
        baseStyleObj.textTransform = "capitalize";

      return baseStyleObj
    }
  
    const formatListSubWrapStyle = (styleObj) => {
      return {
        marginTop: styleObj.marginTop+"px",
        gap: styleObj.spacingtitles+"px",
        fontSize: styleObj.titelFontSize+"px",
        maxWidth: styleObj.minWidth+"px",
        minWidth: styleObj.minWidth+"px",
        marginLeft: styleObj.marginLeft+"px"
      }
    }
  
    const formatListSubSubWrapStyle = (styleObj) => {
      return {
        marginTop: styleObj.spacingPoints+"px",
        gap: styleObj.spacingPoints+"px",
        fontSize: styleObj.pointFontSize+"px"
      }
    }
  
    const formatListSubSubSubWrapStyle = (styleObj) => {
      return {
        marginTop: styleObj.spacingSubPoints+"px",
        gap: styleObj.spacingSubPoints+"px",
        fontSize: styleObj.subPointFontSize+"px"
      }
    }
  
    
    const getListStyle = (style, lvl) => {
      if (lvl === 1)
        return formatListSubSubSubWrapStyle(style)
      else
        return formatListSubSubWrapStyle(style)
    }
  
    const EditBaseComponent = ({path, capitalize}) => {
      const content = getContent(path)

      const highlightClass = () => {
        if (JSON.stringify(focus) === JSON.stringify(path))
            return style.text_focus
        if (JSON.stringify(hover) === JSON.stringify(path))
            return style.text_hover
        return ""
      }
      if (!content || !content.value)
        return (
            <p 
            style={formatStyle(content.style)}
            onClick={() => setEditComponet(path)}
            className={[style.editBase, isSelected(path) ? style.editBase_selected : "", highlightClass()].join(" ")}
          >
            {" "}
          </p>
        )
      return (
        <p 
          style={formatStyle(content.style, capitalize)}
          onClick={() => setEditComponet(path)}
          className={[style.editBase, isSelected(path) ? style.editBase_selected : "", highlightClass()].join(" ")}
        >
          {content.value}
        </p>
      )
    }
  
    const EditContentHeaderComponent = ({path, className, addText}) => {
      const content = getContent(path)

      const highlightClass = () => {
        if (JSON.stringify(focus) === JSON.stringify(path))
            return style.text_focus
        if (JSON.stringify(hover) === JSON.stringify(path))
            return style.text_hover
        return ""
      }

      if (!content || !content.value)
        return (
            <div 
            className={[style.editBase, isSelected(path) ? style.editBase_selected : ""].join(" ")}
            onClick={() => setEditComponet(path)} 
            style={{...styling.courseBreaker, ...formatStyle(content.style)}}
            >
            <div style={styling.courseBreaker_bar}></div>
            <p className={highlightClass()}>
                {" "}
            </p>
            <div style={styling.courseBreaker_bar}></div>
            </div>
        )
      return (
        <div 
            className={[style.editBase, isSelected(path) ? style.editBase_selected : ""].join(" ")}
            onClick={() => setEditComponet(path)} 
            style={{...styling.courseBreaker, ...formatStyle(content.style)}}
        >
        <div style={styling.courseBreaker_bar}></div>
          <p className={highlightClass()}>
            {content.value}
          </p>
          <div style={styling.courseBreaker_bar}></div>
        </div>
      )
    }

    const EditCourseComponent = ({path1, path2, path3}) => {
        const content = getContent(path1)
        const content2 = getContent(path2)
        const content3 = getContent(path3)

        
        const highlightClass = (aPath) => {
            if (JSON.stringify(focus) === JSON.stringify(aPath))
                return style.text_focus
            if (JSON.stringify(hover) === JSON.stringify(aPath))
                return style.text_hover
            return ""
        }

        if (!content.value && !content2.value && !content3.value)
            return (
                <div onClick={() => setEditComponet(path1)} style={formatStyle(content.style)} className={[style.editBase, isSelected(path1) ? style.editBase_selected : ""].join(" ")}>
                    <p className={highlightClass(path1)}>
                        {" "}
                    </p>
                </div>
            )

        return (
            <div onClick={() => setEditComponet(path1)} style={formatStyle(content.style)} className={[style.editBase, isSelected(path1) ? style.editBase_selected : ""].join(" ")}>
                <p className={highlightClass(path1)}>
                    {content.value}
                </p>
                {!content2.value ? null :
                  <p 
                    style={formatStyle(content2.style)}
                    onClick={() => setEditComponet(path2)}
                    className={highlightClass(path2)}
                  >
                    {content2.value}
                  </p>
                }
                {!content3.value ? null :
                  <p 
                      style={formatStyle(content3.style)}
                      onClick={() => setEditComponet(path3)}
                      className={highlightClass(path3)}
                  >
                      {content3.value}
                  </p>
                }
            </div>
        )
    }
  
    const EditContentColoumnComponent = ({lastPath, addText, className, path, item, lvl, originalStyling, Lastindex, originalPath, keyProp}) => {
     if (!lvl)
        lvl = 0
      if (!item && path) {
        item = getContent(path)
        console.log(item, "OOOO", path)
        originalStyling = item.style
      }

      if (path) {
        const interactionClass = (index) => {
            if (JSON.stringify(focus) === JSON.stringify([...itemPath, index]))
                return style.text_focus
            if (JSON.stringify(hover) === JSON.stringify([...itemPath, index]))
                return style.text_hover
            return ""
        }

        const itemPath = [...path, "content"]
        if (item.content.length < 1 || (item.content[0].value === ""))
            return <div key={keyProp+path} style={{...formatListSubWrapStyle(item.style), display: "flex", flexDirection: "column", width: "100%"}} onClick={() => setEditComponet(path)} className={[style.editBase, isSelected(path) ? style.editBase_selected : ""].join(" ")}>
                <p style={{fontWeight: "600"}} className={interactionClass(0)}>{" "}</p>
            </div>
        return (
          <div style={{...formatListSubWrapStyle(item.style), display: "flex", flexDirection: "column", width: "100%"}} onClick={() => setEditComponet(path)} className={[style.editBase, isSelected(path) ? style.editBase_selected : ""].join(" ")}>
          {item.content.map((item_sub, index) => (
            <div style={{width: "100%"}} key={keyProp ? keyProp+index+path+item_sub : index+path+item_sub}>
              <p style={{fontWeight: "600"}} className={interactionClass(index)}>{item_sub.value}</p>
              {"content" in item_sub && item_sub.content.length < 1 ? null : 
                <EditContentColoumnComponent keyProp={keyProp ? key+index+path+item_sub : index+path+item_sub} item={item_sub} originalStyling={{...originalStyling, maxWidth: formatListSubWrapStyle(item.style).maxWidth, minWidth: formatListSubWrapStyle(item.style).minWidth}} originalPath={path} Lastindex={index}/>
              }
            </div>
          ))}
        </div>
        )
      }
      if (!("content" in item))
        return null
  

    if (!lastPath)
        lastPath = [...originalPath, "content", Lastindex, "content"]

    const interactionClass = (index) => {
        if (lvl === 1) {
            if (JSON.stringify(focus) === JSON.stringify([...lastPath, Lastindex, "content", index]))
                return style.text_focus
            if (JSON.stringify(hover) === JSON.stringify([...lastPath, Lastindex, "content", index]))
                return style.text_hover
            return ""
        }
        if (JSON.stringify(focus) === JSON.stringify([...lastPath, index]))
            return style.text_focus
        if (JSON.stringify(hover) === JSON.stringify([...lastPath, index]))
            return style.text_hover
        return ""
    }
      return (
        <div style={{...getListStyle(originalStyling, lvl), display: "flex", flexDirection: "column", width: "100%"}}>
          {item.content.map((item_sub, index) => (
            <div key={index+item_sub.value} style={lvl === 1 ? {...{paddingLeft: "10px"}} : {}}>
              <div style={styling.listValue}>
                <p>{lvl === 1 ? '-' : '•'}</p>
                <p className={interactionClass(index)}>{item_sub.value}</p>
              </div>
              {"content" in item_sub && item_sub.content.length < 1 ? null : 
                <EditContentColoumnComponent item={item_sub} lvl={lvl+1} originalStyling={{...originalStyling}} Lastindex={index} lastPath={lastPath}/>
              }
            </div>
          ))}
        </div>
      )
    }
  
    return (
      <div className={style.displaycomponent}>
        <div style={styling.page}>
          <img src={logo} style={styling.logo}></img>
          <div style={{...styling.baseContent, fontFamily: ""}}>
            <EditBaseComponent 
              path={["base", "titel"]} 
              addText="Tilføj titel" 
            />
            <EditBaseComponent 
              path={["base", "toCertify"]} 
              addText="Tilføj certify tekst" 
            />
            <EditBaseComponent 
              path={["base", "name"]} 
              addText="Tilføj name" 
              capitalize
            />
            <EditBaseComponent 
              path={["base", "date"]} 
              addText="Tilføj date" 
            />
            <EditCourseComponent 
                path1={["base", "course"]} 
                path2={["base", "course_sub_1"]}
                path3={["base", "course_sub_2"]}
            />
            <EditContentHeaderComponent 
              path={["base", "course_content"]} 
              addText="Tilføj kursus indhold header" 
            />
            <div style={styling.courseContent}>
                <div style={styling.courseContent_column}>
                    <EditContentColoumnComponent 
                        path={["coloumn1"]}
                        addText="Tilføj kursus kollonne"
                        // hover={hover}
                    />
                </div>
                <div style={styling.courseContent_column}>
                    <EditContentColoumnComponent 
                        path={["coloumn2"]}
                        addText="Tilføj kursus kollonne"
                        // hover={hover}
                    />
                </div>
            </div>
          </div>
            {("page2" in content) ? <span style={styling.pageNr}>1/2</span> :
            <div style={styling.underskrift}>
                <img src={underskrift} style={styling.underskrift_underskrift} />
                <div style={styling.underskrift_line} />
                <span style={styling.underskrift_navn}>{getContent(["base", "leader_name"])}</span>
                <span style={styling.underskrift_titel}>{getContent(["base", "leader_name_sub"])}</span>
            </div>}
        </div>
        
  
        {!("page2" in content) ? <div onClick={() => addPage()} className={style.addpage}><Cross /></div>
          : (
            <div style={{...styling.page, overflow: "initial"}}>
              <div className={style.removePage} onClick={() => removePage()}><Cross /></div>
              <img src={logo} style={styling.logo}></img>
              <div style={styling.nextPageContentSection}>
                <div style={styling.courseContent}>
                    <div style={styling.courseContent_column}>
                        <EditContentColoumnComponent 
                            path={["page2", "coloumn1"]}
                            addText="Tilføj kursus kollonne"
                            // hover={hover}
                        />
                    </div>
                    <div style={styling.courseContent_column}>
                        <EditContentColoumnComponent 
                            path={["page2", "coloumn2"]}
                            addText="Tilføj kursus kollonne"
                            // addText="Tilføj kursus kollonne"
                            // hover={hover}
                        />
                    </div>
                </div>
                <div style={styling.secondPageUnderskrift}>
                    <img src={underskrift} style={styling.underskrift_underskrift} />
                    <div style={styling.underskrift_line} />
                    <span style={styling.underskrift_navn}>{getContent(["base", "leader_name"])}</span>
                    <span style={styling.underskrift_titel}>{getContent(["base", "leader_name_sub"])}</span>
                </div>
              </div>
              <span style={styling.pageNr}>2/2</span>
            </div>
          )
        }
      </div>
  
    )
  }