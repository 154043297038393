export const holdTableFields: Record<string, {
    filter: {
        search: { default: boolean; hidden: boolean };
        table: { default: boolean; hidden: boolean };
    };
    tab: {
        title: string;
        size: number;
        sortable: boolean;
        contentType?: { type: string; action: string };
    };
}> = {
    Hold_id: {
        filter: {
            search: { default: false, hidden: true },
            table: { default: true, hidden: false }
        },
        tab: {
            title: "ID",
            size: 0,
            sortable: true
        },
    },
    Holdnavn: {
        filter: {
            search: { default: true, hidden: false },
            table: { default: true, hidden: false }
        },
        tab: {
            title: "Hold navn",
            size: 2,
            sortable: true
        },
    },
    Online: {
        filter: {
            search: { default: false, hidden: true },
            table: { default: true, hidden: false }
        },
        tab: {
            title: "Online Hold",
            size: 1,
            sortable: true
        },
    },
    Kursus: {
        filter: {
            search: { default: true, hidden: false },
            table: { default: true, hidden: false }
        },
        tab: {
            title: "Kursus",
            size: 2,
            sortable: true
        },
    },
    Startdato: {
        filter: {
            search: { default: true, hidden: false },
            table: { default: true, hidden: false }
        },
        tab: {
            title: "Startdato",
            size: 1,
            sortable: true
        },
    },
}
