import Link from "../../../assets/icons/indicators/Link"
import Mail from "../../../assets/icons/indicators/Mail"
import Input from "../Input"

import style from "./LinkInput.module.scss"

export default function LinkInput(props) {
    const {disabled, onInput, value, name, link, type} = props

    const event = {
        onInput: (name, value) => {
            onInput(name, value)
        }
    }

    return (
        <Input {...props} >
            {disabled && link ? 
                <a 
                    className={style.linkButton}
                    target="_blank" 
                    href={link}
                    title={"Link til "+link}
                >
                    {type === "mail" ?
                        <Mail className={style.linkButton_mail}/>
                    :
                        <Link />     
                    }
                </a>
            : null}
            <input 
                name={name}
                disabled={disabled} 
                onInput={(e) => event.onInput(name, e.target.value)}
                value={value}>
            </input>
        </Input>
    )
}