export const KursisterTableFields: Record<string, {
  filter: {
    search: { default: boolean; hidden: boolean };
    table: { default: boolean; hidden: boolean };
  };
  tab: {
    title: string;
    size: number;
    sortable: boolean;
    contentType?: { type: string; action: string };
  };
}> = {
  Kursist_id: {
    filter: {
      search: { default: false, hidden: true },
      table: { default: true, hidden: false },
    },
    tab: {
      title: "ID",
      size: 0,
      sortable: true,
    },
  },
  Fornavn: {
    filter: {
      search: { default: true, hidden: false },
      table: { default: true, hidden: false },
    },
    tab: {
      title: "Fornavn",
      size: 1,
      sortable: true,
    },
  },
  Efternavn: {
    filter: {
      search: { default: true, hidden: false },
      table: { default: true, hidden: false },
    },
    tab: {
      title: "Efternavn",
      size: 1,
      sortable: true,
    },
  },
  Tlf: {
    filter: {
      search: { default: true, hidden: false },
      table: { default: true, hidden: false },
    },
    tab: {
      title: "Tlf",
      size: 1,
      sortable: true,
    },
  },
  Mail: {
    filter: {
      search: { default: true, hidden: false },
      table: { default: true, hidden: false },
    },
    tab: {
      title: "Mail",
      size: 1,
      sortable: true,
      contentType: { type: "link", action: "mail" },
    },
  },
  Gender: {
    filter: {
      search: { default: false, hidden: false },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "Køn",
      size: 1,
      sortable: true,
    },
  },
  Birthday: {
    filter: {
      search: { default: false, hidden: false },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "FødselsDato",
      size: 1,
      sortable: true,
    },
  },
  Uddannelsesniveau: {
    filter: {
      search: { default: false, hidden: false },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "Uddannelsesniveau",
      size: 1,
      sortable: true,
    },
  },
  A_kasse: {
    filter: {
      search: { default: false, hidden: false },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "A_kasse",
      size: 1,
      sortable: true,
    },
  },
  Jobcenter: {
    filter: {
      search: { default: false, hidden: false },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "Jobcenter",
      size: 1,
      sortable: true,
    },
  },
  Indgang: {
    filter: {
      search: { default: false, hidden: false },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "Indgang",
      size: 1,
      sortable: true,
    },
  },
  Kontaktperson: {
    filter: {
      search: { default: false, hidden: false },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "Kontaktperson",
      size: 1,
      sortable: true,
    },
  },
  Medarbejder: {
    filter: {
      search: { default: false, hidden: false },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "Medarbejder",
      size: 1,
      sortable: true,
    },
  },
  Rkv: {
    filter: {
      search: { default: false, hidden: true },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "Rkv",
      size: 1,
      sortable: true,
    },
  },
  Udb: {
    filter: {
      search: { default: false, hidden: true },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "Udb",
      size: 1,
      sortable: true,
    },
  },
  Note: {
    filter: {
      search: { default: false, hidden: false },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "Note",
      size: 2,
      sortable: true,
    },
  },
  Oprettet: {
    filter: {
      search: { default: false, hidden: true },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "Oprettet",
      size: 1,
      sortable: true,
    },
  },
  By: {
    filter: {
      search: { default: false, hidden: false },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "By",
      size: 1,
      sortable: true,
    },
  },
  Adresse: {
    filter: {
      search: { default: false, hidden: false },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "Adresse",
      size: 1,
      sortable: true,
    },
  },
  Postnummer: {
    filter: {
      search: { default: false, hidden: false },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "Postnummer",
      size: 1,
      sortable: true,
    },
  },
  Land: {
    filter: {
      search: { default: false, hidden: false },
      table: { default: false, hidden: false },
    },
    tab: {
      title: "Land",
      size: 1,
      sortable: true,
    },
  },
};
