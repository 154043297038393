export default function Kurser() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" style={{fill: "none", strokeWidth: 5, strokeMiterLimit: 10}}>
            <path d="M123.35,49.94v68.08c0,3.84-3.11,6.95-6.95,6.95H11.81c-3.84,0-6.95-3.11-6.95-6.95V49.94"/>
            <path d="M54.32,62.83h-7.07c-2.09,0-3.79-1.7-3.79-3.79v-4.09c0-2.09,1.7-3.79,3.79-3.79h7.07
                c2.09,0,3.79,1.7,3.79,3.79v4.09C58.11,61.13,56.41,62.83,54.32,62.83z"/>
            <path d="M27.66,62.89h-7.07c-2.09,0-3.79-1.7-3.79-3.79v-4.09c0-2.09,1.7-3.79,3.79-3.79h7.07
                c2.09,0,3.79,1.7,3.79,3.79v4.09C31.45,61.19,29.75,62.89,27.66,62.89z"/>
            <path d="M81.21,62.89h-7.07c-2.09,0-3.79-1.7-3.79-3.79v-4.09c0-2.09,1.7-3.79,3.79-3.79h7.07
                c2.09,0,3.79,1.7,3.79,3.79v4.09C85,61.19,83.31,62.89,81.21,62.89z"/>
            <path d="M107.99,62.83h-7.07c-2.09,0-3.79-1.7-3.79-3.79v-4.09c0-2.09,1.7-3.79,3.79-3.79h7.07
                c2.09,0,3.79,1.7,3.79,3.79v4.09C111.78,61.13,110.08,62.83,107.99,62.83z"/>
            <path d="M54.56,86.86h-7.07c-2.09,0-3.79-1.7-3.79-3.79v-4.09c0-2.09,1.7-3.79,3.79-3.79h7.07
                c2.09,0,3.79,1.7,3.79,3.79v4.09C58.35,85.16,56.66,86.86,54.56,86.86z"/>
            <path d="M27.9,86.92h-7.07c-2.09,0-3.79-1.7-3.79-3.79v-4.09c0-2.09,1.7-3.79,3.79-3.79h7.07c2.09,0,3.79,1.7,3.79,3.79
                v4.09C31.69,85.22,30,86.92,27.9,86.92z"/>
            <line x1="70.35" y1="75.26" x2="85" y2="75.26"/>
            <line x1="97.13" y1="75.11" x2="111.78" y2="75.11"/>
            <line x1="17.04" y1="110.85" x2="31.69" y2="110.85"/>
            <line x1="43.7" y1="110.85" x2="58.35" y2="110.85"/>
            <line x1="70.05" y1="99.06" x2="85" y2="99.06"/>
            <line x1="97.13" y1="99.06" x2="111.78" y2="99.06"/>
            <line x1="97.13" y1="110.85" x2="104.45" y2="110.85"/>
            <path d="M12.41,21.48H9.56c-2.59,0-4.69,2.1-4.69,4.69v6.64c0,2.59,2.1,4.69,4.69,4.69h109.11
                c2.59,0,4.69-2.1,4.69-4.69v-6.64c0-2.59-2.1-4.69-4.69-4.69h-2.67"/>
            <line x1="31.55" y1="21.48" x2="97.65" y2="21.48"/>
            <line x1="106.53" y1="26.83" x2="106.53" y2="15.9"/>
            <line x1="22.05" y1="26.83" x2="22.05" y2="15.9"/>
        </svg>

    )
}