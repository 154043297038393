import { useQuery } from '@apollo/client'
import style from './AdminInterfaceBrugere.module.scss'
import Table from '../../../Table/Table'
import Cell from '../../../Table/Cell'
import { graphClient } from '../../../../config/graphClient'
import { useEffect, useState } from 'react'
import { AdminInterfaceTemplate } from '../AdminInterfaceTemplate/AdminInterfaceTemplate'
import Input from '../../../Inputs/Input'
import CustomSelectInput from '../../../Inputs/SelectInput/CustomSelectInput'
import Button from '../../../Button/Button'
import Lock from '../../../../assets/icons/indicators/Lock'

let savedSelected = null

export default function AdminInterfaceBrugere() {
    const accessRoles = [
        {value: process.env.REACT_APP_ADMIN_ID, title: "Admin"},
        {value: process.env.REACT_APP_NONADMIN_ID, title: "Standard"},
        {value: "", title: "Ingen"},
    ]
    const [sort, setSort] = useState({field: "", dir: false})
    const [loading, setLoading] = useState(true)
    const [content, setContent] = useState([])
    const [newContent, setNewContent] = useState([])
    const [selected, setSelected] = useState(null)
    const [updateUserError, setUpdateUserError] = useState(null)
    const [searchString, setSearchString] = useState("")

    useEffect(() => {
        setNewContent(content)
    },[content])
    
    useEffect(() => {
        const filtered = AdminInterfaceTemplate.functions
        .localSearch(searchString, content, ["displayName"])
        setNewContent(filtered)
    },[searchString])

    useEffect(() => {
        const sorted = AdminInterfaceTemplate.functions.localSort(content, sort)
        setContent(sorted)
    },[sort])

    useEffect(() => {
        getUsers();
    },[])

    useEffect(() => {
        if (selected)
            setSelected(content.find(user => user.id === selected.id))
    },[content])

    const getUsers = async () => {
        console.log("ran times")
        try {
            const users = await graphClient.api('/users').get();
            const role = await graphClient.api(`/servicePrincipals/${process.env.REACT_APP_OBJECT_ID}/appRoleAssignedTo`).get();

            // Map the user objects to an array of promises
            const userPromises = users.value.map(async (user) => {
                const roleids = role.value.filter(rv => rv.principalId === user.id).map(r => ({appRoleId: r.appRoleId, id: r.id}))
  
                const determineUserAccess = (id) => {
                    if (id === process.env.REACT_APP_ADMIN_ID)
                        return "Admin"
                    if (id === process.env.REACT_APP_NONADMIN_ID)
                        return "Standard"
                    return "Ingen"
                }

                const roleObj = roleids.length > 0 ? roleids[0] : null
                
                return {
                    ...user,
                    initialer: user.mail ? user.mail.split("@")[0] : user.userPrincipalName.split("@")[0],
                    adgang: !roleObj ? "Ingen" : determineUserAccess(roleObj.appRoleId),
                    appRoleId: !roleObj ? null : roleObj.appRoleId,
                    apRoleInstanceId: !roleObj ? null : roleObj.id,
                    id: user.id,
                };
            });

            const usersWithRole = await Promise.all(userPromises);
            setContent(usersWithRole)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            handleGraphError(err)
        }
    };

    const handleGraphError = (error) => {
        if (error.message.includes("Insufficient privileges")) {
            setUpdateUserError(
                <div className={style.errorMessage}>
                    <Lock />
                    <span>Din bruger har ikke adgang til at redigere andre bruger profiler, vær sikker på du har azure rolen User Adminstrator eller lignende</span>
                </div>
            )
            return
        }
        setUpdateUserError(error.message)
    }

    const event = {
        search: (name, val) => {
            setSearchString(val)
        },
        sort: (field) => {
            setSort({field: field, dir: !sort.dir})
        },
        rowClick: (val, index) => {
            savedSelected = val
            setSelected(val)
        },
        onInput: (name, val) => {
            let updateObj = {...selected}
            updateObj[name] = val
            setSelected(updateObj)
        },
        update: async () => {
            const appRoleAssignment = {
                principalId: selected.id,
                resourceId: process.env.REACT_APP_OBJECT_ID, // object id enterpirce appplication
                appRoleId: selected.appRoleId
            };

     
            if (selected.apRoleInstanceId)
                try {
                    await graphClient.api(`/servicePrincipals/${process.env.REACT_APP_OBJECT_ID}/appRoleAssignedTo/${selected.apRoleInstanceId}`).delete();
                } catch (err) {
                    handleGraphError(err)
                    console.log(err)
                    return
                }

            if (selected.appRoleId)
                try {
                    await graphClient.api(`/servicePrincipals/${process.env.REACT_APP_OBJECT_ID}/appRoleAssignedTo`).post(appRoleAssignment);   
                } catch (err) {
                    handleGraphError(err)
                    console.log(err)
                    return
                }

                setLoading(true)
                setTimeout(() => {
                    getUsers()
                }, 600);
        }
    }

    return (
        <div className={style.component}>
            <div className={style.content}>
                <div className={style.table_section}>
                    <div className={style.table_heading}>
                        <div>
                            <Input style="search" placeholder="søg" value={searchString} onInput={event.search} resetButton={true}/>
                        </div>
                    </div>
                    <div className={style.scrollContainer}>
                        <Table
                            error={updateUserError}
                            content={newContent}
                            loading={loading}
                            rowSelect={!selected ? null : {field: "userPrincipalName", value: selected.userPrincipalName}}
                            onRowClick={(val, index, e) => event.rowClick(val, index, e)}
                        >
                            <Cell 
                                size={1}
                                title="Display name"
                                name={"displayName"}
                                sort={sort}
                                onClick={() => event.sort("displayName")}
                            />
                            <Cell 
                                size={1}
                                title="Initialer"
                                name="initialer"
                                sort={sort}
                                onClick={() => event.sort("initialer")}
                            />
                            <Cell 
                                size={0}
                                title="Adgang"
                                name={"adgang"}
                                sort={sort}
                                onClick={() => event.sort("adgang")}
                            />
                        </Table>
                    </div>
                </div>
                <div className={style.content_split}></div>
                <div className={style.section}>
                    {!selected ? null : <>
                        <h2>Rediger</h2>
                        <Input 
                            name="displayName"
                            label="Display name"
                            value={selected.displayName}
                            onInput={event.onInput}
                            disabled
                        />
                        <Input 
                            name="givenName"
                            label="Fornavn"
                            value={selected.givenName}
                            onInput={event.onInput}
                            disabled
                        />
                        <Input 
                            name="surname"
                            label="Efternavn"
                            value={selected.surname}
                            onInput={event.onInput}
                            disabled
                        />
                        <CustomSelectInput 
                            name="appRoleId"
                            label="Adgang"
                            value={selected.appRoleId}
                            onInput={event.onInput}
                            options={accessRoles}
                        />
                        <div>
                            <Button onClick={() => event.update()}>Gem</Button>
                        </div>
                
                    </>}
                </div>
            </div>
        </div>
    )
}