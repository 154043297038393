// STYLE
import { useContext, useEffect, useRef, useState } from 'react'
import style from './AutoFillMenu.module.scss'
import { AppContext } from '../../../context/AppContext'

export default function AutoFillMenu(props) {
    const {autoFillHandler} = useContext(AppContext)

    const listRef = useRef(null)
    const {target, options, selected, onClickSelect} = props
    let inputBounding = target.getBoundingClientRect()
    let scrollPos = window.scrollY
    let offSetY = () => {
        if ((inputBounding.top)+scrollPos > scrollPos+((window.innerHeight)/2))
            return `calc(-100% - 4px)`
        else
            return `${(inputBounding.height)+4}px`
    }

    useEffect(() => {
        const selected = document.getElementById("selectedOption")
        if (!selected) return
        const container = selected.offsetParent
        
        const containerPos = {
            y: selected.offsetParent.scrollTop,
            height: selected.offsetParent.getBoundingClientRect().height,
        }

        const selectedPos = {
            y: selected.offsetTop,
            height: selected.getBoundingClientRect().height,
        }

        const relativePosition = (selectedPos.y+selectedPos.height)-(containerPos.y+containerPos.height)
        if (relativePosition > 0)
            container.scrollTop = containerPos.y+relativePosition
    
        if (selected.offsetTop < container.scrollTop)
            container.scrollTop = selected.offsetTop

    },[autoFillHandler])

    const event = {
        select: (val) => {
            const selectedState = {
                ...autoFillHandler.getState(),
                selected: val
            }

            onClickSelect(selectedState)
        },
        close: (e) => {
            if (listRef.current && e.target === listRef.current) {
                return
            }
            autoFillHandler.clear();
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", event.close, true)
        window.addEventListener("resize", event.close)
        return () => {
            window.removeEventListener("scroll", event.close, true)
            window.removeEventListener("resize", event.close)
        }
    },[])

    let positionStyle = {
        top: inputBounding.top+scrollPos,
        left: inputBounding.left,
        width: inputBounding.width,
        transform: `translateY(${offSetY()})`
    }

    return (
        <div className={style.component} style={positionStyle}>
            <div className={style.component_list} ref={listRef}>
                {options.map((o, i) => {
                    const active = o === selected;
                    return (
                        <div
                            className={active ? style.active : ""}
                            id={active ? "selectedOption" : ""}
                            key={o+i}
                            onMouseDown={(e) => e.preventDefault()}
                            onMouseUp={() => event.select(o)}
                        >
                            <span>{o}</span>
                        </div>
                    )
                    }
                )}
            </div>
        </div>
    )
}