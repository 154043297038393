import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import Input from '../Input';

import style from './AutoFillMenu.module.scss'

let target = null;

export default function AutoFillInput(props) {
    const [target, setTarget] = useState(null)
    const [manualClose, setManualClose] = useState(false)
    const {autoFillHandler} = useContext(AppContext)
    const {onInput, name, value, options, disabled, onAutoFillComplete} = props

    const inputRef = useRef(null)

    const complete = (state) => {
        if (!state)
            state = autoFillHandler.getState()
        state.onComplete(state.selected)
        autoFillHandler.clear()
        inputRef.current.blur()
    }

    // console.log(options)
    useEffect(() => {
        if (!inputRef.current) {
            return
        }

        if (!target) {
            return
        }
        
        if (manualClose) {
            autoFillHandler.clear()
            setManualClose(false)
            return
        }

        if (target.value === "") {
            autoFillHandler.clear()
            return
        }

        if (inputRef.current != target || !options || options.length < 1) {
            autoFillHandler.clear()
            return
        }

        if (options && options.length > 0) {
            if (autoFillHandler.isOpen()) {
                if (autoFillHandler.getState().options.toString() != options.toString()) {
                    autoFillHandler.add({
                        target: target,
                        options: options,
                        selected: options[0],
                        onComplete: onAutoFillComplete,
                        onClickSelect: complete
                    })  
                }
            } else {
                autoFillHandler.add({
                    target: target,
                    options: options,
                    selected: options[0],
                    onComplete: onAutoFillComplete,
                    onClickSelect: complete
                })  
            }
            
        }

    },[options, target, inputRef])

    const event = {
        onInput: (name, e) => {
            // setTarget(e.target)
            onInput(name, e.target.value, e)
        },
        onFocus: (e) => {
            if (e.target.value === "") {
                autoFillHandler.clear()
            }
            setTarget(e.target)
        },
        onBlur: () => {
            setTarget(null)
            autoFillHandler.clear()
        },
        keyDown: (e) => {
            if (e.key === "Escape") {
                e.preventDefault();
                setManualClose(true)
            }

            if (e.key == "Enter" && autoFillHandler.isOpen()) {
                e.preventDefault()
                complete()
                return
            }

            if (autoFillHandler.isOpen()) {
                autoFillHandler.keyDown(e.key, e)
            }
        }
    }

    return (
        <Input {...props}>
            <input
                ref={inputRef}
                className={style.removeNumberIndicator}
                autoComplete="off"
                name={name}
                disabled={disabled} 
                onBlur={() => event.onBlur()}
                onInput={(e) => event.onInput(name, e)}
                onFocus={(e) => event.onFocus(e)}
                onKeyDown={(e) => event.keyDown(e)}
                value={value ? value : ""}>
            </input>
        </Input>
    )
}