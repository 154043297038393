import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

// QUERIES
import { GET_HOLD, GET_ALL_HOLD } from "../../queries/hold.gql";

// CONTEXT
import { AppContext } from "../../context/AppContext";

// CONFIG
import { holdTableFields } from "./holdTableFields";

// COMPONENTS
import HoldInterface from "../../components/interfaces/HoldInterface/HoldInterface";
import TableInterface from "../../components/interfaces/TableInterface/TableInterface";
import Button from "../../components/Button/Button";

// STYLE
import style from "./HoldPage.module.scss";

const HoldPage: React.FC = () => {
  const { popupHandler } = useContext(AppContext);
  const location = useLocation();

  const Fields = { ...holdTableFields };

  const event = {
    onRowClick: (data, index, e, startPolling, stopPolling) => {
      popupHandler.add(
        <HoldInterface
          hold={data}
          startPolling={startPolling}
          stopPolling={stopPolling}
        />
      );
    },
    onCreateClick: () => {
      popupHandler.add(<HoldInterface createMode />);
    },
  };

  return (
    <div className={style.page}>
      <div className={style.component}>
        <TableInterface
          name={"Hold"}
          primaryKey={"Hold_id"}
          dateBarFilter={true}
          aktivtFilter={true}
          fields={Fields}
          sort={{ field: "Holdnavn", dir: true }}
          query={GET_HOLD}
          locationProps={location.state}
          excelQuery={GET_ALL_HOLD}
          onRowClick={event.onRowClick}
          customActions={[
            <Button
              key={"customActionCreate"}
              outlined
              rounded
              color="main"
              onClick={() => event.onCreateClick()}
            >
              Nyt Hold
            </Button>,
          ]}
        />
      </div>
    </div>
  );
};

export default HoldPage;
