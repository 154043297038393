import React, { useContext, useEffect, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  KURSIST_TYPE_STATISTIK,
  KURSIST_TYPE_E_LEARN_STATISTIK,
} from "../../queries/statistik.gql";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ChartData,
  Point,
  BubbleDataPoint,
  PointElement,
  LineElement,
} from "chart.js";

const doughnutShadow = {
  id: "doughnutShadow",
  beforeDraw(chart, args, options) {
    const { ctx, chartArea } = chart;
    const {
      shadowColor,
      shadowColorInner,
      shadowOffsetX,
      shadowOffsetY,
      shadowBlur,
      shadowBlurInner,
      bgFillColor,
      display,
    } = options;

    if (!display) return;

    ctx.save();
    ctx.shadowColor = shadowColor;
    ctx.shadowOffsetX = -shadowOffsetX;
    ctx.shadowOffsetY = -shadowOffsetY;
    ctx.shadowBlur = shadowBlur;

    const centerX = (chartArea.left + chartArea.right) / 2;
    const centerY = (chartArea.top + chartArea.bottom) / 2;

    const radiusMid = Math.min(
      (chartArea.right - chartArea.left) / 2.3,
      (chartArea.bottom - chartArea.top) / 2.3
    );
    const radiusInner = Math.min(
      (chartArea.right - chartArea.left) / 7,
      (chartArea.bottom - chartArea.top) / 7
    );

    const second = Math.min(
      (chartArea.right - chartArea.left) / 3,
      (chartArea.bottom - chartArea.top) / 3
    );
    const yes = Math.min(
      (chartArea.right - chartArea.left) / 7,
      (chartArea.bottom - chartArea.top) / 7
    );

    ctx.beginPath();
    ctx.arc(centerX, centerY, radiusInner, 0, 2 * Math.PI); // Inner circle
    ctx.arc(centerX, centerY, radiusMid, 0, 2 * Math.PI, true); // Outer circle (clockwise to create an "inside" effect)
    ctx.closePath();
    ctx.fillStyle = bgFillColor; // Set the color of the shadow
    ctx.fill();

    ctx.save();

    ctx.shadowColor = shadowColorInner;
    ctx.shadowOffsetX = shadowOffsetX;
    ctx.shadowOffsetY = shadowOffsetY;
    ctx.shadowBlur = shadowBlurInner;

    ctx.beginPath();
    ctx.arc(centerX, centerY, yes, 0, 2 * Math.PI); // Inner circle
    ctx.arc(centerX, centerY, second, 0, 2 * Math.PI, true); // Outer circle (clockwise to create an "inside" effect)
    ctx.closePath();
    ctx.fillStyle = bgFillColor; // Set the color of the shadow
    ctx.fill();

    ctx.restore();
  },
};
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  // ChartDataLabels,
  doughnutShadow
);

// Style
import style from "./StatistikPage.module.scss";
import { useQuery } from "@apollo/client";
import { AppContext } from "../../context/AppContext";
import PieChart from "./PieChart";
import { useCatalog } from "../../hooks/useCatalog";
import CheckboxInput from "../../components/Inputs/CheckboxInput/CheckboxInput";
import DateInput from "../../components/Inputs/DateInput/DateInput";
import LineChart from "./LineChart";
import Alert from "../../assets/icons/indicators/Alert";
import { ThemeHandler } from "../../functions/ThemeHandler";
import Loading from "../../assets/icons/loaders/Loading";

const StatusCodes = {
  ok: [2, 3],
  uafklaret: [1],
  frafald: [0, 4],
};

interface PieDataType {
  gender: ChartData<
    "doughnut",
    (number | Point | [number, number] | BubbleDataPoint)[],
    unknown
  >;
  avias: ChartData<
    "doughnut",
    (number | Point | [number, number] | BubbleDataPoint)[],
    unknown
  >;
  type: ChartData<
    "doughnut",
    (number | Point | [number, number] | BubbleDataPoint)[],
    unknown
  >;
}

interface CustomLegendProps {
  data:
    | ChartData<
        "doughnut",
        (number | Point | [number, number] | BubbleDataPoint)[],
        unknown
      >
    | string;
  themeHandler: ThemeHandler;
}

interface ChartSectionProps {
  title: string;
  children?: React.ReactNode | React.ReactNode[];
  className: string;
}

function createGradient(colors) {
  var ctx = document.createElement("canvas").getContext("2d");
  const gradient = ctx.createLinearGradient(0, 0, 0, 300);
  colors.forEach((col, i) => {
    let val = i === 0 ? i : i / (colors.length - 1);
    gradient.addColorStop(val, colors[i]);
  });
  return gradient;
}

function createCssGradient(colors) {
  let gradientString = `45deg,`;
  colors.forEach((col, i) => {
    let val = i === 0 ? i : i / (colors.length - 1);
    gradientString += ` ${colors[i]}`;
    if (i != colors.length - 1) {
      gradientString += ",";
    }
  });
  return `linear-gradient(${gradientString})`;
}

const getColors = (label, themehandler) => {
  switch (label) {
    case "Mand":
      return [
        themehandler.getColor("chart-color-blue-light"),
        themehandler.getColor("chart-color-blue-dark"),
      ];
    case "Kvinde":
      return [
        themehandler.getColor("chart-color-red-light"),
        themehandler.getColor("chart-color-red-dark"),
      ];
    case "Andet":
      return ["#ff0000", "#f1ff00", "#00f0ff", "#0016ff", "#ff00b5"];
    case "Jobcenter":
      return [
        themehandler.getColor("chart-color-blue-light"),
        themehandler.getColor("chart-color-blue-dark"),
      ];
    case "Firmabetaler":
      return [
        themehandler.getColor("chart-color-green-light"),
        themehandler.getColor("chart-color-green-dark"),
      ];
    case "A-kasse":
      return [
        themehandler.getColor("chart-color-red-light"),
        themehandler.getColor("chart-color-red-dark"),
      ];
    case "Online":
      return [
        themehandler.getColor("chart-color-blue-light"),
        themehandler.getColor("chart-color-blue-dark"),
      ];
    case "Fremmøde":
      return [
        themehandler.getColor("chart-color-green-light"),
        themehandler.getColor("chart-color-green-dark"),
      ];
    case "uafklaret":
      return [
        themehandler.getColor("chart-color-yellow-light"),
        themehandler.getColor("chart-color-yellow-dark"),
      ];
    default:
      return [
        themehandler.getColor("chart-color-grey-light"),
        themehandler.getColor("chart-color-grey-dark"),
      ];
  }
};

const ChartSection: React.FC<ChartSectionProps> = ({
  title,
  children,
  className,
}) => {
  return (
    <div className={[style.ChartSection, className].join(" ")}>
      <h3>{title}</h3>
      <div>{children}</div>
    </div>
  );
};

const CustomLegend: React.FC<CustomLegendProps> = ({ data, themeHandler }) => {
  if (typeof data === "string") return null;
  if (!data || !data.datasets || !Array.isArray(data.datasets)) {
    return null; // Return null or some default content if data is not as expected
  }

  const elemetens = data.datasets[0].data.map((dat, i) => {
    if (dat === 0) return null;

    const BGcolor = createCssGradient(getColors(data.labels[i], themeHandler));
    const values: number[] = data.datasets[0].data as number[];

    if (!values || !Array.isArray(values)) {
      return null; // Return null or handle the case where values are not as expected
    }

    const maxValue: number = Math.max(...values);
    const barPercentage = Math.round((values[i] / maxValue) * 100);
    const percentage = Math.round(
      (values[i] / values.reduce((acc, val) => acc + val, 0)) * 100
    );

    return (
      <div className={style.custom_legend} key={i}>
        <div>
          <span>
            {(data.labels && data.labels[i] ? data.labels[i].toString() : "") +
              ` (${dat})`}
          </span>
          <span>{percentage}%</span>
        </div>
        <div
          style={{
            background: BGcolor,
            width: `${barPercentage}%`,
          }}
        ></div>
      </div>
    );
  });

  return <>{elemetens}</>;
};

let savedLineData: ChartData<"line", string, unknown> | string = "loading";
let savedPieData: PieDataType | string = "loading";
let savedTotalValues = {
  ok: 0,
  frafald: 0,
  uafklaret: 0,
};

let savedContent = [];
const StatistikPageSegm: React.FC<{ pageType: string }> = ({ pageType }) => {
  const { themeHandler, activeHoldHandler } = useContext(AppContext);
  const [dateWarning, setDateWarning] = useState<string | null>(null);
  const [content, setContent] = useState(savedContent);
  const catalog = useCatalog();

  const [totalValues, setTotalValues] = useState(savedTotalValues);

  const [useAktive, setUseAktive] = useState(true);
  const [selectDates, setSelectdates] = useState({
    fromDate: null,
    toDate: null,
  });

  const query =
    pageType === "e-learn"
      ? {
          query: KURSIST_TYPE_E_LEARN_STATISTIK,
          options: {
            pollInterval: 10000,
            variables: {
              fromDate: useAktive
                ? activeHoldHandler.aktiveELearning.fromDate
                : selectDates.fromDate,
              toDate: useAktive
                ? activeHoldHandler.aktiveELearning.toDate
                : selectDates.toDate,
            },
          },
        }
      : {
          query: KURSIST_TYPE_STATISTIK,
          options: {
            pollInterval: 10000,
            variables: {
              fromDate: useAktive
                ? activeHoldHandler.aktiveHold.fromDate
                : selectDates.fromDate,
              toDate: useAktive
                ? activeHoldHandler.aktiveHold.toDate
                : selectDates.toDate,
            },
          },
        };

  const { data, loading, error } = useQuery(query.query, query.options);

  useEffect(() => {
    if (loading || error || !data) return;

    savedContent = data[Object.keys(data)[0]];
    setContent(data[Object.keys(data)[0]]);
  }, [data, loading, error]);

  const [lineData, setLineData] = useState<
    ChartData<"line", string, unknown> | string
  >(savedLineData);
  // const [genderData, setGenderData] = useState<PieDataType>("loading");
  const [pieDatas, setPieDatas] = useState<PieDataType | string>(savedPieData);

  useEffect(() => {
    savedPieData = pieDatas;
    savedLineData = lineData;
    savedTotalValues = totalValues;
  }, [pieDatas, lineData, totalValues]);

  function formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("da-DK", options).format(date);
  }

  function getDatesBetweenRange(
    startDate: string,
    endDate: string,
    numberOfDates: number
  ): Date[] {
    const dates: Date[] = [];

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start.getTime() > end.getTime())
      setDateWarning("før dato er efter til datoen");
    else if (
      !useAktive &&
      (selectDates.fromDate === null || selectDates.toDate === null)
    )
      setDateWarning("datoer ikke valide");
    else setDateWarning(null);

    const interval = (end.getTime() - start.getTime()) / (numberOfDates - 1);

    for (let i = 0; i < numberOfDates; i++) {
      const newDate = new Date(start.getTime() + i * interval);
      dates.push(newDate);
    }

    return dates;
  }

  useEffect(() => {
    if (loading || error) return;

    const fromToDates =
      pageType == "e-learn"
        ? {
            from: useAktive
              ? activeHoldHandler.aktiveELearning.fromDate
              : selectDates.fromDate,
            to: useAktive
              ? activeHoldHandler.aktiveELearning.toDate
              : selectDates.toDate,
          }
        : {
            from: useAktive
              ? activeHoldHandler.aktiveHold.fromDate
              : selectDates.fromDate,
            to: useAktive
              ? activeHoldHandler.aktiveHold.toDate
              : selectDates.toDate,
          };

    const dates = getDatesBetweenRange(fromToDates.from, fromToDates.to, 8);

    const kursister = {
      ok: content.filter((kursist) =>
        StatusCodes.ok.includes(eval(kursist.kode))
      ),
      uafklaret: content.filter((kursist) =>
        StatusCodes.uafklaret.includes(eval(kursist.kode))
      ),
      frafald: content.filter((kursist) =>
        StatusCodes.frafald.includes(eval(kursist.kode))
      ),
    };

    ///// GENDER
    let genders = {
      dataSet: [],
      labels: [],
      colors: [],
    };

    catalog.genders.forEach((gender, i) => {
      genders.dataSet.push(
        content.filter((kursist) => kursist.Gender_id === gender.Gender_id)
          .length
      );
      genders.labels.push(gender.Gender);

      if (i >= catalog.genders.length - 1) {
        genders.dataSet.push(
          content.filter((kursist) => kursist.Gender_id === null).length
        );
        genders.labels.push("Køn ikke sat");

        genders.dataSet.push(
          content.filter(
            (kursist) =>
              kursist.Gender_id != null &&
              !catalog.genders
                .map((g) => g.Gender_id)
                .includes(kursist.Gender_id)
          ).length
        );
        genders.labels.push("Andet");
      }
    });

    // Create an array of objects with label and data properties
    const genderDataObjs = genders.labels.map((label, index) => ({
      label,
      data: genders.dataSet[index],
      colors: getColors(label, themeHandler),
    }));

    // Sort the array of objects in descending order based on the data values
    genderDataObjs.sort((a, b) => b.data - a.data);
    genders = {
      colors: genderDataObjs.map((g) => createGradient(g.colors)),
      dataSet: genderDataObjs.map((g) => g.data),
      labels: genderDataObjs.map((g) => g.label),
    };

    ///// GENDER

    //// AVIAS
    let avias = {
      dataSet: [],
      labels: [],
      colors: [],
    };

    catalog.ansoger_vias.forEach((avia, i) => {
      avias.dataSet.push(
        content.filter(
          (kursist) => kursist.Ansoger_via_id === avia.Ansoger_via_id
        ).length
      );
      avias.labels.push(avia.Ansoger_via);

      if (i >= catalog.ansoger_vias.length - 1) {
        avias.dataSet.push(
          content.filter((kursist) => kursist.Ansoger_via_id === null).length
        );
        avias.labels.push("ikke sat");
      }
    });

    const aviasDataObjs = avias.labels.map((label, index) => ({
      label,
      data: avias.dataSet[index],
      colors: getColors(label, themeHandler),
    }));

    // Sort the array of objects in descending order based on the data values
    aviasDataObjs.sort((a, b) => b.data - a.data);
    avias = {
      colors: aviasDataObjs.map((g) => createGradient(g.colors)),
      dataSet: aviasDataObjs.map((g) => g.data),
      labels: aviasDataObjs.map((g) => g.label),
    };

    //// AVIAS

    //// INDAGANGE
    let type = {
      dataSet: [],
      labels: [],
      colors: [],
    };

    let typer = ["Online", "Fremmøde"];

    const online = content.filter((k) => k.Online === true).length;
    const fremmøde = content.filter((k) => k.Online != true).length;

    const typeDataObjs = typer.map((label, index) => ({
      colors: getColors(label, themeHandler),
      label,
      data: index === 0 ? online : fremmøde,
    }));

    // Sort the array of objects in descending order based on the data values
    typeDataObjs.sort((a, b) => b.data - a.data);
    type = {
      colors: typeDataObjs.map((g) => createGradient(g.colors)),
      dataSet: typeDataObjs.map((g) => g.data),
      labels: typeDataObjs.map((g) => g.label),
    };
    //// INDAGANGE

    setPieDatas({
      gender: {
        labels: genders.labels,
        datasets: [
          {
            label: "Køn",
            data: genders.dataSet,
            backgroundColor: genders.colors,
          },
        ],
      },
      avias: {
        labels: avias.labels,
        datasets: [
          {
            label: "Ansøger via",
            data: avias.dataSet,
            backgroundColor: avias.colors,
          },
        ],
      },
      type: {
        labels: type.labels,
        datasets: [
          {
            label: "Type",
            data: type.dataSet,
            backgroundColor: type.colors,
          },
        ],
      },
    });

    let okValues = dates.map(
      (date, i) =>
        kursister.ok.filter(
          (kursist) => new Date(kursist.Startdato) <= new Date(date)
        ).length
    );
    let frafaldValues = dates.map(
      (date, i) =>
        kursister.frafald.filter(
          (kursist) => new Date(kursist.Startdato) <= new Date(date)
        ).length
    );
    let uafklaretValues: any = dates.map(
      (date, i) =>
        kursister.uafklaret.filter(
          (kursist) => new Date(kursist.Startdato) <= new Date(date)
        ).length
    );

    const dateLabels: string[] = dates.map((date) => formatDate(date));

    setTotalValues({
      ok: okValues[okValues.length - 1],
      frafald: frafaldValues[frafaldValues.length - 1],
      uafklaret: uafklaretValues[uafklaretValues.length - 1],
    });

    setLineData({
      labels: dateLabels,
      datasets: [
        {
          label: " Ok ",
          data: okValues.map((val) => val),
          backgroundColor: getColors("Fremmøde", themeHandler)[0],
          borderWidth: 2,
          borderColor: getColors("Fremmøde", themeHandler)[1],
          borderDash: [0, 0],
        },
        {
          label: " Frafald ",
          data: frafaldValues.map((val) => val),
          backgroundColor: getColors("Kvinde", themeHandler)[0],
          borderWidth: 2,
          borderColor: getColors("Kvinde", themeHandler)[1],
          borderDash: [0, 0],
        },
        {
          label: " Uafklaret ",
          data: uafklaretValues.map((val) => val),
          backgroundColor: getColors("uafklaret", themeHandler)[0],
          borderWidth: 2,
          borderColor: getColors("uafklaret", themeHandler)[1],
          borderDash: [6, 6],
        },
      ],
    });
  }, [content, themeHandler.activeTheme]);

  const event = {
    input: (name, val) => {
      const updateObj = { ...selectDates };
      updateObj[name] = val;
      setSelectdates(updateObj);
    },
    toggleActive: () => {
      setUseAktive(!useAktive);
    },
  };

  const inputFromDate = () => {
    if (pageType === "e-learn") {
      if (useAktive) return activeHoldHandler.aktiveELearning.fromDate;
      else return selectDates.fromDate;
    } else {
      if (useAktive) return activeHoldHandler.aktiveHold.fromDate;
      else return selectDates.fromDate;
    }
  };
  const inputToDate = () => {
    if (pageType === "e-learn") {
      if (useAktive) return activeHoldHandler.aktiveELearning.toDate;
      else return selectDates.toDate;
    } else {
      if (useAktive) return activeHoldHandler.aktiveHold.toDate;
      else return selectDates.toDate;
    }
  };
  return (
    <div className={style.section_wrap}>
      <div className={style.section_wrap_inputs}>
        <DateInput
          name="fromDate"
          label="Fra"
          value={inputFromDate()}
          onInput={event.input}
          className={style.section_wrap_inputs__fromdate}
          disabled={useAktive}
        />
        <DateInput
          name="toDate"
          label="Til"
          value={inputToDate()}
          onInput={event.input}
          className={style.section_wrap_inputs__todate}
          disabled={useAktive}
        />
        <CheckboxInput
          label="Brug aktive hold"
          name="filterEmpty"
          onInput={event.toggleActive}
          value={useAktive}
          className={style.section_wrap_inputs__empty}
        />
        {!dateWarning ? null : (
          <div className={style.alert}>
            <Alert />
            <p>{dateWarning}</p>
          </div>
        )}
      </div>
      <div className={style.component}>
        <ChartSection
          title="Kursister"
          className={style.ChartSection_kursister}
        >
          <div className={style.ok}>
            <span>{totalValues.ok}</span>
            <span>OK</span>
          </div>
          <div className={style.uafklaret}>
            <span>{totalValues.uafklaret}</span>
            <span>UAFKLARET</span>
          </div>
          <div className={style.frafald}>
            <span>{totalValues.frafald}</span>
            <span>FRAFALDT</span>
          </div>
        </ChartSection>
        <ChartSection title="Køn" className={style.ChartSection_gender}>
          {dateWarning != null ? null : loading ? (
            <Loading className={style.loading} />
          ) : (
            <>
              <div>
                <PieChart
                  data={
                    typeof pieDatas === "string" ? pieDatas : pieDatas.gender
                  }
                  loading={loading}
                  themeHandler={themeHandler}
                />
              </div>
              <div>
                <CustomLegend
                  data={
                    typeof pieDatas === "string" ? pieDatas : pieDatas.gender
                  }
                  themeHandler={themeHandler}
                />
              </div>
            </>
          )}
        </ChartSection>
        <ChartSection title="Type" className={style.ChartSection_type}>
          {dateWarning != null ? null : loading ? (
            <Loading className={style.loading} />
          ) : (
            <>
              <div>
                <PieChart
                  data={typeof pieDatas === "string" ? pieDatas : pieDatas.type}
                  loading={loading}
                  themeHandler={themeHandler}
                />
              </div>
              <div>
                <CustomLegend
                  data={typeof pieDatas === "string" ? pieDatas : pieDatas.type}
                  themeHandler={themeHandler}
                />
              </div>
            </>
          )}
        </ChartSection>
        <ChartSection title="Ansøger via" className={style.ChartSection_via}>
          {dateWarning != null ? null : loading ? (
            <Loading className={style.loading} />
          ) : (
            <>
              <div>
                <PieChart
                  data={
                    typeof pieDatas === "string" ? pieDatas : pieDatas.avias
                  }
                  loading={loading}
                  themeHandler={themeHandler}
                />
              </div>
              <div>
                <CustomLegend
                  data={
                    typeof pieDatas === "string" ? pieDatas : pieDatas.avias
                  }
                  themeHandler={themeHandler}
                />
              </div>
            </>
          )}
        </ChartSection>
        <ChartSection title="Overblik" className={style.ChartSection_overblik}>
          {loading ? (
            <div className={style.loading_chart}>
              <Loading className={style.loading} />
            </div>
          ) : (
            <LineChart lineData={lineData} />
          )}
        </ChartSection>
      </div>
    </div>
  );
};

export default StatistikPageSegm;
