import { ApolloClient, ApolloLink, FetchResult, HttpLink, InMemoryCache, NextLink, Observable, Operation, concat } from '@apollo/client'
import { getAccessToken } from './msal';
import { getEnvVariables } from './getEnvVariables';

const { apiUri } = getEnvVariables()
const httpLink = new HttpLink({ uri: apiUri })

const authMiddleware = new ApolloLink((operation: Operation, forward: NextLink) => {
  return new Observable(observer => {
    getAccessToken()
      .then(token => {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            "x-application-authorization": token ? `Bearer ${token}` : "",
          }
        }));

        forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(error => {
        console.log("auth error:", error);
        observer.error(error);
      });
  });
});

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache()
});

export default client