import { useContext, useEffect, useState } from "react";

// GRAPH CLIENT
import { graphClient } from "../../../config/graphClient";

// CONTEXT
import { AppContext } from "../../../context/AppContext";

// ASSETS
import UserSilhouette from "../../../assets/icons/indicators/UserSilhouette";
import Loading from "../../../assets/icons/loaders/Loading";
import ImageIcon from "../../../assets/icons/indicators/Image";

// COMPONETNT
import Button from "../../Button/Button";
import Input from "../../Inputs/Input";

// STYLE
import style from './UserInterface.module.scss'

export default function UserInterface() {
    const [loading, setLoading] = useState(false)
    const {userDataNeedsUpdate, userData} = useContext(AppContext)
    const [imageDataUrl, setImageDataUrl] = useState(userData.Img)
    const [updateObj, setUpdateObj] = useState({
        img: "",
        Fornavn: userData.Fornavn,
        Efternavn: userData.Efternavn,
        Brugernavn: userData.Brugernavn, 
    })

    const formatUserKeys = (key) => {
        switch(key) {
            case "Fornavn": return "givenName";
            case "Efternavn": return "surname";
            case "Brugernavn": return "displayName";
            case "ForetrukketNavn": return "preferredName";
        }
    }

    const event = {
        onChange: (e) => {
            const file = e.target.files[0];

            if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
              const reader = new FileReader();
              const img = new Image();
        
              reader.onload = (event) => {
                img.src = event.target.result;
        
                const waitForImageLoad = new Promise((resolve) => {
                    img.onload = () => {
                        resolve();
                    };
                });
        
                waitForImageLoad.then(() => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
        
                    // Set the canvas size to 240x240 pixels
                    canvas.width = 240;
                    canvas.height = 240;
        
                    // Calculate dimensions to maintain aspect ratio
                    let x = 0;
                    let y = 0;
                    let width = img.width;
                    let height = img.height;
        
                    if (img.width > img.height) {
                        width = img.height;
                        x = (img.width - img.height) / 2;
                    } else {
                        height = img.width;
                        y = (img.height - img.width) / 2;
                    }
        
                    // Draw the image onto the canvas (centered and cropped)
                    ctx.drawImage(img, x, y, width, height, 0, 0, 240, 240);

                    // Convert the canvas content to a Blob
                    canvas.toBlob((blob) => {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            const arrayBuffer = e.target.result;
                            setUpdateObj({...updateObj, img: arrayBuffer})
                        };
                        reader.readAsArrayBuffer(blob);
                    }, file.type);
        
                    // Convert the canvas content to a Data URL
                    const croppedDataUrl = canvas.toDataURL(file.type);

                    // Convert the Data URL back to ArrayBuffer
                    const binaryData = atob(croppedDataUrl.split(',')[1]);
                    const arrayBuffer = new ArrayBuffer(binaryData.length);
                    const view = new Uint8Array(arrayBuffer);
        
                    for (let i = 0; i < binaryData.length; i++) {
                        view[i] = binaryData.charCodeAt(i);
                    }
        
                    setImageDataUrl(croppedDataUrl);
                });
            };
        
                reader.readAsDataURL(file);
            } else {
                console.error('Please select a JPEG or PNG image.');
            }
        },
        onInput: (name, val) => {
            let newValueObj = {...updateObj}
            newValueObj[name] = val
            setUpdateObj(newValueObj)
        },
        update: async () => {
            setLoading(true)
            try {
                if (updateObj.img)
                    await event.uploadImg(updateObj.img)

                let updateFields = {}
                Object.keys(updateObj).forEach(key => {
                    if (key != "img" && updateObj[key] != userData[key] && updateObj[key] != "")
                        updateFields[formatUserKeys(key)] = updateObj[key]
                })
                if (Object.keys(updateFields).length <= 0) {
                    event.updateComplete();
                    return
                }

                await graphClient.api('/me').update(updateFields)
                event.updateComplete();
            } catch (err) {
                console.log("update user error:", err)
                return err
            }
        },
        uploadImg: async (img) => {
            const requestOptions = {
                headers: {
                    'Content-Type': 'image/png',
                },
            };

            try {
                await graphClient.api('/me/photo/$value').headers(requestOptions.headers).put(img);
                console.log('Image uploaded successfully');
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        },
        updateComplete: () => {
            userDataNeedsUpdate(true)
            setLoading(false)
        }
    }

    const makeProfileRoleName = () => {
        console.log(userData)
        if (userData.Roles.includes("administrate"))
            return "ADMINSTRATOR"
        return "BRUGER"
    }

    return (
        <div className={style.component}>
            {loading ? <div className={style.component_loading}><Loading /></div> : null}
            <div className={style.content}>
                <div className={style.img}>
                    <div className={style.img_preview} style={{backgroundImage: imageDataUrl ? `url(${imageDataUrl})` : "none", backgroundSize: "cover"}}>
                        {!imageDataUrl ? 
                            <div className={style.img_placeholder}>
                                <UserSilhouette className={style.img_placeholder_silhouette}/>
                            </div>
                        : null}
                        <input type="file" id="upload" accept="image/jpeg, image/png" onChange={(e) => event.onChange(e)} hidden/>
                        <label htmlFor="upload" className={style.img_preview_upload}>
                            <ImageIcon />
                        </label>
                    </div>
                </div>
                <div className={style.split}></div>
                <div className={style.inputWrap}>
                    <Input
                        value={updateObj.Fornavn}
                        name="Fornavn"
                        label="Fornavn"
                        onInput={event.onInput}
                        disabled
                    />
                    <Input 
                        value={updateObj.Efternavn}
                        name="Efternavn"
                        label="Efternavn"
                        onInput={event.onInput}
                        disabled
                    />
                    <Input 
                        value={updateObj.Brugernavn}
                        name="Brugernavn"
                        label="Brugernavn"
                        onInput={event.onInput}
                        disabled
                    />
                </div>
            </div>
            <div className={style.footer}>
                <span className={style.footer_account_type}>{makeProfileRoleName()}</span>
                <Button onClick={() => event.update()}>Gem</Button>
            </div>
        </div>
    )
}