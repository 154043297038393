import msalConfig from "./authconfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { getEnvVariables } from "./getEnvVariables";

export const msalInstance = new PublicClientApplication(msalConfig);

const clientId = getEnvVariables().clientId

export const apiAccessScope = `api://${clientId}/Api.Access`;

export const getAccessToken = async (scopes?: string[]) => {
    try {
        const account = msalInstance.getActiveAccount();

        if (!account)
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      
        return await msalInstance.acquireTokenSilent({
            account: account,
            scopes: scopes ? scopes : [apiAccessScope]
        }).then(response => response.accessToken)   
    } catch (error) {
        console.log("msal token error:", error)
    }
}