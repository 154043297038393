import { useState } from 'react';
import Input from '../Input';
import style from './CheckboxInput.module.scss';

export default function CheckboxInput(props) {

    const {name, disabled, onInput, title} = props

    let {value} = props
    value = value ? value : ""

    const event = {
        onInput: (name, val, e) => {
            if (e) {
                if (e.key === "Enter") {
                    onInput(name, val)
                }
                return
            }
            onInput(name, val)
        }
    }

    return (
        <Input {...props} checkboxStyle={style.checkbox}>
            <label className={style.component}>
                <input name={name} checked={value} disabled={disabled} type="checkbox" onKeyDown={(e) => event.onInput(name, !value, e)} onChange={(e) => event.onInput(name, !value)}></input>
                <span className={style.checkmark}></span>
            </label>
        </Input>
    )
}