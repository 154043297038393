import React from "react";

// Components
import StatistikPageSegm from "./StatistikPageSegm";

// Style
import style from "./StatistikPage.module.scss";

const StatistikPage: React.FC = () => {
  return (
    <div className={style.page}>
      <div>
        <h2>Hold</h2>
        <StatistikPageSegm pageType={"hold"} />
      </div>
      <div>
        <h2>E-learning</h2>
        <StatistikPageSegm pageType={"e-learn"} />
      </div>
    </div>
  );
};

export default StatistikPage;
