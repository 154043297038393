import React from "react"

import Arrow from "../../assets/icons/indicators/arrow"

import style from './Table.module.scss'

interface CellProps {
    title: any
    name: any
    sort: any
    onClick: any
    size: any
    align?: any
    sortAlias?: any
    contentType?: any
}

const Cell: React.FC<CellProps> = ({
    title,
    name,
    sort, 
    onClick, 
    size, 
    sortAlias,
    contentType
}) => {

    return (
        <th onClick={onClick} style={{ width: `${size}%`, minWidth: `${size}%` }}>
            {title}
            {sort.field !== name && sort.field !== sortAlias ? null : sort.dir ? <Arrow className={style.arrow_down} /> : <Arrow className={style.arrow_up} />}
        </th>
    )
}

export default Cell