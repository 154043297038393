import React from "react";
import Button from "../../components/Button/Button";
import style from "./ErrorPage.module.scss";

const ErrorPage: React.FC<{ errorMsg: string }> = ({ errorMsg }) => {
  const event = {
    retry: () => {
      window.location.reload();
    },
  };

  return (
    <div className={style.component}>
      <div className={style.content}>
        <p className={style.content__text}>{"Der gik noget galt :("}</p>
        <p className={style.content__text_alt}>{errorMsg}</p>
        <Button onClick={() => event.retry()}>prøv igen</Button>
      </div>
    </div>
  );
};

export default ErrorPage;
