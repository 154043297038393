import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// TYPES
import { Hold_Overview } from "../../../customGraphqlTypes";
import { homePageTableProps } from "./AktiveHoldPage";

// QUERIES
import { HOLD_OVERVIEW_E_LEARNING } from "../../queries/hold.gql";

// CONTEXT
import { AppContext } from "../../context/AppContext";

// ASSETS
import Loading from "../../assets/icons/loaders/Loading";
import Alert from "../../assets/icons/indicators/Alert";
import Link from "../../assets/icons/indicators/Link";

// COMPONENTS
import HomePagePreviews from "./HomePagePreviews";
import DateInput from "../../components/Inputs/DateInput/DateInput";
import CheckboxInput from "../../components/Inputs/CheckboxInput/CheckboxInput";

// Style
import style from "./HomePage.module.scss";

let savedOverViewData = null;
let savedSum = null;

const HomePageELearningTable2: React.FC<homePageTableProps> = ({
  yellowFields,
  catalog,
  StatusCodes,
}) => {
  const { formatHandler, activeHoldHandler } = useContext(AppContext);
  const { aktiveELearning } = activeHoldHandler;
  const [overviewData, setOverviewData] = useState(savedOverViewData);
  const [sum, setSum] = useState(
    savedSum
      ? savedSum
      : {
          alle: 0,
          ok: 0,
          frafald: 0,
          yellowValues: [0, 0, 0],
        }
  );
  const navigate = useNavigate();
  const [SearchDate, setSearchDate] = useState({
    fromDate: aktiveELearning.fromDate,
    toDate: aktiveELearning.toDate,
    filterEmpty: aktiveELearning.filterEmpty,
  });

  const { data, loading, error } = useQuery(HOLD_OVERVIEW_E_LEARNING, {
    pollInterval: 10000,
    variables: SearchDate,
  });

  useEffect(() => {
    if (error || loading || !catalog.kh_statuser.length) return;

    const formatKursistStatus = (hold) => {
      let kursistStatusIds = hold.Kursister_til_hold.map(
        (kh) => kh.Kh_Status_id
      );
      let statuser = kursistStatusIds.map((id) =>
        catalog.kh_statuser.find((status) => status.Kh_Status_id === id)
      );
      return {
        alle: statuser.filter((s) => StatusCodes.alle.includes(s.Kode)).length,
        frafald: statuser.filter((s) => StatusCodes.frafald.includes(s.Kode))
          .length,
        ok: statuser.filter((s) => StatusCodes.ok.includes(s.Kode)).length,
        yellowValues: yellowFields.map(
          (yf) => statuser.filter((rs) => rs.Kh_Status === yf.Kh_Status).length
        ),
      };
    };

    const formatData = data.hold_overview_e_learning
      .map((hold) => {
        const statusObj = formatKursistStatus(hold);
        if (SearchDate.filterEmpty && statusObj.alle < 1) return null;
        return {
          hold: hold.Holdnavn,
          id: hold.Hold_id,
          ...statusObj,
        };
      })
      .filter((res) => res);

    let [sumAll, sumOk, sumFrafald] = [0, 0, 0];

    let sumYellows = new Array(yellowFields.length);
    sumYellows.fill(0);

    formatData.forEach((fdata) => {
      sumAll += fdata.alle;
      sumOk += fdata.ok;
      sumFrafald += fdata.frafald;

      fdata.yellowValues.forEach((yf, index) => {
        sumYellows[index] += yf;
      });
    });

    const sumData = {
      alle: sumAll,
      ok: sumOk,
      frafald: sumFrafald,
      yellowValues: sumYellows,
    };

    savedSum = sumData;
    setSum(savedSum);

    savedOverViewData = formatData;
    setOverviewData(savedOverViewData);
  }, [data, loading]);

  const event = {
    input: (name: string, value: string) => {
      const updateObj = { ...SearchDate };
      const valueString = value.toString();
      updateObj[name] = valueString;
      if (name === "filterEmpty")
        activeHoldHandler.setFilterEmpty("eLearn", valueString);
      else activeHoldHandler.setDate("eLearn", name, valueString);
      setSearchDate(updateObj);
    },
    goToHold: (id: string) => {
      const location = "/hold";
      navigate(location, {
        replace: true,
        state: { initalSearch: `Hold_id=${id}`, select: id },
      });
    },
  };

  return (
    <>
      <div className={style.page_inputs}>
        <DateInput
          name="fromDate"
          label="Fra"
          value={SearchDate.fromDate}
          onInput={event.input}
          className={style.page_inputs__fromdate}
        />
        <DateInput
          name="toDate"
          label="Til"
          value={SearchDate.toDate}
          onInput={event.input}
          className={style.page_inputs__todate}
        />
        <CheckboxInput
          label="Filtrer tomme E-learning fra"
          name="filterEmpty"
          onInput={event.input}
          value={SearchDate.filterEmpty === "true"}
          className={style.page_inputs__empty}
        />
        {aktiveELearning.warning ? (
          <div className={style.alert}>
            <Alert />
            <p>{aktiveELearning.warning}</p>
          </div>
        ) : null}
      </div>
      <HomePagePreviews yellowFields={yellowFields} sum={sum} />
      {loading || catalog.kh_statuser.length <= 0 ? (
        <div className={style.card}>
          <div className={style.loader}>
            <Loading />
          </div>
        </div>
      ) : error ? (
        <div className={style.card}>
          <span>{error.message}</span>
        </div>
      ) : !overviewData ? (
        <div>
          <span>Der er noget galt</span>
        </div>
      ) : overviewData.length <= 0 ? (
        <div className={style.card}>
          <span>
            Ingen hold mellem {formatHandler.date(SearchDate.fromDate)} og{" "}
            {formatHandler.date(SearchDate.toDate)}
          </span>
        </div>
      ) : (
        <div className={style.card}>
          <div className={style.tableWrap}>
            <table className={style.table}>
              <tbody>
                <tr>
                  {yellowFields.map((status, i) => (
                    <th key={i + "yellowFields"}>{status.Kh_Status}</th>
                  ))}
                  <th>Hold</th>
                  <th>ALLE</th>
                  <th>OK</th>
                </tr>
                {overviewData === null || !overviewData.length
                  ? null
                  : overviewData.map((data, i) => (
                      <tr key={i + "yellowFields"}>
                        {data.yellowValues.map((yv, i) => (
                          <td key={i + "yellowFieldsOV"}>{yv}</td>
                        ))}
                        <td className={style.hold_coloumn}>
                          <div>
                            <p>{data.hold}</p>
                            <Link onClick={() => event.goToHold(data.id)} />
                          </div>
                        </td>
                        <td>{data.alle}</td>
                        <td>{data.ok}</td>
                      </tr>
                    ))}
                <tr className={style.sum_row}>
                  {sum.yellowValues.map((yv, i) => (
                    <td key={i + "yellowFieldsOV"}>{yv}</td>
                  ))}
                  <td></td>
                  <td>{sum.alle}</td>

                  <td>{sum.ok}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePageELearningTable2;
