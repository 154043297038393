import {
  Font,
  Document,
  Page,
  View,
  Image,
  Text,
  PDFViewer,
} from "@react-pdf/renderer";
import { styling } from "../content/content";
import logo from "../assets/itucation.png";
import bg from "../assets/bg.jpg";
import underskrift from "../assets/underskrift.jpg";

import Arial from "../assets/fonts/ARIAL.TTF";
import ArialBD from "../assets/fonts/ARIALBD.TTF";
import AriBLK from "../assets/fonts/ARIBLK.TTF";
import ArialLgt from "../assets/fonts/ARIALLGT.TTF";

Font.register({
  family: "Arial",
  fonts: [
    { src: ArialLgt, fontWeight: 100 },
    { src: Arial, fontWeight: 300 },
    { src: ArialBD, fontWeight: 600 },
    { src: AriBLK, fontWeight: 900 },
  ],
});

Font.registerHyphenationCallback((word) => [word]);
//   const chunkSubstr = (str, size) => {
//     const numChunks = Math.ceil(str.length / size);
//     const chunks = new Array(numChunks);

//     for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
//       chunks[i] = str.substr(o, size);
//     }

//     return chunks;
//   };

//   Font.registerHyphenationCallback((word) => {
//     if (word.length > 16) {
//       return chunkSubstr(word, 14);
//     } else {
//       return [word];
//     }
//   });
export default function PdfDisplay({ content }) {
  const formatStyle = (styleObj, capitalize) => {
    let baseStyleObj = {
      ...styleObj,
      fontWeight: styleObj.fontWeight,
      marginTop: styleObj.marginTop + "px",
      fontSize: styleObj.fontSize + "px",
      width: styleObj.width + "px",
    };

    if (capitalize) baseStyleObj.textTransform = "capitalize";

    return baseStyleObj;
  };

  const formatListSubWrapStyle = (styleObj) => {
    if (!styleObj) return "";
    return {
      marginTop: styleObj.marginTop + "px",
      gap: styleObj.spacingtitles + "px",
      fontSize: styleObj.titelFontSize + "px",
      maxWidth: styleObj.minWidth + "px",
      minWidth: styleObj.minWidth + "px",
      marginLeft: styleObj.marginLeft + "px",
    };
  };

  const formatListSubSubWrapStyle = (styleObj) => {
    return {
      marginTop: styleObj.spacingPoints + "px",
      gap: styleObj.spacingPoints + "px",
      fontSize: styleObj.pointFontSize + "px",
    };
  };

  const formatListSubSubSubWrapStyle = (styleObj) => {
    return {
      marginTop: styleObj.spacingSubPoints + "px",
      gap: styleObj.spacingSubPoints + "px",
      fontSize: styleObj.subPointFontSize + "px",
    };
  };

  const widthFix = (lvl, obj) => {
    let widthSplit = obj.maxWidth.split("px");
    let width = eval(widthSplit[0]);
    if (lvl === 0) {
      width = width - 15;
    } else {
      width = width;
    }

    return `${width}px`;
  };

  const getContent = (path) => {
    let obj = { ...content };

    if (!Array.isArray(path)) return;
    let currentObj = obj;

    for (const key of path.slice(0, -1)) {
      if (!currentObj.hasOwnProperty(key)) {
        console.error(`Key "${key}" not found in the object`);
        return null;
      }
      currentObj = currentObj[key];
    }

    const lastKey = path[path.length - 1];
    return currentObj[lastKey];
  };

  const getListStyle = (style, lvl) => {
    if (lvl === 1) return formatListSubSubSubWrapStyle(style);
    else return formatListSubSubWrapStyle(style);
  };

  const PdfText = ({ path, optional, capitalize }) => {
    const content = getContent(path);
    if (optional && content.value === "") return null;
    return (
      <View style={styling.title}>
        <Text style={{ ...formatStyle(content.style, capitalize) }}>
          {content.value}
        </Text>
      </View>
    );
  };

  const CourseBreaker = ({ path }) => {
    const content = getContent(path);
    return (
      <View style={{ ...styling.courseBreaker, ...formatStyle(content.style) }}>
        <View style={styling.courseBreaker_bar}></View>
        <Text>{content.value}</Text>
        <View style={styling.courseBreaker_bar}></View>
      </View>
    );
  };

  const ListText = ({ path, item, lvl, originalStyling }) => {
    if (!lvl) lvl = 0;
    if (!item && path) {
      item = getContent(path);
      originalStyling = item.style;
    }

    if (!item || !("content" in item) || item.content.length < 1) return null;

    if (path) {
      return (
        <View style={{ ...formatListSubWrapStyle(item.style) }}>
          {item.content.map((item_sub, i) => (
            <View key={"item_sub" + i + "path"}>
              <Text style={{ fontWeight: "600" }}>{item_sub.value}</Text>
              <ListText
                item={item_sub}
                originalStyling={{
                  ...originalStyling,
                  maxWidth: formatListSubWrapStyle(item.style).maxWidth,
                  minWidth: formatListSubWrapStyle(item.style).minWidth,
                }}
              />
            </View>
          ))}
        </View>
      );
    }
    return (
      <View
        style={{
          ...getListStyle(originalStyling, lvl),
          ...styling.courseContent_column_sub,
          maxWidth: widthFix(lvl, originalStyling),
          minWidth: widthFix(lvl, originalStyling),
        }}
      >
        {item.content.map((item_sub, i) => (
          <View
            key={"item_sub" + i + "nonpath"}
            style={
              lvl === 1
                ? { paddingLeft: "10px", width: "100%" }
                : { width: "100%" }
            }
          >
            <View style={styling.listValue}>
              <Text>{lvl === 1 ? "-" : "•"}</Text>
              <Text>{item_sub.value}</Text>
            </View>
            <ListText
              item={item_sub}
              lvl={lvl + 1}
              originalStyling={{ ...originalStyling }}
            />
          </View>
        ))}
      </View>
    );
  };

  return (
    <Document>
      <Page size={"A4"} style={styling.page}>
        <View style={styling.baseContent}>
          <PdfText path={["base", "titel"]} />
          <PdfText path={["base", "toCertify"]} />
          <PdfText path={["base", "name"]} capitalize />
          <PdfText path={["base", "date"]} />
          <PdfText path={["base", "course"]} />
          <PdfText path={["base", "course_sub_1"]} optional={true} />
          <PdfText path={["base", "course_sub_2"]} optional={true} />
          <CourseBreaker path={["base", "course_content"]} />
          <View style={styling.courseContent}>
            <View style={styling.courseContent_column}>
              <ListText path={["coloumn1"]} />
            </View>
            <View style={styling.courseContent_column}>
              <ListText path={["coloumn2"]} />
            </View>
          </View>
        </View>
        <Image fixed={true} src={logo} style={styling.logo} />
        <Image fixed={true} src={bg} style={styling.background} />
        {"page2" in content ? (
          <Text style={styling.pageNr}>1/2</Text>
        ) : (
          <View style={styling.underskrift}>
            <Image src={underskrift} style={styling.underskrift_underskrift} />
            <View style={styling.underskrift_line} />
            <Text style={styling.underskrift_navn}>
              {getContent(["base", "leader_name"])}
            </Text>
            <Text style={styling.underskrift_titel}>
              {getContent(["base", "leader_name_sub"])}
            </Text>
          </View>
        )}
      </Page>
      {!("page2" in content) ? null : (
        <Page size={"A4"} style={styling.page}>
          <View style={styling.baseContent}>
            <View style={styling.courseContent}>
              <View style={styling.courseContent_column}>
                <ListText path={["page2", "coloumn1"]} />
              </View>
              <View style={styling.courseContent_column}>
                <ListText path={["page2", "coloumn2"]} />
              </View>
            </View>
          </View>
          <Image fixed={true} src={logo} style={styling.logo} />
          <Image fixed={true} src={bg} style={styling.background} />
          <View style={styling.secondPageUnderskrift}>
            <Image src={underskrift} style={styling.underskrift_underskrift} />
            <View style={styling.underskrift_line} />
            <Text style={styling.underskrift_navn}>
              {getContent(["base", "leader_name"])}
            </Text>
            <Text style={styling.underskrift_titel}>
              {getContent(["base", "leader_name_sub"])}
            </Text>
          </View>
          <Text style={styling.pageNr}>2/2</Text>
        </Page>
      )}
    </Document>
  );
}
