import { useEffect, useState } from "react"

// QUERIES
import { KURSIST_COUNT, UPDATE_KURSISTER, KURSISTER } from '../../../../queries/kursister.gql'
import { CREATE_GENDER, UPDATE_GENDER, DELETE_GENDER } from '../../../../queries/catalogs/genders.gql'
import { ALL_CATALOGS } from '../../../../queries/catalogs/allCatalogs.gql'

// HOOKS
import { useCatalog } from "../../../../hooks/useCatalog"

// COMPONENTS
import { AdminInterfaceTemplate } from "../AdminInterfaceTemplate/AdminInterfaceTemplate"
import CheckboxInput from "../../../Inputs/CheckboxInput/CheckboxInput"
import Input from "../../../Inputs/Input"

let lastData = [] 
let changeTimeStamp = new Date().valueOf();
const primaryKey = "Gender_id"

export default function AdminInterfaceGender() {
    const catalogs = useCatalog()
    const content = catalogs.genders

    const [newContent, setNewContent] = useState(content)
    const [searchString, setSearchString] = useState("")
    const [sort, setSort] = useState({field: "", dir: false})
    const [selected, setSelected] = useState(null)
    const [newObj, setNewObj] = useState(null)
    const [scrollToBottom, setScrollToBottom] = useState(false)
    const [customLoading, setCustomLoading] = useState(false)

    useEffect(() => {
        const filtered = AdminInterfaceTemplate.functions
        .localSearch(searchString, content, ["Gender"])
        setNewContent(filtered)
    },[searchString])

    useEffect(() => {
        const sorted = AdminInterfaceTemplate.functions.localSort(newContent, sort)
        setNewContent(sorted)
    },[sort])

    useEffect(() => {
        if (customLoading)
            setCustomLoading(false)

        if (sort.field) {
            const sorted = AdminInterfaceTemplate.functions.localSort(content, sort)
            setNewContent(sorted)
        } else {
            setNewContent(content)
        }
        if (content.toString() != lastData.toString()) {
            changeTimeStamp = new Date().valueOf();
            lastData = content
        }
    },[content])

    const event = {
        search: (name, val) => {
            setSearchString(val)
        },
        sort: (field) => {
            setSort({field: field, dir: !sort.dir})
        },
        rowClick: (val, index) => {
            setNewObj(null)
            setSelected(val)
        },
        UpdateInput: (name, val) => {
            let updateObj = {...selected}
            updateObj[name] = val
            setSelected(updateObj)
        },
        createInput: (name, val) => {
            let updateObj = {...newObj}
            updateObj[name] = val
            setNewObj(updateObj)
        },
        new: () => {
            setSelected(null)
            setNewObj({
                Gender: "",
                Aktivt: false
            })
        },
        createSuccess: (res) => {
            setSearchString("")
            setSelected(res)
            setScrollToBottom(true)
        },
        createStart: () => {
            setCustomLoading(true)
        },
        updateSuccess: (id) => {
            setSearchString("")
            setCustomLoading(false)
        },
        updateStart: () => {
            setCustomLoading(true)
        },
        deleteStart: () => {
            const htmlRow = document.querySelector(`[data-primary-key="${primaryKey}${selected[primaryKey]}"]`)
            htmlRow.classList.add("inactive_load")
            setCustomLoading(true)
        },
        deleteSuccess: () => {
            setSearchString("")
            setSelected(null)
            setNewObj(null)
        },
        moveStart: () => {
            setCustomLoading(true)
        },
        moveSuccess: () => {
            setSearchString("")
            setCustomLoading(false)
        }
    }

    return (
        <AdminInterfaceTemplate.component>
            <AdminInterfaceTemplate.content>
                <AdminInterfaceTemplate.table 
                    primaryKey={primaryKey}
                    changeTimeStamp={changeTimeStamp}
                    scrollToBottom={scrollToBottom}
                    setScrollToBottom={setScrollToBottom}
                    onNew={event.new}
                    searchFunction={event.search}
                    searchString={searchString}
                    content={newContent}
                    selected={selected}
                    rowClick={event.rowClick}
                    rowSelect={!selected ? null : {field: primaryKey, value: selected.Gender_id}}
                    cells={[
                        {
                            size: 0,
                            title: "ID",
                            name: "Gender_id",
                            sort: sort,
                            onClick: () => event.sort("Gender_id")
                        },
                        {
                            size: 1,
                            title: "Gender",
                            name: "Gender",
                            sort: sort,
                            onClick: () => event.sort("Gender")
                        },
                        {
                            size: 0,
                            title: "Aktiv",
                            name: "Aktivt",
                            sort: sort,
                            onClick: () => event.sort("Aktivt")
                        }
                    ]}
                />
                {/* `{{count}} Kursister er medlem af {{title}}, sletning er ikke opfordret! */}
                {selected ? 
                    <AdminInterfaceTemplate.update
                        variables={{
                            id: selected.Gender_id,
                            input: {
                                Gender: selected.Gender,
                                Aktivt: selected.Aktivt
                            }
                        }}
                        updateQuery={UPDATE_GENDER}
                        deleteQuery={DELETE_GENDER}
                        refetchQueries={[ALL_CATALOGS]}
                        title={selected.Gender}
                        id={selected.Gender_id}
                        countQueryObj={{
                            query: KURSIST_COUNT,
                            variables: {
                                conditions: {
                                    Gender: selected.Gender_id
                                }
                            },
                            loading: "Checkker Køn...",
                            warning: "{{count}} kursister identificere sig som {{title}}, Sletning er ikke opfordret!",
                            goTo: {
                                text: "Find kursisterne",
                                path: "/kursister",
                                value: `Køn_id=${selected.Gender_id}`
                            },
                            move: {
                                query: UPDATE_KURSISTER,
                                variables: {
                                    field: `Gender`,
                                    from: selected.Gender_id,
                                    title: selected.Gender
                                },
                                refetchQueries: [KURSISTER, KURSIST_COUNT, ALL_CATALOGS],
                                onMoveStart: event.moveStart,
                                onMoveSuccess: event.moveSuccess,
                                text: "Skift kursisternes køn",
                                menuText: "Skift køn for {{count}} kursister?",
                                selectOptions: content.map(obj => ({value: obj.Gender_id, title: obj.Gender})),
                            },
                            delete: {
                                text: "Handlingen vil forsøge at nulstille køn'et værdierne for {{count}} kursister, er du sikker på du ikke vil ændre køn værdierne først?"
                            }
                        }}
                        customLoading={customLoading}
                        onUpdateStart={event.updateStart}
                        onUpdateSuccess={event.updateSuccess}
                        onDeleteStart={event.deleteStart}
                        onDeleteSuccess={event.deleteSuccess}
                    >
                        <Input 
                            label="Gender" 
                            name="Gender" 
                            value={selected.Gender} 
                            onInput={event.UpdateInput} 
                        />
                        <CheckboxInput 
                            label="Aktiv"
                            name="Aktivt"
                            value={selected.Aktivt}
                            onInput={event.UpdateInput} 
                        />
                    </AdminInterfaceTemplate.update>
                : newObj ?
                    <AdminInterfaceTemplate.create
                        variables={{
                            input: {...newObj}
                        }}
                        createQuery={CREATE_GENDER}
                        refetchQuery={ALL_CATALOGS}
                        customLoading={customLoading}
                        onCreateSuccess={event.createSuccess}
                        onCreateStart={event.createStart}
                    >
                        <Input 
                            label="Gender" 
                            name="Gender" 
                            value={newObj.Gender} 
                            onInput={event.createInput} 
                        />
                        <CheckboxInput 
                            label="Aktiv"
                            name="Aktivt"
                            value={newObj.Aktivt}
                            onInput={event.createInput} 
                        />
                    </AdminInterfaceTemplate.create>
                : <AdminInterfaceTemplate.empty />}

            </AdminInterfaceTemplate.content>
        </AdminInterfaceTemplate.component>
    )
}
