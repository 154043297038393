import React from "react";
import style from './Logo.module.scss'

export default function Logo(props: { className: string | undefined }) {
    return (
        <svg version="1.1" className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 560.68 140.17">
            <g className={style.red_part}>
                <path d="M123.33,109.74v14.04H15.84V16.04h107.49v28.6c0,0,5.87,1.02,8.94-1.28c3.06-2.3,3.83-3.83,3.83-6.13
                    s0-25.79,0-25.79s-1.79-7.53-7.53-7.53c-5.74,0-116.04,0-116.04,0s-8.87,2.3-8.87,8.87s0,115.09,0,115.09s1.28,8.27,8.27,8.27
                    s115.24,0,115.24,0s9.88-1.53,9.88-9.88s0-8.35,0-8.35S136.86,109.74,123.33,109.74z"/>
                <path d="M39.33,44.89h13.02v-12C52.35,32.89,39.33,31.36,39.33,44.89z"/>
                <path d="M52.35,51.27c0,0-13.02-2.55-13.02,8.94s0,42.89,0,42.89s13.02,0.51,13.02-11.49S52.35,51.27,52.35,51.27z"/>
                <path d="M82.48,52.3V32.84c0,0-13.28-1.22-13.28,12.05s0,49.53,0,49.53s1.02,7.66,10.21,7.66s29.62,0,29.62,0
                    s-0.51-12-8.43-12s-18.64,0-18.64,0V64.3h27.06c0,0,1.02-12-8.68-12S82.48,52.3,82.48,52.3z"/>
            </g>
            <g className={style.other_part}>
                <path d="M137.04,52.04c0,0-12.94-1.02-12.94,8.94s0,32.17,0,32.17s1.21,8.94,8.36,8.94c7.15,0,40.09,0,40.09,0s8.36-0.77,8.36-8.81
                    s0-32.55,0-32.55s-1.56-8.63-8.63-8.63s-3.75,0-3.75,0l-0.38,37.5l-31.1,0.49V52.04z"/>
                <path d="M251.79,64.95c0,0,1.02-12.94-8.94-12.94c-9.96,0-37.03,0-37.03,0s-8.94,1.21-8.94,8.36s0,33.71,0,33.71
                    s0.77,8.36,8.81,8.36s37.41,0,37.41,0s8.63-1.56,8.63-8.63s0-3.75,0-3.75l-42.36-0.38l-0.49-24.72H251.79z"/>
                <path d="M312.87,93.86l0-28.91h-33.94l0.49,24.72l26.14,0.38c0,0,0-3.32,0,3.75s-8.63,8.63-8.63,8.63s-13.15,0-21.19,0
                    c-8.04,0-8.81-8.36-8.81-8.36s0-26.56,0-33.71s8.94-8.36,8.94-8.36s31.56,0,41.51,0c9.96,0,8.94,12.94,8.94,12.94l-0.8,37.49
                    C325.51,102.44,315.44,103.35,312.87,93.86z"/>
                <path d="M354.12,52.3V32.84c0,0-13.28-1.22-13.28,12.05s0,49.53,0,49.53s1.02,7.66,10.21,7.66s29.62,0,29.62,0s-0.51-12-8.43-12
                    c-7.91,0-18.64,0-18.64,0V64.3h27.06c0,0,1.02-12-8.68-12C362.29,52.3,354.12,52.3,354.12,52.3z"/>
                <path d="M408.81,52.25c0,0-13.02-2.55-13.02,8.94s0,42.89,0,42.89s13.02,0.51,13.02-11.49S408.81,52.25,408.81,52.25z"/>
                <path d="M395.79,45.61h13.02v-12C408.81,33.61,395.79,32.08,395.79,45.61z"/>
                <path d="M542.69,102.24c0,0,12.94,1.02,12.94-8.94s0-32.17,0-32.17s-1.21-8.94-8.36-8.94c-7.15,0-40.09,0-40.09,0
                    s-8.36,0.77-8.36,8.81s0,32.55,0,32.55s1.56,8.63,8.63,8.63c7.07,0,3.75,0,3.75,0l0.38-37.5l31.1-0.49V102.24z"/>
                <path d="M470.29,102.23c0,0,12.94,1.02,12.94-8.94c0-9.96,0-32.17,0-32.17s-1.21-8.94-8.36-8.94c-7.15,0-42.76,0-42.76,0
                    s-8.36,0.77-8.36,8.81s0,32.55,0,32.55s1.56,8.63,8.63,8.63s37.91,0,37.91,0V90.11l-34.04-0.2l0.26-25.23l33.78-0.49v25.92"/>
            </g>
        </svg>
    )
}


