
import React, { useContext, useEffect } from 'react'


// CONTEXT
import { AppContext } from '../../context/AppContext'

// ASSETS
import Profile from '../../assets/icons/indicators/Profile'
import Logout from '../../assets/icons/indicators/Logout'

// STYLE
import style from './UserMenu.module.scss'
import UserInterface from '../interfaces/UserInsterface/UserInterface'

const UserMenu: React.FC = () =>  {
    const { popupHandler, userHandler, dropDownMenuHandler } = useContext(AppContext)
    
    function handleLogout() {
        userHandler.logout()
    }

    useEffect(() => {
        setTimeout(() => {
            window.addEventListener("mousedown", closeUserMenu)
        }, 250);
        return () => window.removeEventListener("mousedown", closeUserMenu)
    },[])

    const closeUserMenu = (e) => {
        if (!e.target.className)
            dropDownMenuHandler.toggleProfileMenu(false)
        else if (!String(e.target.className).includes("UserMenu_button"))
            dropDownMenuHandler.toggleProfileMenu(false)
    }

    const openUserInterFace = () => {
        dropDownMenuHandler.toggleProfileMenu(false)
        popupHandler.add(<UserInterface />)
    }

    return (
        <div className={style.component}>
            <div className={style.button} onClick={() => openUserInterFace()}>
                <span>Profil</span>
                <Profile className={style.button_profile_icon}/>
            </div>
            <div className={style.button} onClick={() => handleLogout()}>
                <span>Log ud</span>
                <Logout className={style.button_logout_icon}/>
            </div>
        </div>
    )
}

export default UserMenu