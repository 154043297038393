import React, { useEffect } from "react";

// COMPONENTS
import TabInterface from "../TabInterface/TabInterface";
import KursistInterfaceCreate from "./KursistInterfaceCreate/KursistInterfaceCreate";
import KursistInterfaceUpdate from "./KursistInterfaceUpdate/KursistInterfaceUpdate";


interface KursistInterfaceProps {
    kursist?: any;
    createMode?: boolean;
    scrollable?: boolean;
    startPolling?: (pollInterval: number) => void
    stopPolling?: () => void
}

const KursistInterface: React.FC<KursistInterfaceProps> = ({
    kursist, 
    createMode, 
    scrollable, 
    startPolling, 
    stopPolling
}) => {
    useEffect(() => {
        if (!stopPolling || !startPolling)
            return
        stopPolling()
        return () => {
            startPolling(5000)
        }
    },[])

    return (
        <TabInterface scrollable={false} tabs={[
            "Kursist",
        ]} size={undefined}>
            {createMode ? <KursistInterfaceCreate /> : <KursistInterfaceUpdate kursist={kursist} />}
        </TabInterface> 
    )
}

export default KursistInterface
