// STYLE
import { useMutation, useQuery } from '@apollo/client'
import { forwardRef, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Arrow from '../../../../assets/icons/indicators/arrow'
import Loading from '../../../../assets/icons/loaders/Loading'
import { AppContext } from '../../../../context/AppContext'
import Button from '../../../Button/Button'
import Input from '../../../Inputs/Input'
import CustomSelectInput from '../../../Inputs/SelectInput/CustomSelectInput'
import Cell from '../../../Table/Cell'
import Table from '../../../Table/Table'
import style from './AdminInterfaceTemplate.module.scss'

export const AdminInterfaceTemplate = {

    component: ({children}) => {
        return (
            <div className={style.component}>
                {children}
            </div>
        )
    },
    content: ({children}) => {
        return (
            <div className={style.content}>
                {children.map((section, i) => (
                    [
                        section,
                        i != children.length-1 ? <div key={"split-"+i} className={style.content_split}></div> : null
                    ]
                ))}
            </div>
        )
    },
    table: (props) => {
        const tableRef = useRef(null)
        const selectedRef = useRef(null);
        const {
            primaryKey,
            cells, 
            content, 
            searchFunction, 
            searchString, 
            rowClick, 
            rowSelect, 
            onNew,
            scrollToBottom,
            changeTimeStamp,
            selected,
            setScrollToBottom
        } = props
        
        useEffect(() => {
            if (tableRef.current && scrollToBottom && selected && selectedRef.current) {
                selectedRef.current.scrollIntoView({behavior: "smooth", block: "center"})
                setScrollToBottom(false)
            }
        },[content])

        return (
            <div className={style.table_section}>
                <div className={style.table_heading}>
                    <div>
                        <Input style="search" placeholder="søg" value={searchString} onInput={searchFunction} resetButton={true}/>
                    </div>
                    <Button outlined rounded color="main" onClick={() => onNew()}>Opret</Button>
                </div>
                <div className={style.scrollContainer} ref={tableRef}>
                    <Table
                        primaryKey={primaryKey}
                        loading={false}
                        error={null}
                        content={content}
                        rowSelect={rowSelect}
                        changeTimeStamp={changeTimeStamp}
                        onRowClick={(val, index, e) => rowClick(val, index, e)}
                        ref={selectedRef}
                    >
                        {cells.map((c, i) => (
                            <Cell 
                                key={`${c.title}-i`}
                                size={c.size}
                                title={c.title}
                                name={c.name}
                                sort={c.sort}
                                contentType={c.contentType}
                                onClick={c.onClick}
                            />
                        ))}
                    </Table>
                </div>
            </div>
        )
    },
    update: (props) => {

        const {
            children, 
            updateQuery, 
            deleteQuery, 
            refetchQueries, 
            variables,
            onUpdateStart,
            onUpdateSuccess,
            customLoading,
            countQueryObj,
            title,
            onDeleteSuccess,
            id,
            onDeleteStart,
            validate
        } = props
        const {popupHandler} = useContext(AppContext)
        const [count, setCount] = useState(0)
        const [gotoValues, setGotoValues] = useState(null)

        const countQuery = useQuery(countQueryObj.query, {
            variables: countQueryObj.variables,
            fetchPolicy: "no-cache",
        })

        useEffect(() => {
            if (countQuery.loading || countQuery.error)
                return
            if (!countQuery.data)
                return

            
            let result = countQuery.data[Object.keys(countQuery.data)[0]]
            // if (queryRes && "count" in queryRes) {
            //     setCount(queryRes.count)
            //     const cleanCustomGotoKeys = {...queryRes}
            //     delete cleanCustomGotoKeys.count
            //     delete cleanCustomGotoKeys.__typename
            //     setGotoValues({...cleanCustomGotoKeys})
            // } else 
            setCount(result)
        },[countQuery.data])
    
        const [update_query, updateRes] = useMutation(updateQuery, {
            refetchQueries: refetchQueries,
            notifyOnNetworkStatusChange: true
        });

        const [delete_query, deleteRes] = useMutation(deleteQuery, {
            refetchQueries: refetchQueries
        });

        useEffect(() => {
            if (updateRes.loading || updateRes.error)
                return
            if (!updateRes.data)
                return
            onUpdateSuccess(updateRes.data[Object.keys(updateRes.data)[0]])
        },[updateRes.data])

        useEffect(() => {
            if (deleteRes.loading || deleteRes.error)
                return
            if (!deleteRes.data)
                return
            onDeleteSuccess()
        },[deleteRes.data])

        const event = {
            update: () => {
                if (typeof validate != "undefined" && !validate())
                    return
                onUpdateStart()
                update_query({variables: variables})
            },
            delete: () => {
                onDeleteStart()
                delete_query({variables: {id: id}})
                popupHandler.remove(1)
            }
        }

        return (
            <div className={style.section}>
                {updateRes.loading || deleteRes.loading || customLoading? <div className={style.loading_overlay}><Loading /></div> : null}
                <h2>Rediger</h2>
                {children}
                {countQuery.loading ? <p>{countQueryObj.loading}</p> : count <= 0 ? null : 
                    <div className={style.warning_wrap}>
                        <AdminInterfaceTemplate.warning 
                            warning={countQueryObj.warning} 
                            title={title}
                            count={count} 
                        />
                        {!countQueryObj.goTo ? null :
                            <AdminInterfaceTemplate.goTo 
                                goTo={countQueryObj.goTo} 
                                gotoValues={gotoValues}
                            />
                        }
                        <AdminInterfaceTemplate.move 
                            move={countQueryObj.move}
                            count={count}
                        />
                    </div>
                }
                <div className={style.actions}>
                    <Button onClick={() => event.update()}>Gem</Button>
                    <Button outlined onClick={() => popupHandler.add(
                     <div className={style.delete_card}>
                        {count < 1 ? null : <div className={style.delete_card_warning}>
                            <AdminInterfaceTemplate.warning 
                                warning={countQueryObj.warning} 
                                title={title}
                                count={count} 
                            />
                            <p>
                                {countQueryObj.delete.text.replace("{{count}}", count)}
                            </p>
                            <AdminInterfaceTemplate.move 
                                move={countQueryObj.move}
                                count={count}
                                replace={true}
                            />
                            <div className={style.delete_card_split}></div>
                        </div>}
                        <p>Er du sikker på at du vil slette <span>{title}</span>?</p>   
                        <div>
                            <Button onClick={() => event.delete()}>ja</Button>
                            <Button outlined onClick={() => popupHandler.remove(1)}>nej</Button>
                        </div>
                    </div>
                    )}>Slet</Button>
                </div>
            </div>
        )
    },
    warning: ({warning, title, count}) => {
        const withCount = warning.replace("{{count}}", count)
        const splitted = withCount.split("{{title}}")

        return (
            <>
                <p className={style.warning}>
                    {splitted[0]}
                    <span className={style.bold}>{title}</span>
                    {splitted[1]}
                </p>
            </>
        )
    },
    goTo: ({goTo, gotoValues}) => {
        if (!Array.isArray(goTo))
            goTo = [goTo]

        const {popupHandler} = useContext(AppContext)
        const navigate = useNavigate()
        const event = {
            navigate: (path, value) => {
                const regex = /{{(.+?)}}/;
                const match = value.match(regex);
                if (match) {
                    const key = match[1]
                    value = value.replace(`{{${key}}}`, gotoValues[key].join(","));
                }

                navigate(path, {replace: true, state: {initalSearch: `${value}`}})
                popupHandler.clear()
            }
        }

        return (
            goTo.map((gt, i) => (
                <p key={i+"goto"}>
                    {gt.text} <span className={style.goTo_link} onClick={
                        () => event.navigate(gt.path, gt.value)
                    }>her</span>
                </p>
            ))
        )
    },
    move: ({move, count, replace}) => {
        if (!move)
            return null
        const {popupHandler} = useContext(AppContext)

        const event = {
            click: () => {
                if (!replace)
                    popupHandler.add(<AdminInterfaceTemplate.movePopup move={move} count={count}/>)
                else
                    popupHandler.replace(1, <AdminInterfaceTemplate.movePopup move={move} count={count}/>)
            }
        }
        return (
            <p>
                {move.text} <span className={style.move_link} onClick={() => event.click()}>her</span>
            </p>
        )
    },
    movePopup: ({move, count}) => {
        const {popupHandler} = useContext(AppContext)
        const [toVal, setToVal] = useState(null)
        const text = move.menuText.replace("{{count}}", count)

        const [move_query, moveRes] = useMutation(move.query, {
            refetchQueries: move.refetchQueries
        });
        
        useEffect(() => {
            if (moveRes.loading || moveRes.error)
                return
            if (!moveRes.data)
                return
            event.close()
            move.onMoveSuccess()
        },[moveRes.data])

        const event = {
            setTo: (name, value) => {
                setToVal(value) 
            },
            close: () => {
                popupHandler.close()
            },
            move: () => {
                move.onMoveStart()
                move_query({
                    variables: {
                        where: {[move.variables.field]: move.variables.from},
                        data: {[move.variables.field]: toVal}
                    }
                })
            }
        }

        return (
            <div className={style.move_container}>
                <p>{text}</p>
                <div className={style.move_input_container}>
                    <Input value={move.variables.title} label="fra" disabled />
                    <Arrow className={style.move_arrow} />
                    <CustomSelectInput 
                        search={true}
                        label="til"
                        name="to"
                        options={move.selectOptions}
                        value={toVal}
                        onInput={event.setTo}
                    />
                </div>
                <div className={style.move_button_container}>
                    <Button onClick={() => event.move()}>Skift</Button>
                    <Button outlined onClick={() => event.close()}>Fortryd</Button>
                </div>
            </div>
        )
    },
    create: (props) => {
        const {
            children, 
            createQuery, 
            refetchQuery, 
            variables, 
            onCreateSuccess, 
            onCreateStart,
            customLoading,
            validate
        } = props

        const [create_query, createRes] = useMutation(createQuery, {
            refetchQueries: [
                refetchQuery
            ],
        });

        useEffect(() => {
            if (createRes.loading || createRes.error)
                return
            if (!createRes.data)
                return
            onCreateSuccess(createRes.data[Object.keys(createRes.data)[0]])
        },[createRes.data])


        const event = {
            create: () => {
                if (typeof validate != "undefined" && !validate())
                    return
                onCreateStart()
                create_query({variables: variables})
            }
        }

        return (
            <div className={style.section}>
                {createRes.loading || customLoading ? <div className={style.loading_overlay}><Loading /></div> : null}
                <h2>Opret</h2>
                {children}
                <div className={style.actions}>
                    <Button onClick={() => event.create()}>Opret</Button>
                </div>
            </div>
        )
    },
    empty: () => {
        return (
            <div className={style.section}></div>
        )
    },
    functions: {
        localSearch: (searchString, content, searchFields) => {
            if (!searchString)
                return content

            const UpdateArray = [...content]
            return UpdateArray.filter(a => {
                const searchableString = searchFields.map(f => a[f]).join(" ")
                const searchArray = searchString.split(" ").filter(v => v)
                const matches = searchArray.filter(v => searchableString.toLowerCase().includes(v.toLowerCase()))
                return matches.length === searchArray.length
            })
        },
        localSort: (content, sort) => {
            if (!sort.field)
                return content
            const UpdateArray = [...content]
            return UpdateArray.sort((a,b) => {
                let [fieldA, fieldB] = [a[sort.field], b[sort.field]]

                if (typeof fieldA === "boolean") {
                    fieldA = fieldA ? 1 : 0
                    fieldB = fieldB ? 1 : 0
                } else if (typeof fieldA === "string") {
                    fieldA = fieldA.toLowerCase()
                    fieldB = fieldB.toLowerCase()
                }

                if (sort.dir)
                    return (fieldA < fieldB) ? 1 : ((fieldB < fieldA) ? -1 : 0)
                
                return (fieldA > fieldB) ? 1 : ((fieldB > fieldA) ? -1 : 0)
            })
        }
    }
}