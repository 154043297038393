import React, { useRef, useState } from "react";

// STYLE
import style from "./Msg.module.scss";

interface CardProps {
  index: number;
  message: string;
  time: number;
  transition: number;
  close: (index: number) => void;
}

const Msg = {
  Container: ({ children }: { children: React.ReactNode }) => {
    return <div className={style.container}>{children}</div>;
  },
  Card: ({ index, message, time, transition, close }: CardProps) => {
    const msgCardRef = useRef(null);
    const [tick, setTick] = useState(0);

    if (tick >= time) {
      if (msgCardRef.current) msgCardRef.current.style.opacity = 0;
      if (tick >= transition + time) {
        return null;
      }
    }

    setTimeout(() => {
      setTick(tick + 1);
    }, 1000);

    return (
      <div
        onClick={() => close(index)}
        ref={msgCardRef}
        style={{
          transition: `opacity ${transition}s`,
          animationDuration: `${transition}s`,
        }}
        className={style.card}
      >
        <p>{message}</p>
      </div>
    );
  },
};

export default Msg;
