import { useContext, useEffect, useState } from "react"

// QUERIES
import { KURSISTER, GET_KURSISTER_BY_HOLD } from '../../../../queries/kursister.gql'
import { GET_KURSIST_HOLD } from '../../../../queries/hold.gql'
import { CREATE_KH_STATUS, UPDATE_KH_STATUS, DELETE_KH_STATUS } from '../../../../queries/catalogs/kh_statuser.gql'
import { KURSIST_HOLD_COUNT, UPDATE_KURSIST_TIL_HOLDS } from '../../../../queries/kursistHold.gql'
import { ALL_CATALOGS } from '../../../../queries/catalogs/allCatalogs.gql'

// HOOKS
import { useCatalog } from "../../../../hooks/useCatalog"

import style from './AdminInterfaceKursistTilHoldStatuser.module.scss'

// COMPONENTS
import { AdminInterfaceTemplate } from "../AdminInterfaceTemplate/AdminInterfaceTemplate"
import CheckboxInput from "../../../Inputs/CheckboxInput/CheckboxInput"
import Input from "../../../Inputs/Input"
import Color from "../../../../assets/icons/indicators/Color"
import { AppContext } from "../../../../context/AppContext"
import CustomSelectInput from "../../../Inputs/SelectInput/CustomSelectInput"

let lastData = [] 
let changeTimeStamp = new Date().valueOf();
const primaryKey = "Kh_Status_id"

export default function AdminInterfaceKursistTilHoldStatuser() {
    const {formatHandler} = useContext(AppContext)
    const catalogs = useCatalog()
    const content = catalogs.kh_statuser

    const [newContent, setNewContent] = useState(content)
    const [searchString, setSearchString] = useState("")
    const [sort, setSort] = useState({field: "", dir: false})
    const [selected, setSelected] = useState(null)
    const [newObj, setNewObj] = useState(null)
    const [scrollToBottom, setScrollToBottom] = useState(false)
    const [customLoading, setCustomLoading] = useState(false)
    
    const statusOptions = [
        {
            title: "0",
            value: 0,
            jsx: <span className={style.colorSelect}><Color color={formatHandler.statusColor(0)} size="10"></Color><span>0</span></span>
        },
        {
            title: "1",
            value: 1,
            jsx: <span className={style.colorSelect}><Color color={formatHandler.statusColor(1)} size="10"></Color><span>1</span></span>
        },
        {
            title: "2",
            value: 2,
            jsx: <span className={style.colorSelect}><Color color={formatHandler.statusColor(2)} size="10"></Color><span>2</span></span>
        },
        {
            title: "3",
            value: 3,
            jsx: <span className={style.colorSelect}><Color color={formatHandler.statusColor(3)} size="10"></Color><span>3</span></span>
        },
        {
            title: "4",
            value: 4,
            jsx: <span className={style.colorSelect}><Color color={formatHandler.statusColor(4)} size="10"></Color><span>4</span></span>
        }
    ]

    useEffect(() => {
        const filtered = AdminInterfaceTemplate.functions
        .localSearch(searchString, content, ["Kh_Status"])
        setNewContent(filtered)
    },[searchString])

    useEffect(() => {
        const sorted = AdminInterfaceTemplate.functions.localSort(newContent, sort)
        setNewContent(sorted)
    },[sort])

    useEffect(() => {
        if (customLoading)
            setCustomLoading(false)

        if (sort.field) {
            const sorted = AdminInterfaceTemplate.functions.localSort(content, sort)
            setNewContent(sorted)
        } else {
            setNewContent(content)
        }
        if (content.toString() != lastData.toString()) {
            changeTimeStamp = new Date().valueOf();
            lastData = content
        }
    },[content])

    const event = {
        search: (name, val) => {
            setSearchString(val)
        },
        sort: (field) => {
            setSort({field: field, dir: !sort.dir})
        },
        rowClick: (val, index) => {
            setNewObj(null)
            if ("Kode" in val)
                val.Kode = (typeof val.Kode != "number") ? val.Kode.value : val.Kode
            setSelected(val)
        },
        UpdateInput: (name, val) => {
            let updateObj = {...selected}
            updateObj[name] = val
            setSelected(updateObj)
        },
        createInput: (name, val) => {
            let updateObj = {...newObj}
            updateObj[name] = val
            setNewObj(updateObj)
        },
        new: () => {
            setSelected(null)
            setNewObj({
                Kh_Status: "",
                Kode: null,
                Aktivt: false
            })
        },
        createSuccess: (res) => {
            setSearchString("")
            setSelected(res)
            setScrollToBottom(true)
        },
        createStart: () => {
            setCustomLoading(true)
        },
        updateSuccess: (id) => {
            setSearchString("")
            setCustomLoading(false)
        },
        updateStart: () => {
            setCustomLoading(true)
        },
        deleteStart: () => {
            const htmlRow = document.querySelector(`[data-primary-key="${primaryKey}${selected[primaryKey]}"]`)
            htmlRow.classList.add("inactive_load")
            setCustomLoading(true)
        },
        deleteSuccess: () => {
            setSearchString("")
            setSelected(null)
            setNewObj(null)
        },
        moveStart: () => {
            setCustomLoading(true)
        },
        moveSuccess: () => {
            setSearchString("")
            setCustomLoading(false)
        }
    }

    const getJsxContent = (kode) => {
        return (
            <div className={style.cell_jsx}>
                <Color color={formatHandler.statusColor(kode)} size="10"></Color>
                <span>{kode}</span>
            </div>
        )
    }

    return (
        <AdminInterfaceTemplate.component>
            <AdminInterfaceTemplate.content>
                <AdminInterfaceTemplate.table
                    primaryKey={primaryKey}
                    changeTimeStamp={changeTimeStamp}
                    scrollToBottom={scrollToBottom}
                    setScrollToBottom={setScrollToBottom}
                    onNew={event.new}
                    searchFunction={event.search}
                    searchString={searchString}
                    content={[...newContent].map(c => ({...c, Kode: {jsx: getJsxContent(c.Kode), value: c.Kode}}))}
                    selected={selected}
                    rowClick={event.rowClick}
                    rowSelect={!selected ? null : {field: primaryKey, value: selected.Kh_Status_id}}
                    cells={[
                        {
                            size: 0,
                            title: "ID",
                            name: "Kh_Status_id",
                            sort: sort,
                            onClick: () => event.sort("Kh_Status_id")
                        },
                        {
                            size: 1,
                            title: "Kursist til hold status",
                            name: "Kh_Status",
                            sort: sort,
                            onClick: () => event.sort("Kh_Status")
                        },
                        {
                            size: 1,
                            title: "Kode",
                            name: "Kode",
                            sort: sort,
                            contentType: {type: "jsx"},
                            onClick: () => event.sort("Kode")
                        },
                        {
                            size: 0,
                            title: "Aktiv",
                            name: "Aktivt",
                            sort: sort,
                            onClick: () => event.sort("Aktivt")
                        }
                    ]}
                />
                {/* `{{count}} Kursister er medlem af {{title}}, sletning er ikke opfordret! */}
                {selected ? 
                    <AdminInterfaceTemplate.update
                        variables={{
                            id: selected.Kh_Status_id,
                            input: {
                                Kh_Status: selected.Kh_Status,
                                Aktivt: selected.Aktivt,
                                Kode: selected.Kode
                            }
                        }}
                        updateQuery={UPDATE_KH_STATUS}
                        deleteQuery={DELETE_KH_STATUS}
                        refetchQueries={[ALL_CATALOGS]}
                        title={selected.Kh_Status}
                        id={selected.Kh_Status_id}
                        countQueryObj={{
                            query: KURSIST_HOLD_COUNT,
                            variables: {
                                conditions: {
                                    Kh_Status_id: selected.Kh_Status_id
                                }
                            },
                            loading: "Checkker Status...",
                            warning: "{{count}} kursist-til-hold relationer med statussen {{title}}, Sletning er ikke opfordret!",
                            move: {
                                query: UPDATE_KURSIST_TIL_HOLDS,
                                variables: {
                                    field: `Kh_Status_id`,
                                    from: selected.Kh_Status_id,
                                    title: selected.Kh_Status
                                },
                                refetchQueries: [KURSISTER, KURSIST_HOLD_COUNT, ALL_CATALOGS, GET_KURSISTER_BY_HOLD, GET_KURSIST_HOLD],
                                onMoveStart: event.moveStart,
                                onMoveSuccess: event.moveSuccess,
                                text: "Skift status på kursist-til-hold relationer",
                                menuText: "Skift status for {{count}} kursist-til-hold relationer?",
                                selectOptions: content.map(obj => ({value: obj.Kh_Status_id, title: obj.Kh_Status})),
                            },
                            delete: {
                                text: "Handlingen vil forsøge at nulstille Statuserne for {{count}} kursist-til-hold relationerne, er du sikker på du ikke vil ændre værdierne først?"
                            }
                        }}
                        customLoading={customLoading}
                        onUpdateStart={event.updateStart}
                        onUpdateSuccess={event.updateSuccess}
                        onDeleteStart={event.deleteStart}
                        onDeleteSuccess={event.deleteSuccess}
                    >
                        <Input 
                            label="Kh_Status" 
                            name="Kh_Status" 
                            value={selected.Kh_Status} 
                            onInput={event.UpdateInput} 
                        />
                        <CustomSelectInput 
                            required={true}
                            label="Kode" 
                            name="Kode" 
                            value={selected.Kode}
                            options={statusOptions}
                            onInput={event.UpdateInput}
                        />
                        <CheckboxInput 
                            label="Aktiv"
                            name="Aktivt"
                            value={selected.Aktivt}
                            onInput={event.UpdateInput} 
                        />
                    </AdminInterfaceTemplate.update>
                : newObj ?
                    <AdminInterfaceTemplate.create
                        variables={{
                            input: {...newObj}
                        }}
                        createQuery={CREATE_KH_STATUS}
                        refetchQuery={ALL_CATALOGS}
                        customLoading={customLoading}
                        onCreateSuccess={event.createSuccess}
                        onCreateStart={event.createStart}
                    >
                        <Input 
                            label="Status" 
                            name="Kh_Status" 
                            value={newObj.Kh_Status} 
                            onInput={event.createInput} 
                        />
                        <CustomSelectInput 
                            required={true}
                            label="Kode" 
                            name="Kode" 
                            value={newObj.Kode}
                            options={statusOptions}
                            onInput={event.createInput}
                        />
                        <CheckboxInput 
                            label="Aktiv"
                            name="Aktivt"
                            value={newObj.Aktivt}
                            onInput={event.createInput} 
                        />
                    </AdminInterfaceTemplate.create>
                : <AdminInterfaceTemplate.empty />}

            </AdminInterfaceTemplate.content>
        </AdminInterfaceTemplate.component>
    )
}