import { Configuration } from "@azure/msal-browser";
import { getEnvVariables } from "./getEnvVariables";

const { clientId, redirectUri, tenantId } = getEnvVariables()

const msalConfig: Configuration = {
    auth: {
        clientId: clientId, // This is the ONLY mandatory field that you need to supply.
        redirectUri: redirectUri,
        authority: `https://login.microsoftonline.com/${tenantId}`,
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export default msalConfig