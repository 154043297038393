import { useQuery } from "@apollo/client";
import React, { useState, useContext, useEffect, useMemo } from "react";

// CONTEXT
import { AppContext } from "../../../context/AppContext";

// COMPONENT
import TableInterfaceFilter from "./TableInterfaceFilter/TableInterfaceFilter";
import Table from "../../Table/Table";
import Cell from "../../Table/Cell";

// STYLE
import style from "./TableInterface.module.scss";
import { Interface } from "readline";

let lastData = [];
let newDate = new Date();
let changeTimeStamp = newDate.valueOf();
const currentDate = newDate;

let yearArray = new Array(20).fill({});
const getYears = yearArray
  .map((y, i) => ({ title: currentDate.getFullYear() + 1 - i, value: i + 1 }))
  .reverse();

interface TableInterfaceProps {
  query: any;
  fields: any;
  name: any;
  locationProps: any;
  excelQuery: any;
  onRowClick: any;
  primaryKey: any;
  customActions: any;
  tableScroll?: any;
  aktivtFilter?: any;
  dateBarFilter?: any;
  sort?: any;
}

const TableInterface: React.FC<TableInterfaceProps> = (props) => {
  const {
    query,
    fields,
    name,
    locationProps,
    excelQuery,
    onRowClick,
    primaryKey,
    customActions,
    tableScroll,
    aktivtFilter,
    dateBarFilter,
  } = props;

  const { formatHandler, activeHoldHandler } = useContext(AppContext);
  const { aktiveHold, aktiveELearning } = activeHoldHandler;
  const [sort, setSort] = useState(
    props.sort ? props.sort : { field: primaryKey, dir: false }
  );
  const [searchString, setSearchString] = useState("");
  const [searchChunk, setSeachChunk] = useState("");
  const [autoSelect, setAutoSelect] = useState(null);

  const dateToQueryValue = () => {
    if (!dateValue.month || !dateValue.year) return { from: null, to: null };
    return {
      from: new Date(
        getYears.find((y) => y.value === dateValue.year).title,
        dateValue.month - 1,
        1
      ),
      to: new Date(
        getYears.find((y) => y.value === dateValue.year).title,
        dateValue.month,
        0
      ),
    };
  };

  useEffect(() => {
    console.log(locationProps);
    // return
    if (!locationProps) return;
    setSearchString(locationProps.initalSearch);
    // setAutoSelect(locationProps.select ? locationProps.select : null)
  }, [locationProps]);

  const defaultOptions = { search: {}, table: {} };
  Object.keys(fields)
    .filter((t) => fields[t].filter)
    .forEach((t) => {
      if (!fields[t].filter.search.hidden)
        defaultOptions.search[t] = fields[t].filter.search.default;
      if (!fields[t].filter.table.hidden)
        defaultOptions.table[t] = fields[t].filter.table.default;
    });

  const filterOptionsLocalStorageString = `${name}_filter_options`;
  const savedOptions = JSON.parse(
    localStorage.getItem(filterOptionsLocalStorageString)
  );
  const [filterOptions, setFilterOptions] = useState(
    savedOptions ? savedOptions : defaultOptions
  );

  const aktiveFilterLocalStorageString = `${name}_aktive_filter`;
  const savedAktiveFilter = localStorage.getItem(
    aktiveFilterLocalStorageString
  );
  const [filterAktive, setFilterAktive] = useState(
    savedAktiveFilter ? savedAktiveFilter : ""
  );

  const dateFilterLocalStorageString = `${name}_date_filter`;
  const savedDateFilter = JSON.parse(
    localStorage.getItem(dateFilterLocalStorageString)
  );
  const [dateValue, setDateValue] = useState(
    savedDateFilter ? savedDateFilter : { month: null, year: null }
  );

  const tableTabs = Object.keys(fields)
    .filter(
      (t) =>
        (t in filterOptions.table && filterOptions.table[t]) ||
        (fields[t].filter.table.default && fields[t].filter.table.hidden)
    )
    .map((t) => {
      return {
        ...fields[t].tab,
        name: t,
      };
    });

  const searchFields = Object.keys(filterOptions.search).filter(
    (f) => filterOptions.search[f]
  );
  const { data, error, loading, startPolling, stopPolling } = useQuery(query, {
    pollInterval: 5000,
    variables: {
      sortBy: {
        field: sort.field,
        dir: sort.dir,
      },
      filter: {
        search: formatHandler.searchToIdSearch(searchChunk, searchFields),
        fields: searchFields,
      },
      aktivtFilter: {
        enable: !aktivtFilter ? "" : filterAktive,
        time: {
          from:
            filterAktive === "hold"
              ? aktiveHold.fromDate
              : aktiveELearning.fromDate,
          to:
            filterAktive === "hold"
              ? aktiveHold.toDate
              : aktiveELearning.toDate,
        },
      },
      dateFilter: dateToQueryValue(),
    },
  });

  const event = {
    sort: (sortName, sortDir) => {
      setSort({ field: sortName, dir: !sortDir });
    },
    onRowClick: (value, index?, e?) => {
      const selectedData = data[Object.keys(data)[0]].find(
        (d) => eval(d[primaryKey]) === eval(value)
      );
      onRowClick(selectedData, index, e, startPolling, stopPolling);
    },
  };

  useEffect(() => {
    let timer;

    if (searchString.length === 0) {
      setSeachChunk("");
    } else {
      // Clear the previous timer
      clearTimeout(timer);

      // Set a new timer to update searchChunk after 1000ms
      timer = setTimeout(() => {
        setSeachChunk(searchString);
      }, 300);
    }

    // Cleanup the timer on component unmount or when searchString changes
    return () => clearTimeout(timer);
  }, [searchString]);

  useEffect(() => {

    if (error) return;
    if (loading) return;
    if (!data) return;
    if (locationProps && data && autoSelect === null) {
      setAutoSelect(locationProps.select ? locationProps.select : null);
    }
    if (autoSelect != null && data) {
      setAutoSelect(null);
      event.onRowClick(autoSelect);
    }
    if (data.toString() != lastData.toString()) {
      changeTimeStamp = new Date().valueOf();
      lastData = data;
    }
  }, [data, error, loading]);

  return (
    <div className={style.component}>
      <TableInterfaceFilter
        {...{
          aktivtFilter,
          filterOptions,
          setFilterOptions,
          filterAktive,
          setFilterAktive,
          defaultOptions,
          searchString,
          setSearchString,
          filterOptionsLocalStorageString,
          aktiveFilterLocalStorageString,
          dateFilterLocalStorageString,
          excelQuery,
          name,
          dateBarFilter,
          primaryKey,
          customActions,
          activeHoldHandler,
          dateValue,
          setDateValue,
          getYears,
          currentDate,
        }}
      />
      <div className={!tableScroll ? "" : style.scrollContainer}>
        <Table
          changeTimeStamp={changeTimeStamp}
          loading={loading}
          primaryKey={primaryKey}
          error={error ? error.message : null}
          content={
            data
              ? formatHandler.dataToReadable(data[Object.keys(data)[0]])
              : null
          }
          onRowClick={(value, index, e) =>
            event.onRowClick(value[primaryKey], index, e)
          }
        >
          {tableTabs.map((k, i) => (
            <Cell
              contentType={k.contentType}
              key={`${k.title}-${i}`}
              size={k.size}
              title={k.title}
              name={k.name}
              sort={sort}
              onClick={() => (k.sortable ? event.sort(k.name, sort.dir) : null)}
            />
          ))}
        </Table>
      </div>
    </div>
  );
};

export default TableInterface;
