import React from "react";
import TabInterface from "../TabInterface/TabInterface";
import AdminInterfaceAKasser from "./AdminInterfaceAKasser/AdminInterfaceAKasser";
import AdminInterfaceAnsogerVia from "./AdminInterfaceAnsogerVia/AdminInterfaceAnsogerVia";
import AdminInterfaceBrugere from "./AdminInterfaceBrugere/AdminInterfaceBrugere";
import AdminInterfaceGender from "./AdminInterfaceGender/AdminInterfaceGender";
import AdminInterfaceIndgange from "./AdminInterfaceIndgange/AdminInterfaceIndgange";
import AdminInterfaceJobcentre from "./AdminInterfaceJobcentre/AdminInterfaceJobcentre";
import AdminInterfaceJobcoachStatuser from "./AdminInterfaceJobcoachStatuser/AdminInterfaceJobcoachStatuser";
import AdminInterfaceKurser from "./AdminInterfaceKurser/AdminInterfaceKurser";
import AdminInterfaceKursistTilHoldStatuser from "./AdminInterfaceKursistTilHoldStatuser/AdminInterfaceKursistTilHoldStatuser";
import AdminInterfaceRecoveryKeys from "./AdminInterfaceRecoveryKeys/AdminInterfaceRecoveryKeys";

interface AdminInterfaceProps {
    scrollable?: boolean
}

const AdminInterface: React.FC<AdminInterfaceProps> = ({scrollable}) => {
    return (
        <TabInterface scrollable={scrollable} tabs={[
            "A-kasser",
            "Jobcentre ",
            "Indgange",
            "Køn",
            "Kurser",
            "Ansøger via",
            "Jobcoach-status",
            "Kursist-hold-status",
            "Brugere/adgang",
        ]}>
            <AdminInterfaceAKasser />
            <AdminInterfaceJobcentre />
            <AdminInterfaceIndgange />
            <AdminInterfaceGender />
            <AdminInterfaceKurser />
            <AdminInterfaceAnsogerVia />
            <AdminInterfaceJobcoachStatuser />
            <AdminInterfaceKursistTilHoldStatuser />
            <AdminInterfaceBrugere />
        </TabInterface> 
    )
}

export default AdminInterface