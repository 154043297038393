import { useEffect, useState } from "react"

// QUERIES
import { KURSIST_COUNT, UPDATE_KURSISTER, KURSISTER } from '../../../../queries/kursister.gql'
import { CREATE_A_KASSE, UPDATE_A_KASSE, DELETE_A_KASSE } from '../../../../queries/catalogs/a_kasser.gql'
import { ALL_CATALOGS } from '../../../../queries/catalogs/allCatalogs.gql'

// COMPONENTS
import { AdminInterfaceTemplate } from "../AdminInterfaceTemplate/AdminInterfaceTemplate"
import CheckboxInput from "../../../Inputs/CheckboxInput/CheckboxInput"
import Input from "../../../Inputs/Input"

// HOOKS
import { useCatalog } from "../../../../hooks/useCatalog"
import { useInputOptions } from "../../../../hooks/useInputOptions"

let lastData = [] 
let changeTimeStamp = new Date().valueOf();
const primaryKey = "A_kasse_id"

export default function AdminInterfaceAKasser() {
    const catalogs = useCatalog()
    const content = catalogs.a_kasser
    const [newContent, setNewContent] = useState(content)
    const [searchString, setSearchString] = useState("")
    const [sort, setSort] = useState({field: "", dir: false})
    const [selected, setSelected] = useState(null)
    const [newObj, setNewObj] = useState(null)
    const [scrollToBottom, setScrollToBottom] = useState(false)
    const [customLoading, setCustomLoading] = useState(false)

    useEffect(() => {
        const filtered = AdminInterfaceTemplate.functions
        .localSearch(searchString, content, ["A_kasse"])
        setNewContent(filtered)
    },[searchString])

    useEffect(() => {
        const sorted = AdminInterfaceTemplate.functions.localSort(newContent, sort)
        setNewContent(sorted)
    },[sort])

    useEffect(() => {
        if (customLoading)
            setCustomLoading(false)

        if (sort.field) {
            const sorted = AdminInterfaceTemplate.functions.localSort(content, sort)
            setNewContent(sorted)
        } else {
            setNewContent(content)
        }
        if (content.toString() != lastData.toString()) {
            changeTimeStamp = new Date().valueOf();
            lastData = content
        }
    },[content])

    const event = {
        search: (name, val) => {
            setSearchString(val)
        },
        sort: (field) => {
            setSort({field: field, dir: !sort.dir})
        },
        rowClick: (val, index, e) => {
            setNewObj(null)
            setSelected(val)
        },
        UpdateInput: (name, val) => {
            let updateObj = {...selected}
            updateObj[name] = val
            setSelected(updateObj)
        },
        createInput: (name, val) => {
            let updateObj = {...newObj}
            updateObj[name] = val
            setNewObj(updateObj)
        },
        new: () => {
            setSelected(null)
            setNewObj({
                A_kasse: "",
                Aktivt: false
            })
        },
        createSuccess: (res) => {
            setSearchString("")
            setSelected(res)
            setScrollToBottom(true)
        },
        createStart: () => {
            setCustomLoading(true)
        },
        updateSuccess: (id) => {
            setSearchString("")
            setCustomLoading(false)
        },
        updateStart: () => {
            setCustomLoading(true)
        },
        deleteStart: () => {
            const htmlRow = document.querySelector(`[data-primary-key="${primaryKey}${selected[primaryKey]}"]`)
            htmlRow.classList.add("inactive_load")
            setCustomLoading(true)
        },
        deleteSuccess: () => {
            setSearchString("")
            setSelected(null)
            setNewObj(null)
        },
        moveStart: () => {
            setCustomLoading(true)
        },
        moveSuccess: () => {
            setSearchString("")
            setCustomLoading(false)
        }
    }

    return (
        <AdminInterfaceTemplate.component>
            <AdminInterfaceTemplate.content>
                <AdminInterfaceTemplate.table 
                    primaryKey={primaryKey}
                    changeTimeStamp={changeTimeStamp}
                    scrollToBottom={scrollToBottom}
                    setScrollToBottom={setScrollToBottom}
                    onNew={event.new}
                    searchFunction={event.search}
                    searchString={searchString}
                    content={newContent}
                    selected={selected}
                    rowClick={event.rowClick}
                    rowSelect={!selected ? null : {field: primaryKey, value: selected.A_kasse_id}}
                    cells={[
                        {
                            size: 0,
                            title: "ID",
                            name: "A_kasse_id",
                            sort: sort,
                            onClick: () => event.sort("A_kasse_id")
                        },
                        {
                            size: 1,
                            title: "A-Kasse",
                            name: "A_kasse",
                            sort: sort,
                            onClick: () => event.sort("A_kasse")
                        },
                        {
                            size: 0,
                            title: "Aktivt",
                            name: "Aktivt",
                            sort: sort,
                            onClick: () => event.sort("Aktivt")
                        }
                    ]}
                />
                {selected ? 
                    <AdminInterfaceTemplate.update
                        variables={{
                            id: selected.A_kasse_id,
                            input: {
                                A_kasse: selected.A_kasse,
                                Aktivt: selected.Aktivt
                            }
                        }}
                        updateQuery={UPDATE_A_KASSE}
                        deleteQuery={DELETE_A_KASSE}
                        refetchQueries={[ALL_CATALOGS]}
                        title={selected.A_kasse}
                        id={selected.A_kasse_id}
                        countQueryObj={{
                            query: KURSIST_COUNT,
                            variables: {
                                conditions: {
                                    A_kasse: selected.A_kasse_id
                                }
                            },
                            loading: "Checkker A-kasse...",
                            warning: "{{count}} kursister er medlem af {{title}}, Sletning er ikke opfordret!",
                            goTo: {
                                text: "Find kursisterne",
                                path: "/kursister",
                                value: `A-kasse_id=${selected.A_kasse_id}`
                            },
                            move: {
                                query: UPDATE_KURSISTER,
                                variables: {
                                    field: "A_kasse",
                                    from: selected.A_kasse_id,
                                    title: selected.A_kasse
                                },
                                refetchQueries: [KURSISTER, KURSIST_COUNT, ALL_CATALOGS],
                                onMoveStart: event.moveStart,
                                onMoveSuccess: event.moveSuccess,
                                text: "Skift A-kassen for kursisterne",
                                menuText: "Skift A-kassen for {{count}} kursister?",
                                selectOptions: content.map(obj => ({value: obj.A_kasse_id, title: obj.A_kasse})),
                            },
                            delete: {
                                text: "Handlingen vil forsøge at nulstille A-kasse værdierne for {{count}} kursister, er du sikker på du ikke vil ændre A-kasse værdierne først?"
                            }
                        }}
                        customLoading={customLoading}
                        onUpdateStart={event.updateStart}
                        onUpdateSuccess={event.updateSuccess}
                        onDeleteStart={event.deleteStart}
                        onDeleteSuccess={event.deleteSuccess}
                    >
                        <Input 
                            label="A-kasse" 
                            name="A_kasse" 
                            value={selected.A_kasse} 
                            onInput={event.UpdateInput} 
                        />
                        <CheckboxInput 
                            label="Aktiv"
                            name="Aktivt"
                            value={selected.Aktivt}
                            onInput={event.UpdateInput} 
                        />
                    </AdminInterfaceTemplate.update>
                : newObj ?
                    <AdminInterfaceTemplate.create
                        variables={{
                            input: {...newObj}
                        }}
                        createQuery={CREATE_A_KASSE}
                        refetchQuery={ALL_CATALOGS}
                        customLoading={customLoading}
                        onCreateSuccess={event.createSuccess}
                        onCreateStart={event.createStart}
                    >
                        <Input
                            label="A-kasse" 
                            name="A_kasse" 
                            value={newObj.A_kasse} 
                            onInput={event.createInput} 
                        />
                        <CheckboxInput
                            label="Aktiv"
                            name="Aktivt"
                            value={newObj.Aktivt}
                            onInput={event.createInput} 
                        />
                    </AdminInterfaceTemplate.create>
                : <AdminInterfaceTemplate.empty />}

            </AdminInterfaceTemplate.content>
        </AdminInterfaceTemplate.component>
    )
}