import useAllInputOptions from "../hooks/useAllInputOptions";

export interface FormatHandlerType {
  inputOptions: ReturnType<typeof useAllInputOptions>;
}

export class FormatHandler implements FormatHandlerType {
  inputOptions: ReturnType<typeof useAllInputOptions>;
  constructor() {
    this.inputOptions = useAllInputOptions();
  }

  date(date: string | Date, mod?: "short" | "long" | "DB") {
    if (date === null || typeof date == "undefined") {
      return "(ugyldig dato)";
    }
    
    let dateObj: Date
    if (typeof date === "string") {
      if (date.includes("T")) date = date.split("T")[0];
      dateObj = new Date(date);
    } else dateObj = date;

    const [day, month, year] = [
      dateObj.getDate().toString(),
      (dateObj.getMonth() + 1).toString(),
      dateObj.getFullYear().toString(),
    ];

    let options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    

    switch (mod) {
      case "short":
        return `${day.length < 2 ? "0" + day : day}-${
          month.length < 2 ? "0" + month : month
        }-${year}`;
      case "long": return dateObj.toLocaleDateString('da-DK', options);
      case "DB":
        return `${year}-${month.length < 2 ? "0" + month : month}-${
          day.length < 2 ? "0" + day : day
        }`;
      default:
        return `${day.length < 2 ? "0" + day : day}-${
          month.length < 2 ? "0" + month : month
        }-${year}`;
    }
  }

  time(date) {
    const [hours, minutes] = [
      date.getHours().toString(),
      date.getMinutes().toString(),
    ];

    return `${hours.length < 2 ? "0" + hours : hours}:${
      minutes.length < 2 ? "0" + minutes : minutes
    }`;
  }

  apiError(error) {
    if ("message" in error)
      return [error.message]
    if (!error) return ["fejl"];


    return ["fejl"]


    console.log(error.message)
    // const networkErrors = error.networkError
    //   ? error.networkError.result.errors
    //   : [];
    // console.log(error.message)
    // return [error.message]
    const networkErrors = [];
    return [
      ...error.clientErrors.map((e) => ({
        message: e.message,
        code: e.extensions.code,
        path: e.path,
      })),
      ...error.graphQLErrors.map((e) => ({
        message: e.message,
        code: e.extensions.code,
        path: e.path,
        key: e.extensions.key,
      })),
      ...networkErrors.map((e) => ({
        message: e.message,
        code: e.extensions.code,
        path: e.path,
      })),
    ];
  }

  statusColor(kode) {
    switch (kode) {
      case 0:
        return "red";
      case 1:
        return "yellow";
      case 2:
        return "lime";
      case 3:
        return "green";
      case 4:
        return "purple";
      default:
        return "black";
    }
  }

  fieldToOption(field) {
    switch (field) {
      case "A_kasse":
        return "a_kasser";
      case "Gender":
        return "genders";
      case "Indgang":
        return "indgange";
      case "Jobcenter":
        return "jobcentre";
      default:
        return field;
    }
  }

  fieldToTitle(field, primaryKey) {
    switch (field) {
      case "A_kasse":
        return "A-kasse";
      case "Gender":
        return "Køn";
      case "Birthday":
        return "Fødselsdato";
      case "Udb":
        return "Udb. modtaget";
      case "Rkv":
        return "RKV godkendt";
      case primaryKey:
        return "Id";
      default:
        return field;
    }
  }

  dataToReadable(data: Object[], customFunction?) {
    return data.map((dataObj) => {
      const updateObj = { ...dataObj };
      Object.keys(updateObj).forEach((key, i) => {
        let [field, value] = [key, updateObj[key]];

        if (customFunction) {
          if (customFunction.condition(field, value)) {
            updateObj[field] = customFunction.value(field, value);
            return;
          }
        }

        if (field === "Online") {
          updateObj[field] = value ? "Online" : "Fremmøde";
          return;
        }

        // remove?
        // if (field === "jsx")
        //     return

        // if (field == "PC_sendt")
        //     console.log(field, typeof value)

        // if (typeof value === "object") {
        //     return
        // }

        // if (field == "BM_sendt")
        //     console.log(field, value)

        if (value === null || value == "null") {
          updateObj[field] = "nej";
          return;
        }

        // boolean to string
        if (typeof value === "boolean") {
          updateObj[field] = value ? "ja" : "nej";
          return;
        }

        if (typeof value === "number") value = `${value}`;

        if (value === "true") {
          updateObj[field] = "ja";
          return;
        }

        if (value === "false") {
          updateObj[field] = "nej";
          return;
        }

        if (value.includes("000Z")) {
          updateObj[field] = this.date(value);
          return;
        }

        let optionField = this.fieldToOption(field);
        if (optionField in this.inputOptions) {
          let validIdField = this.inputOptions[this.fieldToOption(field)].find(
            (v) => v.value == value
          );
          updateObj[field] = validIdField ? validIdField.title : value;
          return;
        }
      });

      return updateObj;
    });
  }

  searchToIdSearch(input, fields) {
    if (!input) return "";

    const inputOptionFields = fields.filter(
      (f) => this.fieldToOption(f) in this.inputOptions
    );

    if (inputOptionFields.length < 1) return input;

    let inputArray = input.split(" ").filter((r) => r);

    let UpdatedInputStrings = inputArray
      .map((inputValue, i) => {
        const inputResults = inputOptionFields
          .map((field) => {
            let ids = this.inputOptions[this.fieldToOption(field)]
              .map((option) =>
                option.title.toLowerCase().includes(inputValue.toLowerCase())
                  ? option.value
                  : null
              )
              .filter((res) => res != null);

            if (ids.length < 1) return null;

            return {
              field: field,
              ids: ids.join(","),
            };
          })
          .filter((res) => res != null);

        if (inputResults.length < 1) return null;

        const result = inputResults
          .map((res) => `${res.field}>>${res.ids}`)
          .join("--");

        return {
          index: i,
          value: `((${inputValue}::${result}))`,
        };
      })
      .filter((res) => res != null);

    UpdatedInputStrings.forEach((r) => {
      inputArray[r.index] = r.value;
    });

    return inputArray.join(" ");
  }
}
