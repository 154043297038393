import React, { useContext } from "react";
import { Line } from "react-chartjs-2";
import { AppContext } from "../../context/AppContext";
import { ChartData } from "chart.js";
import Loading from "../../assets/icons/loaders/Loading";

interface LineChartProps {
  lineData: ChartData<"line", string, unknown> | string;
}

const LineChart: React.FC<LineChartProps> = ({ lineData }) => {
  const { themeHandler } = useContext(AppContext);

  const options = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: true,
        fontSize: 2,
        labels: {
          fontSize: 2,
          boxWidth: 40,
          color: themeHandler.getColor("text-color"),
        },
      },
    },
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
          color: themeHandler.getColor("text-soft-color"),
        },
        ticks: {
          color: themeHandler.getColor("text-color"),
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          color: themeHandler.getColor("text-softest-color"),
        },
        ticks: {
          color: themeHandler.getColor("text-color"),
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  if (typeof lineData === "string") return <Loading />;

  return <Line data={lineData} options={options} />;
};

export default LineChart;
