import { Dispatch, SetStateAction, useState } from "react";
import { FormatHandler } from "./FormatHandler";

interface HoldObjType {
    fromDate: string
    toDate: string
    filter: string
    filterEmpty: string
    warning: string | null
}

interface AktiveHoldHandlerType {
    formatHandler: FormatHandler
    aktiveHold: HoldObjType
    setAktiveHold: Dispatch<SetStateAction<HoldObjType>>
    aktiveELearning: HoldObjType
    setAktiveELearning: Dispatch<SetStateAction<HoldObjType>>
    aktiveHoldLocalString: string
    aktiveeLearningLocalString: string
}

export class AktiveHoldHandler implements AktiveHoldHandlerType{
    formatHandler: FormatHandler
    aktiveHold: HoldObjType
    setAktiveHold: Dispatch<SetStateAction<HoldObjType>>
    aktiveELearning: HoldObjType
    setAktiveELearning: Dispatch<SetStateAction<HoldObjType>>
    aktiveHoldLocalString: string
    aktiveeLearningLocalString: string
    constructor() {
        this.aktiveHoldLocalString = "itucation_aktive_hold_"
        this.aktiveeLearningLocalString = "itucation_aktive_e_learning_"

        const initialAktiveHold = this.getAktiveObj(this.aktiveHoldLocalString)
        const initialAktiveELearning = this.getAktiveObj(this.aktiveeLearningLocalString)

        const [aktiveHold, setAktiveHold] = useState(initialAktiveHold)
        const [aktiveELearning, setAktiveELearning] = useState(initialAktiveELearning)

        this.formatHandler = new FormatHandler()
        this.aktiveHold = aktiveHold
        this.setAktiveHold = setAktiveHold;
        this.aktiveELearning = aktiveELearning
        this.setAktiveELearning = setAktiveELearning

        this.initLocalStorage()
    }

    getAktiveObj(preflix: string) {
        return {
            fromDate: localStorage.getItem(preflix+"fromDate"),
            toDate: localStorage.getItem(preflix+"toDate"),
            filter: localStorage.getItem(preflix+"filter"),
            filterEmpty: localStorage.getItem(preflix+"filterEmpty"),
            warning: null
        }
    }

    initLocalStorage() {
        let shouldUpdate = []
        const now = new Date()

        const aktiveHold = this.getAktiveObj(this.aktiveHoldLocalString)
        const aktiveELearning = this.getAktiveObj(this.aktiveeLearningLocalString)
        if (!aktiveHold.fromDate && !aktiveHold.toDate) {
            let [fromMonth, toMonth] = [
                now.getMonth()-1 >= 0 ? now.getMonth()-1 : 0,
                now.getMonth()+1 < 12 ? now.getMonth()+1 : 11
            ]
            let initFromDate = this.formatHandler.date(new Date(now.getFullYear(), fromMonth, 1), "DB")
            let initToDate = this.formatHandler.date(new Date(now.getFullYear(), toMonth, 1), "DB")
            localStorage.setItem(this.aktiveHoldLocalString+"fromDate", initFromDate)
            localStorage.setItem(this.aktiveHoldLocalString+"toDate", initToDate)
            localStorage.setItem(this.aktiveHoldLocalString+"filter", "false")
            localStorage.setItem(this.aktiveHoldLocalString+"filterEmpty", "false")
            shouldUpdate.push("hold")
        }
        if (!aktiveELearning.fromDate && !aktiveELearning.toDate) {
            let initFromDate = this.formatHandler.date(new Date(now.getFullYear(), 0, 1), "DB")
            let initToDate = this.formatHandler.date(new Date(now.getFullYear()+1, 0, 1), "DB")
            localStorage.setItem(this.aktiveeLearningLocalString+"fromDate", initFromDate)
            localStorage.setItem(this.aktiveeLearningLocalString+"toDate", initToDate)
            localStorage.setItem(this.aktiveeLearningLocalString+"filter", "false")
            localStorage.setItem(this.aktiveeLearningLocalString+"filterEmpty", "false")
            shouldUpdate.push("eLearn")
        }

        if (shouldUpdate.length > 0)
            this.update(shouldUpdate)
    }

    validate(aktiveDate: HoldObjType, navn: string) {
        if (!aktiveDate.fromDate && !aktiveDate.toDate)
            return `Ingen aktive ${navn} datoer er valgt`
        else if (!aktiveDate.fromDate)
            return `Ingen aktive ${navn} 'fra dato' ikke er sat!`
        else if (!aktiveDate.toDate)
            return `Ingen aktive ${navn} 'til dato' ikke er sat!`

        const dateFrom = new Date(aktiveDate.fromDate)
        const dateTo = new Date(aktiveDate.toDate)

        if (dateFrom.getTime() > dateTo.getTime()) 
            return `Aktive ${navn} 'fra dato' er efter 'til datoen'!`

        const dateNow = new Date()
        if (dateNow.getTime() > dateTo.getTime()) 
            return `Nuværende dato er efter den valgte aktive ${navn} 'til dato'`
        return null
    }

    update(toUpdate: ('hold' | 'eLearn')[]) {
        if (toUpdate.includes("hold")) {
            const updateHold = this.getAktiveObj(this.aktiveHoldLocalString)
            this.setAktiveHold({
                ...updateHold,
                warning: this.validate(updateHold, "hold"),
            })
        }
        if (toUpdate.includes("eLearn")) {
            const updateELearn = this.getAktiveObj(this.aktiveeLearningLocalString)
            this.setAktiveELearning({
                ...updateELearn,
                warning: this.validate(updateELearn, "e-learning"),
            })
        }        
    }

    clearFilters() {
        localStorage.setItem(this.aktiveHoldLocalString+"filter", "false")
        localStorage.setItem(this.aktiveeLearningLocalString+"filter", "false")
        this.update(["hold", "eLearn"])
    }

    setFilterEmpty(name: "hold" | "eLearn", value: string) {
        if (name === "hold") {
            localStorage.setItem(this.aktiveHoldLocalString+"filterEmpty", value)
            this.update(["hold"])
        }
        if (name === "eLearn") {
            localStorage.setItem(this.aktiveeLearningLocalString+"filterEmpty", value)
            this.update(["eLearn"])
        }
    }
    
    setDate(name: "hold" | "eLearn", key: any, val: any) {
        if (name === "hold") {
            localStorage.setItem(this.aktiveHoldLocalString+key, this.formatHandler.date(val, "DB"))
            this.update(["hold"])
        }
        if (name === "eLearn") {
            localStorage.setItem(this.aktiveeLearningLocalString+key, this.formatHandler.date(val, "DB"))
            this.update(["eLearn"])
        }
    }
}
