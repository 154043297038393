import React from "react"
import { SvgComponentType } from "../../assetTypes"

interface ColorProps extends SvgComponentType {
    color: any
    size: any
    top?: any
    keyProp?: any
}

const Color: React.FC<ColorProps> = ({onClick, title, color, size, top, keyProp}) => {
    let colorKey = keyProp ? "default" : keyProp
    return (
        <svg key={colorKey} viewBox='0 0 100 100' style={{fill: color, height: size, top: top, position: "relative"}} xmlns='http://www.w3.org/2000/svg'>
            {title? <title>{title}</title> : null}
            <circle cx="50" cy="50" r="50" />
        </svg>
    )
}

export default Color