import React, { useContext, useEffect, useState } from 'react';

// HOOKS
import useDate from '../../hooks/useDate';

// CONTEXT
import { AppContext } from '../../context/AppContext';

// COMPONENTS
import DateTime from '../DateTime/DateTime';

// ASSETS
import Logo from '../../assets/logo/Logo';
import Burger from '../../assets/icons/indicators/Burger';
import Cross from '../../assets/icons/indicators/Cross';
import UserSilhouette from '../../assets/icons/indicators/UserSilhouette';
import Alert from '../../assets/icons/indicators/Alert';

// STYLE
import style from './PageHeaderBar.module.scss'

const PageHeaderBar: React.FC = () => {
    
    const { activeHoldHandler, dropDownMenuHandler, msgHandler, userData } = useContext(AppContext)
    const {aktiveHold, aktiveELearning} = activeHoldHandler

    const decideHeaderName = () => {
        if (userData.Brugernavn)
            return userData.Brugernavn
        if (userData.Fornavn || userData.Efternavn) {
            const displayNames = []
            if (userData.Fornavn)
                displayNames.push(userData.Fornavn)
            if (userData.Efternavn)
                displayNames.push(userData.Efternavn)
            return displayNames.join(" ")
        }
        return userData.Email
    }

    const event = {
        openProfileMenu: () => {
            dropDownMenuHandler.toggleProfileMenu()
        },
        showAlert: (message) => {
            msgHandler.add(message)
        }
    }

    return (
        <div className={style.component}>
            <div className={style.section}>
                {dropDownMenuHandler.dropDownMenu.settings ? 
                    <Cross className={style.cross} onClick={() => dropDownMenuHandler.toggleSettingsMenu(false)} /> 
                : 
                    <Burger className={style.burger} onClick={() => dropDownMenuHandler.toggleSettingsMenu(true)}/>
                }
                <div className={style.spacer}></div>
                <Logo className={style.logo} />
            </div>
            <p className={style.welcome}>{useDate().wish} {decideHeaderName()}</p>
            <div className={style.section}>
                {aktiveHold.warning || aktiveELearning.warning ?
                    <div className={style.Alert} title={aktiveHold.warning ? aktiveHold.warning : aktiveELearning.warning}>
                        <Alert onClick={() => event.showAlert(aktiveHold.warning ? aktiveHold.warning : aktiveELearning.warning)}/>
                    </div>
                : null}
                <DateTime />
                <div className={style.spacer}></div>
                <div className={style.profile_icon} 
                    style={{backgroundImage: userData.Img ? `url(${userData.Img})` : "none", backgroundSize: "cover"}}
                    onClick={() => event.openProfileMenu()}
                >
                    {!userData.Img ? 
                        <div className={style.img_placeholder}>
                            <UserSilhouette className={style.img_placeholder_silhouette}/>
                        </div>
                    : null}
                </div>
            </div>
        </div>
    )
}

export default PageHeaderBar;