import React, { ReactNode } from 'react'

// STYLE
import style from './Button.module.scss'

type ButtonProps = {
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    outlined?: boolean,
    rounded?: boolean
    small?: boolean,
    color?: string,
    children?: React.ReactNode | React.ReactNode[],
    active?: boolean,
    disabled?: boolean
    className?: String
}

const Button: React.FC<ButtonProps> = (props) => {
    const {
        children,
        rounded,
        small,
        color,
        outlined,
        onClick,
        active,
        disabled,
        className
    } = props

    const state = active ? "--active" : "";

    let classname = `${style.base}`

    if (rounded)
        classname += ` ${style.base_rounded}`

    let classSring = ""
    if (outlined) {
        if (color)
            classSring = `base_style_outlined_${color+state}`
        else
            classSring = `base_style_outlined${state}`
    } else {
        if (color)
            classSring = `base_style_default_${color+state}`
        else
            classSring = `base_style_default${state}`
    }

    classname += ` ${style[classSring]}`

    if (disabled)
        classname += ` ${style.disabled}`

    if (small)
        classname += ` ${style.small}`

    if (className)
        classname += ` ${className}`

    const event = {
        onMouseDown: (e: { preventDefault: () => void }) => {
            e.preventDefault();
            onClick
        }
    }

    return (
        <button className={classname} onMouseDown={(e) => event.onMouseDown(e)} onClick={(e) => onClick ? onClick(e) : console.log("button needs event")}>
            {children}
        </button>
    )
}

export default Button