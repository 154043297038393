import { useRef } from "react"
import Calendar from "../../../assets/icons/indicators/Calendar"
import Kurser from "../../../assets/icons/navigation/Kurser"
import Input from "../Input"

// STYLE
import style from './DateInput.module.scss'

const today = new Date()

export default function DateInput(props) {
    const ref = useRef(null)
    const {disabled, onInput, onChange, name, todayButton} = props

    let {value} = props

    const getTodayValue = () => {
        const today = new Date();
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'Europe/Copenhagen' };

        var dd = String(today.getDate('da-DK', options)).padStart(2, '0');
        var mm = String(today.getMonth('da-DK', options) + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear('da-DK', options);
        
       return yyyy + '-' + mm + '-' + dd;
    }

    const inputFunction = (e, pickToday) => {
        const value = pickToday ? getTodayValue() : e.target.value
        if (onInput)
            onInput(name, value)
        else if (onChange)
            onChange(name, value)
    }

    if (value === "nej" || value === null || value === "") {
        value = ""
    } else if (value.includes("T")) {
        value = new Date(value).toISOString().split("T")[0]
    } else if (value[2] === "-") {
        value = value.split("-").reverse().join("-")
    }

    const openPicker = (e) => {
        ref.current.showPicker()
    }

    return (
        <Input {...props}>
            <div className={style.component}>
                {disabled ? null : <>
                    {!todayButton ? null : <div className={style.component_today} onClick={(e) => inputFunction(e, true)}>i dag</div> }
                    <Calendar onClick={() => openPicker()} />
                </>}
                <input ref={ref} type="date" name={name} placeholder="DD-MM-YYYY" disabled={disabled} value={value ? value : ""} onChange={(e) => inputFunction(e)} onInput={(e) => inputFunction(e)} />
            </div>
        </Input>
    )
}