import React, { ComponentPropsWithoutRef, forwardRef, ForwardRefRenderFunction, LegacyRef, useRef } from 'react';

// style
import style from './Loading.module.scss'

interface SVGProps extends ComponentPropsWithoutRef<"svg"> {
  className?: string;
}

const Loading = React.forwardRef<SVGSVGElement, SVGProps>(({ className = '' }, ref) => {
  return (
    <svg
      className={className}
      ref={ref}
      width="96"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      style={{ strokeWidth: 10, strokeMiterlimit: 10 }}
    >
        <path style={{fill:"none"}} className={style.outline} strokeLinecap="round" strokeDashoffset="470" strokeDasharray="400 70" d="M118.36,121.42H10.17c-1.99,0-3.6-1.61-3.6-3.6V9.63c0-1.99,1.61-3.6,3.6-3.6h108.19c1.99,0,3.6,1.61,3.6,3.6
            v108.19C121.96,119.8,120.35,121.42,118.36,121.42z">
                <animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1.25s" keyTimes="0;0.2;1" values="0;100;470"></animate>
        </path>
        <g transform-origin="center" className={style.content}>
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s" keyTimes="0;0.65;1" values="360 0 0;360 0 0;0 0 0"></animateTransform >
            <path d="M35.6,94.96c0,0,11.34,2.29,11.34-7.42s0-41.03,0-41.03S35.6,46.24,35.6,54.48S35.6,94.96,35.6,94.96z">
            </path>
            <path d="M35.6,39.63h11.34V28.16C46.94,28.16,35.6,26.27,35.6,39.63z"/>
            <path d="M75.28,28.07c0,0-11.01-0.18-11.01,7.78s0,51.01,0,51.01s0.78,7.78,7.78,7.78s29.68,0,29.68,0s-0.67-11.43-7.83-11.43
                c-7.15,0-18.49,0-18.49,0V57.58h26.32c0,0,0.13-11.07-8.77-11.07s-17.51,0-17.51,0L75.28,28.07z"/>
        </g>
    </svg>
)})

Loading.displayName = "Loading"
export default Loading;