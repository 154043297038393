import { useState, useContext, useEffect } from "react"
import * as XLSX from 'xlsx/xlsx.mjs';

// CONTEXT
import client from "../../../../config/apolloClient";
import { AppContext } from "../../../../context/AppContext";

// COMPONETS
import CheckboxInput from "../../../Inputs/CheckboxInput/CheckboxInput";
import Input from "../../../Inputs/Input";
import Button from "../../../Button/Button";

// STYLE
import style from './TableInterfaceFilter.module.scss'
import CustomSelectInput from "../../../Inputs/SelectInput/CustomSelectInput";

const months = [
    'januar', 'februar', 'marts', 'april',
    'maj', 'juni', 'juli', 'august',
    'september', 'oktober', 'november', 'december'
];

const getMonths = months.map((m, i) => ({title: m, value: i+1}))

export default function TableInterfaceFilter(props) {
    const {
        activeHoldHandler,
        currentDate,
        getYears,
        dateBarFilter,
        filterOptions,
        setFilterOptions,
        filterAktive, 
        setFilterAktive,
        defaultOptions,
        name,
        searchString,
        setSearchString,
        filterOptionsLocalStorageString,
        aktiveFilterLocalStorageString,
        dateFilterLocalStorageString,
        excelQuery,
        primaryKey,
        customActions,
        aktivtFilter,
        dateValue,
        setDateValue
    } = props
    
    const {formatHandler} = useContext(AppContext)
    const [openFilter, setOpenFilter] = useState(false)

    const checkIfDefault = () => {
        return JSON.stringify(defaultOptions) === JSON.stringify(filterOptions)
    }

    const [isDefault, setIsDefault] = useState(checkIfDefault())
    const [excelLoading, setExcelLoading] = useState(false)

    useEffect(() => {
        localStorage.setItem(filterOptionsLocalStorageString, JSON.stringify(filterOptions));
        setIsDefault(checkIfDefault())
    },[filterOptions])

    useEffect(() => {
        localStorage.setItem(aktiveFilterLocalStorageString, filterAktive);
    },[filterAktive])

    useEffect(() => {
        localStorage.setItem(dateFilterLocalStorageString, JSON.stringify(dateValue));
    },[dateValue])

    const event = {
        input: (name, val) => {
            let updateObj = {...dateValue}
            updateObj[name] = val
            setDateValue(updateObj)
            setFilterAktive("")
        },
        dateSelect: (month, year) => {
            let updateObj = {...dateValue}
            updateObj.month = getMonths.find(m => m.title == month).value
            updateObj.year = getYears.find(y => y.title == year).value
            setDateValue(updateObj)
            setFilterAktive("")
            activeHoldHandler.clearFilters()
        },
        resetDateFilter: () => {
            setDateValue({
                month: null,
                year: null
            })
        },
        reset: () => {
            setFilterOptions(defaultOptions)
        },
        FilterInput: {
            search: (name, val) => {
                let updateObj = {...filterOptions}
                updateObj.search[name] = val
                setFilterOptions(updateObj)
            },
            table: (name, val) => {
                let updateObj = {...filterOptions}
                updateObj.table[name] = val
                setFilterOptions(updateObj)
            }
        },
        search: (name, val) => {
            setSearchString(val)
        },
        excelExport: async () => {
            setExcelLoading(true)
            let {data} = await client.query({query: excelQuery})
            const formattedContent = formatHandler.dataToReadable(data[Object.keys(data)[0]])
            const titles = Object.keys(formattedContent[0]).map(k => formatHandler.fieldToTitle(k, primaryKey)).filter(key => key != "__typename")
            let reMapped = formattedContent.map(obj => Object.keys(obj).filter(key => key != "__typename").map(key => obj[key]))

            const workBook = XLSX.utils.book_new();
            const workSheetData = [
                titles,
                ...reMapped
            ]

            const workSheet = XLSX.utils.aoa_to_sheet(workSheetData)
            XLSX.utils.book_append_sheet(workBook, workSheet, name)
            XLSX.writeFile(workBook, `${name}.xlsx`)

            setExcelLoading(false)
        },
        aktiveCheckbox: (key) => {
            if (filterAktive === key)
                setFilterAktive("")
            else 
                setFilterAktive(key)

            setDateValue({
                month: null,
                year: null
            })
        },
    }

    const getSortDates = () => {
        const monthsAhead = 3
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
      
        const sortedMonths = [];
      
        for (let i = 0; i < 12; i++) {
          let monthIndex = (currentMonth + monthsAhead - i) % 12;
          let year = currentYear;
      
          if (monthIndex < 0) {
            monthIndex += 12;
            year--;
          }

          if (i < monthsAhead+1 && monthIndex < currentMonth) {
            year++;
          }

          const datePreset = {
            currentMonth: i === monthsAhead && monthIndex === currentMonth,
            month: months[monthIndex],
            year: year
          }
          
          sortedMonths.push(datePreset);
        }

        return sortedMonths
      }

    return (
        <>
            {!dateBarFilter ? null : <div className={style.dateBarFilter}>
                {getSortDates().map((f, i) => {
                    return <span
                        key={"table_Dato_Filter_"+i}
                        className={(getMonths.find(m => m.title == f.month).value == dateValue.month && getYears.find(m => m.title == f.year).value == dateValue.year) ? style.dateBarFilter_preset_active : style.dateBarFilter_preset}
                        onClick={() => event.dateSelect(f.month, f.year)}
                    >
                        {f.currentMonth ? `( ${f.month} ${f.year} )` : `${f.month} ${f.year}`}
                    </span>
                })}
                <div className={style.dateBarFilter_selects}>
                    <CustomSelectInput 
                        options={getMonths} 
                        onInput={event.input}
                        name="month"
                        sort={{type: "number", dir: true, key: "value"}}
                        value={dateValue.month}
                        placeholder="Måned"
                    />
                    <CustomSelectInput 
                        options={getYears} 
                        onInput={event.input}
                        placeholder="År"
                        sort={{type: "number", dir: false, key: "title"}}
                        name="year"
                        value={dateValue.year}
                    />
                </div>
                {dateValue.month != null && dateValue.year != null ? 
                    <span onClick={() => event.resetDateFilter()} className={style.dateBarFilter_reset_button}>Fjern Dato filter</span>
                : null}
            </div>}
            <div className={style.component}>
                <div>
                    <Input style="search" placeholder="søg" value={searchString} onInput={event.search} resetButton={true} />
                    <Button active={openFilter} outlined rounded onClick={() => setOpenFilter(!openFilter)}>filter</Button>
                    {!isDefault ? <span onClick={() => event.reset()} className={style.reset_button}>Reset filter</span> : null}
                    {!aktivtFilter ? null : <>
                        <CheckboxInput title="baseret på dato configurationen på forsiden" onInput={() => event.aktiveCheckbox("hold")} name={"Aktivt"} value={filterAktive === "hold"} label="Vis kun aktive hold" />
                        <CheckboxInput title="baseret på dato configurationen på forsiden" onInput={() => event.aktiveCheckbox("eLearn")} name={"Aktivt"} value={filterAktive === "eLearn"} label="Vis kun aktive E-Learning" />
                    </>
                    }
                </div>
                <div>
                    {excelQuery ? 
                        <Button disabled={excelLoading} outlined rounded onClick={() => event.excelExport()}>{excelLoading ? "loading" : "excel"}</Button>
                    : null}
                    {customActions}
                </div>
            </div>
            {openFilter ? 
                <div className={style.filter_wrap}>
                    <div>
                        <p className={style.filter_wrap_title} title="Felter registreret af søge functionen">Søge felter</p>
                        <div className={style.filter_wrap_content}>
                            {Object.keys(filterOptions.search).map((k, i) => (
                                <CheckboxInput 
                                    title="Felt registreret af søge functionen"
                                    key={"searchOption"+k+i}
                                    value={filterOptions.search[k]} 
                                    label={formatHandler.fieldToTitle(k, primaryKey)}
                                    name={k}
                                    onInput={event.FilterInput.search}
                                />
                            ))}
                        </div>
                    </div>
                    <div>
                        <p className={style.filter_wrap_title} title="Felter der skal vises i tabellen">Tabel felter</p>
                        <div className={style.filter_wrap_content}>
                            {Object.keys(filterOptions.table).map((k, i) => (
                                <CheckboxInput
                                    title="Felt der skal vises i tabellen"
                                    key={"tableOption"+k+i}
                                    value={filterOptions.table[k]} 
                                    label={formatHandler.fieldToTitle(k, primaryKey)}
                                    name={k}
                                    onInput={event.FilterInput.table}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            : null}
        </>
    )
}