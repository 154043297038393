import { useContext, useEffect, useRef } from 'react'
import style from './SelectInput.module.scss'

import Input from '../Input'
import { AppContext } from '../../../context/AppContext'

export default function CustomSelectMenu(props) {
    const {selectMenuHandler} = useContext(AppContext)

    const {target, search, searchString, selected, options, setvalue} = props

    const listRef = useRef(null)

    let inputBounding = target.getBoundingClientRect()
    let scrollPos = window.scrollY
    let offSetY = () => {
        if ((inputBounding.top)+scrollPos > scrollPos+((window.innerHeight)/2))
            return `calc(-100% - 4px)`
        else
            return `${(inputBounding.height)+4}px`
    }

    let positionStyle = {
        top: inputBounding.top+scrollPos,
        left: inputBounding.left,
        width: inputBounding.width,
        transform: `translateY(${offSetY()})`
    }

    useEffect(() => {
        const selected = document.getElementById("selectedOption")
        if (!selected) return
        const container = selected.offsetParent
        
        const containerPos = {
            y: selected.offsetParent.scrollTop,
            height: selected.offsetParent.getBoundingClientRect().height,
        }

        const selectedPos = {
            y: selected.offsetTop,
            height: selected.getBoundingClientRect().height,
        }

        const relativePosition = (selectedPos.y+selectedPos.height)-(containerPos.y+containerPos.height)
        if (relativePosition > 0)
            container.scrollTop = containerPos.y+relativePosition
    
        if (selected.offsetTop < container.scrollTop)
            container.scrollTop = selected.offsetTop

    },[selectMenuHandler])

    const event = {
        select: (val) => {
            setvalue(val)
            selectMenuHandler.clear()
        },
        close: (e) => {
            if (listRef.current && e.target === listRef.current) {
                return
            }
            selectMenuHandler.clear()
        }
    }

    useEffect(() => {
        // document.querySelector("body").addEventListener("scroll", event.close)
        window.addEventListener("scroll", event.close, true)
        window.addEventListener("resize", event.close)
        return () => {
            // document.querySelector("body").removeEventListener("scroll", event.close)
            window.removeEventListener("scroll", event.close, true)
            window.removeEventListener("resize", event.close)
        }
    },[])

    return (
        <div className={style.custom_options} style={positionStyle}>
            {search ? 
                <div className={style.searchWrap} onMouseDown={(e) => e.preventDefault()}>
                    <Input value={searchString} name="selectSearch" style="search" placeholder="Skriv for at søge" className={style.custom_options_search} />
                </div>
            : null}
            <div className={style.custom_options_list} ref={listRef}>
                {options.map((o, i) => {
                    let active = selected == o.value;
                    return (
                        <div 
                            className={active ? style.active : ""} 
                            id={active ? "selectedOption" : ""}
                            key={`${o.title+i}`} 
                            value={o.value ? o.value : ""}
                            onMouseDown={(e) => e.preventDefault()}
                            onMouseUp={() => event.select(o.value)}
                        >
                            <span className={style.custom_option}>{o.jsx ? o.jsx : o.title}</span>
                        </div>
                    )
                })}
            </div>
        </div> 
    )
}