import style from "./PdfProof.module.scss";
import PdfDisplay from "./view/PdfDisplay";
import { PDFViewer, pdf } from "@react-pdf/renderer";

import { content } from "./content/content";
import { useContext, useEffect, useState } from "react";
import PdfEdit from "./view/PdfEdit";
import Options from "./options/Options";
import CourseOptions from "./options/CourseOptions";
import ListOptions from "./options/ListOptions";
import Button from "../../../Button/Button";
import JSZip, { file } from "jszip";
import { graphClient } from "../../../../config/graphClient";
import { AppContext } from "../../../../context/AppContext";
import Input from "../../../Inputs/Input";
import Loading from "../../../../assets/icons/loaders/Loading";
import CustomSelectInput from "../../../Inputs/SelectInput/CustomSelectInput";
import { mailClient } from "../../../../config/mailClient";
import AreaInput from "../../../Inputs/AreaInput/AreaInput";

export default function PdfProof({ downloadUrl, templates, data }) {
  const [templateArray, setTemplateArray] = useState(templates);
  const [selectedTempVal, setSelectedTempVal] = useState(downloadUrl);
  const { popupHandler, mailHandler, formatHandler } = useContext(AppContext);
  const [tab, setTab] = useState("edit");
  const [temp, setTemp] = useState({ ...content });
  const [editComponent, setEditComponet] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [kursistContents, setKursistContents] = useState([]);
  const [defaultKursistContents, setDefaultKursistContents] = useState([]);
  const [focus, setFocus] = useState([]);
  const [hover, setHover] = useState([]);
  const [copiedListItem, setCopiedListItem] = useState({ lvl: 0, item: null });

  const copyListItem = (i1, i2, i3) => {
    let obj =
      selectedIndex != null
        ? { ...kursistContents[selectedIndex] }
        : { ...temp };
    if (!obj) return;

    let path = editComponent;
    if (!Array.isArray(editComponent)) return;

    let currentObj = obj;

    for (const key of path.slice(0, -1)) {
      if (!currentObj.hasOwnProperty(key)) {
        console.error(`Key "${key}" not found in the object`);
        return obj;
      }
      currentObj = currentObj[key];
    }

    const lastKey = path[path.length - 1];
    if (typeof i3 != "undefined" && i3 != null) {
      const toCopy = JSON.stringify(
        currentObj[lastKey].content[i1].content[i2].content[i3]
      );
      setCopiedListItem({ lvl: 3, item: { ...JSON.parse(toCopy) } });
    } else if (typeof i2 != "undefined" && i2 != null) {
      const toCopy = JSON.stringify(
        currentObj[lastKey].content[i1].content[i2]
      );
      setCopiedListItem({ lvl: 2, item: { ...JSON.parse(toCopy) } });
    } else if (typeof i1 != "undefined" && i1 != null) {
      const toCopy = JSON.stringify(currentObj[lastKey].content[i1]);
      setCopiedListItem({ lvl: 1, item: { ...JSON.parse(toCopy) } });
    }
  };

  const insetCopiedListItem = (i1, i2) => {
    let obj =
      selectedIndex != null
        ? { ...kursistContents[selectedIndex] }
        : { ...temp };
    if (!obj) return;

    let path = editComponent;
    if (!Array.isArray(editComponent)) return;

    let currentObj = obj;

    for (const key of path.slice(0, -1)) {
      if (!currentObj.hasOwnProperty(key)) {
        console.error(`Key "${key}" not found in the object`);
        return obj;
      }
      currentObj = currentObj[key];
    }

    const lastKey = path[path.length - 1];
    if (typeof i2 === "undefined") {
      if (typeof i1 === "undefined") {
        currentObj[lastKey].content.push(copiedListItem.item);
      } else {
        currentObj[lastKey].content[i1].content.push(copiedListItem.item);
      }
    } else {
      currentObj[lastKey].content[i1].content[i2].content.push(
        copiedListItem.item
      );
    }

    if (selectedIndex != null) {
      const updatedKursistContent = [...kursistContents];
      updatedKursistContent[selectedIndex] = obj;
      setKursistContents(updatedKursistContent);
    } else setTemp({ ...obj });

    setCopiedListItem({ lvl: 0, item: null });
  };

  const updateTemplates = async (fileName) => {
    // const templateOptions = await graphClient.api(`/sites/root/drive/items/root:/testfolder:/children/?select=id,name,@microsoft.graph.downloadUrl`).get()
    const site = await graphClient
      .api(`/sites/itucationprod.sharepoint.com:/sites/adm:/`)
      .get();
    const templateOptions = await graphClient
      .api(
        `/sites/${site.id}/drive/items/root:/x-drev/kursusadministration/Kursusbeviser/CarlKursusbeviser:/children/?select=id,name,@microsoft.graph.downloadUrl`
      )
      .get();

    // return
    const options = templateOptions.value
      .map((o) => ({
        title: o.name,
        value: o["@microsoft.graph.downloadUrl"],
        id: o.id,
      }))
      .filter((o2) => o2.title && o2.title.endsWith(".json"));

    let newSelected;

    if (fileName) {
      let newfileName = `${fileName.toString()}.json`;
      let modifiedString = newfileName.replace("\n", "");
      newSelected = options.find((op, i) => `${op.title}` == modifiedString);
    } else newSelected = options.find((op, i) => `${op.title}` == "Blank.json");
    if (!newSelected) newSelected = options[0];

    setTemplateArray(options);
    setSelectedTempVal(newSelected.value);
  };

  const updateKursistContents = (templateValue) => {
    const newKursistobjs = data.map((kursist, i) => {
      const obj = JSON.parse(JSON.stringify(templateValue));
      replaceWords(obj, i);
      return obj;
    });
    setDefaultKursistContents(JSON.parse(JSON.stringify(newKursistobjs)));
    setKursistContents(newKursistobjs);
  };

  useEffect(() => {
    const getTemplateJson = async () => {
      const response = await fetch(selectedTempVal, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        updateTemplates();
        return;
      }
      const fileBinary = await response.arrayBuffer();

      const parsedJson = JSON.parse(new TextDecoder().decode(fileBinary));

      updateKursistContents(parsedJson);
      setTemp(parsedJson);
    };
    getTemplateJson();
  }, [selectedTempVal]);

  const addPage = () => {
    let obj =
      selectedIndex != null
        ? { ...kursistContents[selectedIndex] }
        : { ...temp };

    obj.page2 = {
      coloumn1: {
        content: [
          {
            value: "Ny kolonne",
            content: [],
          },
        ],
        style: {
          ...obj.coloumn1.style,
          marginTop: "190",
        },
      },
      coloumn2: {
        content: [
          {
            value: "Ny kolonne",
            content: [],
          },
        ],
        style: {
          ...obj.coloumn2.style,
          marginTop: "190",
        },
      },
    };
    if (selectedIndex != null) {
      const updatedKursistContent = [...kursistContents];
      updatedKursistContent[selectedIndex] = obj;
      setKursistContents(updatedKursistContent);
    } else setTemp({ ...obj });
  };

  const removePage = () => {
    let obj =
      selectedIndex != null
        ? { ...kursistContents[selectedIndex] }
        : { ...temp };

    delete obj.page2;
    if (selectedIndex != null) {
      const updatedKursistContent = [...kursistContents];
      updatedKursistContent[selectedIndex] = obj;
      setKursistContents(updatedKursistContent);
    } else setTemp({ ...obj });
  };

  const updateTemp = (value, updateKey, pathRef) => {
    let path = pathRef ? pathRef : editComponent;
    let obj =
      selectedIndex != null
        ? { ...kursistContents[selectedIndex] }
        : { ...temp };

    if (!Array.isArray(path)) return;
    let currentObj = obj;

    for (const key of path.slice(0, -1)) {
      if (!currentObj.hasOwnProperty(key)) {
        console.error(`Key "${key}" not found in the object`);
        return obj;
      }
      currentObj = currentObj[key];
    }

    const lastKey = path[path.length - 1];
    if (updateKey === "value") currentObj[lastKey][updateKey] = value;
    else {
      const updateStyleObj = { ...currentObj[lastKey].style };
      updateStyleObj[updateKey] = value;
      currentObj[lastKey].style = updateStyleObj;
    }
    if (selectedIndex != null) {
      const updatedKursistContent = [...kursistContents];
      updatedKursistContent[selectedIndex] = obj;
      setKursistContents(updatedKursistContent);
    } else setTemp({ ...obj });
  };

  const moveListItem = (dir, i1, i2, i3) => {
    let obj =
      selectedIndex != null
        ? { ...kursistContents[selectedIndex] }
        : { ...temp };
    if (!obj) return;

    let path = editComponent;
    if (!Array.isArray(editComponent)) return;

    let currentObj = obj;

    for (const key of path.slice(0, -1)) {
      if (!currentObj.hasOwnProperty(key)) {
        console.error(`Key "${key}" not found in the object`);
        return obj;
      }
      currentObj = currentObj[key];
    }

    const lastKey = path[path.length - 1];
    if (typeof i3 != "undefined" && i3 != null) {
      const toMove = currentObj[lastKey].content[i1].content[i2].content.splice(
        i3,
        1
      );
      currentObj[lastKey].content[i1].content[i2].content.splice(
        i3 + dir < 0 ? 0 : i3 + dir,
        0,
        toMove[0]
      );
    } else if (typeof i2 != "undefined" && i2 != null) {
      const toMove = currentObj[lastKey].content[i1].content.splice(i2, 1);
      currentObj[lastKey].content[i1].content.splice(
        i2 + dir < 0 ? 0 : i2 + dir,
        0,
        toMove[0]
      );
    } else if (typeof i1 != "undefined" && i1 != null) {
      const toMove = currentObj[lastKey].content.splice(i1, 1);
      currentObj[lastKey].content.splice(
        i1 + dir < 0 ? 0 : i1 + dir,
        0,
        toMove[0]
      );
    }

    if (selectedIndex != null) {
      const updatedKursistContent = [...kursistContents];
      updatedKursistContent[selectedIndex] = obj;
      setKursistContents(updatedKursistContent);
    } else setTemp({ ...obj });
  };

  const addListItem = (i1, i2) => {
    let obj =
      selectedIndex != null
        ? { ...kursistContents[selectedIndex] }
        : { ...temp };

    console.log(obj, "OOOO");
    let path = editComponent;
    if (!Array.isArray(editComponent)) return;

    let currentObj = obj;

    for (const key of path.slice(0, -1)) {
      if (!currentObj.hasOwnProperty(key)) {
        console.error(`Key "${key}" not found in the object`);
        return obj;
      }
      currentObj = currentObj[key];
    }

    const lastKey = path[path.length - 1];
    if (typeof i2 === "undefined") {
      if (typeof i1 === "undefined") {
        currentObj[lastKey].content.push({ value: "ny tekst", content: [] });
      } else {
        currentObj[lastKey].content[i1].content.push({
          value: "ny tekst",
          content: [],
        });
      }
    } else {
      currentObj[lastKey].content[i1].content[i2].content.push({
        value: "ny tekst",
      });
    }

    if (selectedIndex != null) {
      const updatedKursistContent = [...kursistContents];
      updatedKursistContent[selectedIndex] = obj;
      setKursistContents(updatedKursistContent);
    } else setTemp({ ...obj });
  };

  const removeListItem = (i1, i2, i3) => {
    let obj =
      selectedIndex != null
        ? { ...kursistContents[selectedIndex] }
        : { ...temp };

    let path = editComponent;
    if (!Array.isArray(editComponent)) return;

    let currentObj = obj;

    for (const key of path.slice(0, -1)) {
      if (!currentObj.hasOwnProperty(key)) {
        console.error(`Key "${key}" not found in the object`);
        return obj;
      }
      currentObj = currentObj[key];
    }

    const lastKey = path[path.length - 1];
    if (typeof i3 === "undefined") {
      if (typeof i2 === "undefined") currentObj[lastKey].content.splice(i1, 1);
      else currentObj[lastKey].content[i1].content.splice(i2, 1);
    } else currentObj[lastKey].content[i1].content[i2].content.splice(i3, 1);

    if (selectedIndex != null) {
      const updatedKursistContent = [...kursistContents];
      updatedKursistContent[selectedIndex] = obj;
      setKursistContents(updatedKursistContent);
    } else setTemp({ ...obj });
  };

  const updateListValues = (val, i1, i2, i3) => {
    let path = editComponent;
    let obj =
      selectedIndex != null
        ? { ...kursistContents[selectedIndex] }
        : { ...temp };

    if (!Array.isArray(path)) return;
    let currentObj = obj;

    for (const key of path.slice(0, -1)) {
      if (!currentObj.hasOwnProperty(key)) {
        console.error(`Key "${key}" not found in the object`);
        return obj;
      }
      currentObj = currentObj[key];
    }

    const lastKey = path[path.length - 1];
    if (typeof i3 === "undefined") {
      if (typeof i2 === "undefined")
        currentObj[lastKey].content[i1].value = val;
      else currentObj[lastKey].content[i1].content[i2].value = val;
    } else currentObj[lastKey].content[i1].content[i2].content[i3].value = val;

    if (selectedIndex != null) {
      const updatedKursistContent = [...kursistContents];
      updatedKursistContent[selectedIndex] = obj;
      setKursistContents(updatedKursistContent);
    } else setTemp({ ...obj });
  };

  const formatReadableString = (string) => {
    const cleanedString = string.replace(/[^\w\s\dæøåÆØÅ]/gu, " ");

    const finalString = cleanedString.replace(/\s+/g, " ");

    return finalString.trim();
  };

  const replaceWords = (obj, index, needsFormat) => {
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        let formatText = key === "name";

        replaceWords(obj[key], index, formatText);
      } else if (key === "value" && typeof obj[key] === "string") {
        let regex = /<<(\w+)>>/g;
        let replacedString = obj[key].replace(
          regex,
          (match, keynew) => data[index][keynew] || match
        );
        if (needsFormat) replacedString = formatReadableString(replacedString);

        obj[key] = replacedString;
      }
    }
  };

  const downloadPDFs = () => {
    const getPdfBlob = async () => {
      let promises = kursistContents.map(async (content, i) => {
        return pdf(<PdfDisplay content={content} />).toBlob();
      });

      const pdfBlobs = await Promise.all(promises);

      const zip = JSZip();

      const selectedTemplate = templateArray.find(
        (t) => t.value === selectedTempVal
      );

      pdfBlobs.forEach((obj, index) => {
        zip.file(`${data[index].Fornavn} ${data[index].Efternavn}.pdf`, obj);
      });

      zip
        .generateAsync({ type: "blob" })
        .then((zipBlob) => {
          // Create a download link for the ZIP file
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(zipBlob);
          downloadLink.download = selectedTemplate.title + " beviser" + ".zip";

          // Trigger the click event to prompt the user to download the ZIP file
          downloadLink.click();
        })
        .catch((error) => {
          console.error("Error generating ZIP file:", error);
        });
    };
    getPdfBlob();
  };

  const uploadPDFs = () => {
    popupHandler.add(<UploadTemplateComponent />);
  };

  const saveTemplateConfirm = () => {
    popupHandler.add(<SaveTemplateComponent />);
  };

  // const urlEncodeFileName = (fileName) => {
  //   fileName = fileName.replace("#", "%23")
  //   fileName = fileName.replace("-", "%2D")
  //   return fileName
  // }

  const SaveTemplateComponent = () => {
    let autoName = [
      temp.base.course.value,
      temp.base.course_sub_1.value,
      temp.base.course_sub_2.value,
    ]
      .filter((val) => val != "")
      .join(" ");
    autoName = autoName.replace("\n", "");
    autoName = autoName.replace(/ +(?= )/g, "");

    const [templateName, setTemplateName] = useState(autoName);

    const event = {
      input: (name, val) => {
        setTemplateName(val);
      },
    };

    return (
      <div className={style.saveTemplateConfirm}>
        <Input
          value={templateName}
          name={"templateName"}
          label="Template navn"
          onInput={event.input}
        />
        <div className={style.saveTemplateConfirm_actions}>
          <Button onClick={() => popupHandler.remove(2)} outlined>
            Luk
          </Button>
          <Button onClick={() => saveTemplate(templateName)}>Gem</Button>
        </div>
      </div>
    );
  };

  const UploadTemplateComponent = () => {
    let autoName = [
      temp.base.course.value,
      temp.base.course_sub_1.value,
      temp.base.course_sub_2.value,
      data[0].Startdato,
    ]
      .filter((val) => val != "")
      .join(" ");
    autoName = autoName.replace("\n", "");
    autoName = autoName.replace(/ +(?= )/g, "");
    autoName = autoName.replace("/", "-");

    if (autoName[autoName.length - 1] == " ") {
      autoName = autoName.slice(autoName.length, i);
    }

    const [templateName, setTemplateName] = useState(autoName);

    const event = {
      input: (name, val) => {
        setTemplateName(val);
      },
    };

    return (
      <div className={style.saveTemplateConfirm}>
        <span className={style.pathDisplay}>
          {"x-drev > Kursusdminstration > Kursusbeviser > CarlKursusBeviser > Hold > " +
            autoName}
        </span>
        <Input
          value={templateName}
          name={"Mappenavn"}
          label="Mappe navn"
          onInput={event.input}
        />
        <div className={style.saveTemplateConfirm_actions}>
          <Button onClick={() => popupHandler.remove(2)} outlined>
            Luk
          </Button>
          <Button onClick={() => saveResult(templateName)}>Gem</Button>
        </div>
      </div>
    );
  };

  const uploadKursusBeviserSharePoint = async (folder, site, pdfBlobs) => {
    try {
      let uploadPrommises = pdfBlobs.map((blobData, i) => {
        return graphClient
          .api(
            `/sites/${site.id}/drive/items/root:/x-drev/kursusadministration/Kursusbeviser/CarlKursusbeviser/Hold/${folder.name}/${blobData.name}:/content`
          )
          .put(blobData.blob);
      });
      const resolve = Promise.all(uploadPrommises);

      if (resolve) {
        popupHandler.replace(
          2,
          <div className={style.delete_card}>
            <span>beviser uploaded til sharePoint!</span>
            <a href={folder.webUrl} target="_blank" className={style.link}>
              Link til mappe
            </a>
          </div>
        );
      }
    } catch (err) {
      popupHandler.replace(
        2,
        <div className={style.saveTemplateConfirm}>
          <p>
            {"message" in err
              ? err.message
              : "der er sket en fejl... templaten er ikke gemt"}
          </p>
        </div>
      );
    }
  };


  const checkUploadKursusBeviserSharePoint = async (folder, site) => {
    try {
      const Existingbeviser = await graphClient
        .api(
          `/sites/${site.id}/drive/items/root:/x-drev/kursusadministration/Kursusbeviser/CarlKursusbeviser/Hold/${folder.name}:/children`
        )
        .get();

      let promises = kursistContents.map(async (content, i) => {
        return pdf(<PdfDisplay content={content} />).toBlob();
      });

      const pdfBlobs = await Promise.all(promises);

      const pdfData = kursistContents.map((kContent, i) => ({
        name: `Kursusbevis ${formatReadableString(
          kContent.base.name.value
        )}.pdf`,
        blob: pdfBlobs[i],
      }));

      const countSameNames = () => {
        const nameCount = pdfData
          .concat(Existingbeviser.value)
          .reduce((acc, obj) => {
            const name = obj.name;
            acc[name] = (acc[name] || 0) + 1;
            return acc;
          }, {});

        return Object.values(nameCount).filter((count) => count > 1).length;
      };

      const overWriteCount = countSameNames();
      if (overWriteCount > 0) {
        popupHandler.replace(
          2,
          <div className={style.delete_card}>
            <span>Dette vil overskrive {overWriteCount} filer</span>
            <div>
              <Button outlined onClick={() => popupHandler.remove(2)}>
                Tilbage
              </Button>
              <Button
                onClick={() =>
                  uploadKursusBeviserSharePoint(folder, site, pdfData)
                }
              >
                Overskriv
              </Button>
            </div>
          </div>
        );
      } else {
        uploadKursusBeviserSharePoint(folder, site, pdfData);
      }
    } catch (err) {
      popupHandler.replace(
        2,
        <div className={style.saveTemplateConfirm}>
          <p>
            {"message" in err
              ? err.message
              : "fejl i forbindelsen med sharepoint"}
          </p>
        </div>
      );
    }
  };

  const createSharePointFolder = async (fileName, site) => {
    try {
      const driveItem = {
        name: fileName,
        folder: {},
      };

      const parentFolder = await graphClient
        .api(
          `/sites/${site.id}/drive/items/root:/x-drev/kursusadministration/Kursusbeviser/CarlKursusbeviser/Hold`
        )
        .get();
      const newFolder = await graphClient
        .api(`/sites/${site.id}/drive/items/${parentFolder.id}/children`)
        .post(driveItem);
      if (newFolder) {
        await checkUploadKursusBeviserSharePoint(newFolder, site);
      }
    } catch (err) {
      popupHandler.replace(
        2,
        <div className={style.saveTemplateConfirm}>
          <p>
            {"message" in err
              ? err.message
              : "der er sket en fejl... beviserne er ikke gemt"}
          </p>
        </div>
      );
    }
  };

  const saveResult = async (fileName, overWrite) => {
    popupHandler.replace(
      2,
      <div className={style.saveTemplateConfirm}>
        <Loading />
      </div>
    );
    try {
      const site = await graphClient
        .api(`/sites/itucationprod.sharepoint.com:/sites/adm:/`)
        .get();
      try {
        let containerFolder = await graphClient
          .api(
            `/sites/${site.id}/drive/items/root:/x-drev/kursusadministration/Kursusbeviser/CarlKursusbeviser/Hold/${fileName}`
          )
          .get();
        await checkUploadKursusBeviserSharePoint(containerFolder, site);
      } catch (err) {
        if (err.statusCode !== 404) throw err;
        await createSharePointFolder(fileName, site);
      }
    } catch (err) {
      popupHandler.replace(
        2,
        <div className={style.saveTemplateConfirm}>
          <p>
            {"message" in err
              ? err.message
              : "der er sket en fejl... beviserne er ikke gemt"}
          </p>
        </div>
      );
    }

    return;
  };

  const saveTemplate = async (fileName, overWrite) => {
    popupHandler.replace(
      2,
      <div className={style.saveTemplateConfirm}>
        <Loading />
      </div>
    );
    try {
      const site = await graphClient
        .api(`/sites/itucationprod.sharepoint.com:/sites/adm:/`)
        .get();

      // Check existing file
      let existingFile;
      if (!overWrite) {
        try {
          let filenameEncoded = fileName.replace("/", "%2F");
          existingFile = await graphClient
            .api(
              `/sites/${site.id}/drive/items/root:/x-drev/kursusadministration/Kursusbeviser/CarlKursusbeviser/${fileName}.json`
            )
            .get();
        } catch (err) {
          if (err.statusCode !== 404) {
            throw err; // Re-throw other errors
          }
        }
        if (existingFile) {
          popupHandler.replace(
            2,
            <div className={style.delete_card}>
              <span>
                Der findes allerede en template med navnet "{fileName}"
              </span>
              <div>
                <Button
                  outlined
                  onClick={() =>
                    popupHandler.replace(2, <SaveTemplateComponent />)
                  }
                >
                  Tilbage
                </Button>
                <Button onClick={() => saveTemplate(fileName, true)}>
                  Overskriv
                </Button>
              </div>
            </div>
          );
          return;
        }
      }

      const fileContentString = JSON.stringify(temp);

      // Convert the JSON string to Uint8Array
      const bytes = new TextEncoder().encode(fileContentString);

      const blobData = new Blob([bytes], {
        type: "application/json;charset=utf-8",
      });
      let filenameEncoded = fileName.replace("#", "%23");
      filenameEncoded = filenameEncoded.replace("/", "-");
      const uploadedFile = await graphClient
        .api(
          `/sites/${site.id}/drive/items/root:/x-drev/kursusadministration/Kursusbeviser/CarlKursusbeviser/${filenameEncoded}.json:/content`
        )
        .put(blobData);
      if (uploadedFile) {
        console.log("hasFileName", fileName);
        updateTemplates(fileName);
        popupHandler.remove(2);
      }
    } catch (err) {
      popupHandler.replace(
        2,
        <div className={style.saveTemplateConfirm}>
          <p>
            {"message" in err
              ? err.message
              : "der er sket en fejl... templaten er ikke gemt"}
          </p>
        </div>
      );
    }
  };

  const DeleteCard = () => {
    const [isDeleting, setIsDeleting] = useState(false);

    const deleteInit = () => {
      setIsDeleting(true);
      deleteConfirm();
    };

    return (
      <div className={style.delete_card}>
        {isDeleting ? (
          <Loading />
        ) : (
          <>
            <span>Er du sikker på at du vil slette templaten?</span>
            <div>
              <Button outlined onClick={() => popupHandler.remove(2)}>
                Luk
              </Button>
              <Button onClick={() => deleteInit()}>Slet</Button>
            </div>
          </>
        )}
      </div>
    );
  };

  const deleteTemplate = () => {
    popupHandler.add(<DeleteCard />);
  };

  const deleteConfirm = async () => {
    try {
      const selectedTemplate = templateArray.find(
        (templateItem) => templateItem.value === selectedTempVal
      );
      const site = await graphClient
        .api(`/sites/itucationprod.sharepoint.com:/sites/adm:/`)
        .get();
      await graphClient
        .api(`/sites/${site.id}/drive/items/${selectedTemplate.id}`)
        .delete();
      updateTemplates();
      popupHandler.remove(2);
    } catch (err) {
      console.log(err);
    }
  };

  const MailConfirm = () => {
    const {msgHandler} = useContext(AppContext)
    let mailContentTemplate = mailHandler.contentTemplate
    var parts = data[0].Startdato.split('-');
    var validDate = new Date(parts[2], parts[1] - 1, parts[0]);
    let Startdato = formatHandler.date(validDate, "long")
    let updatedMailContentTemplate = mailContentTemplate.replace("<<StartDato>>", Startdato)
    const [mailContent, setMailContent] = useState(updatedMailContentTemplate)
    const [sendMailState, setSendMailState] = useState({state: "", message: ""})


    useEffect(() => {
      if (sendMailState.state === "success" || sendMailState.state === "error") {
        setTimeout(() => {
          popupHandler.remove(3)
          msgHandler.add(sendMailState.message, 6)
        }, 1500);
      }

    },[sendMailState])

    return (
      <div className={style.mail}>
        {sendMailState.state === "loading" || sendMailState.state === "error" || sendMailState.state === "success" ? 
          <div className={style.loading_overlay}>
            <div className={style.loading_overlay_content}>
              {sendMailState.state === "error" ? 
                <>
                  <p>FEJL</p>
                  <p>{sendMailState.message}</p>
                </>
              : sendMailState.state === "success" ? 
                <>
                  <p>Success</p>
                  <p>{sendMailState.message}</p>
                </>
              : 
                <>
                  <Loading />
                  <p>{sendMailState.message}</p>
                </>
              }
            </div>
          </div>
        : null
        }
          <>
            <span>Dette vil sende mails til {kursistContents.length} kursister er du sikker?</span>
            <div className={style.mail_preview}>
              {mailHandler.kursusBevisPreview(
                mailContent,
                <AreaInput 
                  className={style.mail_preview_content}
                  value={mailContent}
                  autoSize={true}
                  onInput={(name, val) => setMailContent(val)}
                />
              )}
            </div>
            <div>
              <Button outlined onClick={() => popupHandler.remove(2)}>
                Luk
              </Button>
              <Button onClick={() => mailHandler.sendKursusBeviser(kursistContents, data, mailContent, setSendMailState)}>Send beviser</Button>
            </div>
          </>
      </div>
    );
  }

  const sendMail = async () => {
    popupHandler.add(<MailConfirm />)
  }

  return (
    <div className={style.component}>
      {popupHandler.layer(2)}
      <div className={style.list}>
        <div
          onClick={() => setSelectedIndex(null)}
          className={[
            style.list_template,
            selectedIndex === null ? style.selected : "",
          ].join(" ")}
        >
          <span>{"Template"}</span>
        </div>
        {data.map((d, i) => (
          <div
            key={i + d.Mail}
            className={selectedIndex === i ? style.selected : ""}
            onClick={() => setSelectedIndex(i)}
          >
            <span>{d.Fornavn + " " + d.Efternavn}</span>
            {JSON.stringify(defaultKursistContents[i]) !=
            JSON.stringify(kursistContents[i]) ? (
              <span className={style.changeIndicator}>!</span>
            ) : null}
          </div>
        ))}
      </div>
      <div className={style.view}>
        <div className={style.view_tabs}>
          <div>
            <span
              onClick={() => setTab("view")}
              className={tab === "view" ? style.view_tabs_selected : ""}
            >
              View
            </span>
            <span
              onClick={() => setTab("edit")}
              className={tab === "edit" ? style.view_tabs_selected : ""}
            >
              Rediger
            </span>
          </div>
          <CustomSelectInput
            search={true}
            className={style.templateSelect}
            name="templateOption"
            options={templateArray}
            value={selectedTempVal}
            onInput={(name, val) => setSelectedTempVal(val)}
          />
        </div>
        <div className={style.view_display}>
          {tab === "view" ? (
            <PDFViewer className={style.pdfViewer}>
              <PdfDisplay
                content={
                  selectedIndex != null ? kursistContents[selectedIndex] : temp
                }
              />
            </PDFViewer>
          ) : (
            <PdfEdit
              addPage={addPage}
              removePage={removePage}
              updateContent={updateTemp}
              content={
                selectedIndex != null ? kursistContents[selectedIndex] : temp
              }
              editComponent={editComponent}
              setEditComponet={setEditComponet}
              focus={focus}
              hover={hover}
            />
          )}
        </div>
        <div className={style.bottemBar}>
          <div onClick={() => saveTemplateConfirm()}>
            <p>GEM</p>
            <p>TEMPLATE</p>
          </div>
          <div onClick={() => deleteTemplate()}>
            <p>SLET</p>
            <p>TEMPLATE</p>
          </div>
          <div onClick={() => downloadPDFs()}>
            <p>DOWNLOAD</p>
            <p>BEVISER</p>
          </div>
          <div onClick={() => uploadPDFs()}>
            <p>GEM</p>
            <p>SHAREPOINT</p>
          </div>
          <div onClick={() => sendMail()}>
            <p>SEND</p>
            <p>BEVISER</p>
          </div>
        </div>
      </div>
      <div className={style.options}>
        {editComponent === null || tab === "view" ? null : editComponent[
            editComponent.length - 1
          ] === "course" ? (
          <CourseOptions
            setHover={setHover}
            setFocus={setFocus}
            editComponent={editComponent}
            temp={selectedIndex != null ? kursistContents[selectedIndex] : temp}
            updateTemp={updateTemp}
          />
        ) : editComponent[0] === "coloumn1" ||
          editComponent[0] === "coloumn2" ||
          editComponent[0] === "page2" ? (
          <ListOptions
            setHover={setHover}
            setFocus={setFocus}
            editComponent={editComponent}
            temp={selectedIndex != null ? kursistContents[selectedIndex] : temp}
            updateTemp={updateTemp}
            addListItem={addListItem}
            removeListItem={removeListItem}
            updateListValues={updateListValues}
            moveListItem={moveListItem}
            copyListItem={copyListItem}
            copiedListItem={copiedListItem}
            insetCopiedListItem={insetCopiedListItem}
          />
        ) : (
          <Options
            setHover={setHover}
            setFocus={setFocus}
            editComponent={editComponent}
            temp={selectedIndex != null ? kursistContents[selectedIndex] : temp}
            updateTemp={updateTemp}
          />
        )}
      </div>
    </div>
  );
}
