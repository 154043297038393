import React from "react";
import Loading from "../../assets/icons/loaders/Loading";
import { Doughnut, Pie } from "react-chartjs-2";
import {
  BubbleDataPoint,
  ChartData,
  PluginOptionsByType,
  Point,
} from "chart.js";
import { _DeepPartialObject } from "chart.js/dist/types/utils";

import style from "./StatistikPage.module.scss";
import { ThemeHandler } from "../../functions/ThemeHandler";

interface DoughnutChartProps {
  data:
    | ChartData<
        "doughnut",
        (number | Point | [number, number] | BubbleDataPoint)[],
        unknown
      >
    | string;
  loading: boolean;
  themeHandler: ThemeHandler;
}

const PieChart: React.FC<DoughnutChartProps> = ({
  data,
  loading,
  themeHandler,
}) => {
  if (typeof data === "string" || loading)
    return <Loading className={style.loading} />;

  const options = {
    responsive: true,
    animation: false,
    datasets: {
      doughnut: {
        borderWidth: 0,
        offset: 6,
        radius: 75,
      },
    },
    plugins: {
      doughnutShadow: {
        shadowColor: "rgba(0, 0, 0, 0.075)",
        shadowColorInner: "rgba(0, 0, 0, 0.05)",
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 9,
        shadowBlurInner: 4,
        bgFillColor: themeHandler.getColor("base-color"),
        display: true,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  };

  return <Doughnut data={data} options={options} />;
};

export default PieChart;
