import React from "react"
import { SvgComponentType } from "../../assetTypes"

const StatistikSVG: React.FC<SvgComponentType> = ({onClick, title}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" onClick={(e) => onClick(e)} style={{strokeWidth: 5}}>
            <g id="graph">
                <line x1="2.5" y1="120.45" x2="119.57" y2="120.45" />
                <path
                d="M13.65,122.59V99.35s.11-4.62,4.62-4.62h13S35,95.36,35,98.44v23.41"
                transform="translate(-2.97 -2.52)"
                />
                <path
                d="M53.39,121.45V79a4,4,0,0,1,4.34-4.34H70.86s3.86.28,3.86,3.85v42.91"
                transform="translate(-2.97 -2.52)"
                />
                <path
                d="M93.81,121.45V43.8S93.36,40,97.58,40h13.36a3.46,3.46,0,0,1,3.72,3.71v77.71"
                transform="translate(-2.97 -2.52)"
                />
                <line x1="15.02" y1="73.33" x2="15.78" y2="72.68" />
                <path d="M26.06,68" transform="translate(-2.97 -2.52)" />
                <line x1="80.57" y1="32.03" x2="111.69" y2="2.51" />
                <line x1="95.76" y1="2.5" x2="111.23" y2="2.5" />
                <line x1="111.82" y1="16.84" x2="111.82" y2="3.45" />
                <line x1="69.35" y1="21.68" x2="80.26" y2="32.03" />
                <line x1="69.04" y1="21.48" x2="24.12" y2="64.27" />
            </g>
        </svg>
    )
}

export default StatistikSVG