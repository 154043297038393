import React, { useContext, useState } from "react";

// CONTEXT
import { AppContext } from "../../../context/AppContext";

// STYLE
import style from "./TabInterface.module.scss";

interface TabInterfaceProps {
  children: any;
  tabs: any;
  scrollable?: any;
  size?: any;
}

const TabInterface: React.FC<TabInterfaceProps> = ({
  children,
  tabs,
  scrollable,
  size,
}) => {
  const [tab, setTab] = useState(0);
  const { popupHandler } = useContext(AppContext);
  const [layer] = useState(popupHandler.nextLayer());
  const content = Array.isArray(children) ? children : [children];

  let componentSizeClass = "";

  switch (size) {
    case "mid":
      componentSizeClass = style.component_size_mid;
      break;
    case "small":
      componentSizeClass = style.component_size_small;
      break;
    case "auto":
      componentSizeClass = style.component_size_auto;
      break;
    default:
      componentSizeClass = "";
  }

  return (
    <div className={[style.component, componentSizeClass].join(" ")}>
      {popupHandler.layer(layer)}
      <div className={style.navigation}>
        <div className={style.tabs}>
          {tabs.map((t, i) => (
            <span
              onClick={() => setTab(i)}
              key={"tab" + i}
              className={
                tab === i ? style.tabs__button_active : style.tabs__button
              }
            >
              {t}
            </span>
          ))}
        </div>
      </div>
      <div
        id="interface"
        className={!scrollable ? style.content : style.content_scrollable}
      >
        {content[tab]}
      </div>
    </div>
  );
};

export default TabInterface;
