import React from "react";

// HOOKS
import { useCatalog } from "../../hooks/useCatalog";

// COMPONENTS
import HomePageHoldTable from "./HomePageHoldTable";
import HomePageELearningTable from "./HomePageELearningTable";

// Style
import style from "./HomePage.module.scss";
import { All_CatalogsQuery, Kh_Status } from "../../../customGraphqlTypes";

interface statusCodes {
  ok: number[];
  pending: number[];
  frafald: number[];
  alle: number[];
}

interface sumSegments {
  ok: number;
  frafald: number;
  alle: number;
  yellowValues: number[];
}

interface sum {
  fremmøde: sumSegments;
  online: sumSegments;
}

export interface homePageTableProps {
  yellowFields: Kh_Status[];
  catalog: All_CatalogsQuery;
  StatusCodes: statusCodes;
}

export interface homePagePreveiwsProps {
  yellowFields: Kh_Status[];
  sum: sum;
}

const StatusCodes = {
  ok: [2, 3],
  pending: [1],
  frafald: [0, 4],
  alle: [1, 2, 3],
};

const AktiveHoldPage: React.FC = () => {
  const catalog = useCatalog();
  const yellowFields = catalog.kh_statuser.filter(
    (status) => status.Kode === 1
  );

  return (
    <div className={style.page}>
      <h2>Aktive hold</h2>
      <HomePageHoldTable
        yellowFields={yellowFields}
        catalog={catalog}
        StatusCodes={StatusCodes}
      />
      <h2 className={style.heading}>Aktive E-learning</h2>
      <HomePageELearningTable
        yellowFields={yellowFields}
        catalog={catalog}
        StatusCodes={StatusCodes}
      />
    </div>
  );
};

export default AktiveHoldPage;
