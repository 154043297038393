import React, { useState, useContext, useEffect } from "react";

// CONTEXT
import { AppContext } from "../../context/AppContext";

// ASSETS
import Cog from "../../assets/icons/indicators/Cog";
import MenuArrow from "../../assets/icons/indicators/MenuArrow";

// COMPONENTS
import AdminInterface from "../interfaces/AdminInterface/AdminInterface";

// STYLE
import style from "./SettingsMenu.module.scss";

const RadioButtonDisplay = ({ active }) => {
  return (
    <div
      className={
        active ? style.radio_button_display__active : style.radio_button_display
      }
    ></div>
  );
};

const SettingsMenu: React.FC = () => {
  const { popupHandler, userData, dropDownMenuHandler, themeHandler } =
    useContext(AppContext);
  const [themeDropDown, setThemeDropDown] = useState(false);

  const event = {
    theme: (theme: string) => {
      themeHandler.setTheme(theme);
    },
    admin: () => {
      dropDownMenuHandler.toggleSettingsMenu(false);
      popupHandler.add(<AdminInterface />);
    },
  };

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener("mousedown", updateMenuState);
    }, 250);
    return () => window.removeEventListener("mousedown", updateMenuState);
  }, []);

  const updateMenuState = (ev: MouseEvent) => {
    const target = ev.target as HTMLElement;
    if (!target || !target.className)
      dropDownMenuHandler.toggleSettingsMenu(false);
    else if (!String(target.className).includes("SettingsMenu_button"))
      dropDownMenuHandler.toggleSettingsMenu(false);
  };

  return (
    <div className={style.component}>
      {userData.Roles.includes("administrate") ? (
        <div className={style.button} onClick={() => event.admin()}>
          <span>Administrator</span>
          <Cog />
        </div>
      ) : null}
      <div
        className={style.button}
        onClick={() => setThemeDropDown(!themeDropDown)}
      >
        <span>Tema</span>
        <MenuArrow className={themeDropDown ? style.open_arrow : ""} />
      </div>
      {themeDropDown ? (
        <div className={style.dropContainer}>
          <div className={style.button} onClick={() => event.theme("default")}>
            <span>Default</span>
            <RadioButtonDisplay
              active={
                themeHandler.activeTheme === "default" ||
                themeHandler.activeTheme === null
              }
            />
          </div>
          <div className={style.button} onClick={() => event.theme("henrik")}>
            <span>Default (Tydelig)</span>
            <RadioButtonDisplay
              active={themeHandler.activeTheme === "henrik"}
            />
          </div>
          <div className={style.button} onClick={() => event.theme("legacy")}>
            <span>Legacy</span>
            <RadioButtonDisplay
              active={themeHandler.activeTheme === "legacy"}
            />
          </div>
          <div className={style.button} onClick={() => event.theme("dark")}>
            <span>Dark</span>
            <div
              className={
                themeHandler.activeTheme === "dark"
                  ? style.radio_button_display__active
                  : style.radio_button_display
              }
            ></div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SettingsMenu;
