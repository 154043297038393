import React, { createContext } from "react";
import { PopupHandler } from "../functions/PopupHandler";
import { FormatHandler } from "../functions/FormatHandler";
import { MsgHandler } from "../functions/MsgHandler";
import { SelectMenuHandler } from "../functions/SelectMenuHandler";
import { AutoFillHandler } from "../functions/AutoFillHandler";
import { AktiveHoldHandler } from "../functions/AktiveHoldHandler";
import { ThemeHandler } from "../functions/ThemeHandler";
import { DropDownMenuHandler } from "../functions/DropDownMenuHandler";
import { UserData } from "../hooks/useUserData";
import { UserHandler } from "../functions/UserHandler";
import { EmailHandler } from "../functions/EmailHandler";

export interface ContextType {
    popupHandler: PopupHandler
    formatHandler: FormatHandler
    userHandler: UserHandler
    msgHandler: MsgHandler
    selectMenuHandler: SelectMenuHandler
    autoFillHandler: AutoFillHandler
    userDataNeedsUpdate: React.Dispatch<React.SetStateAction<boolean>>
    activeHoldHandler: AktiveHoldHandler
    themeHandler: ThemeHandler
    dropDownMenuHandler: DropDownMenuHandler
    userData: UserData
    mailHandler: EmailHandler
}
export const AppContext = createContext<ContextType>(null)