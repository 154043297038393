import { AccountInfo, EventType, IPublicClientApplication } from "@azure/msal-browser";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { apiAccessScope } from "../config/msal";
import { useMsal } from "@azure/msal-react";

export interface UserHandlerType {
    instance: IPublicClientApplication;
    signOutLoad: boolean;
    setSignOutLoad: Dispatch<SetStateAction<boolean>>
}

export class UserHandler implements UserHandlerType {
    instance: IPublicClientApplication;
    signOutLoad: boolean;
    setSignOutLoad: Dispatch<SetStateAction<boolean>>

    constructor() {
        this.instance = null;
        this.signOutLoad = null;
        this.setSignOutLoad = null
    }

    setStates(instance: IPublicClientApplication, signOutLoad, setSignOutLoad) {
        this.signOutLoad = signOutLoad;
        this.setSignOutLoad = setSignOutLoad
        this.instance = instance
        this.listen()
    }
    // async tryAutoSignIn() {
    //     if (!this.instance.getActiveAccount() && this.instance.getAllAccounts().length > 0) {
    //         // Account selection logic is app dependent. Adjust as needed for different use cases.
    //         this.instance.setActiveAccount(this.instance.getAllAccounts()[0]);
    //         return "success"
    //     }

    //     this.instance.enableAccountStorageEvents();
    //     return "error"
    // }


    listen() {

        if (!this.instance.getActiveAccount() && this.instance.getAllAccounts().length > 0) {
            // Account selection logic is app dependent. Adjust as needed for different use cases.
            this.instance.setActiveAccount(this.instance.getAllAccounts()[0]);
        }

        this.instance.enableAccountStorageEvents();

        this.instance.addEventCallback((event: {payload: { account: AccountInfo; }, eventType: EventType}) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                const account = event.payload.account;
                this.instance.setActiveAccount(account);
          
            }
        });
    }

    async login() {
        this.listen()
        await this.instance.loginPopup({ scopes: [apiAccessScope] }).catch(e => {
            console.log(e);
        })
    }

    logout() {
        this.setSignOutLoad(true)
        this.instance.logoutPopup().catch(e => {
            console.error(e);
        }).then(res => {
            this.setSignOutLoad(false)
        })
    }
}