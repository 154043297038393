import { useEffect, useRef } from 'react'
import Cross from '../../../../../assets/icons/indicators/Cross'
import Trash from '../../../../../assets/icons/indicators/Trash'
import AreaInput from '../../../../Inputs/AreaInput/AreaInput'
import Input from '../../../../Inputs/Input'
import style from './Options.module.scss'
import Arrow from '../../../../../assets/icons/indicators/arrow'
import Copy from '../../../../../assets/icons/indicators/Copy'

export default function ListOptions({editComponent, temp, copiedListItem, insetCopiedListItem, copyListItem, updateTemp, setHover, setFocus, addListItem, removeListItem, updateListValues, moveListItem}) {
    const listRef = useRef(null)

    if (!editComponent)
        return null

    const getTempValue = () => {
        console.log("dkwoad", editComponent)
        let baseKey = editComponent[editComponent.length-1]
        let path = editComponent
        let obj = {...temp}
    
        if (!Array.isArray(path))
            return
        let currentObj = obj;

    
        for (const key of path.slice(0, -1)) {
            if (!currentObj.hasOwnProperty(key)) {
                console.error(`Key "${key}" not found in the object`);
                return obj;
            }
            currentObj = currentObj[key];
        }
    
        const lastKey = path[path.length - 1];
        return {...currentObj[lastKey], baseKey: baseKey }
    }

    const hoverHighLight = (e, item, i1) => {
        if (e.target.value === "ny tekst")
            e.target.select()
        setHover([...editComponent, "content", i1])
    }

    const hoverHighLightSub = (e, item, i1, i2) => {
        if (e.target.value === "ny tekst")
            e.target.select()
        setHover([...editComponent, "content", i1, "content", i2])
    }

    const hoverHighLightSubSub = (e, item, i1, i2, i3) => {
        if (e.target.value === "ny tekst")
            e.target.select()
        setHover([...editComponent, "content", i1, "content", i2, "content", i3])
    }

    const focusHighLight = (item, i1) => {
        setFocus([...editComponent, "content", i1])
    }

    const focusHighLightSub = (item, i1, i2) => {
        setFocus([...editComponent, "content", i1, "content", i2])
    }

    const focusHighLightSubSub = (item, i1, i2, i3) => {
        setFocus([...editComponent, "content", i1, "content", i2, "content", i3])
    }

    return (
        <div className={style.optionsWrap}>
            <div className={style.listOptions} ref={listRef}>
                {getTempValue().content.map((item, i1) => (
                    <div className={style.listItem} key={"lvl"+i1}>
                        <div className={style.listItem_content}>
                            <AreaInput 
                                onMouseOut={() => setHover([])}
                                onBlur={() => setFocus([])}
                                onFocus={(e) => focusHighLight(item, i1)} 
                                onMouseOver={(e) => hoverHighLight(e, item, i1)} 
                                autoSize={true} 
                                onInput={(name, val) => updateListValues(val, i1)} 
                                value={item.value} 
                                className={style.titleInput}
                            ></AreaInput>
                            
                            {item.content.map((itemSub, i2) => (
                                <div className={style.listItem_sub} key={"lvl"+i1+"lvl2"+i2}>
                                    <span>•</span>
                                    <div className={style.listItem_content}>
                                        <AreaInput 
                                            onMouseOut={() => setHover([])}
                                            onBlur={() => setFocus([])}
                                            onFocus={() => focusHighLightSub(item, i1, i2)}
                                            onMouseOver={(e) => hoverHighLightSub(e, item, i1, i2)} 
                                            autoSize={true} 
                                            onInput={(name, val) => updateListValues(val, i1, i2)} 
                                            value={itemSub.value}
                                        ></AreaInput>
                                        {itemSub.content.map((itemSubSub, i3) => (
                                            <div className={style.listItem_sub_sub} key={"lvl"+i1+"lvl2"+i2+"lvl3"+i3}>
                                                <span>-</span>
                                                <div className={style.listItem_content}>
                                                    <AreaInput 
                                                        onMouseOut={() => setHover([])}
                                                        onBlur={() => setFocus([])}
                                                        onFocus={() => focusHighLightSubSub(item, i1, i2, i3)} 
                                                        onMouseOver={(e) => hoverHighLightSubSub(e, item, i1, i2, i3)} 
                                                        autoSize={true} 
                                                        onInput={(name, val) => updateListValues(val, i1, i2, i3)} 
                                                        value={itemSubSub.value} className={style.listOptions_subsubnIput} 
                                                    />
                                                </div>
                                                <div className={style.listItem_buttonWrap}>
                                                    <Trash className={style.listItem_deleteIcon} onClick={() => removeListItem(i1, i2, i3)} />
                                                    {i3 > 0 ?
                                                        <Arrow className={style.listItem_arrow_up} title="ryk op" onClick={() => moveListItem(-1, i1, i2, i3)} />
                                                    : null}
                                                    {i3 < itemSub.content.length-1 ?
                                                        <Arrow className={style.listItem_arrow_down} title="ryk ned" onClick={() => moveListItem(1, i1, i2, i3)}/>
                                                    : null}
                                                    <Copy className={style.listItem_copy} title={"kopier"} onClick={() => copyListItem(i1, i2, i3)} />
                                                </div>
                                            </div>
                                        ))}
                                        <div className={style.listOptions_subsubInput_add}>
                                            <span>-</span>
                                            <div className={style.listOptions_add_subsub} onClick={() => addListItem(i1, i2)}><Cross/></div>
                                            {copiedListItem.lvl === 3 ?
                                                <span className={style.listItem_inset} onClick={() => insetCopiedListItem(i1, i2)}>Indset</span>
                                            : null}
                                        </div>
                                    </div>
                                    <div className={style.listItem_buttonWrap}>
                                        <Trash className={style.listItem_deleteIcon} onClick={() => removeListItem(i1, i2)} />
                                        {i2 > 0 ?
                                            <Arrow className={style.listItem_sub_arrow_up} title="ryk op" onClick={() => moveListItem(-1, i1, i2)}/>
                                        : null}
                                        {i2 < item.content.length-1 ?
                                            <Arrow className={style.listItem_sub_arrow_down} title="ryk ned" onClick={() => moveListItem(1, i1, i2)}/>
                                        : null}
                                        <Copy className={style.listItem_copy} title={"kopier"} onClick={() => copyListItem(i1, i2)} />
                                    </div>
                                </div>
                            ))}
                            <div className={style.listOptions_subInput_add}>
                                <span>•</span>
                                <div className={style.listOptions_add_sub} onClick={() => addListItem(i1)}><Cross/></div>
                                {copiedListItem.lvl === 2 ?
                                    <span className={style.listItem_inset} onClick={() => insetCopiedListItem(i1)}>Indset</span>
                                : null}
                            </div>
                        </div>
                        <div className={style.listItem_buttonWrap}>
                            <Trash className={style.listItem_deleteIcon} onClick={() => removeListItem(i1)} />
                            {i1 > 0 ?
                                <Arrow className={style.listItem_arrow_up} title="ryk op" onClick={() => moveListItem(-1, i1)}/>
                            : null}
                            {i1 < getTempValue().content.length-1 ?
                                <Arrow className={style.listItem_arrow_down} title="ryk ned" onClick={() => moveListItem(1, i1)}/>
                            : null}
                            <Copy className={style.listItem_copy} title={"kopier"} onClick={() => copyListItem(i1)} />
                        </div>
                    </div>
                ))}
                <div className={style.listItem}>
                    <div className={style.listOptions_add} onClick={() => addListItem()}><Cross/></div>
                    {copiedListItem.lvl === 1 ?
                        <span className={style.listItem_inset} onClick={() => insetCopiedListItem()}>Indset</span>
                    : null}
                </div>
            </div>
            <Input 
                label="Top"
                value={getTempValue().style.marginTop}
                onInput={(name, val, e) => updateTemp(val, "marginTop")}
            />
            <Input 
                label="Venstre"
                value={getTempValue().style.marginLeft}
                onInput={(name, val, e) => updateTemp(val, "marginLeft")}
            />
            <Input 
                label="Bredde"
                value={getTempValue().style.minWidth}
                onInput={(name, val, e) => {
                    updateTemp(val, "minWidth")
                    updateTemp(val, "maxWidth")
                }}
            />
            <Input 
                label="Punk Tekst størrelse"
                value={getTempValue().style.titelFontSize}
                onInput={(name, val, e) => updateTemp(val, "titelFontSize")}
            />
            <Input 
                label="Punkt mellemrum"
                value={getTempValue().style.spacingtitles}
                onInput={(name, val, e) => updateTemp(val, "spacingtitles")}
            />
            <Input 
                label="Under Punkt Tekst størrelse (•)"
                value={getTempValue().style.pointFontSize}
                onInput={(name, val, e) => updateTemp(val, "pointFontSize")}
            />
            <Input 
                label="Under-punkt mellemrum (•)"
                value={getTempValue().style.spacingPoints}
                onInput={(name, val, e) => updateTemp(val, "spacingPoints")}
            />
            <Input 
                label="Under Punkt 2 Tekst størrelse (-)"
                value={getTempValue().style.subPointFontSize}
                onInput={(name, val, e) => updateTemp(val, "subPointFontSize")}
            />
            <Input 
                label="Under-punkt 2 mellemrum (-)"
                value={getTempValue().style.spacingSubPoints}
                onInput={(name, val, e) => updateTemp(val, "spacingSubPoints")}
            />
        </div>
    )
}