import { useEffect, useState } from "react";

const useDate = () => {
  const locale = "da-DK";
  const [today, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const day = today.toLocaleDateString(locale, { weekday: "long" });
  const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, {
    month: "long",
  })}\n\n`;

  const hour = today.getHours();
  const wish = `${
    (hour < 12 && "Godmorgen") || (hour < 17 && "Hej") || "Godaften"
  }, `;

  const time = today.toLocaleTimeString(locale, {
    hour: "numeric",
    minute: "numeric",
  });

  return {
    date,
    time,
    wish,
  };
};

export default useDate