import { useQuery, ApolloQueryResult, ApolloError } from "@apollo/client";
import { useEffect, useState } from "react";
import client from "../config/apolloClient";
import { ALL_CATALOGS } from "../queries/catalogs/allCatalogs.gql";
import { All_CatalogsQuery, All_CatalogsQueryVariables } from "../../customGraphqlTypes";

type CatalogData = All_CatalogsQuery | null;
type CatalogError = ApolloError | undefined;

let lastActualData: CatalogData | null = null;

export const usePreloadCatalog = (): {
    loading: boolean;
    data: CatalogData;
    error: CatalogError;
  } => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<CatalogData>(null);
    const [error, setError] = useState<CatalogError>(undefined);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const { data, loading, error } = await client.query<All_CatalogsQuery, All_CatalogsQueryVariables>({
            query: ALL_CATALOGS,
          });
  
          if (loading) {
            setLoading(true);
          } else if (error) {
            setError(error);
          } else {
            setData(data);
            setLoading(false);
          }
        } catch (error) {
          setError(error);
        }
      };
  
      fetchData();
    }, []);
  
    return { loading, data, error };
};

export const useCatalog = (): CatalogData => {
  const catalogsQuery = useQuery<All_CatalogsQuery, All_CatalogsQueryVariables>(ALL_CATALOGS, {
    pollInterval: 10000,
  });

  if (catalogsQuery.loading || catalogsQuery.error) {
    if (lastActualData !== null) {
      return lastActualData;
    } else {
      return {
        a_kasser: [],
        genders: [],
        indgange: [],
        jc_statuser: [],
        jobcentre: [],
        kh_statuser: [],
        kurser: [],
        ansoger_vias: [],
      };
    }
  }

  lastActualData = catalogsQuery.data;
  return lastActualData;
};

