import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

// QUERIES
import { KURSISTER, GET_ALL_KURSISTER } from "../../queries/kursister.gql";

// CONTEXT
import { AppContext } from "../../context/AppContext";

// CONFIG
import { KursisterTableFields } from "./KursisterTableFields";

// COMPONENTS
import KursistInterface from "../../components/interfaces/KursistInterface/KursistInterface";
import TableInterface from "../../components/interfaces/TableInterface/TableInterface";
import Button from "../../components/Button/Button";

// ICONS
import Copy from "../../assets/icons/indicators/Copy";

// STYLE
import style from "./KursisterPage.module.scss";

const KursisterPage: React.FC = () => {
  const { popupHandler, msgHandler } = useContext(AppContext);
  const location = useLocation();

  const Fields = {
    ...KursisterTableFields,
    Kopier: {
      filter: {
        search: { default: false, hidden: true },
        table: { default: true, hidden: true },
      },
      tab: {
        title: "Kopier",
        size: 0,
        sortable: false,
        contentType: {
          type: "button",
          action: (kursist) => {
            var input = document.createElement("textarea");
            input.innerHTML = `Navn: ${kursist.Fornavn} ${kursist.Efternavn}\nTlf: ${kursist.Tlf}\nMail: ${kursist.Mail}`;
            document.body.appendChild(input);
            input.select();
            document.execCommand("copy");
            document.body.removeChild(input);
            msgHandler.add("kontakt information kopieret");
          },
          content: <Copy className={style.copy} />,
        },
      },
    },
  };

  const event = {
    onRowClick: (data, index, e, startPolling, stopPolling) => {
      popupHandler.add(
        <KursistInterface
          kursist={data}
          scrollable={false}
          startPolling={startPolling}
          stopPolling={stopPolling}
        />
      );
    },
    onCreateClick: () => {
      popupHandler.add(<KursistInterface createMode scrollable={false} />);
    },
  };

  return (
    <div className={style.page}>
      <div className={style.component}>
        <TableInterface
          name={"Kursister"}
          primaryKey={"Kursist_id"}
          fields={Fields}
          query={KURSISTER}
          locationProps={location.state}
          excelQuery={GET_ALL_KURSISTER}
          onRowClick={event.onRowClick}
          customActions={[
            <Button
              key={"customActionCreate"}
              outlined
              rounded
              color="main"
              onClick={() => event.onCreateClick()}
            >
              Ny Kursist
            </Button>,
          ]}
        />
      </div>
    </div>
  );
};

export default KursisterPage;
