import { useMutation, useQuery } from '@apollo/client'
import { useContext, useEffect, useMemo, useState } from 'react'
import * as XLSX from 'xlsx/xlsx.mjs';


// QUERIES
import {GET_KURSISTER_BY_HOLD, KURSISTER } from '../../../../queries/kursister.gql'
import { DELETE_KURSIST_TIL_HOLD, KURSIST_HOLDS, MASS_UPDATE_KURSIST_TIL_HOLDS } from '../../../../queries/kursistHold.gql'

// CONTEXT
import { AppContext } from '../../../../context/AppContext'

// COMPONENTS
import Loading from '../../../../assets/icons/loaders/Loading'
import CheckboxInput from '../../../Inputs/CheckboxInput/CheckboxInput'
import CustomSelectInput from '../../../Inputs/SelectInput/CustomSelectInput'
import Cell from '../../../Table/Cell'
import Table from '../../../Table/Table'
import { KursisterTableFields } from '../../../../pages/KursisterPage/KursisterTableFields'
import TableInterface from '../../TableInterface/TableInterface'
import Button from '../../../Button/Button'
import KursistHoldInterface from '../../KursistHoldInterface/KursistHoldInterface'

// STYLE
import style from './HoldInterfaceTemplate.module.scss'
import Color from '../../../../assets/icons/indicators/Color'
import DateInput from '../../../Inputs/DateInput/DateInput'
import { AdminInterfaceTemplate } from '../../AdminInterface/AdminInterfaceTemplate/AdminInterfaceTemplate';
import Input from '../../../Inputs/Input';
import useAllInputOptions from '../../../../hooks/useAllInputOptions';
import { useCatalog } from '../../../../hooks/useCatalog';
import LinkInput from '../../../Inputs/LinkInput/LinkInput';
import { graphClient } from '../../../../config/graphClient';
import { PDFDocument, rgb, PDFRawStream, decodePDFRawStream, arrayAsString } from 'pdf-lib';
import AreaInput from '../../../Inputs/AreaInput/AreaInput';
import JSZip from 'jszip';
import PdfProof from '../PdfProof/PdfProof';


export const HoldInterfaceTemplate = {
    baseInputs: ({hold, disabledFields, onInput, inputErrors, deleteButton, isELearning, children}) => {
        const catalog = useCatalog()
        const inputOptions = useAllInputOptions()
        inputErrors = inputErrors ? inputErrors : {}

        const event = {
            kursusSelect: (name, val) => {
                const E_hold = catalog.kurser.filter(k => k.Kursus_id === val)
                if (E_hold.length > 0)
                    onInput(name, val, E_hold[0])
                else onInput(name, val)
            }
        }

        return (
            <div className={style.section_small}>
                <div className={style.heading}>
                    <h2>Hold</h2>
                    {!deleteButton ? null : deleteButton}
                </div>
                <CheckboxInput 
                    label="Online hold"
                    name="Online"
                    onInput={onInput}
                    value={hold.Online ? hold.Online : ""}
                    disabled={disabledFields || isELearning}
                />
                <CustomSelectInput
                    required={true}
                    label="Kursus" 
                    name="Kursus_id"
                    onInput={event.kursusSelect} 
                    search={true}
                    disabled={disabledFields}
                    value={hold.Kursus_id ? hold.Kursus_id : ""}
                    options={inputOptions.kurser}
                    error={"Kursus_id" in inputErrors ? inputErrors.Kursus_id : null}
                />
                <DateInput 
                    todayButton={true}
                    label="Startdato"
                    name="Startdato"
                    onInput={onInput}
                    disabled={disabledFields}
                    value={hold.Startdato ? hold.Startdato : ""}
                    required={true}
                    error={"Startdato" in inputErrors ? inputErrors.Startdato : null}
                />
                <Input 
                    label="Hold navn"
                    name="Holdnavn"
                    onInput={onInput}
                    value={hold.Holdnavn ? hold.Holdnavn : ""}
                    disabled={disabledFields}
                />
                <LinkInput
                    label="Sharepoint"
                    name="Sharepoint"
                    link={hold.Sharepoint}
                    onInput={onInput}
                    value={hold.Sharepoint ? hold.Sharepoint : ""}
                    disabled={disabledFields}
                />
                {!children ? null : <div className={style.section_small_footer}>{children}</div>}
            </div>
        )
    },
    kursister: ({hold}) => {
        const {formatHandler, popupHandler} = useContext(AppContext)
        const [sort, setSort] = useState({field: "", dir: false})
        const [selectAll, setSelectAll] = useState(false)
        const [originalContent, setOriginalContent] = useState([])
        const [content, setContent] = useState([])
        const [customLoading, setCustomLoading] = useState(true)
        const catalog = useCatalog()
        const [excelLoading, setExcelLoading] = useState(false)
        const [statusFilter, setStatusFilter] = useState({
            "0": true,
            "1": true,
            "2": true,
            "3": true,
            "4": true,
            "all": true,
        })
        const [onlineFilter, setOnlineFilter] = useState(2)

        const {data, loading, error} = useQuery(GET_KURSISTER_BY_HOLD, {
            pollInterval: 5000,
            variables: {
                holdId: hold.Hold_id
            }
        })

        const [remove_kursist_on_hold, removeKursistQuery] = useMutation(DELETE_KURSIST_TIL_HOLD, {
            refetchQueries: [
                GET_KURSISTER_BY_HOLD
            ],
        });

        useEffect(() => {
            if (error || loading) {
                return
            }
            if (data) {
                const formatData = formatHandler.dataToReadable(data.kursister_by_hold)
                const getJsxContent = (text, kode) => {
                    return (
                        <div className={style.cell_status}>
                            <Color color={formatHandler.statusColor(eval(kode))} size="10"></Color>
                            <span>{text}</span>
                        </div>
                    )
                }

                const FindPrevSelected = (h) => {
                    const kursistTilHold = content.find(({Kursist_Hold_id}) => Kursist_Hold_id === h.Kursist_Hold_id)
                    return kursistTilHold ? kursistTilHold.checkbox : false
                }

                let withCheckboxAndJsx = formatData.map((h,i) => ({
                    checkbox: FindPrevSelected(h),
                    ...h,
                    Status: {jsx: <span key={i+"jsxContent"}>{getJsxContent(h.Status, h.kode)}</span>}
                }))

                setOriginalContent(withCheckboxAndJsx)
                setContent(withCheckboxAndJsx)
                setCustomLoading(false)
            }
        },[data, loading])

        const SelectedKursister = content.filter(k => k.checkbox)
    
        const event = {
            checkboxSelect: (val, index) => {
                const updateArray = [...content]
                const updateObj = updateArray[index]
                updateObj.checkbox = !updateObj.checkbox
                updateArray[index] = updateObj
                setContent(updateArray)
            },
            checkboxAll: () => {
                const newVal = !selectAll
                const updateArray = [...content]
                updateArray.map(k => k.checkbox = newVal)
                setSelectAll(newVal)
                setContent(updateArray)
            },
            onRowClick: (val) => {
                const dataObj = {
                    Hold: hold.Holdnavn, 
                    Hold_id: hold.Hold_id,
                    ...val,
                    Online: val.Online === "Online" ? true : false,
                    PC_sendt: val.PC_sendt === "ja" ? true : false,
                    Books_sendt: val.Books_sendt === "ja" ? true : false,
                    PC_return: val.PC_return === "ja" ? true : false,
                    Books_return: val.Books_return === "ja" ? true : false
                }
                popupHandler.add(
                    <KursistHoldInterface dataObj={dataObj} />
                )
            },
            statusFilter: (kode) => {
                const updateObj = {...statusFilter}
                updateObj[kode] = !updateObj[kode]
                setStatusFilter(updateObj)
            },
            statusFilterAll: () => {
                const updateObj = {...statusFilter}
                Object.keys(statusFilter).forEach(key => {
                    updateObj[key] = !statusFilter.all
                })
                setStatusFilter(updateObj)
            },
            onlineFilter: (val) => {
                setOnlineFilter(val)
            },
            removeKursister: () => {
                setSelectAll(false)
                setCustomLoading(true)
                popupHandler.remove(1)
                const selectedKursistHold = SelectedKursister.map(k => eval(k.Kursist_Hold_id))
                remove_kursist_on_hold({variables: {id: selectedKursistHold}})
            },
            mail: () => {
                const mails = SelectedKursister.filter(k => k.Mail).map(k => k.Mail)
                const KursisterWithoutMails = SelectedKursister.length-mails.length
                const Copy = () => {
                    const copyContent = mails.join("; ")
                    var input = document.createElement('textarea');
                    input.innerHTML = copyContent
                    document.body.appendChild(input);
                    input.select();
                    var result = document.execCommand('copy');
                    document.body.removeChild(input);
                    popupHandler.remove(1)
                }
                popupHandler.add(
                    <div className={style.mail_card}>
                        {KursisterWithoutMails > 0 ?
                            <span>vær opmærksom på at {KursisterWithoutMails} af de valgte kursister ikke har opgivet en mail</span>
                        : null}
                        <a href={`mailto:${mails.join(";")}`}>{mails.join(",")}</a>
                        <div>
                            <Button onClick={() => Copy()}>Kopier</Button>
                            <Button outlined onClick={() => popupHandler.remove(1)}>Luk</Button>
                        </div>
                    </div>
                )
            },
            excel: async (content) => {
                setExcelLoading(true)
                const filterTitles = [
                    "checkbox", 
                    "__typename", 
                    "Status",
                    "Kursist_id",
                    "Kursist_Hold_id",
                    "kode",
                    "Certificering",
                    "Jc_Samtaler"
                ]
                const titles = Object.keys(content[0]).filter(title => !filterTitles.includes(title)).map(title => {
                    if (title === "Ansoger_via_id")
                        return "Ansøger via"
                    if (title === "Status_id")
                        return "Status"
                    if (title === "Jc_Status_id")
                        return "jobcoach Status"
                    if (title === "Birthday")
                        return "Alder"
                    if (title === "Gender")
                        return "Køn"
                    if (title === "PC_sendt")
                        return "PC sendt"
                    if (title === "Books_sendt")
                        return "Bøger sendt"
                    if (title === "PC_return")
                        return "PC retur"
                    if (title === "Books_return")
                        return "Bøger retur"
                    return title
                })

                let reMapped = content.map(obj => Object.keys(obj).filter(key => !filterTitles.includes(key)).map(key => {
                    if (!obj[key])
                        return null
                    if (obj[key] === "nej")
                        return obj[key]
                    if (key === "Ansoger_via_id") {
                        return catalog.ansoger_vias.find(ans => ans.Ansoger_via_id === obj[key]).Ansoger_via
                    }
                    if (key === "Status_id")
                        return catalog.kh_statuser.find(sta => sta.Kh_Status_id === obj[key]).Kh_Status

                    if (key === "Jc_Status_id")
                        return catalog.jc_statuser.find(sta => sta.Jc_Status_id === obj[key]).Jc_Status

                    if (key === "Birthday") {
                        const today = new Date();
                        const birthDateSplit = obj[key].split("-")
                        const birthDate = new Date(`${birthDateSplit[2]}-${birthDateSplit[1]}-${birthDateSplit[0]}`);
                        let age = today.getFullYear() - birthDate.getFullYear();
                        const m = today.getMonth() - birthDate.getMonth();
                        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                            age--;
                        }

                        return age;
                    }

                    // if (obj[key] === "nej")
                    //     return null

                    return obj[key]
                }))

                const workBook = XLSX.utils.book_new();
                const workSheetData = [
                    titles,
                    ...reMapped
                ]
    
                const workSheet = XLSX.utils.aoa_to_sheet(workSheetData)
                XLSX.utils.book_append_sheet(workBook, workSheet, "kursister_på_hold")
                XLSX.writeFile(workBook, `kursister_på_hold.xlsx`)
                XLSX
                setExcelLoading(false)
            },
            sort: (sortName, sortDir) => {
                setSort({field: sortName, dir: !sortDir})
            }
        }

        useEffect(() => {
            if (!sort.field) return
            const sorted = AdminInterfaceTemplate.functions.localSort(content, sort, [])
            setContent(sorted)
        },[sort])

        useEffect(() => {
            if (originalContent.length < 1)
                return
            const activeFilters = Object.keys(statusFilter).filter(key => statusFilter[key])
            let filtered = originalContent.filter(obj => activeFilters.includes(obj.kode))

            if (onlineFilter === 1 || onlineFilter === 0)
                filtered = filtered.filter(obj => {
                    if (onlineFilter === 0)
                        return obj.Online === "Online"
                    if (onlineFilter === 1)
                        return obj.Online === "Fremmøde"
                })

            if (sort.field) {
                filtered = AdminInterfaceTemplate.functions.localSort(filtered, sort, [])
            }

            setContent(filtered)
        },[statusFilter, originalContent, onlineFilter])


        return (
            <div className={style.section_big}>
                <div className={style.heading}>
                    <h2>Kursister på hold</h2>
                    <div className={style.heading_actions}>
                        <p>
                            {content.length} kursister
                        </p>
                        <Button
                            rounded
                            outlined
                            onClick={() => event.excel(content)}
                        >excel</Button>
                        <Button 
                            outlined 
                            color="main" 
                            rounded
                            onClick={() => popupHandler.add(<HoldInterfaceTemplate.kursistSelect content={content} hold={hold} />, "big")}
                        >Tilføj kursister</Button>
                    </div>
                </div>
                <div className={style.status_filter}>
                    <CheckboxInput 
                        value={statusFilter["4"]} 
                        name={"statusOption1"}
                        label={<Color size="12" color="purple" />}
                        onInput={() => event.statusFilter("4")}
                    />
                    <CheckboxInput 
                        value={statusFilter["0"]} 
                        name={"statusOption2"}
                        label={<Color size="12" color="red" />} 
                        onInput={() => event.statusFilter("0")}
                    />
                    <CheckboxInput 
                        value={statusFilter["1"]} 
                        name={"statusOption3"}
                        label={<Color size="12" color="yellow" />} 
                        onInput={() => event.statusFilter("1")}
                    />
                    <CheckboxInput 
                        value={statusFilter["3"]} 
                        name={"statusOption4"}
                        label={<Color size="12" color="green" />} 
                        onInput={() => event.statusFilter("3")}
                    />
                    <CheckboxInput 
                        value={statusFilter["2"]}
                        name={"statusOption5"}
                        label={<Color size="12" color="lightGreen" />}
                        onInput={() => event.statusFilter("2")}
                    />
                    <CheckboxInput 
                        value={statusFilter["all"]}
                        name={"statusOption6"}
                        label="Alle"
                        onInput={() => event.statusFilterAll()}
                    />
                    {hold.Online ? null : <>
                        <div className={style.Filter_split}></div>
                        <CheckboxInput 
                            value={onlineFilter === 0} 
                            label="Online"
                            onInput={() => event.onlineFilter(0)}
                        />
                        <CheckboxInput 
                            value={onlineFilter === 1}
                            label="Fremmøde"
                            onInput={() => event.onlineFilter(1)}
                        />
                        <CheckboxInput 
                            value={onlineFilter === 2}
                            label="Online/Fremmøde"
                            onInput={() => event.onlineFilter(2)}
                        />
                    </>}
                </div>
                <div className={style.tableContainer}>
                    <Table
                        loading={customLoading}
                        error={error ? error.msg : null}
                        content={content}
                        rowSelect={null}
                        onRowClick={(val, index) => event.onRowClick(val, index)}
                    >
                        {[
                            <Cell 
                                key="KonHCell1"
                                title={<CheckboxInput value={selectAll} onInput={() => event.checkboxAll()}/> }
                                name="Checkbox"
                                sort={sort}
                                size={0}
                                contentType={{
                                    type: "checkbox", 
                                    action: (val, index) => event.checkboxSelect(val, index), 
                                    value: (val, index) => (content[index].checkbox)
                                }}
                            />,
                            <Cell 
                                key="KonHCell2"
                                title="Status"
                                name="Status"
                                sortAlias="kode"
                                onClick={() => event.sort("kode", sort.dir)}
                                sort={sort}
                                contentType={{type: "jsx"}}
                                size={0}
                            />,
                            <Cell 
                                key="KonHCell3"
                                title="Type"
                                name="Online"
                                onClick={() => event.sort("Online", sort.dir)}
                                sort={sort}
                                size={0}
                            />,
                            <Cell 
                                key="KonHCell4"
                                title="Fornavn"
                                name="Fornavn"
                                sort={sort}
                                onClick={() => event.sort("Fornavn", sort.dir)}
                                size={0}
                            />,
                            <Cell 
                                key="KonHCell5"
                                title="Efternavn"
                                name="Efternavn"
                                sort={sort}
                                onClick={() => event.sort("Efternavn", sort.dir)}
                                size={0}
                            />,
                            <Cell 
                                key="KonHCell6"
                                title="Tlf"
                                name="Tlf"
                                sort={sort}
                                onClick={() => event.sort("Tlf", sort.dir)}
                                size={0}
                            />,
                            <Cell 
                                key="KonHCell7"
                                title="Mail"
                                name="Mail"
                                sort={sort}
                                onClick={() => event.sort("Mail", sort.dir)}
                                size={0}
                            />,
                            <Cell 
                                key="KonHCell8"
                                title="BM sendt"
                                name="BM_sendt"
                                onClick={() => event.sort("BM_sendt", sort.dir)}
                                sort={sort}
                                size={0}
                            />,
                            <Cell 
                                key="KonHCell9"
                                title="BT modtaget"
                                name="BT_sendt"
                                onClick={() => event.sort("BT_sendt", sort.dir)}
                                sort={sort}
                                size={0}
                            />,
                            <Cell 
                                key="KonHCell10"
                                title="VM sendt"
                                name="Velkomstmail"
                                onClick={() => event.sort("Velkomstmail", sort.dir)}
                                sort={sort}
                                size={0}
                            />,
                            <Cell 
                                key="KonHCell11"
                                title="UDB"
                                name="Udb"
                                onClick={() => event.sort("Udb", sort.dir)}
                                sort={sort}
                                size={0}
                            />,
                            <Cell 
                                key="KonHCell12"
                                title="Bøger sendt"
                                name="Books_sendt"
                                onClick={() => event.sort("Books_sendt", sort.dir)}
                                sort={sort}
                                size={0}
                            />,
                            <Cell 
                                key="KonHCell13"
                                title="PC sendt"
                                name="PC_sendt"
                                onClick={() => event.sort("PC_sendt", sort.dir)}
                                sort={sort}
                                size={0}
                            />,
                            <Cell 
                                key="KonHCell14"
                                title="Bøger retur"
                                name="Books_return"
                                onClick={() => event.sort("Books_return", sort.dir)}
                                sort={sort}
                                size={0}
                            />,
                            <Cell 
                                key="KonHCell15"
                                title="PC retur"
                                name="PC_return"
                                onClick={() => event.sort("PC_return", sort.dir)}
                                sort={sort}
                                size={0}
                            />,
                        ]}
                    </Table>
                </div>
                {content.some(s => s.checkbox) ? 
                    <div className={style.select_options}>
                        <div>
                            <Button onClick={() => event.mail()}>Send mail</Button>
                            <Button onClick={() => popupHandler.add(
                                <HoldInterfaceTemplate.massEdit 
                                    SelectedKursister={SelectedKursister} 
                                    setCustomLoading={setCustomLoading}
                                />)}>Rediger</Button>
                            <Button onClick={() => popupHandler.add(
                                <HoldInterfaceTemplate.bevis popupHandler={popupHandler} data={SelectedKursister.map(d => ({...hold, ...d}))} />
                            )}>Bevis</Button>
                        </div>
                        <Button onClick={() => popupHandler.add(
                            <div className={style.delete_card}>
                                <p>Er du sikker på at du vil fjerne <span>{SelectedKursister.map(k => `${k.Fornavn} ${k.Efternavn}`).join(", ")}</span> fra hold?</p>
                                <div>
                                    <Button onClick={() => event.removeKursister()}>ja</Button>
                                    <Button outlined onClick={() => popupHandler.remove(1)}>nej</Button>
                                </div>
                            </div>
                        )}>Fjern fra hold</Button>
                    </div>
                : null}
            </div>
        )
    },
    bevis: ({popupHandler, data}) => {
        const [loading, setLoading] = useState("Henter beviser")
        const [templates, setTemplates] = useState([])
        const [selectValue, setSelectValue] = useState("")
        const getTemplates = async () => {
            // const templateOptions = await graphClient.api(`/sites/root/drive/items/root:/testfolder:/children/?select=id,name,@microsoft.graph.downloadUrl`).get()
            const site = await graphClient.api(`/sites/itucationprod.sharepoint.com:/sites/adm:/`).get()
            const templateOptions = await graphClient.api(`/sites/${site.id}/drive/items/root:/x-drev/kursusadministration/Kursusbeviser/CarlKursusbeviser:/children/?select=id,name,@microsoft.graph.downloadUrl`).get()
   
            // return
            const options = templateOptions.value.map(o => ({title: o.name, value: o["@microsoft.graph.downloadUrl"], id: o.id}))
            .filter(o2 => o2.title && o2.title.endsWith('.json'));
            
            setTemplates(options)
            setLoading(null)
        }

        useEffect(() => {
            getTemplates()
        },[])

        const event = {
            select: (name,val) => {
                setSelectValue(val)
            },
            pick: () => {
                popupHandler.add(
                    <PdfProof downloadUrl={selectValue} data={data} templates={templates} />
                , "big")
            }
        }

        return (
            <div className={style.edit_card}>
                <CustomSelectInput 
                    loading={loading}
                    search={true}
                    name="templateOption"
                    label="template"
                    options={templates}
                    value={selectValue}
                    onInput={event.select}
                />
                <Button onClick={event.pick}>Vælg</Button>
            </div>
        )
    },
    editPDF: ({downloadUrl, data}) => {
        const [PdfBinary, setPdfBinary] = useState(null)
        const [focusedTextArea, setFocusedTextArea] = useState(null);
        const [textAreaInputObj, setTextAreaInputObj] = useState({})

        const formatDataobj = (obj) => {
            const formattedObj = {
                kursist: {},
                hold: {},
            }
            Object.keys(obj).forEach(key => {
                if ([
                    "Fornavn",
                    "Efternavn",
                    "Tlf",
                    "By",
                    "Birthday",
                    "Gender",
                    "Indgang",
                    "Jobcenter",
                    "Land",
                    "Mail",
                    "Postnummer"
                ].includes(key)) {
                    formattedObj.kursist[key] = `<<${key}>>`
                }
                if ([
                    "Holdnavn",
                    "Kursus",
                    "Startdato",
                    "Online"
                ].includes(key)) {
                    formattedObj.hold[key] = `<<${key}>>`
                }
            })
            return formattedObj
        } 
        const [dataObjFormat, setDataObjFormat] = useState(formatDataobj(data[0]))

        const getPdfFile = async () => {
            try {
                const response = await fetch(downloadUrl, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                    },
                });
        
                if (!response.ok) {
                    throw new Error(`Failed to fetch file from Microsoft Graph. Status: ${response.status}`);
                }
                const fileBinary = await response.arrayBuffer();

                const pdfDoc = await PDFDocument.load(fileBinary);

                setPdfBinary(fileBinary)
                const page = pdfDoc.getPages()[0]
                const text = [
                    'Item 1',
                    'Item 2',
                    'Item 3',
                  ];
                
                  const formattedText = text.map(item => `• ${item}`).join('\n');
                
                  page.drawText(formattedText, {
                    x: 100,
                    y: 500,
                    color: rgb(0, 0, 0),
                    size: 12,

                  });

                pdfDoc.save()
                const inputObj = {}
                pdfDoc.getForm().getFields().forEach(field => {
                    inputObj[field.getName()] = field.getText()
                })
                setTextAreaInputObj(inputObj)
            } catch (error) {
                console.error('Error downloading file from Microsoft Graph:', error.message);
            }
        }
        
        useEffect(() => {
            getPdfFile()
        },[])

        if (!PdfBinary)
            return <Loading />


        const event = {
            onInput: (key, val) => {
                const updateObj = {...textAreaInputObj}
                updateObj[key] = val
                setTextAreaInputObj(updateObj)

            },
            insert: (e, val) => {
                const start = focusedTextArea.elm.selectionStart;
                const end = focusedTextArea.elm.selectionEnd;
                const preValues = focusedTextArea.elm.value
                const newValue = preValues.substring(0, start) + val + preValues.substring(end);
                const updateObj = {...textAreaInputObj}
                updateObj[focusedTextArea.key] = newValue
                setTextAreaInputObj(updateObj)
                focusedTextArea.elm.focus()
  
            },
            focus: (e,key) => {
                setFocusedTextArea({elm: e.target, key: key})
            },
            bakePDF: async () => {

                const makeBlob = async (kursist) => {
                    const pdfDocNew = await PDFDocument.load(PdfBinary);

                    const form = pdfDocNew.getForm()
                    Object.keys(textAreaInputObj).forEach(key => {
                        const field = form.getField(key)
                        // Use a regular expression to find all occurrences of <<key>> in the template string
                        let regex = /<<(\w+)>>/g;

                        // Replace each occurrence of <<key>> with the corresponding value from the object
                        let replacedString = textAreaInputObj[key].replace(regex, (match, keynew) => kursist[keynew] || match);
                        console.log(replacedString)
                        field.setText(replacedString)
                    })
                    form.flatten();
                    const bytes = await pdfDocNew.save();
                    return {blob: new Blob([bytes], { type: "application/pdf" }), name: kursist.Fornavn+" "+kursist.Efternavn};
                }

                const fileBlobPromises = data.map(kursist => makeBlob(kursist));

                // Wait for all promises to resolve
                const fileBlobs = await Promise.all(fileBlobPromises);

                
                // return
                const zip = new JSZip();

                let names = []

                fileBlobs.forEach((obj, index) => {
                    let name = obj.name
                    if (names.includes(name))
                        name = name+"("+names.filter(name => name === name).length+")";
                    zip.file(`${name}.pdf`, obj.blob);
                    names.push(name)
                });
                
                // Generate the ZIP file asynchronously
                zip.generateAsync({ type: "blob" })
                    .then((zipBlob) => {
                        // Create a download link for the ZIP file
                        const downloadLink = document.createElement('a');
                        downloadLink.href = URL.createObjectURL(zipBlob);
                        downloadLink.download = data[0].Holdnavn+" beviser"+'.zip';
                
                        // Trigger the click event to prompt the user to download the ZIP file
                        downloadLink.click();
                    })
                    .catch((error) => {
                        console.error('Error generating ZIP file:', error);
                    });
            
                }
        }

          
        return (
            <div className={style.editPDF}>
                <div className={style.editPDF_props}>
                    <div>
                        <p className={style.editPDF_props_title}>Kursist data</p>
                        <div className={style.editPDF_props_button_container}>
                            {Object.keys(dataObjFormat.kursist).map((key,i) => (
                                <span key={i+"editPDF-kursist"+key} className={style.editPDF_props_button} onClick={(e) => event.insert(e, dataObjFormat.kursist[key])}>{key}</span>
                            ))}
                        </div>
                    </div>
                    <div>
                        <p className={style.editPDF_props_title}>Hold data</p>
                        <div className={style.editPDF_props_button_container}>
                            {Object.keys(dataObjFormat.hold).map((key,i) => (
                                <span key={i+"editPDF-hold"+key} className={style.editPDF_props_button} onClick={(e) => event.insert(e, dataObjFormat.hold[key])}>{key}</span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={style.editPDF_inputs}>
                    {Object.keys(textAreaInputObj).map(key => (
                        <>
                            <AreaInput 
                                label={key}
                                name={key}
                                value={textAreaInputObj[key]}
                                autoSize={true} 
                                onInput={event.onInput}
                                onFocus={event.focus}
                            />
                        </>
                    ))}
                </div>
                <Button onClick={() => event.bakePDF()}>DOWNALOD</Button>
            </div>
   
        )
    },
    massEdit: ({SelectedKursister, setCustomLoading}) => {
        const inputOptions = useAllInputOptions()
        const {popupHandler, formatHandler} = useContext(AppContext)
        const [massEditData, setMassEditData] = useState({
            Velkomstmail: null,
            BT_sendt: null,
            BM_sendt: null,
            Kh_Status_id: null
        }) 

        const [mass_update_kursist_til_holds, updateKursistHoldQuery] = useMutation(MASS_UPDATE_KURSIST_TIL_HOLDS, {
            refetchQueries: [
                KURSIST_HOLDS,
                GET_KURSISTER_BY_HOLD,
            ],
        });

        useEffect(() => {
            if (updateKursistHoldQuery.error || updateKursistHoldQuery.loading)
                return
            if (!updateKursistHoldQuery.data)
                return
            setCustomLoading(false)
            popupHandler.remove(1)
        },[updateKursistHoldQuery.data])

        const event = {
            input: (name, value) => {
                let updateObj = {...massEditData}
                updateObj[name] = value
                setMassEditData(updateObj)
            },
            edit: () => {
                setCustomLoading(true)
                let updateData = {}
                Object.keys(massEditData).forEach(key => {
                    if (massEditData[key] === null)
                        return

                    if (["Velkomstmail", "BT_sendt", "BM_sendt"].includes(key))
                        updateData[key] = new Date(massEditData[key])
                    else
                        updateData[key] = massEditData[key]
                })
                const ids = SelectedKursister.map(obj => eval(obj.Kursist_Hold_id))
                mass_update_kursist_til_holds({variables: {ids: ids, data: updateData}})
            }
        }

        const StatusOptions = inputOptions.kh_statuser.map((s, i) => ({
            ...s,
            jsx: <span className={style.colorSelect} key={i+"Color"}><Color color={formatHandler.statusColor(s.kode)} size="10"></Color><span>{s.title}</span></span>
        }))

        return (
            <div className={style.edit_card}>
                <p>Dette vil redigere <span style={{fontWeight: "bolder"}}>{SelectedKursister.length} kursister</span>.</p>
                <div className={style.edit_card_inputs}>
                    <DateInput 
                        todayButton={true}
                        name="Velkomstmail"
                        label="Velkomstmail"
                        value={massEditData.Velkomstmail}
                        onChange={event.input}
                    />
                    <DateInput 
                        todayButton={true}
                        name="BM_sendt"
                        label="Bekræftelsesmail sendt"
                        value={massEditData.BM_sendt}
                        onChange={event.input}
                    />
                    <DateInput 
                        todayButton={true}
                        name="BT_sendt"
                        value={massEditData.BT_sendt}
                        label="BT sendt"
                        onChange={event.input}
                    />
                    <CustomSelectInput 
                        name="Kh_Status_id"
                        label="Status"
                        sort={{type: "code"}}
                        search={true}
                        options={StatusOptions}
                        value={massEditData.Kh_Status_id}
                        onInput={event.input}
                    />
                </div>
                <div className={style.edit_card_footer}>
                    <Button outlined onClick={() => popupHandler.remove(1)}>Luk</Button>
                    <Button onClick={() => event.edit()}>Gem</Button>
                </div>
            </div>
        )
    },
    kursistSelect: ({content, hold, layer}) => {
        const {popupHandler} = useContext(AppContext)
        const [kursisterOnHold, setKursisterOnHold] = useState(content.map(k => k.Kursist_id))

        const kurissterOnHoldQuery = useQuery(GET_KURSISTER_BY_HOLD, {
            pollInterval: 2000,
            variables: {
                holdId: hold.Hold_id
            }
        })

        useEffect(() => {
            if (kurissterOnHoldQuery.error || kurissterOnHoldQuery.loading)
                return
            if (!kurissterOnHoldQuery.data)
                return
            setKursisterOnHold(kurissterOnHoldQuery.data.kursister_by_hold.map(k => k.Kursist_id))
        },[kurissterOnHoldQuery.data])

        const [remove_kursist_on_hold, removeKursistOnHoldQuery] = useMutation(DELETE_KURSIST_TIL_HOLD, {
            refetchQueries: [
                GET_KURSISTER_BY_HOLD,
                KURSIST_HOLDS
            ],
        });

        useEffect(() => {
            if (removeKursistOnHoldQuery.error || removeKursistOnHoldQuery.loading)
                return
            if (!removeKursistOnHoldQuery.data)
                return
        },[removeKursistOnHoldQuery.data])


        const event = {
            addKursist: (target, kursist) => {
                const onSubmit = () => {
                    target.innerHTML = "Loading"
                    target.classList.add(style.button_loading)
                }

                popupHandler.add(
                    <KursistHoldInterface dataObj={{
                        ...kursist,
                        "Hold_id": hold.Hold_id,
                        "Hold": hold.Holdnavn,
                        "Online": hold.Online,
                        "Hold_Online": hold.Online,
                        "Rykket": null,
                        "BT_sendt": null,
                        "BM_sendt": null,
                        "Blanket_sendt": null,
                        "Velkomstmail": null,
                        "Certificering": null,
                        "Jc_Samtaler": null,
                        "Jc_Status_id": null,
                        "Jc_Success_Date": null,
                        "Jc_Note": null
                    }} mode="add" onSubmit={onSubmit}/>
                )
            },
            removeKursist: (target, kursist) => {
                target.innerHTML = "Loading"
                target.classList.add(style.button_loading)
                const kursistHoldIds = kurissterOnHoldQuery.data.kursister_by_hold
                    .filter(kh => kh.Kursist_id === kursist.Kursist_id)
                    .map(kh => eval(kh.Kursist_Hold_id))
                remove_kursist_on_hold({variables: {id: kursistHoldIds}})
            }
        }

        let Fields = {...KursisterTableFields}
        Fields.Select = {
            filter: {
                search: {default: false, hidden: true},
                table: {default: true, hidden: true}
            },
            tab: {
                title: "Tilføj Kursist",
                size: 0,
                sortable: false,
                contentType: {
                    type: "button",
                    action: (kursist) => {},
                    content: (kursist) => (
                        kursisterOnHold.includes(kursist.Kursist_id) ?
                            <Button 
                                className={style.addButton}
                                outlined
                                onClick={(e) => {
                                    const target = e.target
                                    popupHandler.add(
                                        <div className={style.delete_card}>
                                            <p>Er du sikker på at du vil fjerne <span>{kursist.Fornavn} {kursist.Efternavn}</span> fra <span>{hold.Holdnavn}</span>?</p>
                                            <div>
                                                <Button onClick={() => {
                                                    popupHandler.remove(layer+1)
                                                    event.removeKursist(target, kursist)
                                                }}>ja</Button>
                                                <Button outlined onClick={() => popupHandler.remove(layer+1)}>nej</Button>
                                            </div>
                                        </div>
                                    )
                                }}
                            >
                            Fjern</Button>
                        :
                        <Button
                            className={style.addButton}
                            onClick={(e) => event.addKursist(e.target, kursist)}  
                        >Tilføj</Button>
                    )
                },
            }
        }

        return (
            <div className={style.Kursist_select}>
                {popupHandler.layer(layer+1)}
                <TableInterface 
                    name={"Kursister-Select"}
                    primaryKey={"Kursist_id"}
                    fields={Fields}
                    query={KURSISTER}
                    onRowClick={() => console.log("dwa")}
                    tableScroll={true}
                />
            </div>
        )
    },
    content: ({children}) => {
        return (
            <div className={style.content}>
                {children.map((section, i) => (
                    [
                        section,
                        i != children.length-1 ? <div key={"split-"+i} className={style.content_split}></div> : null
                    ]
                ))}
            </div>
        )
    },
    component: ({children, error, loading}) => {
        let errorMessages = []
        if (error) {
            const networkErrors = error.networkError ? error.networkError.result.errors : []
            errorMessages = [
                ...error.clientErrors.map(e => `(${e.extensions.code}) → `+e.message),
                ...error.graphQLErrors.map(e => `(${e.extensions.code}) → `+e.message),
                ...networkErrors.map(e => `(${e.extensions.code}) → `+e.message)
            ]
        }
        return (
            <div className={style.component}>
                {loading ? <div className={style.loading_overlay}><Loading /></div> : null}
                {errorMessages.length > 0 ? 
                    <ul className={style.errors}>
                        {errorMessages.map(e => <li>{e}</li>)}
                    </ul>
                : null}
                {children}
            </div>
        )
    },
    footer: ({children}) => {
        return (
            <div className={style.footer}>
                {[children]}
            </div>
        )
    },
    functions: {
        validate: (hold) => {
            let frontEndErrors = {}

            const RequiredSelect = [
                "Kursus_id",
                "Startdato"
            ]

            RequiredSelect.map(field => {
                if (hold[field] == "" || hold[field] == null)
                    frontEndErrors[field] = "skal udfyldes"
            })

            if (Object.keys(frontEndErrors).length < 1)
                return {valid: true}
        
            return {valid: false, errors: frontEndErrors}
        }
    }
}