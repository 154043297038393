import { useState } from "react";
import AutoFillMenu from "../components/Inputs/AutoFillInput/AutoFillMenu";
import React from "react";

interface AutoFillHandlerType {
  autoFillSelectmenuState: any;
  postnummerAutoFillOptions: any[];
  setAutoFillSelectmenuState: any;
}

export class AutoFillHandler implements AutoFillHandlerType {
  autoFillSelectmenuState: any;
  postnummerAutoFillOptions: any[];
  setAutoFillSelectmenuState: any;
  constructor() {
    const [autoFillmenuState, setAutoFillSelectmenuState] = useState(null);
    this.autoFillSelectmenuState = autoFillmenuState;
    this.setAutoFillSelectmenuState = setAutoFillSelectmenuState;
    this.postnummerAutoFillOptions = [];
  }

  display() {
    if (this.autoFillSelectmenuState === null) return;
    return <AutoFillMenu {...this.autoFillSelectmenuState} />;
  }

  add(props) {
    this.setAutoFillSelectmenuState(props);
  }

  clear() {
    this.setAutoFillSelectmenuState(null);
  }

  isOpen() {
    return this.autoFillSelectmenuState != null;
  }

  setSelected(value) {
    this.setAutoFillSelectmenuState({
      ...this.autoFillSelectmenuState,
      selected: value,
    });
  }

  getState() {
    return this.autoFillSelectmenuState;
  }

  keyDown(key, e) {
    if (key === "ArrowDown") {
      e.preventDefault();
      let index = this.autoFillSelectmenuState.options.findIndex(
        (o) => o == this.autoFillSelectmenuState.selected
      );
      if (index + 1 < this.autoFillSelectmenuState.options.length)
        this.setSelected(this.autoFillSelectmenuState.options[index + 1]);
      return;
    }
    if (key === "ArrowUp") {
      e.preventDefault();
      let index = this.autoFillSelectmenuState.options.findIndex(
        (o) => o == this.autoFillSelectmenuState.selected
      );
      if (index - 1 >= 0)
        this.setSelected(this.autoFillSelectmenuState.options[index - 1]);
      return;
    }
  }

  async PostNummerOptions(value) {
    try {
      const response = await fetch(
        `https://api.dataforsyningen.dk/postnumre/autocomplete?q=${value}`
      );
      const options = await response.json();
      this.postnummerAutoFillOptions = options;
      return options;
    } catch (err) {
      return [];
    }
  }

  async addressOptions(value) {
    try {
      const response = await fetch(
        `https://api.dataforsyningen.dk/adgangsadresser/autocomplete?q=${value}`
      );
      const options = await response.json();
      return options; 
    } catch (err) {
      return [];
    }
  }
}
