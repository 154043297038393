import React, { useState } from "react";
import CustomSelectMenu from "../components/Inputs/SelectInput/CustomSelectMenu";

export interface selectMenuProps {
  name: string;
  search: string;
  target: Element;
  options: any[];
  searchOptions: any[];
  selected: number;
  searchString: string;
  setvalue: () => void;
}

export interface SelectMenuHandlerType {
  selectmenuState: selectMenuProps | null;
  setSelectMenuState: React.Dispatch<selectMenuProps>;
}

export class SelectMenuHandler implements SelectMenuHandlerType {
  selectmenuState: selectMenuProps | null;
  setSelectMenuState: React.Dispatch<selectMenuProps>;
  constructor() {
    const [selectmenuState, setSelectmenuState] = useState(null);
    this.selectmenuState = selectmenuState;
    this.setSelectMenuState = setSelectmenuState;
  }

  display() {
    if (this.selectmenuState === null) return;
    return (
      <CustomSelectMenu
        setvalue={this.selectmenuState.setvalue}
        target={this.selectmenuState.target}
        options={this.selectmenuState.searchOptions}
        selected={this.selectmenuState.selected}
        search={this.selectmenuState.search}
        searchString={this.selectmenuState.searchString}
      />
    );
  }

  hasName(name: string) {
    return this.selectmenuState.name == name;
  }

  add(props: selectMenuProps) {
    this.setSelectMenuState(props);
  }

  clear() {
    this.setSelectMenuState(null);
  }

  isOpen() {
    return this.selectmenuState != null;
  }

  value() {
    return this.selectmenuState.selected;
  }

  setSelected(value) {
    this.setSelectMenuState({
      ...this.selectmenuState,
      selected: value,
    });
  }

  search(value) {
    let searchOptions = this.selectmenuState.options
      .filter((o) => o.title.toLowerCase().includes(value.toLowerCase()))
      .sort((a, b) => {
        // Sort results by matching name with keyword position in name
        if (
          a.title.toLowerCase().indexOf(value.toLowerCase()) >
          b.title.toLowerCase().indexOf(value.toLowerCase())
        ) {
          return 1;
        } else if (
          a.title.toLowerCase().indexOf(value.toLowerCase()) <
          b.title.toLowerCase().indexOf(value.toLowerCase())
        ) {
          return -1;
        } else {
          if (a.title > b.title) return 1;
          else return -1;
        }
      });

    this.setSelectMenuState({
      ...this.selectmenuState,
      searchString: value,
      searchOptions: searchOptions,
    });
  }

  keyDown(key: string) {
    if (key === "ArrowDown") {
      let index = this.selectmenuState.searchOptions.findIndex(
        (o) => o.value == this.selectmenuState.selected
      );
      if (index + 1 < this.selectmenuState.searchOptions.length)
        this.setSelected(this.selectmenuState.searchOptions[index + 1].value);
      return;
    }
    if (key === "ArrowUp") {
      let index = this.selectmenuState.searchOptions.findIndex(
        (o) => o.value == this.selectmenuState.selected
      );
      if (index - 1 >= 0)
        this.setSelected(this.selectmenuState.searchOptions[index - 1].value);
      return;
    }

    if (!this.selectmenuState.search) return;

    if (key == "Backspace") {
      let value = this.selectmenuState.searchString.substring(
        0,
        this.selectmenuState.searchString.length - 1
      );
      this.search(value);
      return;
    }

    if (key.length === 1) this.search(this.selectmenuState.searchString + key);
  }
}
