import { useContext, useEffect, useRef } from 'react';
import { AppContext } from '../../../context/AppContext';
import Input from '../Input';

import style from './SelectInput.module.scss'

export default function CustomSelectInput(props) {
    const {customSelectMenu, selectMenuHandler} = useContext(AppContext)
    const {options, onInput, name, required, value, search, sort, filter, placeholder, loading} = props
    let {disabled} = props
    const inputRef = useRef(null)
    let newOptions;

    if (typeof loading === "string") {
        disabled = true
    }

    if (filter != false)
        newOptions = [...options].filter(o => {
            if (!("aktivt" in o))
                return true

            if (o.aktivt.toString() === "true")
                return true

            return false
        })
    else
        newOptions = [...options].filter(obj => obj.aktivt)



    if (sort && "type" in sort && sort.type === "number") {
        if (!("dir") in sort) {
            newOptions = [...newOptions].sort((a,b) => b[sort.key] - a[sort.key])
            return  
        }

        if (!sort.dir)
            newOptions = [...newOptions].sort((a,b) => b[sort.key] - a[sort.key])
        else
            newOptions = [...newOptions].sort((a,b) => a[sort.key] - b[sort.key])

    }

    if (sort && "type" in sort && sort.type === "code") {
        const codeOrder = [1, 3, 2, 0, 4];
        newOptions = [...newOptions].sort((a, b) => {
            const indexA = codeOrder.indexOf(a.kode);
            const indexB = codeOrder.indexOf(b.kode);
          
            // Handle case where code values are the same
            if (indexA === indexB) {
              // Compare based on their original order in the data array
              return newOptions.indexOf(a) - newOptions.indexOf(b);
            }
          
            return indexA - indexB;
        })
    }
         
    if (!sort) {
        newOptions = [...newOptions].sort(function(a, b) {
            if (a.title.toLowerCase()[0] === "æ" || a.title.toLowerCase()[0] === "å" || a.title.toLowerCase()[0] === "ø")
                return false
            return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
        })
    }

    useEffect(() => {
        return () => {
            selectMenuHandler.clear()
        }
    },[])

    const setvalue = (val) => {
        onInput(name, val)
    }

     useEffect(() => {
        if (selectMenuHandler.isOpen() && selectMenuHandler.hasName(name)) {
            setvalue(selectMenuHandler.value())
        }
     },[selectMenuHandler])


    const event = {
        open: (e) => {
            if (disabled)
                return
            selectMenuHandler.add({
                name: name,
                search: search,
                target: e.target,
                options: newOptions,
                searchOptions: newOptions,
                selected: value,
                searchString: "",
                setvalue: setvalue
            })
        },
        close: () => {
            selectMenuHandler.clear()
        },
        keyDown: (e) => {
            if (selectMenuHandler.isOpen()) {
                if ((e.key == "Tab" || e.key == "Enter")) {
                    selectMenuHandler.clear()
                    return
                }
                if ((e.key.includes("Arrow"))) {
                    e.preventDefault()
                }
                selectMenuHandler.keyDown(e.key)
                return
            }
            if (e.key === "ArrowDown") {
                e.preventDefault()
                let index = value != null ? newOptions.findIndex(o => o.value == value)+1 : 0
                if (index > newOptions.length-1) {
                    index = 0
                }
                
                let newValue = newOptions[index].value
                setvalue(newValue)
                return
            }
            if (e.key === "ArrowUp") {
                e.preventDefault()
                let index = value != null ? newOptions.findIndex(o => o.value == value)-1 : 0
                if (index < 0) {
                    index = newOptions.length-1
                }
                
                let newValue = newOptions[index].value
                setvalue(newValue)
                return
            }

            if (e.key == "Enter") {
                event.open(e)
                return
            }
            if (selectMenuHandler.isOpen()) {
                e.preventDefault()
                selectMenuHandler.keyDown(e.key)
            }


            if (e.key.length === 1 && /^[a-zA-Z0-9]+$/.test(e.key)) {
                let keyOptions = newOptions.filter((o, i) => {
                    return o.title[0].toLowerCase() === e.key.toLowerCase()
                })
                if (keyOptions.length === 0)
                    return
                let selectedOption = keyOptions.findIndex(o => o.value === value)
                let nextOption = selectedOption+1 > keyOptions.length-1 ? 0 : selectedOption+1
                setvalue(keyOptions[nextOption].value)
                return
            }            
        },
    }

    let selectedDisplay = () => {
        if (typeof loading === "string")
            return <span className={style.placeholder}>{loading}</span>
        if (typeof value !== "number" && !value || value === "nej") {
            if (placeholder)
                return <span className={style.placeholder}>{placeholder}</span>
            if (required)
                return <span className={style.placeholder}>required</span>
            return <span className={style.placeholder}>vælg</span>
        }
        let selectedValue = options[options.findIndex((o) => o.value == value)]
        if (!selectedValue)
            return <span className={style.placeholder}>fejl</span>
        if (selectedValue.jsx)
            return selectedValue.jsx
        return selectedValue.title
    }

    return (
        <Input {...props}>
            <div className={!disabled ? style.wrapper : style.wrapper_disabled} ref={inputRef}>
                <div className={style.custominput+`${customSelectMenu ? " "+style.isOpen: ""}`} tabIndex={disabled ? null : "0"}
                    onBlur={() => event.close()}
                    onClick={(e) => selectMenuHandler.isOpen() ? event.close() : event.open(e)}
                    onKeyDown={(e) => event.keyDown(e)}
                >
                    <span className={style.custom_option}>{selectedDisplay()}</span>
                </div>
            </div>
        </Input>
    )
}