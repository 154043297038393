// STYLE
import style from './DateTime.module.scss'

// HOOKS
import useDate from '../../hooks/useDate';

export default function DateTime() {
    return (
        <p className={style.component}>
            <span>{useDate().date}</span>
            <span>{useDate().time}</span>
        </p>
    )
}