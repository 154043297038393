import Input from "../Input"

import style from "../Input.module.scss"

export default function NumberInput(props) {
    const {disabled, onInput, name, error, style: type} = props

    let classname = ""

    if (type)
        classname = style[type]

    let {value} = props
    value = value ? value : ""

    const event = {
        onInput: (name, value) => {
            if (value === "") {
                onInput(name, value)
                return
            }

            const reg = new RegExp('^[0-9]+$');
            if (!reg.test(value))
                return
            onInput(name, value)
        }
    }

    return (
        <Input {...props} >
            <input 
                name={name}
                disabled={disabled} 
                onInput={(e) => event.onInput(name, e.target.value)}
                className={`${classname}${error ? " "+style.input_error : ""}`} 
                value={value}>
            </input>
        </Input>
    )
}