import React, { useContext, useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";

// QUERIES
import { GET_KURSIST_HOLD } from "../../../../queries/hold.gql";
import {
  UPDATE_KURSIST,
  KURSISTER,
  DELETE_KURSIST,
  GET_KURSISTER_BY_HOLD,
} from "../../../../queries/kursister.gql";
import { KURSIST_HOLDS } from "../../../../queries/kursistHold.gql";

// CONTEXT
import client from "../../../../config/apolloClient";
import { AppContext } from "../../../../context/AppContext";

// TEMPLATE
import { KursistInterfaceTemplate } from "../KursistInterfaceTemplate/KursistInterfaceTemplate";

// COMPONENTS
import Button from "../../../Button/Button";
import KursistInterface from "../KursistInterface";
import KursistHoldInterface from "../../KursistHoldInterface/KursistHoldInterface";

// STYLE
import style from "./KursistInterfaceUpdate.module.scss";
// import { formatDate, formatTime } from "../../../../functions/format/date";
import Trash from "../../../../assets/icons/indicators/Trash";

interface KursistInterfaceUpdateProps {
  kursist: any;
}

const KursistInterfaceUpdate: React.FC<KursistInterfaceUpdateProps> = ({
  kursist,
}) => {
  const { popupHandler, userData, formatHandler } = useContext(AppContext);
  const [newKursist, setNewKursist] = useState(kursist);
  const [inputErrors, setInputErrors] = useState({});
  const [disable, setDisable] = useState(true);

  const holdQuery = useQuery(GET_KURSIST_HOLD, {
    pollInterval: 5000,
    variables: {
      id: kursist.Kursist_id,
    },
  });

  const [update_kursist, updateQuery] = useMutation(UPDATE_KURSIST, {
    refetchQueries: [KURSISTER, GET_KURSIST_HOLD, KURSIST_HOLDS],
  });

  const [delete_kursist, deleteQuery] = useMutation(DELETE_KURSIST, {
    refetchQueries: [KURSISTER, GET_KURSIST_HOLD, KURSIST_HOLDS],
  });

  useEffect(() => {
    if (updateQuery.error) {
      const errorObjs = formatHandler.apiError(updateQuery.error);
      let inputErrorKeys = {};
      errorObjs.forEach((err) => {
        if ("key" in err) {
          inputErrorKeys[err.key] = err.message;
        }
      });
      setInputErrors(inputErrorKeys);
      return;
    }
    if (updateQuery.loading) return;
    if (!updateQuery.data || !updateQuery.data.update_kursist) return;

    setInputErrors({});
    setDisable(true);
    popupHandler.replace(
      0,
      <KursistInterface
        kursist={updateQuery.data.update_kursist}
        scrollable={false}
      />
    );
  }, [updateQuery.data, updateQuery.loading, updateQuery.error]);

  const event = {
    input: (name, val) => {
      console.log(kursist);
      let updateObj = { ...newKursist };
      updateObj[name] = val;
      setNewKursist(updateObj);
    },
    reset: () => {
      setInputErrors({});
      setDisable(true);
      setNewKursist({
        ...kursist,
        Adresse: kursist.Adresse === null ? "" : kursist.Adresse,
        Postnummer: kursist.Postnummer === null ? "" : kursist.Postnummer,
        By: kursist.By === null ? "" : kursist.By,
      });
    },
    update: () => {
      const fieldsToUpdate = Object.keys(newKursist).filter(
        (k) => newKursist[k] != kursist[k]
      );

      if (fieldsToUpdate.length < 1) {
        setDisable(true);
        return;
      }

      let validateObj = KursistInterfaceTemplate.functions.validate(newKursist);
      if (!validateObj.valid) {
        setInputErrors(validateObj.errors);
        return;
      }

      let updatedKursist = newKursist;

      const updateObj = {
        Mail: newKursist.Mail,
        Tlf: newKursist.Tlf,
      };
      fieldsToUpdate.forEach((f) => {
        if (f === "Note") {
          updateObj[f] = newKursist[f];
          const date = new Date();
          updatedKursist[f] = `${updateObj[f]} - ${
            userData.Initialer
          } - ${formatHandler.date(date)} ${formatHandler.time(date)} \r\n`;
          updateObj[f] = updatedKursist[f];
          setNewKursist(updatedKursist);
          return;
        }
        if (f === "Birthday") {
          updateObj["Birthday"] = new Date(newKursist[f]);
          return;
        }
        if (f === "Postnummer") {
          updateObj[f] = eval(newKursist[f]);
          return;
        }
        updateObj[f] = newKursist[f];
      });
      update_kursist({
        variables: { id: eval(newKursist.Kursist_id), input: updateObj },
      });
    },
    delete: () => {
      const htmlRow = document.querySelector(
        `[data-primary-key="Kursist_id${kursist.Kursist_id}"]`
      );
      htmlRow.classList.add("inactive_load");
      popupHandler.close();
      delete_kursist({ variables: { id: eval(kursist.Kursist_id) } });
    },
    holdClick: (hold, e) => {
      if (e.target.tagName == "svg") return;
      const dataObj = {
        ...kursist,
        ...hold,
      };
      popupHandler.add(
        <KursistHoldInterface dataObj={dataObj} />
      );
    },
  };

  return (
    <KursistInterfaceTemplate.component
      error={Object.keys(inputErrors).length > 0 ? updateQuery.error : null}
      loading={updateQuery.loading}
    >
      <div className={style.header}>
        <span>
          {formatHandler.date(kursist.Oprettet)} - {kursist.Medarbejder}
        </span>
        <div
          className={style.Trash}
          title="Slet kursist?"
          onClick={() =>
            popupHandler.add(
              <div className={style.delete_card}>
                <p>
                  Er du sikker på at du vil slette{" "}
                  <span>
                    {kursist.Fornavn} {kursist.Efternavn}
                  </span>
                  ?
                </p>
                <div>
                  <Button onClick={() => event.delete()}>ja</Button>
                  <Button outlined onClick={() => popupHandler.remove(1)}>
                    nej
                  </Button>
                </div>
              </div>
            )
          }
        >
          <Trash />
        </div>
      </div>
      <KursistInterfaceTemplate.content>
        <KursistInterfaceTemplate.baseInputs
          kursist={newKursist}
          onInput={event.input}
          disabledFields={disable}
          inputErrors={inputErrors}
        />
        <KursistInterfaceTemplate.detailInputs
          kursist={newKursist}
          setKursist={setNewKursist}
          onInput={event.input}
          disabledFields={disable}
          inputErrors={inputErrors}
        />
        <KursistInterfaceTemplate.holdInputs
          data={holdQuery.data}
          loading={holdQuery.loading}
          error={holdQuery.error}
          onClick={event.holdClick}
          kursist={kursist}
        />
      </KursistInterfaceTemplate.content>
      <KursistInterfaceTemplate.footer>
        {disable ? (
          <Button onClick={() => setDisable(false)}>Rediger</Button>
        ) : (
          [
            <Button outlined onClick={() => event.reset()}>
              Fortryd
            </Button>,
            <Button onClick={() => event.update()}>Gem</Button>,
          ]
        )}
      </KursistInterfaceTemplate.footer>
    </KursistInterfaceTemplate.component>
  );
};

export default KursistInterfaceUpdate;
