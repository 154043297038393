import React from "react";

// TYPES
import { homePagePreveiwsProps } from "./AktiveHoldPage";

// CONTEXT
import { AppContext } from "../../context/AppContext";

// COMPONENTS
import { useContext } from "react";
import PieGraph from "../../components/Graph/PieGraph";

// Style
import style from "./HomePage.module.scss";

const HomePagePreviews: React.FC<homePagePreveiwsProps> = ({
  yellowFields,
  sum,
}) => {
  const { themeHandler } = useContext(AppContext);
  const isE_learning = !("fremmøde" in sum);
  const combinedSum = isE_learning
    ? sum
    : {
        ok: sum.fremmøde.ok + sum.online.ok,
        alle: sum.fremmøde.alle + sum.online.alle,
        frafald: sum.fremmøde.frafald + sum.online.frafald,
        yellowValues: sum.fremmøde.yellowValues.map(
          (yV, i) => yV + sum.online.yellowValues[i]
        ),
      };

  return (
    <div className={style.quick_sums}>
      <PieGraph
        name={"OK"}
        online={isE_learning ? null : sum.online.ok}
        fremmøde={isE_learning ? null : sum.fremmøde.ok}
        data={[combinedSum.ok, combinedSum.alle - combinedSum.ok]}
        colors={[
          themeHandler.getColor("chart-color-green-dark"),
          themeHandler.getColor("chart-color-green-light"),
        ]}
      />
      {yellowFields.map((status, i) => (
        <PieGraph
          key={i + "yellowFields"}
          name={status.Kh_Status}
          online={isE_learning ? null : sum.online.yellowValues[i]}
          fremmøde={isE_learning ? null : sum.fremmøde.yellowValues[i]}
          data={[
            combinedSum.yellowValues[i],
            combinedSum.alle - combinedSum.yellowValues[i],
          ]}
          colors={[
            themeHandler.getColor("chart-color-yellow-dark"),
            themeHandler.getColor("chart-color-yellow-light"),
          ]}
        />
      ))}
      <PieGraph
        name={"Frafald"}
        online={isE_learning ? null : sum.online.frafald}
        fremmøde={isE_learning ? null : sum.fremmøde.frafald}
        data={[combinedSum.frafald, combinedSum.alle - combinedSum.frafald]}
        colors={[
          themeHandler.getColor("chart-color-red-dark"),
          themeHandler.getColor("chart-color-red-light"),
        ]}
      />
      <PieGraph
        name={"Alle"}
        online={isE_learning ? null : sum.online.alle}
        fremmøde={isE_learning ? null : sum.fremmøde.alle}
        data={[combinedSum.alle, 0]}
        colors={[themeHandler.getColor("chart-color-grey-dark")]}
      />
    </div>
  );
};

export default HomePagePreviews;
