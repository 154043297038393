import { useContext, useEffect, useState } from "react"

// QUERIES
import { KURSISTER, GET_KURSISTER_BY_HOLD } from '../../../../queries/kursister.gql'
import { GET_KURSIST_HOLD } from '../../../../queries/hold.gql'
import { CREATE_ANSOGER_VIA, UPDATE_ANSOGER_VIA, DELETE_ANSOGER_VIA } from '../../../../queries/catalogs/ansogerVia.gql'
import { KURSIST_HOLD_COUNT, UPDATE_KURSIST_TIL_HOLDS } from '../../../../queries/kursistHold.gql'
import { ALL_CATALOGS } from '../../../../queries/catalogs/allCatalogs.gql'

// HOOKS
import { useCatalog } from "../../../../hooks/useCatalog"

// COMPONENTS
import { AdminInterfaceTemplate } from "../AdminInterfaceTemplate/AdminInterfaceTemplate"
import CheckboxInput from "../../../Inputs/CheckboxInput/CheckboxInput"
import Input from "../../../Inputs/Input"

let lastData = [] 
let changeTimeStamp = new Date().valueOf();
const primaryKey = "Ansoger_via_id"

export default function AdminInterfaceAnsogerVia() {
    const catalogs = useCatalog()
    const content = catalogs.ansoger_vias

    const [newContent, setNewContent] = useState(content)
    const [searchString, setSearchString] = useState("")
    const [sort, setSort] = useState({field: "", dir: false})
    const [selected, setSelected] = useState(null)
    const [newObj, setNewObj] = useState(null)
    const [scrollToBottom, setScrollToBottom] = useState(false)
    const [customLoading, setCustomLoading] = useState(false)
    

    useEffect(() => {
        const filtered = AdminInterfaceTemplate.functions
        .localSearch(searchString, content, ["Ansoger_via"])
        setNewContent(filtered)
    },[searchString])

    useEffect(() => {
        const sorted = AdminInterfaceTemplate.functions.localSort(newContent, sort)
        setNewContent(sorted)
    },[sort])

    useEffect(() => {
        if (customLoading)
            setCustomLoading(false)

        if (sort.field) {
            const sorted = AdminInterfaceTemplate.functions.localSort(content, sort)
            setNewContent(sorted)
        } else {
            setNewContent(content)
        }
        if (content.toString() != lastData.toString()) {
            changeTimeStamp = new Date().valueOf();
            lastData = content
        }
    },[content])

    const event = {
        search: (name, val) => {
            setSearchString(val)
        },
        sort: (field) => {
            setSort({field: field, dir: !sort.dir})
        },
        rowClick: (val, index) => {
            setNewObj(null)
            setSelected(val)
        },
        UpdateInput: (name, val) => {
            let updateObj = {...selected}
            updateObj[name] = val
            setSelected(updateObj)
        },
        createInput: (name, val) => {
            let updateObj = {...newObj}
            updateObj[name] = val
            setNewObj(updateObj)
        },
        new: () => {
            setSelected(null)
            setNewObj({
                Ansoger_via: "",
                Aktivt: false
            })
        },
        createSuccess: (res) => {
            setSearchString("")
            setSelected(res)
            setScrollToBottom(true)
        },
        createStart: () => {
            setCustomLoading(true)
        },
        updateSuccess: (id) => {
            setSearchString("")
            setCustomLoading(false)
        },
        updateStart: () => {
            setCustomLoading(true)
        },
        deleteStart: () => {
            const htmlRow = document.querySelector(`[data-primary-key="${primaryKey}${selected[primaryKey]}"]`)
            htmlRow.classList.add("inactive_load")
            setCustomLoading(true)
        },
        deleteSuccess: () => {
            setSearchString("")
            setSelected(null)
            setNewObj(null)
        },
        moveStart: () => {
            setCustomLoading(true)
        },
        moveSuccess: () => {
            setSearchString("")
            setCustomLoading(false)
        }
    }

    return (
        <AdminInterfaceTemplate.component>
            <AdminInterfaceTemplate.content>
                <AdminInterfaceTemplate.table
                    primaryKey={primaryKey}
                    changeTimeStamp={changeTimeStamp}
                    scrollToBottom={scrollToBottom}
                    setScrollToBottom={setScrollToBottom}
                    onNew={event.new}
                    searchFunction={event.search}
                    searchString={searchString}
                    content={newContent}
                    selected={selected}
                    rowClick={event.rowClick}
                    rowSelect={!selected ? null : {field: primaryKey, value: selected[primaryKey]}}
                    cells={[
                        {
                            size: 0,
                            title: "ID",
                            name: primaryKey,
                            sort: sort,
                            onClick: () => event.sort(primaryKey)
                        },
                        {
                            size: 1,
                            title: "Asnøger Via",
                            name: "Ansoger_via",
                            sort: sort,
                            onClick: () => event.sort("Ansoger_via")
                        },
                        {
                            size: 0,
                            title: "Aktiv",
                            name: "Aktivt",
                            sort: sort,
                            onClick: () => event.sort("Aktivt")
                        }
                    ]}
                />
                {/* `{{count}} Kursister er medlem af {{title}}, sletning er ikke opfordret! */}
                {selected ? 
                    <AdminInterfaceTemplate.update
                        variables={{
                            id: selected[primaryKey],
                            input: {
                                Ansoger_via: selected.Ansoger_via,
                                Aktivt: selected.Aktivt,
                            }
                        }}
                        updateQuery={UPDATE_ANSOGER_VIA}
                        deleteQuery={DELETE_ANSOGER_VIA}
                        refetchQueries={[ALL_CATALOGS]}
                        title={selected.Ansoger_via}
                        id={selected.Ansoger_via_id}
                        countQueryObj={{
                            query: KURSIST_HOLD_COUNT,
                            variables: {
                                conditions: {
                                    Ansoger_via_id: selected.Ansoger_via_id
                                }
                            },
                            loading: "Checkker Status...",
                            warning: "{{count}} kursist-til-hold relationer ansøger via {{title}}, Sletning er ikke opfordret!",
                            move: {
                                query: UPDATE_KURSIST_TIL_HOLDS,
                                variables: {
                                    field: `Ansoger_via_id`,
                                    from: selected.Ansoger_via_id,
                                    title: selected.Ansoger_via
                                },
                                refetchQueries: [KURSISTER, KURSIST_HOLD_COUNT, ALL_CATALOGS, GET_KURSISTER_BY_HOLD, GET_KURSIST_HOLD],
                                onMoveStart: event.moveStart,
                                onMoveSuccess: event.moveSuccess,
                                text: "Skift Ansøger via på kursist-til-hold relationer",
                                menuText: "Skift Ansøger via for {{count}} kursist-til-hold relationer?",
                                selectOptions: content.map(obj => ({value: obj.Ansoger_via_id, title: obj.Ansoger_via})),
                            },
                            delete: {
                                text: "Handlingen vil forsøge at nulstille Ansøger via for {{count}} kursist-til-hold relationerne, er du sikker på du ikke vil ændre værdierne først?"
                            }
                        }}
                        customLoading={customLoading}
                        onUpdateStart={event.updateStart}
                        onUpdateSuccess={event.updateSuccess}
                        onDeleteStart={event.deleteStart}
                        onDeleteSuccess={event.deleteSuccess}
                    >
                        <Input 
                            label="Ansoger_via" 
                            name="Ansoger_via" 
                            value={selected.Ansoger_via} 
                            onInput={event.UpdateInput} 
                        />
                        <CheckboxInput 
                            label="Aktiv"
                            name="Aktivt"
                            value={selected.Aktivt}
                            onInput={event.UpdateInput} 
                        />
                    </AdminInterfaceTemplate.update>
                : newObj ?
                    <AdminInterfaceTemplate.create
                        variables={{
                            input: {...newObj}
                        }}
                        createQuery={CREATE_ANSOGER_VIA}
                        refetchQuery={ALL_CATALOGS}
                        customLoading={customLoading}
                        onCreateSuccess={event.createSuccess}
                        onCreateStart={event.createStart}
                    >
                        <Input 
                            label="Ansøger via" 
                            name="Ansoger_via" 
                            value={newObj.Ansoger_via} 
                            onInput={event.createInput} 
                        />
                        <CheckboxInput 
                            label="Aktiv"
                            name="Aktivt"
                            value={newObj.Aktivt}
                            onInput={event.createInput} 
                        />
                    </AdminInterfaceTemplate.create>
                : <AdminInterfaceTemplate.empty />}

            </AdminInterfaceTemplate.content>
        </AdminInterfaceTemplate.component>
    )
}