import React, { ReactElement, useState } from "react";
import Popup, { PopupProps } from "../components/Popup/Popup";

let body = document.querySelector("body");

export interface PopupHandlerType {
    popupState: ReactElement<any, any>[];
    setPopupState: React.Dispatch<ReactElement<any, any>[]>;
}

export class PopupHandler implements PopupHandlerType {
    popupState: ReactElement<any, any>[];
    setPopupState: React.Dispatch<ReactElement<any, any>[]>;
    constructor() {
        const [popupState, setPopupState] = useState([])
        this.popupState = popupState;
        this.setPopupState = setPopupState;
    }

    setScroll(popupArray: ReactElement[]) {
        if (popupArray.length > 0) {
            body.style.overflowY = "hidden"
            body.style.marginRight = "12px"
        } else {
            body.style.overflowY = "scroll"
            body.style.marginRight = "0px" 
        }
    }

    layer(index: number) {
        if (this.popupState[index])
            return this.popupState[index]
        return null
    }

    add(content: ReactElement, modifier?: PopupProps["modifier"]) {
        const newContent = [...this.popupState]
        newContent.push(
            <Popup 
                modifier={modifier}
                layer={newContent.length}
                content={content}
                close={() => {
                    this.remove(newContent.length)
                }}
            />
        )
        this.setPopupState(newContent)
        this.setScroll(newContent)
    }

    replace(index: number, content: ReactElement) {
        const newContent = [...this.popupState];
        newContent[index] = (
            <Popup 
                layer={index}
                content={content}
                close={() => this.remove(index)} 
            />
        );
        this.setPopupState(newContent);
        this.setScroll(newContent)
    }

    remove(index: number) {
        let newState = [...this.popupState]
        newState.splice(index, 1)
        this.setPopupState(newState)
        this.setScroll(newState)
    }

    close() {
        let newState = [...this.popupState]
        newState.pop()
        this.setPopupState(newState)
        this.setScroll(newState)
    }

    clear() {
        this.setPopupState([])
        this.setScroll([])
    }

    state() {
        return this.popupState
    }

    nextLayer() {
        return !this.popupState ? 0 : this.popupState.length
    }

}