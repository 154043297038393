import Cross from '../../assets/icons/indicators/Cross'
import style from './Input.module.scss'

export default function Input(props) {
    const {label, disabled, placeholder, title, Link, onInput, name, style: type, required, error, InputKey, resetButton, className, checkboxStyle, autoFocus, onBlur} = props

    let classname = ""

    if (type)
        classname = style[type]

    if (resetButton)
        classname += " "+style["WithResetButton"]
    else {
        classname += " "+style["withoutResetButton"]
    }

    let {value} = props
    value = value ? value : ""

    return (
        <div className={`${style.component}${className ? " "+className : ""}${checkboxStyle ? " "+checkboxStyle : ""}`} key={InputKey ? InputKey : name} title={title}>
            {resetButton && value ? <Cross className={style.resetSearch} onClick={() => onInput(name, "")} /> : null}
            {label ? <label htmlFor={name+"id"}>{label}</label> : null}
            {error ? <span className={style.input_error_msg}>- {error}</span> : null}
            {props.children ? props.children : 
                Link && disabled ? 
                    <a href={Link} className={style.link}>
                        <input 
                            type="text"
                            onInput={(e) => onInput(name, e.target.value)}
                            className={`${classname}${error ? " "+style.input_error : ""}`}
                            placeholder={`${placeholder ? placeholder : ""} ${required ? "required" : ""}`}
                            disabled={disabled}
                            value={value}
                            id={name+"id"}
                            name={name}>
                        </input>
                    </a>
                : 
                <input 
                    onBlur={onBlur}
                    autoFocus={autoFocus}
                    type="text"
                    onInput={(e) => onInput(name, e.target.value)}
                    className={`${classname}${error ? " "+style.input_error : ""}`}
                    placeholder={`${placeholder ? placeholder : ""} ${required ? "required" : ""}`}
                    disabled={disabled}
                    value={value}
                    name={name}>
                </input>}
        </div>
    )
}