import { useContext, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { holdTableFields } from '../../../../pages/HoldPage/holdTableFields'

// QUERIES
import { GET_HOLD, GET_KURSIST_HOLD } from '../../../../queries/hold.gql'
import { KURSIST_HOLDS, DELETE_KURSIST_TIL_HOLD } from '../../../../queries/kursistHold.gql'

// CONTEXT
import { AppContext } from '../../../../context/AppContext'

// HOOKS
import useAllInputOptions from '../../../../hooks/useAllInputOptions'
// COMPONENTS
import Input from '../../../Inputs/Input'
import AreaInput from '../../../Inputs/AreaInput/AreaInput'
import DateInput from '../../../Inputs/DateInput/DateInput'
import CheckboxInput from '../../../Inputs/CheckboxInput/CheckboxInput'
import AutoFillInput from '../../../Inputs/AutoFillInput/AutoFillInput'
import Loading from '../../../../assets/icons/loaders/Loading'
import LinkInput from '../../../Inputs/LinkInput/LinkInput'
import Button from '../../../Button/Button'
import TableInterface from '../../TableInterface/TableInterface'
import CustomSelectInput from '../../../Inputs/SelectInput/CustomSelectInput'
import Color from '../../../../assets/icons/indicators/Color'
import KursistHoldInterface from '../../KursistHoldInterface/KursistHoldInterface'

// STYLE
import style from './KursistInterfaceTemplate.module.scss'
import Folder from '../../../../assets/icons/indicators/Folder'


export const KursistInterfaceTemplate = {
    baseInputs: ({kursist, disabledFields, inputErrors, onInput}) => {
        const inputOptions = useAllInputOptions()
        return (
            <div className={style.section_scrollable}>
                <div className={style.section_scrollable_container}>
                    <div className={style.heading}>
                        <h2>Basis</h2>
                    </div>
                    <div className={style.split_inputs}>
                        <Input 
                            required={true}
                            label="Navn" 
                            name="Fornavn" 
                            value={kursist.Fornavn} 
                            disabled={disabledFields} 
                            onInput={onInput} 
                            error={"Fornavn" in inputErrors ? inputErrors.Fornavn : null}
                        />
                        <Input 
                            required={true}
                            label="Efternavn" 
                            name="Efternavn" 
                            value={kursist.Efternavn} 
                            disabled={disabledFields} 
                            onInput={onInput} 
                            error={"Efternavn" in inputErrors ? inputErrors.Efternavn : null}
                        />
                    </div>
                    <Input 
                        label="Tlf" 
                        name="Tlf" 
                        value={kursist.Tlf} 
                        disabled={disabledFields} 
                        onInput={onInput} 
                        error={"Tlf" in inputErrors ? inputErrors.Tlf : null}
                    />
                    <LinkInput 
                        link={kursist.Mail ? "mailto:"+kursist.Mail : null}
                        label="Mail" 
                        name="Mail"
                        type="mail"
                        value={kursist.Mail} 
                        disabled={disabledFields} 
                        onInput={onInput}
                        error={"Mail" in inputErrors ? inputErrors.Mail : null}
                    />
                    <CustomSelectInput 
                        label="Køn" 
                        name="Gender" 
                        onInput={onInput}
                        options={inputOptions.genders}
                        value={kursist.Gender}
                        disabled={disabledFields} 
                    />
                    <DateInput 
                        label="Fødselsdato" 
                        name="Birthday"
                        value={kursist.Birthday} 
                        disabled={disabledFields} 
                        onInput={onInput}
                    />
                    <AreaInput 
                        label="Note" 
                        name="Note" 
                        value={kursist.Note} 
                        autoSize={true} 
                        disabled={disabledFields} 
                        onInput={onInput} 
                    />
                </div>
            </div>
        )
    },
    detailInputs: ({kursist, setKursist, disabledFields, inputErrors, onInput}) => {
        const {autoFillHandler} = useContext(AppContext)
        const inputOptions = useAllInputOptions()
        const [postNrOptions, setPostNrOptions] = useState([])
        const [addressOptions, setAddressOptions] = useState([])

        async function getAddressOptions(val) {
            const options = await autoFillHandler.addressOptions(val);
            setAddressOptions(options)
        }

        async function getPostnrOptions(val) {
            const options = await autoFillHandler.PostNummerOptions(val);
            setPostNrOptions(options)
        }

        useEffect(() => {
            getAddressOptions(kursist.Adresse)
        },[kursist.Adresse])

        useEffect(() => {
            getPostnrOptions(kursist.Postnummer)
        },[kursist.Postnummer])

        useEffect(() => {
            getPostnrOptions(kursist.By)
        },[kursist.By])

        const event = {
            address: {
                onInput: (name, val) => {
                    onInput(name, val)
                },
                onComplete: (val) => {
                    let selectedOption = addressOptions.find(ao => ao.tekst.includes(val))
                    if (!selectedOption)
                        return

                    setKursist({
                        ...kursist,
                        Adresse: `${selectedOption.adgangsadresse.vejnavn} ${selectedOption.adgangsadresse.husnr}${selectedOption.adgangsadresse.supplerendebynavn ? ", "+selectedOption.adgangsadresse.supplerendebynavn : ""}`,
                        Postnummer: selectedOption.adgangsadresse.postnr,
                        By: selectedOption.adgangsadresse.postnrnavn, 
                        Land: "Danmark"
                    })
                }
            },
            postNr: {
                onInput: (name, val) => {
                    if (name === "Postnummer") {
                        const pattern = /^\d{0,4}$/;
                        if (!pattern.test(val)) {
                            return
                        }
                    }
    
                    onInput(name, val)
                },
                onComplete: (val) => {
                    let selectedOption = postNrOptions.find(ao => ao.tekst.includes(val))
                    if (!selectedOption)
                        return

                    setKursist({
                        ...kursist,
                        Postnummer: selectedOption.postnummer.nr,
                        By: selectedOption.postnummer.navn, 
                        Land: "Danmark"
                    })
                }
            },
        }

        return (
            <div className={style.section_scrollable}>
                <div className={style.section_scrollable_container}>
                    <div className={style.heading}>
                        <h2>Detaljer</h2>
                    </div>
                    <Input 
                        label="Uddannelsesniveau" 
                        name="Uddannelsesniveau" 
                        value={kursist.Uddannelsesniveau} 
                        disabled={disabledFields} 
                        onInput={onInput} 
                    />
                    <CustomSelectInput 
                        search={true}
                        label="A-kasse" 
                        name="A_kasse"
                        onInput={onInput} 
                        options={inputOptions.a_kasser}
                        value={kursist.A_kasse} 
                        disabled={disabledFields} 
                    />
                    <CustomSelectInput 
                        search={true}
                        label="Jobcenter" 
                        name="Jobcenter"
                        onInput={onInput} 
                        options={inputOptions.jobcentre}
                        value={kursist.Jobcenter} 
                        disabled={disabledFields} 
                    />
                    <Input 
                        label="Kontaktperson" 
                        name="Kontaktperson" 
                        value={kursist.Kontaktperson} 
                        disabled={disabledFields} 
                        onInput={onInput} 
                    />
                    <CustomSelectInput 
                        search={true}
                        label="Indgang" 
                        name="Indgang"
                        onInput={onInput} 
                        options={inputOptions.indgange}
                        value={kursist.Indgang} 
                        disabled={disabledFields} 
                    />
                    <CheckboxInput 
                        label="CV + Udb modtaget"
                        name="Udb"
                        onInput={onInput}
                        value={kursist.Udb}
                        disabled={disabledFields} 
                    />
                    <CheckboxInput 
                        label="RKV godkendt" 
                        name="Rkv"
                        onInput={onInput}
                        value={kursist.Rkv}
                        disabled={disabledFields} 
                    />
                    <AutoFillInput 
                        label="Adresse" 
                        name="Adresse"
                        onAutoFillComplete={event.address.onComplete}
                        value={kursist.Adresse} 
                        disabled={disabledFields} 
                        onInput={event.address.onInput}
                        options={addressOptions.map(o => o.tekst)}
                    />
                    <div className={style.split_inputs}>
                        <AutoFillInput
                            type="number"
                            onAutoFillComplete={event.postNr.onComplete}
                            label="Postnummer" 
                            name="Postnummer"
                            options={postNrOptions.map(o => o.postnummer.nr)}
                            value={kursist.Postnummer} 
                            disabled={disabledFields} 
                            onInput={event.postNr.onInput}
                            maxlength="4"
                        />
                        <AutoFillInput 
                            onAutoFillComplete={event.postNr.onComplete}
                            label="By" 
                            name="By"
                            options={[...new Set(postNrOptions.map(p => p.postnummer.navn))]}
                            value={kursist.By} 
                            disabled={disabledFields} 
                            onInput={event.postNr.onInput}
                        />
                    </div>
                    <Input 
                        label="Land" 
                        name="Land" 
                        value={kursist.Land} 
                        disabled={disabledFields} 
                        onInput={onInput} 
                    />
                </div>
            </div>
        )
    },

    holdInputs: ({data, loading, error, onClick, kursist}) => {
        const {popupHandler, formatHandler} = useContext(AppContext)

        const combindeJCNote = (index) => {
            if (!data.kursist_hold)
                return

            let noteTextTemplate = ""

            for (let i=data.kursist_hold.length-1; i>=0; i--) {
                if (i != index && data.kursist_hold[i].Jc_Note != null && data.kursist_hold[i].Jc_Note != "null" && data.kursist_hold[i].Jc_Note != "") {
                    if (noteTextTemplate.length > 0) {
                        noteTextTemplate += "\n"
                    }
                    noteTextTemplate += `${data.kursist_hold[i].Hold}\n`
                    noteTextTemplate += `${data.kursist_hold[i].Jc_Note}`
                }
            }

            return noteTextTemplate
        }

        const event = {
            onKeyDown: (e, hold, combindeJCNote) => {
                if (e.key != "Enter")
                    return

                onClick({...hold, combinedNote: combindeJCNote})

            }
        }

        return (
            <div className={style.section}>
                <div className={style.heading}>
                    <h2>Hold</h2>
                    <Button 
                        outlined 
                        rounded 
                        color="main"
                        onClick={() => popupHandler.add(<KursistInterfaceTemplate.holdSelect content={data.kursist_hold} kursist={kursist} />, "big")}
                    >Tilmeld hold</Button>
                </div>
                <div className={style.hold}>
                    {loading ? <Loading className={style.hold_load} /> :
                        <div className={style.hold_list}>
                            {!data ? null : data.kursist_hold.map((hold, i) => (
                                <div tabIndex={"0"} className={style.hold_card} key={"HoldCard-"+i} onClick={(e) => onClick({...hold, combinedNote: combindeJCNote(i)}, e)} onKeyDown={(e) => event.onKeyDown(e, hold, combindeJCNote(i))}>
                                    {!hold.Sharepoint ? null : <a title='sharepoint mappe' href={hold.Sharepoint} target='_blank' className={style.hold_card_folder}><Folder /></a>}
                                    <p className={style.hold_card_title}>{hold.Hold}</p>
                                    <p className={style.hold_card_title}>
                                        {hold.Online ? "Online" : "Fremmøde"}
                                    </p>
                                    <p className={style.hold_card_prop}>
                                        <span>Kursus:</span> {hold.Kursus}
                                    </p>
                                    <p className={style.hold_card_prop}>
                                        <span>Start:</span> {formatHandler.date(hold.Startdato)}
                                    </p>
                                    <p className={style.hold_card_prop}>
                                        <span>Status:</span> <Color size="8" color={formatHandler.statusColor(eval(hold.kode))} /> {hold.Status}
                                    </p>
                                    <p className={style.hold_card_prop}>
                                        <span>Blanket Sendt:</span> {hold.Blanket_sendt ? formatHandler.date(hold.Blanket_sendt) : "nej"}
                                    </p>
                                    <p className={style.hold_card_prop}>
                                        <span>Rykket:</span> {hold.Rykket ? formatHandler.date(hold.Rykket) : "nej"}
                                    </p>
                                    <p className={style.hold_card_prop}>
                                        <span>BM sendt</span> {hold.BM_sendt ? formatHandler.date(hold.BM_sendt) : "nej"}
                                    </p>
                                    <p className={style.hold_card_prop}>
                                        <span>BT Sendt:</span> {hold.BT_sendt ? formatHandler.date(hold.BT_sendt) : "nej"}
                                    </p>
                                    <p className={style.hold_card_prop}>
                                        <span>Velkomstmail:</span> {hold.Velkomstmail ? formatHandler.date(hold.Velkomstmail) : "nej"}
                                    </p>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div>
        )
    },
    holdSelect: ({content, kursist, layer}) => {
        const [shouldClose, setShouldClose] = useState(false)
        const {popupHandler} = useContext(AppContext)
        const [HoldByKursist, setHoldByKursist] = useState(content.map(h => eval(h.Hold_id)))

        const HoldByKursistQuery = useQuery(GET_KURSIST_HOLD, {
            pollInterval: 2000,
            variables: {
                id: kursist.Kursist_id
            },
        })

        useEffect(() => {
            if (HoldByKursistQuery.error || HoldByKursistQuery.loading)
                return
            if (!HoldByKursistQuery.data)
                return
                setHoldByKursist(HoldByKursistQuery.data.kursist_hold.map(h => eval(h.Hold_id)))
                if (shouldClose)
                    popupHandler.close()
        },[HoldByKursistQuery.data])

        const [remove_kursist_on_hold, removeKursistOnHoldQuery] = useMutation(DELETE_KURSIST_TIL_HOLD, {
            refetchQueries: [
                KURSIST_HOLDS,
                GET_KURSIST_HOLD
            ],
        });

        const event = {
            addHold: (target, hold) => {
                const onSubmit = () => {
                    setShouldClose(true)
                    target.innerHTML = "Loading"
                    target.classList.add(style.button_loading)
                }  
                popupHandler.add(
                    <KursistHoldInterface dataObj={{
                        ...kursist,
                        "Hold_id": hold.Hold_id,
                        "Hold": hold.Holdnavn,
                        "Online": (hold.Online === "Online" || hold.Online === true),
                        "Hold_Online": (hold.Online === "Online" || hold.Online === true),
                        "Rykket": null,
                        "BT_sendt": null,
                        "BM_sendt": null,
                        "Blanket_sendt": null,
                        "Velkomstmail": null,
                        "Certificering": null,
                        "Jc_Samtaler": null,
                        "Jc_Status_id": null,
                        "Jc_Success_Date": null,
                        "Jc_Note": null
                    }} mode="add" onSubmit={onSubmit}/>
                )
            },
            removeHold: (target, hold) => {
                target.innerHTML = "Loading"
                target.classList.add(style.button_loading)
                const kursistHoldIds = HoldByKursistQuery.data.kursist_hold
                    .filter(kh => eval(kh.Hold_id) === eval(hold.Hold_id))
                    .map(kh => eval(kh.Kursist_Hold_id))

                remove_kursist_on_hold({variables: {id: kursistHoldIds}})
            }
        }

        let Fields = {...holdTableFields}
        let buttonField = {
            filter: {
                search: {default: false, hidden: true},
                table: {default: true, hidden: true}
            },
            tab: {
                title: "Tilføj Hold",
                size: 0,
                sortable: false,
                contentType: {
                    type: "button",
                    action: (hold) => {},
                    content: (hold) => {
                        return HoldByKursist.includes(hold.Hold_id) ?
                            <Button 
                                className={style.addButton}
                                outlined
                                onClick={(e) => {
                                    const target = e.target
                                    popupHandler.add(
                                        <div className={style.delete_card}>
                                            <p>Er du sikker på at du vil fjerne <span>{kursist.Fornavn} {kursist.Efternavn}</span> fra <span>{hold.Holdnavn}</span>?</p>
                                            <div>
                                                <Button onClick={() => {
                                                    popupHandler.remove(layer+1)
                                                    event.removeHold(target, hold)
                                                }}>ja</Button>
                                                <Button outlined onClick={() => popupHandler.remove(layer+1)}>nej</Button>
                                            </div>
                                        </div>
                                    )
                                }}
                            >
                            Fjern</Button>
                        :
                        <Button
                            className={style.addButton}
                            onClick={(e) => event.addHold(e.target, hold)}  
                        >Tilføj</Button>
                    }
                },
            }
        }

        let keyValues = Object.entries(Fields);
        keyValues.splice(2,0, ["Select", buttonField]);
        let orderedFields = Object.fromEntries(keyValues)

        return (
            <div className={style.Hold_select}>
                {popupHandler.layer(layer+1)}
                <TableInterface
                    dateBarFilter={true}
                    aktivtFilter={true}
                    name={"hold-Select"}
                    sort={{field: "Holdnavn", dir: true}}
                    primaryKey={"Hold_id"}
                    fields={orderedFields}
                    query={GET_HOLD}
                    onRowClick={() => console.log("dwa")}
                    tableScroll={true}
                />
            </div>
        )
    },
    content: ({children}) => {
        return (
            <div className={style.content}>
                {children.map((section, i) => (
                    [
                        section,
                        i != children.length-1 ? <div key={"split-"+i} className={style.content_split}></div> : null
                    ]
                ))}
            </div>
        )
    },
    component: ({children, error, loading}) => {
        const {formatHandler} = useContext(AppContext)
        let errorMessages = []
        if (error) {
            const errorObjs = formatHandler.apiError(error)
            errorMessages = errorObjs.map(e =>  `(${e.code}) → `+e.message)
        }

        return (
            <div className={style.component}>
                {loading ? <div className={style.loading_overlay}><Loading /></div> : null}
                {errorMessages.length > 0 ? 
                    <ul className={style.errors}>
                        {errorMessages.map(e => <li>{e}</li>)}
                    </ul>
                : null}
                {children}
            </div>
        )
    },
    footer: ({children}) => {
        return (
            <div className={style.footer}>
                {[children]}
            </div>
        )
    },
    functions: {
        validate: (kursist) => {
            const regex = {
                mail: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/),
                tlf: new RegExp(/^[0-9]/),
            }
        
            let frontEndErrors = {}
            if (!kursist.Fornavn)
                frontEndErrors.Fornavn = "skal udfyldes"
        
            if (!kursist.Efternavn)
                frontEndErrors.Efternavn = "skal udfyldes"
        
            if (kursist.Mail)
                if (!regex.mail.test(kursist.Mail))
                    frontEndErrors.Mail = "invalid mail"
            
            if (kursist.Tlf)
                if (!regex.tlf.test(kursist.Tlf) || kursist.Tlf.length > 12)
                    frontEndErrors.Tlf = "invalid phone number"
                    
            const RequiredSelect = [

            ]

            RequiredSelect.map(field => {
                if (kursist[field] == "" || kursist[field] == null)
                    frontEndErrors[field] = "skal udfyldes"
            })
        
            if (Object.keys(frontEndErrors).length < 1)
                return {valid: true}
            
            return {valid: false, errors: frontEndErrors}
        }
    }
}