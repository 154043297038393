import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// TYPES
import { Hold_Overview } from "../../../customGraphqlTypes";
import { homePageTableProps } from "./AktiveHoldPage";

// QUERIES
import { HOLD_OVERVIEW } from "../../queries/hold.gql";

// CONTEXT
import { AppContext } from "../../context/AppContext";

// ASSETS
import Loading from "../../assets/icons/loaders/Loading";
import Alert from "../../assets/icons/indicators/Alert";
import Link from "../../assets/icons/indicators/Link";

// COMPONENTS
import HomePagePreviews from "./HomePagePreviews";
import DateInput from "../../components/Inputs/DateInput/DateInput";
import CheckboxInput from "../../components/Inputs/CheckboxInput/CheckboxInput";

// Style
import style from "./HomePage.module.scss";

let savedOverViewData = null;
let savedSum = null;

const HomePageHoldTableCell: React.FC<{
  O: string | number;
  F: string | number;
  OF: string | number;
}> = ({ O, F, OF }) => {
  return (
    <div>
      <div className={style.table_textwrap}>
        <span>Online:</span>
        <span>{O}</span>
      </div>
      <div className={style.table_textwrap}>
        <span>Fremmøde:</span>
        <span>{F}</span>
      </div>
      <div className={style.table_textwrap}>
        <span>Online/Fremmøde:</span>
        <span>{OF}</span>
      </div>
    </div>
  );
};

const HomePageHoldTable: React.FC<homePageTableProps> = ({
  yellowFields,
  catalog,
  StatusCodes,
}) => {
  const { formatHandler, activeHoldHandler } = useContext(AppContext);
  const { aktiveHold } = activeHoldHandler;
  const [overviewData, setOverviewData] = useState(savedOverViewData);
  const [sum, setSum] = useState(
    savedSum
      ? savedSum
      : {
          online: {
            alle: 0,
            ok: 0,
            frafald: 0,
            yellowValues: [0, 0, 0],
          },
          fremmøde: {
            alle: 0,
            ok: 0,
            frafald: 0,
            yellowValues: [0, 0, 0],
          },
        }
  );
  const navigate = useNavigate();
  const [SearchDate, setSearchDate] = useState({
    fromDate: aktiveHold.fromDate,
    toDate: aktiveHold.toDate,
    filterEmpty: aktiveHold.filterEmpty,
  });

  const { data, loading, error } = useQuery(HOLD_OVERVIEW, {
    pollInterval: 10000,
    variables: SearchDate,
  });

  useEffect(() => {
    if (error || loading || !catalog.kh_statuser.length) return;

    const formatKursistStatus = (hold) => {
      const kursisterOnline = hold.Kursister_til_hold.filter((k) => k.Online);
      const kursisterFremmøde = hold.Kursister_til_hold.filter(
        (k) => !k.Online
      );
      let kursistStatusIds = kursisterOnline.map((kh) => kh.Kh_Status_id);
      let statuser = kursistStatusIds.map((id) =>
        catalog.kh_statuser.find((status) => status.Kh_Status_id === id)
      );
      const online = {
        alle: statuser.filter((s) => StatusCodes.alle.includes(s.Kode)).length,
        frafald: statuser.filter((s) => StatusCodes.frafald.includes(s.Kode))
          .length,
        ok: statuser.filter((s) => StatusCodes.ok.includes(s.Kode)).length,
        yellowValues: yellowFields.map(
          (yf) => statuser.filter((rs) => rs.Kh_Status === yf.Kh_Status).length
        ),
      };

      kursistStatusIds = kursisterFremmøde.map((kh) => kh.Kh_Status_id);
      statuser = kursistStatusIds.map((id) =>
        catalog.kh_statuser.find((status) => status.Kh_Status_id === id)
      );
      const fremmøde = {
        alle: statuser.filter((s) => StatusCodes.alle.includes(s.Kode)).length,
        frafald: statuser.filter((s) => StatusCodes.frafald.includes(s.Kode))
          .length,
        ok: statuser.filter((s) => StatusCodes.ok.includes(s.Kode)).length,
        yellowValues: yellowFields.map(
          (yf) => statuser.filter((rs) => rs.Kh_Status === yf.Kh_Status).length
        ),
      };
      return { online: online, fremmøde: fremmøde };
    };

    const formatData = data.hold_overview
      .map((hold: Hold_Overview) => {
        const statusObj = formatKursistStatus(hold);
        if (
          SearchDate.filterEmpty === "true" &&
          statusObj.online.alle + statusObj.fremmøde.alle < 1
        ) {
          return null;
        }
        return {
          hold: hold.Holdnavn,
          id: hold.Hold_id,
          ...statusObj,
        };
      })
      .filter((res: any) => res != null);

    let [sumAll, sumOk, sumFrafald] = [
      { online: 0, fremmøde: 0 },
      { online: 0, fremmøde: 0 },
      { online: 0, fremmøde: 0 },
    ];
    let sumYellows = {
      online: new Array(yellowFields.length),
      fremmøde: new Array(yellowFields.length),
    };
    sumYellows.online.fill(0);
    sumYellows.fremmøde.fill(0);

    formatData.forEach((fdata) => {
      sumAll.online += fdata.online.alle;
      sumAll.fremmøde += fdata.fremmøde.alle;
      sumOk.online += fdata.online.ok;
      sumOk.fremmøde += fdata.fremmøde.ok;
      sumFrafald.online += fdata.online.frafald;
      sumFrafald.fremmøde += fdata.fremmøde.frafald;

      fdata.online.yellowValues.forEach((yf, index) => {
        sumYellows.online[index] += yf;
      });

      fdata.fremmøde.yellowValues.forEach((yf, index) => {
        sumYellows.fremmøde[index] += yf;
      });
    });

    const sumData = {
      online: {
        alle: sumAll.online,
        ok: sumOk.online,
        frafald: sumFrafald.online,
        yellowValues: sumYellows.online,
      },
      fremmøde: {
        alle: sumAll.fremmøde,
        ok: sumOk.fremmøde,
        frafald: sumFrafald.fremmøde,
        yellowValues: sumYellows.fremmøde,
      },
    };

    savedSum = sumData;
    setSum(savedSum);

    savedOverViewData = formatData;
    setOverviewData(savedOverViewData);
  }, [data, loading]);

  const event = {
    input: (name: string, value: string) => {
      const updateObj = { ...SearchDate };
      const valueString = value.toString();
      updateObj[name] = valueString;
      if (name === "filterEmpty")
        activeHoldHandler.setFilterEmpty("hold", valueString);
      else activeHoldHandler.setDate("hold", name, valueString);
      setSearchDate(updateObj);
    },
    goToHold: (id: string) => {
      const location = "/hold";
      navigate(location, {
        replace: true,
        state: { initalSearch: `Hold_id=${id}`, select: id },
      });
    },
  };

  return (
    <>
      <div className={style.page_inputs}>
        <DateInput
          name="fromDate"
          label="Fra"
          value={SearchDate.fromDate}
          onInput={event.input}
          className={style.page_inputs__fromdate}
        />
        <DateInput
          name="toDate"
          label="Til"
          value={SearchDate.toDate}
          onInput={event.input}
          className={style.page_inputs__todate}
        />
        <CheckboxInput
          label="Filtrer tomme hold fra"
          name="filterEmpty"
          onInput={event.input}
          value={SearchDate.filterEmpty === "true"}
          className={style.page_inputs__empty}
        />
        {aktiveHold.warning ? (
          <div className={style.alert}>
            <Alert />
            <p>{aktiveHold.warning}</p>
          </div>
        ) : null}
      </div>
      <HomePagePreviews yellowFields={yellowFields} sum={sum} />
      {loading || catalog.kh_statuser.length <= 0 ? (
        <div className={style.card}>
          <div className={style.loader}>
            <Loading />
          </div>
        </div>
      ) : error ? (
        <div className={style.card}>
          <span>{error.message}</span>
        </div>
      ) : !overviewData ? (
        <div>
          <span>Der er noget galt</span>
        </div>
      ) : overviewData.length <= 0 ? (
        <div className={style.card}>
          <span>
            Ingen hold mellem {formatHandler.date(SearchDate.fromDate)} og{" "}
            {formatHandler.date(SearchDate.toDate)}
          </span>
        </div>
      ) : (
        <div className={style.card}>
          <div className={style.tableWrap}>
            <table className={style.table}>
              <tbody>
                <tr>
                  {yellowFields.map((status, i) => (
                    <th key={i + "yellowFields"}>{status.Kh_Status}</th>
                  ))}
                  <th>Hold</th>
                  <th>ALLE</th>
                  <th>OK</th>
                </tr>
                {overviewData === null || !overviewData.length
                  ? null
                  : overviewData.map((data, i) => (
                      <tr key={i + "yellowFields"}>
                        {data.fremmøde.yellowValues.map((yv, i) => (
                          <td key={i + "yellowFieldsOV"}>
                            <HomePageHoldTableCell
                              O={data.online.yellowValues[i]}
                              F={data.fremmøde.yellowValues[i]}
                              OF={
                                data.online.yellowValues[i] +
                                data.fremmøde.yellowValues[i]
                              }
                            />
                          </td>
                        ))}
                        <td className={style.hold_coloumn}>
                          <div>
                            <p>{data.hold}</p>
                            <Link onClick={() => event.goToHold(data.id)} />
                          </div>
                        </td>
                        <td>
                          <HomePageHoldTableCell
                            O={data.online.alle}
                            F={data.fremmøde.alle}
                            OF={data.online.alle + data.fremmøde.alle}
                          />
                        </td>
                        <td>
                          <HomePageHoldTableCell
                            O={data.online.ok}
                            F={data.fremmøde.ok}
                            OF={data.online.ok + data.fremmøde.ok}
                          />
                        </td>
                      </tr>
                    ))}
                <tr className={style.sum_row}>
                  {sum.fremmøde.yellowValues.map((yv, i) => (
                    <td key={i + "yellowFieldsOV"}>
                      <HomePageHoldTableCell
                        O={sum.online.yellowValues[i]}
                        F={sum.fremmøde.yellowValues[i]}
                        OF={
                          sum.online.yellowValues[i] +
                          sum.fremmøde.yellowValues[i]
                        }
                      />
                    </td>
                  ))}
                  <td></td>
                  <td>
                    <HomePageHoldTableCell
                      O={sum.online.alle}
                      F={sum.fremmøde.alle}
                      OF={sum.online.alle + sum.fremmøde.alle}
                    />
                  </td>
                  <td>
                    <HomePageHoldTableCell
                      O={sum.online.ok}
                      F={sum.fremmøde.ok}
                      OF={sum.online.ok + sum.fremmøde.ok}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePageHoldTable;
