// COMPONENTS
import React, { useEffect } from "react";
import TabInterface from "../TabInterface/TabInterface";
import HoldInterfaceCreate from "./HoldInterfaceCreate/HoldInterfaceCreate";
import HoldInterfaceUpdate from "./HoldInterfaceUpdate/HoldInterfaceUpdate";

interface HoldInterfaceProps {
  hold?: any;
  createMode?: any;
  scrollable?: any;
  stopPolling?: any;
  startPolling?: any;
}

const HoldInterface: React.FC<HoldInterfaceProps> = ({
  hold,
  createMode,
  scrollable,
  stopPolling,
  startPolling,
}) => {
  useEffect(() => {
    if (!stopPolling || !startPolling) return;
    stopPolling();
    return () => {
      startPolling(5000);
    };
  }, []);

  return (
    <TabInterface scrollable={scrollable} tabs={["Hold"]}>
      {createMode ? (
        <HoldInterfaceCreate />
      ) : (
        <HoldInterfaceUpdate hold={hold} />
      )}
    </TabInterface>
  );
};

export default HoldInterface;
