import React, { ComponentPropsWithRef, TableHTMLAttributes, forwardRef } from 'react'

// COMPONENTS
import Loading from '../../assets/icons/loaders/Loading'
import CheckboxInput from '../Inputs/CheckboxInput/CheckboxInput'

// STYLE
import style from './Table.module.scss'

interface TableProps extends ComponentPropsWithRef<"table"> {
    changeTimeStamp: any
    children: any
    onRowClick: any
    loading: any
    error: any
    rowSelect?: any
    content: any
    primaryKey: any
}

const Table = React.forwardRef<HTMLTableRowElement, TableProps>((props, ref) => {
// const Table = forwardRef((props, ref) => {
    const {
        changeTimeStamp,
        children,
        onRowClick,
        loading,
        error,
        rowSelect,
        content,
        primaryKey
    } = props

    const cells = Array.isArray(children) ? children : [children]

    const dataToReadable = (data) => {
        if (typeof data === "boolean")
            return data ? "ja" : "nej"
        
        return data
    }

    const event = {
        onRowClick: (val, index, e) => {
            if (onRowClick) {
                onRowClick(val, index, e)
            }
        }
    }


    console.log(cells, "OOOODADA")
    return (
        <>
            <table className={style.component}>
                <tbody>
                    <tr>
                        {[...cells]}
                    </tr>
                    {content && !error && !loading ? content.map((c, i) => (
                        <tr 
                            ref={rowSelect && rowSelect.value === c[rowSelect.field] ? ref : null}
                            key={`tableRow${i}-${c[primaryKey]}-${changeTimeStamp ? changeTimeStamp : ""}`}
                            data-primary-key={`${primaryKey}${c[primaryKey]}`}
                            className={rowSelect && rowSelect.value === c[rowSelect.field] ? style.selected_row : ""}>
                            {cells.map(({props}, index) => {
                                const customClass = []
                                if (props.align) {
                                    if (props.align == "right")
                                        customClass.push(style.custom_cell_right)
                                }

                                if (props.contentType) {
                                    if (props.contentType.type == "link") {
                                        return (
                                            <td key={`tableColumn${index}`} className={`${customClass.join(" ")}`}>
                                                <a className={style.link} href={`mailto:${c[props.name]}`}>
                                                    {c[props.name]}
                                                </a>
                                            </td>
                                        )
                                    }
                                    if (props.contentType.type === "button") {
                                        return (
                                            <td key={`tableColumn${index}`} onClick={() => props.contentType.action(c)} className={`${customClass.join(" ")} ${style.buttonColumn}`} title="Kopier kontakt information" >
                                                <div>
                                                    {typeof props.contentType.content === "function" ? props.contentType.content(c) : props.contentType.content}
                                                </div>
                                            </td>
                                        )
                                    }
                                    if (props.contentType.type === "checkbox") {
                                        return (
                                            <td key={`tableColumn${index}`} className={`${customClass.join(" ")} ${style.buttonColumn}`} onClick={() => props.contentType.action(c, i)} >
                                                <div>
                                                    <CheckboxInput value={props.contentType.value(c, i)} onInput={() => props.contentType.action(c, i)} /> 
                                                </div>
                                            </td>
                                        )
                                    }
                                    if (props.contentType.type === "jsx") {
                                        return (
                                            <td key={`tableColumn${index}`} onClick={(e) => event.onRowClick(c, i, e)} className={`${customClass.join(" ")}`}>
                                                {typeof c[props.name] === "object" ? c[props.name].jsx : c[props.name] ? c[props.name] : null}
                                            </td> 
                                        )
                                    }
                                }

                                return (
                                    <td key={`tableColumn${index}`} onClick={(e) => event.onRowClick(c, i, e)} className={`${customClass.join(" ")}`}>
                                        {dataToReadable(c[props.name])}
                                    </td> 
                                )
                            })}
                        </tr>
                    )) : null}
                </tbody>
            </table>

            {loading || error || !content.length ?
                loading ? 
                <div className={style.message_container}>
                    <Loading className={style.loading} />
                </div>
                : error ? 
                <div className={style.message_container}>
                    {typeof error === "string" ? <p>{error}</p> : error}
                </div>
                :
                <div className={style.message_container}>
                    <p>ingen resultater fundet</p>
                </div>
            : null}
        </>
    )
})


export default Table