import React from "react";
import { Doughnut } from "react-chartjs-2";
import {Chart, ArcElement} from 'chart.js'

// style
import style from './PieGraph.module.scss'

Chart.register(ArcElement);

interface PieGraphProps {
    data: number[];
    name: string;
    online: number | null;
    fremmøde: number | null;
    colors: string[]
}

const PieGraph: React.FC<PieGraphProps> = ({
    data, 
    name, 
    colors, 
    online, 
    fremmøde
}) => {
    const dataContent = {
        labels: [],
        datasets: [{
          data: data,
          borderWidth: 0,
          backgroundColor: colors
        }]
      };
    
    var options = {        
        cutout: 40,
        events: [],
        animation: true,
        plugins: {
            legend: {
                display: false
            }
        }
    };


    const percentage = () => {
        return Math.round((data[0]/(data[1]+data[0]))*100)
    }

    return (
        <div className={style.component}>
            <div className={style.component_container}>
                <div className={style.component_graph_wrap}>
                    <div className={style.component_graph_wrap_center}>
                        <span className={style.component_value}>{data[0] > 999 ? 999 : data[0]}</span>
                            {data[1] === 0 ? null : 
                                <span className={style.component_percent}>{percentage()}%</span>
                            }
                    </div>
                    <div className={style.component_graph_wrap_canvas}>
                        <Doughnut data={dataContent} options={options} />
                    </div>
                </div>
                {online === null ? null : 
                    <div className={style.textWrap}>
                        <span>Online: {online}</span>
                        <span>Fremmøde: {fremmøde}</span>
                    </div>
                }
                <div className={style.component_container_text}>
                    <span className={style.component_name}>{name}</span>
                </div>
            </div>
        </div>
    )
}

export default PieGraph