import React from "react";
import { SvgComponentType } from "../../assetTypes";

const HoldSVG: React.FC<SvgComponentType> = ({ onClick, title }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      onClick={(e) => onClick(e)}
      style={{strokeWidth: 6}}
    >
      {title ? <title>{title}</title> : null}
      <g id="course">
        <path
          d="M72,35.5H17.5c-9.5,0-9,10-9,10v28"
          transform="translate(-2.97 -2.52)"
        />
        <line x1="86.53" y1="32.98" x2="97.53" y2="32.98" />
        <line x1="68.53" y1="46.98" x2="97.53" y2="46.98" />
        <line x1="58.53" y1="61.98" x2="83.53" y2="61.98" />
        <line x1="47.53" y1="79.98" x2="78.53" y2="79.98" />
        <path
          d="M86.5,76.5h7s3,1,4,3a7.74,7.74,0,0,1,1,3h23"
          transform="translate(-2.97 -2.52)"
        />
        <line x1="96.53" y1="61.98" x2="97.53" y2="61.98" />
        <path
          d="M116.5,73.5v-58s0-8-8-8h-61"
          transform="translate(-2.97 -2.52)"
        />
        <line x1="5.53" y1="117.98" x2="118.53" y2="117.98" />
        <line x1="10.53" y1="75.98" x2="10.53" y2="115.98" />
        <line x1="22.53" y1="77.98" x2="22.53" y2="115.98" />
        <line x1="33.53" y1="46.98" x2="33.53" y2="115.98" />
        <circle cx="22.03" cy="14.48" r="9.5" />
      </g>
    </svg>
  );
};

export default HoldSVG;
