import { Dispatch, SetStateAction, useState } from "react";
import SettingsMenu from "../components/SettingsMenu/SettingsMenu";
import UserMenu from "../components/UserMenu/UserMenu";
import React from "react";

interface dropDownStateType {
    profile: boolean
    settings: boolean
}

interface DropDownMenuHandlerType {
    dropDownMenu: dropDownStateType;
    setDropDownMenu: Dispatch<SetStateAction<dropDownStateType>>
}

export class DropDownMenuHandler implements DropDownMenuHandlerType {
    dropDownMenu: dropDownStateType;
    setDropDownMenu: Dispatch<SetStateAction<dropDownStateType>>
    constructor() {
        const [dropDownMenu, setDropDownMenu] = useState<dropDownStateType>({
            profile: false,
            settings: false
        })
        this.dropDownMenu = dropDownMenu
        this.setDropDownMenu = setDropDownMenu
    }

    clear() {
        this.setDropDownMenu({
            profile: false,
            settings: false
        })
    }

    toggleProfileMenu(state?: boolean) {
        const value = typeof state === "undefined" ? !this.dropDownMenu.profile : state
        const updateObj = {
            ...this.dropDownMenu,
            profile: value
        }
        this.setDropDownMenu(updateObj)
    }

    toggleSettingsMenu(state?: boolean) {
        const value = typeof state != "undefined" ? !this.dropDownMenu.settings : state
        const updateObj = {
            ...this.dropDownMenu,
            settings: value
        }
        this.setDropDownMenu(updateObj)
    }

    display() {
        if (this.dropDownMenu.settings)
            return <SettingsMenu />
            
        if (this.dropDownMenu.profile)
            return <UserMenu />
    }
}