import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider } from "@apollo/client";

// Authentication
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./config/msal";

// Configuration
import client from "./config/apolloClient";

// Components
import LoginCheck from "./LoginCheck";

// Styles
import "./index.scss";

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <MsalProvider instance={msalInstance}>
          <LoginCheck />
        </MsalProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
} else {
  <p>page not found</p>;
}
