import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { graphClient } from "../config/graphClient";

// Define a type for user data
export interface UserData {
    Fornavn: string;
    Efternavn: string;
    Brugernavn: string;
    Email: string;
    Initialer: string;
    Roles: string[];
    Img: string | null;
    error?: any;
}

interface UseUserData {
    data?: UserData,
    loading?: boolean,
    error?: Error, 
}


export const useUserData = (needsUpdate: boolean, setUpdateUserData: (update: boolean) => void): UseUserData => {
  const { accounts } = useMsal();
  const currentUser = accounts[0];
  
  const [userData, setUserData] = useState<UseUserData>({loading: true});

  useEffect(() => {
    if (needsUpdate) {
      getUserData();
    }
  }, [needsUpdate]);

  const getUserImage = async () => {
    try {
      const image = await graphClient.api('/me/photo/$value').get();
      return image;
    } catch (err) {
      return null;
    }
  };

  const getUserData = async () => {
    const userImage = await getUserImage();
    try {
      const data = await graphClient.api('/me').select('givenName,surname,displayName').get();
      const { givenName, surname, displayName } = data;

      const dataRes: UserData = {
        Fornavn: givenName ?? "",
        Efternavn: surname ?? "",
        Brugernavn: displayName ?? "",
        Email: currentUser.username,
        Initialer: currentUser.username.split("@")[0],
        Roles: currentUser.idTokenClaims.roles || [],
        Img: null
    }

      const reader = new FileReader();
      reader.onload = function (event) {
        const convertedDataUrl = event.target.result as string;
        dataRes.Img = convertedDataUrl
        setUserData({data: dataRes})
      };

      if (userImage) {
        reader.readAsDataURL(userImage);
        setUpdateUserData(false);
        return;
      }

      setUserData({data: dataRes})
      setUpdateUserData(false);
    } catch (err) {
        const error = err as Error
        setUserData({ error: error });
    }
  };

  return userData;
};