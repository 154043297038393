import React, { useEffect, useMemo, useState } from "react";

// Authentication
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";

// Functions
import { UserHandler } from "./functions/UserHandler";
import { ThemeHandler } from "./functions/ThemeHandler";

// Components
import LoginPage from "./pages/LoginPage/LoginPage";
import AppInit from "./AppInit";
import { InteractionType } from "@azure/msal-browser";

const userHandler = new UserHandler();

const LoginCheck: React.FC = () => {
  const [signOutLoad, setSignOutLoad] = useState<boolean>(false)
  const {instance} = useMsal()
  const themeHandler = new ThemeHandler();

  userHandler.setStates(instance, signOutLoad, setSignOutLoad);

  useMsalAuthentication(InteractionType.Redirect);

  useEffect(() => {
    themeHandler.setTheme();
  },[])

  return (
    <>
      <UnauthenticatedTemplate>
        <LoginPage userHandler={userHandler} />
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <AppInit userHandler={userHandler} themeHandler={themeHandler} />
      </AuthenticatedTemplate>
    </>
  );
};

export default LoginCheck;
