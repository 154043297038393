export default function Cog() {
    return (
        <svg viewBox='0 0 128 128' fill='%51657' xmlns='http://www.w3.org/2000/svg'>
            <path d="M121.96,54.13l-9.84-2.94c-2.12-5.12-3.07-7.41-5.19-12.53l4.88-9.04c0.98-1.82,0.65-4.08-0.81-5.55
                c-3.2-3.21-4.33-4.34-7.54-7.54c-1.47-1.46-3.72-1.79-5.55-0.81l-9.04,4.88c-5.12-2.12-7.41-3.07-12.54-5.19l-2.94-9.83
                c-0.59-1.99-2.42-3.35-4.5-3.35H58.23c-2.08,0-3.91,1.36-4.5,3.35L50.8,15.4c-5.02,2.07-7.55,3.12-12.55,5.19l-9.04-4.88
                c-1.83-0.99-4.08-0.65-5.55,0.81c-3.2,3.2-4.33,4.33-7.54,7.54c-1.47,1.47-1.8,3.73-0.81,5.55l4.88,9.04
                c-2.12,5.12-3.07,7.41-5.19,12.53l-9.84,2.94c-1.99,0.59-3.35,2.42-3.35,4.5V69.3c0,2.08,1.36,3.91,3.35,4.5l9.83,2.93
                c2.07,5.02,3.12,7.55,5.19,12.55l-4.87,9.03c-0.99,1.83-0.65,4.09,0.82,5.56c3.2,3.2,4.33,4.33,7.53,7.53
                c1.47,1.47,3.73,1.8,5.56,0.82l9.03-4.87c5,2.07,7.53,3.12,12.55,5.19l2.93,9.83c0.59,1.99,2.42,3.35,4.5,3.35H68.9
                c2.07,0,3.9-1.36,4.5-3.35l2.94-9.84c5.12-2.12,7.41-3.07,12.53-5.19l9.04,4.88c1.83,0.99,4.08,0.65,5.55-0.81
                c3.21-3.21,4.34-4.34,7.54-7.54c1.47-1.47,1.8-3.73,0.81-5.55l-4.88-9.04c2.07-5,3.12-7.53,5.19-12.55l9.83-2.93
                c1.99-0.59,3.35-2.42,3.35-4.5V58.63C125.31,56.56,123.95,54.73,121.96,54.13z M63.56,100c-19.9,0-36.03-16.13-36.03-36.03
                c0-19.9,16.13-36.03,36.03-36.03c19.9,0,36.03,16.13,36.03,36.03C99.59,83.87,83.46,100,63.56,100z"/>
        </svg>
    )
}