import Input from "../Input";
import { useEffect, useRef } from "react";

export default function AreaInput(props) {
    const {autoSize, disabled, name, onInput, onFocus, onBlur, onMouseOver, onMouseOut} = props
    const textareaRef = useRef(null)

    let {value} = props
    value = value ? value : ""

    const event = {
        autoSize: (e) => {
            if (autoSize && textareaRef.current) {
                textareaRef.current.style.minHeight = 0;
                textareaRef.current.style.minHeight = (textareaRef.current.scrollHeight) + "px"
            }
        },
        onFocus: () => {
            if (onFocus)
                onFocus()
        },
        onBlur: () => {
            if (onBlur)
                onBlur()
        },
        onMouseOut: () => {
            if (onMouseOut)
                onMouseOut()
        },
        onMouseOver: (e) => {
            if (onMouseOver)
                onMouseOver(e)
        }
    }

    useEffect(() => {
        event.autoSize()
    },[disabled])

    return (
        <Input {...props}>
            <textarea 
                onMouseOut={(e) => event.onMouseOut(e)}
                onMouseOver={(e) => event.onMouseOver(e)} 
                onFocus={(e) => event.onFocus()} 
                onBlur={(e) => event.onBlur()}
                ref={textareaRef} 
                disabled={disabled} 
                name={name} 
                onInput={(e) => onInput(name, e.target.value)} 
                value={value} 
                onChange={(e) => event.autoSize(e)} 
                spellCheck={false} 
                className={props.className}
            ></textarea>
        </Input>
    )
}