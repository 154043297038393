import { NavLink } from 'react-router-dom'
import React, { ReactElement, useContext } from 'react'

// CONTEXT
import { AppContext } from '../../context/AppContext'

// ASSETS
import Kursister from '../../assets/icons/navigation/Kursister'
import Kurser from '../../assets/icons/navigation/Kurser'

// STYLE
import style from './Navigation.module.scss'
import HoldSVG from '../../assets/icons/navigation/HoldSVG'
import StatistikSVG from '../../assets/icons/navigation/StatistikSVG'

interface NavigationButtonProps {
    title: string;
    to: string;
    icon: ReactElement;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({title, to, icon}) => {
    return (
        <NavLink 
            className={({ isActive }) => (isActive ? style.button__active :  style.button)} 
            to={to}
            >
            {icon ? icon : null}
            <p>{title}</p>
        </NavLink>
    )
}

const Navigation: React.FC = () => {
    const {popupHandler, dropDownMenuHandler} = useContext(AppContext)
    
    const resetDisplays = () => {
        dropDownMenuHandler.clear()
        popupHandler.clear()
    }

    return (
        <div className={style.component} onClick={() => resetDisplays()}>
            <div className={style.content}>
                <NavigationButton title="Aktive" to="/aktive" icon={<Kurser />}/>
                <NavigationButton title="Kursister" to="/kursister" icon={<Kursister />}/>
                <NavigationButton title="Hold" to="/hold" icon={<HoldSVG onClick={function (event: React.MouseEvent<SVGSVGElement, MouseEvent>): void {
                    throw new Error('Function not implemented.')
                } } />}/>
                <NavigationButton title="Statistik" to="/statistik" icon={<StatistikSVG onClick={function (event: React.MouseEvent<SVGSVGElement, MouseEvent>): void {
                    throw new Error('Function not implemented.')
                } } />}/>
            </div>
        </div>
    )
}

export default Navigation