import React, { useEffect } from "react";

// Functions
import { UserHandler } from "../../functions/UserHandler";

// Assets
import Logo from "../../assets/logo/Logo";
import Loading from "../../assets/icons/loaders/Loading";

// Components
import Button from "../../components/Button/Button";

// Style
import style from "./LoginPage.module.scss";

type LoginPageProps = {
  userHandler: UserHandler;
};

const LoginPage: React.FC<LoginPageProps> = ({ userHandler }) => {
  return (
    <div className={style.page}>
      <div className={style.card}>
        {userHandler.signOutLoad ? (
          <>
            <Loading />
            <p>Logger ud</p>
          </>
        ) : (
          <>
            <Logo className={style.logo} />
            <Button
              rounded
              outlined
              onClick={async () => await userHandler.login()}
            >
              Log in
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
