import { useMutation } from "@apollo/client"
import { useContext, useEffect, useState } from "react"

// QUERIES
import { CREATE_HOLD, GET_HOLD } from '../../../../queries/hold.gql'

// CONTEXT 
import { AppContext } from "../../../../context/AppContext"

// COMPONENTS
import HoldInterface from "../HoldInterface"
import { HoldInterfaceTemplate } from "../HoldInterfaceTemplate/HoldInterfaceTemplate"
import Button from '../../../Button/Button'
import useAllInputOptions from "../../../../hooks/useAllInputOptions"

const holdObjTemplate = {
    Kursus_id: "",
    Startdato: "",
    Holdnavn: ""
}

export default function HoldInterfaceCreate() {
    const [isELearning, setIsELearning] = useState(false)
    const {popupHandler} = useContext(AppContext)
    const inputOptions = useAllInputOptions()
    const [newHold, setNewHold] = useState(holdObjTemplate)
    const [inputErrors, setInputErrors] = useState({})

    const [create_hold, {data, loading, error}] = useMutation(CREATE_HOLD, {
        refetchQueries: [
            GET_HOLD
        ]
    });

    useEffect(() => {
        if (loading || error)
            return
        if (!data)
            return
            
        popupHandler.replace(0, <HoldInterface hold={data.create_hold} scrollable={true} />)
    },[data])


    useEffect(() => {
        let updateObj = {...newHold}
        if (updateObj.Startdato != "" && updateObj.Kursus_id != "" && updateObj.Holdnavn === "") {
            let kursusObj = inputOptions.kurser.find(k => k.value === updateObj.Kursus_id)
            if (kursusObj)
                updateObj.Holdnavn = `${kursusObj.title} - ${updateObj.Startdato}`
        }
    },[newHold])

    const event = {
        input: (name, val, kursus) => {
            let updateObj = {...newHold}
            if (kursus && kursus.E_learning) {
                setIsELearning(true)
                updateObj["Online"] = true
            } else {
                setIsELearning(false)
            }

            updateObj[name] = val
            if (updateObj.Startdato != "" && updateObj.Kursus_id != "" && name === "Startdato") {
                let kursusObj = inputOptions.kurser.find(k => k.value === updateObj.Kursus_id)
                if (kursusObj)
                    updateObj.Holdnavn = `${kursusObj.title} - ${updateObj.Startdato}`
            }
            setNewHold(updateObj)
        },
        close: () => {
            popupHandler.close()
        },
        create_hold: () => {
            let validateObj = HoldInterfaceTemplate.functions.validate(newHold)
            if (!validateObj.valid) {
                setInputErrors(validateObj.errors)
                return;
            }
            create_hold({variables: {input: {
                ...newHold,
                Startdato: new Date(newHold.Startdato)
            }}})
        }
    }

    return (
        <HoldInterfaceTemplate.component error={error} loading={loading}>
            <HoldInterfaceTemplate.baseInputs 
                hold={newHold} 
                onInput={event.input}
                isELearning={isELearning}
                disabledFields={false}
                inputErrors={inputErrors}
            />
            <HoldInterfaceTemplate.footer>
                <Button outlined onClick={() => event.close()}>Luk</Button>
                <Button onClick={(() => event.create_hold())}>Gem</Button>
            </HoldInterfaceTemplate.footer>
        </HoldInterfaceTemplate.component>
    )
}