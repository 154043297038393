import { useCatalog } from "./useCatalog"

const useAllInputOptions = () => {
    const catalogs = useCatalog()

    return {
        a_kasser: catalogs.a_kasser.map(Akasse => ({value: Akasse.A_kasse_id, title: Akasse.A_kasse, aktivt: Akasse.Aktivt})),
        genders: catalogs.genders.map(gender => ({value: gender.Gender_id, title: gender.Gender, aktivt: gender.Aktivt})),
        indgange: catalogs.indgange.map(indgang => ({value: indgang.Indgang_id, title: indgang.Indgang, aktivt: indgang.Aktivt})),
        jc_statuser: catalogs.jc_statuser.map(jc_status => ({value: jc_status.Jc_Status_id, title: jc_status.Jc_Status, aktivt: jc_status.Aktivt})),
        jobcentre: catalogs.jobcentre.map(jobcenter => ({value: jobcenter.Jobcenter_id, title: `${jobcenter.Jobcenter} ${jobcenter.Region}`, aktivt: jobcenter.Aktivt})),
        kh_statuser: catalogs.kh_statuser.map(kh_status => ({value: kh_status.Kh_Status_id, title: kh_status.Kh_Status, kode: kh_status.Kode, aktivt: kh_status.Aktivt})),
        kurser: catalogs.kurser.map(kursus => ({value: kursus.Kursus_id, title: kursus.Kursus, aktivt: kursus.Aktivt})),
        ansoger_vias: catalogs.ansoger_vias.map(ans => ({value: ans.Ansoger_via_id, title: ans.Ansoger_via, aktivt: ans.Aktivt})),
        // medarbejdere: catalogs.medarbejdere.map(medarbejder => ({value: medarbejder.Medarbejder_id, title: medarbejder.Initialer, aktivt: medarbejder.Aktivt}))
    }
}

export default useAllInputOptions