export default function Calendar(props) {
    return (
        <svg className={props.className} onClick={() => props.onClick()} tabIndex={props.tabIndex} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"
            style={{fill: 'none', strokeWidth:8, strokeLinecap:'round', strokeMiterlimit:10} }
        >
            <path d="M121,119.47H7c-1.81,0-3.28-1.47-3.28-3.28V17.96c0-1.81,1.47-3.28,3.28-3.28h114c1.81,0,3.28,1.47,3.28,3.28
                v98.22C124.29,118,122.82,119.47,121,119.47z"/>
            <line x1="64" y1="21.44" x2="64" y2="8.53"/>
            <line x1="93.75" y1="21.44" x2="93.75" y2="8.53"/>
            <line x1="33.2" y1="21.44" x2="33.2" y2="8.53"/>
            <line x1="26.39" y1="48.39" x2="40.02" y2="48.39"/>
            <line x1="57.19" y1="48.39" x2="70.81" y2="48.39"/>
            <line x1="86.93" y1="48.39" x2="100.56" y2="48.39"/>
            <line x1="26.68" y1="69.2" x2="40.31" y2="69.2"/>
            <line x1="57.47" y1="69.2" x2="71.1" y2="69.2"/>
            <line x1="87.22" y1="69.2" x2="100.85" y2="69.2"/>
            <line x1="26.68" y1="90" x2="40.31" y2="90"/>
            <line x1="57.47" y1="90" x2="71.1" y2="90"/>
            <line x1="87.22" y1="90" x2="100.85" y2="90"/>
        </svg>
    )
}