import { Dispatch, SetStateAction, useState } from "react";

import variables from '../theme.scss';

export interface ThemeHandlerType {
    activeTheme: string;
    setActiveTheme: Dispatch<SetStateAction<string>>
}

export class ThemeHandler implements ThemeHandlerType {
    activeTheme: string;
    setActiveTheme: Dispatch<SetStateAction<string>>
    constructor() {
        const [activeTheme, setActivetheme] = useState(localStorage.getItem("itucation_theme"))
        this.activeTheme = activeTheme
        this.setActiveTheme = setActivetheme
    }

    // setStates(activeTheme: string, setActivetheme: Dispatch<SetStateAction<string>>) {
    //     this.activeTheme = activeTheme
    //     this.setActiveTheme = setActivetheme
    // }

    setTheme(theme?: string) {
        if (!theme) {
            const localStorageTheme = localStorage.getItem("itucation_theme")
            if (localStorageTheme)
                theme = localStorageTheme
            else return
        } else 
            localStorage.setItem("itucation_theme", theme)
    
 
        if (this.activeTheme != theme) {
            this.setActiveTheme(theme)
        }
        
        const themeKeys = Object.keys(variables).filter(k => k.includes(`theme-${theme}`))
    
        let themeObj = {} as any
        themeKeys.forEach(key => {
            themeObj[key.replace(`theme-${theme}`, '-')] = variables[key]
        })
    
        const root = document.documentElement;
        Object.keys(themeObj).forEach(k => {
            root.style.setProperty(k, themeObj[k]);
        })
    }

    getColor(color: string) {
        const localStorageTheme = localStorage.getItem("itucation_theme")
        let theme = "default"
        if (localStorageTheme)
            theme = localStorageTheme
    
        return variables[`theme-${theme}-${color}`]
    }
}