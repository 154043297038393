import AreaInput from "../../../../Inputs/AreaInput/AreaInput"
import Input from "../../../../Inputs/Input"
import CustomSelectInput from "../../../../Inputs/SelectInput/CustomSelectInput"

import style from './Options.module.scss'

export default function CourseOptions({setFocus, setHover, editComponent, temp, updateTemp}) {
    if (!editComponent)
        return null

    const getTempValue = () => {
        let baseKey = editComponent[editComponent.length-1]
        let path = editComponent
        let obj = {...temp}
    
        if (!Array.isArray(path))
        return
        let currentObj = obj;
    
        for (const key of path.slice(0, -1)) {
        if (!currentObj.hasOwnProperty(key)) {
            console.error(`Key "${key}" not found in the object`);
            return obj;
        }
        currentObj = currentObj[key];
        }
    
        const lastKey = path[path.length - 1];
        return {
            course: {...currentObj[lastKey], baseKey: baseKey},
            course_sub_1: {...currentObj["course_sub_1"], baseKey: baseKey},
            course_sub_2: {...currentObj["course_sub_2"], baseKey: baseKey},
        }
    }
          
    return (
        <>
            <AreaInput 
                onMouseOut={() => setHover([])}
                onMouseOver={() => setHover(editComponent)} 
                onBlur={() => setFocus([])}
                onFocus={() => setFocus(editComponent)}
                label="Tekst"
                value={getTempValue().course.value}
                autoSize={true}
                onInput={(name, val, e) => updateTemp(val, "value")}
            />
            <div className={style.flex}>
                <Input 
                    label="Top"
                    value={getTempValue().course.style.marginTop}
                    onInput={(name, val, e) => updateTemp(val, "marginTop")}
                />
                <Input 
                    label="Bredde"
                    value={getTempValue().course.style.width}
                    onInput={(name, val, e) => updateTemp(val, "width")}
                />
                <Input 
                    label="Tekst størrelse"
                    value={getTempValue().course.style.fontSize}
                    onInput={(name, val, e) => updateTemp(val, "fontSize")}
                />
                <CustomSelectInput 
                    label="Tekst tykkelse"
                    name="course"
                    value={getTempValue().course.style.fontWeight}
                    onInput={(name, val, e) => updateTemp(val, "fontWeight")}
                    sort={{type: "default"}}
                    options={[
                        {title: "tynd", value: "100" },
                        {title: "normal", value: "300" },
                        {title: "tyk", value: "600" },
                        {title: "tykkere", value: "900" }
                    ]}
                />
            </div>
            <div className={style.split}></div>
            <AreaInput 
                onMouseOut={() => setHover([])}
                onMouseOver={() => setHover([editComponent[0], "course_sub_1"])} 
                onBlur={() => setFocus([])}
                onFocus={() => setFocus([editComponent[0], "course_sub_1"])}
                label="Under tekst"
                value={getTempValue().course_sub_1.value}
                autoSize={true}
                onInput={(name, val, e) => updateTemp(val, "value", ["base", "course_sub_1"])}
            />
            <div className={style.flex}>
                <Input 
                    label="Top"
                    value={getTempValue().course_sub_1.style.marginTop}
                    onInput={(name, val, e) => updateTemp(val, "marginTop", ["base", "course_sub_1"])}
                />
                <Input 
                    label="Tekst størrelse"
                    value={getTempValue().course_sub_1.style.fontSize}
                    onInput={(name, val, e) => updateTemp(val, "fontSize", ["base", "course_sub_1"])}
                />
                <CustomSelectInput 
                    label="Tekst tykkelse"
                    name="course_sub_1"
                    value={getTempValue().course_sub_1.style.fontWeight}
                    onInput={(name, val, e) => updateTemp(val, "fontWeight", ["base", "course_sub_1"])}
                    sort={{type: "default"}}
                    options={[
                        {title: "tynd", value: "100" },
                        {title: "normal", value: "300" },
                        {title: "tyk", value: "600" },
                        {title: "tykkere", value: "900" }
                    ]}
                />
            </div>
            <div className={style.split}></div>
            <AreaInput 
                onMouseOut={() => setHover([])}
                onMouseOver={() => setHover([editComponent[0], "course_sub_2"])} 
                onBlur={() => setFocus([])}
                onFocus={() => setFocus([editComponent[0], "course_sub_2"])}
                label="Under tekst 2"
                value={getTempValue().course_sub_2.value}
                autoSize={true}
                onInput={(name, val, e) => updateTemp(val, "value", ["base", "course_sub_2"])}
            />
            <div className={style.flex}>
                <Input 
                    label="Top"
                    value={getTempValue().course_sub_2.style.marginTop}
                    onInput={(name, val, e) => updateTemp(val, "marginTop", ["base", "course_sub_2"])}
                />
                <Input 
                    label="Tekst størrelse"
                    value={getTempValue().course_sub_2.style.fontSize}
                    onInput={(name, val, e) => updateTemp(val, "fontSize", ["base", "course_sub_2"])}
                />
                <CustomSelectInput 
                    label="Tekst tykkelse"
                    name="course_sub_2"
                    value={getTempValue().course_sub_2.style.fontWeight}
                    onInput={(name, val, e) => updateTemp(val, "fontWeight", ["base", "course_sub_2"])}
                    sort={{type: "default"}}
                    options={[
                        {title: "tynd", value: "100" },
                        {title: "normal", value: "300" },
                        {title: "tyk", value: "600" },
                        {title: "tykkere", value: "900" }
                    ]}
                />
            </div>
        </>
    )
}