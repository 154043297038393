import React, { useState } from "react";
import Msg from "../components/StatusMessage/Msg";

interface MsgStateContentProps {
  message: string;
  transition: number;
  time: number;
  count: number;
}

interface MsgStateProps {
  content: MsgStateContentProps[];
  count: number;
}

interface MsgHandlerType {
  msgState: MsgStateProps;
  setMsgState: React.Dispatch<MsgStateProps>;
  time: number;
  transition: number;
}

export class MsgHandler implements MsgHandlerType {
  msgState: MsgStateProps;
  setMsgState: React.Dispatch<MsgStateProps>;
  time: number;
  transition: number;
  constructor() {
    const [msgState, setMsgState] = useState({ content: [], count: 0 });
    this.msgState = msgState;
    this.setMsgState = setMsgState;
    this.time = 3;
    this.transition = 0.5;
  }

  display() {
    return (
      <Msg.Container>
        {this.msgState.content.map((m, i) => (
          <Msg.Card
            key={`msgCard-${m.count}`}
            close={(index: number) => this.remove(index)}
            index={i}
            message={m.message}
            time={m.time}
            transition={m.transition}
          />
        ))}
      </Msg.Container>
    );
  }

  add(message: string, time?: number, transition?: number) {
    time = time ? time : this.time;
    transition = transition ? transition : this.transition;

    let updateObj = { ...this.msgState };
    updateObj.count += 1;
    if (updateObj.content.length >= 10) {
      updateObj.content.shift();
    }

    updateObj.content.push({
      message: message,
      transition: transition,
      time: time,
      count: updateObj.count,
    });

    this.setMsgState(updateObj);
  }

  remove(index: number) {
    let updateObj = { ...this.msgState };
    updateObj.content.splice(index, 1);
    this.setMsgState(updateObj);
  }
}
