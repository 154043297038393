import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

// QUERIES
import {
  GET_KURSISTER_BY_HOLD,
  CREATE_KURSIST_ON_HOLD,
} from "../../../queries/kursister.gql";
import {
  GET_KURSIST_HOLD,
  HOLD_OVERVIEW_E_LEARNING,
  HOLD_OVERVIEW,
} from "../../../queries/hold.gql";
import {
  UPDATE_KURSIST_TIL_HOLD,
  DELETE_KURSIST_TIL_HOLD,
  CREATE_KURSIST_TIL_HOLD,
} from "../../../queries/kursistHold.gql";
import {
  KURSIST_TYPE_STATISTIK,
  KURSIST_TYPE_E_LEARN_STATISTIK,
} from "../../../queries/statistik.gql";
import { UPDATE_JOBCOACH } from "../../../queries/jobcoach.gql";

// CONTEXT
import client from "../../../config/apolloClient";
import { AppContext } from "../../../context/AppContext";

// COMPONENTS
import Button from "../../Button/Button";
import DateInput from "../../Inputs/DateInput/DateInput";
import NumberInput from "../../Inputs/NumberInput/NumberInput";
import CustomSelectInput from "../../Inputs/SelectInput/CustomSelectInput";
import Loading from "../../../assets/icons/loaders/Loading";

// STYLE
import style from "./KursistHoldInterface.module.scss";
import Color from "../../../assets/icons/indicators/Color";
import TabInterface from "../TabInterface/TabInterface";
import Input from "../../Inputs/Input";
import AreaInput from "../../Inputs/AreaInput/AreaInput";
import useAllInputOptions from "../../../hooks/useAllInputOptions";
import CheckboxInput from "../../Inputs/CheckboxInput/CheckboxInput";

// export default function KursistHoldInterface({dataObj, mode, layer, onSubmit}) {
interface KursistHoldInterfaceProps {
  dataObj: any;
  mode?: any;
  onSubmit?: any;
}

const KursistHoldInterface: React.FC<KursistHoldInterfaceProps> = ({
  dataObj,
  mode,
  onSubmit,
}) => {
  const { popupHandler, formatHandler, userData } = useContext(AppContext);
  const inputOptions = useAllInputOptions();
  const navigate = useNavigate();
  const [newDataObj, setNewDataObj] = useState(dataObj);
  const [inputErrors, setInputErrors] = useState({});

  const [create_kursist_til_hold, createKursistOnHoldQuery] = useMutation(
    CREATE_KURSIST_TIL_HOLD,
    {
      refetchQueries: [
        GET_KURSISTER_BY_HOLD,
        GET_KURSIST_HOLD,
        HOLD_OVERVIEW,
        HOLD_OVERVIEW_E_LEARNING,
        KURSIST_TYPE_STATISTIK,
        KURSIST_TYPE_E_LEARN_STATISTIK,
      ],
    }
  );

  const [update_kursist_til_hold, updateKursistHoldQuery] = useMutation(
    UPDATE_KURSIST_TIL_HOLD,
    {
      refetchQueries: [
        GET_KURSIST_HOLD,
        GET_KURSISTER_BY_HOLD,
        HOLD_OVERVIEW,
        HOLD_OVERVIEW_E_LEARNING,
        KURSIST_TYPE_STATISTIK,
        KURSIST_TYPE_E_LEARN_STATISTIK,
      ],
    }
  );

  const [update_jobcoach, updatejobcaochQuery] = useMutation(UPDATE_JOBCOACH, {
    refetchQueries: [
      GET_KURSIST_HOLD,
      GET_KURSISTER_BY_HOLD,
      HOLD_OVERVIEW,
      HOLD_OVERVIEW_E_LEARNING,
      KURSIST_TYPE_STATISTIK,
      KURSIST_TYPE_E_LEARN_STATISTIK,
    ],
  });

  const [remove_kursist_on_hold, deleteKursistHoldQuery] = useMutation(
    DELETE_KURSIST_TIL_HOLD,
    {
      refetchQueries: [
        GET_KURSISTER_BY_HOLD,
        GET_KURSIST_HOLD,
        HOLD_OVERVIEW,
        HOLD_OVERVIEW_E_LEARNING,
        KURSIST_TYPE_STATISTIK,
        KURSIST_TYPE_E_LEARN_STATISTIK,
      ],
    }
  );

  useEffect(() => {
    if (deleteKursistHoldQuery.error || deleteKursistHoldQuery.loading) return;
    if (!deleteKursistHoldQuery.data) return;
    popupHandler.close();
  }, [deleteKursistHoldQuery.data]);

  useEffect(() => {
    if (updateKursistHoldQuery.error || updateKursistHoldQuery.loading) return;
    if (!updateKursistHoldQuery.data) return;
    popupHandler.close();
  }, [updateKursistHoldQuery.data]);

  useEffect(() => {
    if (updatejobcaochQuery.error || updatejobcaochQuery.loading) return;
    if (!updatejobcaochQuery.data) return;
    popupHandler.close();
  }, [updatejobcaochQuery.data]);

  useEffect(() => {
    if (createKursistOnHoldQuery.error || createKursistOnHoldQuery.loading)
      return;
    if (!createKursistOnHoldQuery.data) return;
    popupHandler.close();
  }, [createKursistOnHoldQuery.data]);

  const validate = () => {
    let frontEndErrors: any = {};
    if (!newDataObj.Status_id) frontEndErrors.Status_id = "skal udfyldes";

    if (!newDataObj.Ansoger_via_id)
      frontEndErrors.Ansoger_via_id = "skal udfyldes";

    if (Object.keys(frontEndErrors).length < 1) return { valid: true };

    return { valid: false, errors: frontEndErrors };
  };

  const event = {
    input: (name, val) => {
      let updateObj = { ...newDataObj };
      updateObj[name] = val;
      setNewDataObj(updateObj);
    },
    newDataObj: (name, val) => {
      let updateObj = { ...newDataObj };
      updateObj[name] = val;
      setNewDataObj(updateObj);
    },
    update: () => {
      client.clearStore();
      const fieldsToUpdate = Object.keys(newDataObj).filter(
        (k) => newDataObj[k] != dataObj[k]
      );

      let updatedData = newDataObj;
      const updateObj = {};
      const jobcoachUpdateObj = {};
      fieldsToUpdate.forEach((f) => {
        if (f === "Jc_Note") {
          const date = new Date();
          jobcoachUpdateObj[f] = `${updatedData[f]} - ${
            userData.Initialer
          } - ${formatHandler.date(date)} ${formatHandler.time(date)} \r\n`;
          return;
        }
        if (f === "Status_id") {
          updateObj["Kh_Status_id"] = newDataObj[f];
          return;
        }
        if (
          [
            "BT_sendt",
            "BM_sendt",
            "Rykket",
            "Blanket_sendt",
            "Velkomstmail",
          ].includes(f)
        ) {
          updateObj[f] = new Date(newDataObj[f]);
          return;
        }

        if (
          [
            "Jc_Samtaler",
            "Jc_Note",
            "Jc_Success_Date",
            "Jc_Status_id",
          ].includes(f)
        ) {
          jobcoachUpdateObj[f] =
            f === "Jc_Samtaler" || f === "Jc_Status_id"
              ? eval(newDataObj[f])
              : f === "Jc_Success_Date"
              ? new Date(newDataObj[f])
              : newDataObj[f];
          return;
        }

        updateObj[f] = newDataObj[f];
      });

      if (Object.keys(updateObj).length > 0) {
        let validateObj = validate();
        if (!validateObj.valid) {
          setInputErrors(validateObj.errors);
          return;
        }
        update_kursist_til_hold({
          variables: {
            where: { Kursist_Hold_id: eval(dataObj.Kursist_Hold_id) },
            data: updateObj,
          },
        });
      }

      if (Object.keys(jobcoachUpdateObj).length > 0)
        update_jobcoach({
          variables: {
            where: { Kursist_Hold_id: eval(dataObj.Kursist_Hold_id) },
            data: jobcoachUpdateObj,
          },
        });
    },
    add: () => {
      let validateObj = validate();
      if (!validateObj.valid) {
        setInputErrors(validateObj.errors);
        return;
      }
      const fieldsToUpdate = Object.keys(newDataObj).filter(
        (k) => newDataObj[k] != dataObj[k]
      );
      let updatedData = newDataObj;
      const jcCreateObj = {};
      const khCreateObj = {};
      fieldsToUpdate.forEach((f) => {
        if (f === "Jc_Note") {
          jcCreateObj[f] = newDataObj[f];
          const date = new Date();
          updatedData[f] = `${jcCreateObj[f]} - ${
            userData.Initialer
          } - ${formatHandler.date(date)} ${formatHandler.time(date)} \r\n`;
          jcCreateObj[f] = updatedData[f];
          setNewDataObj(updatedData);
          return;
        }
        if (f === "Jc_Samtaler") {
          jcCreateObj[f] = eval(newDataObj[f]);
          return;
        }
        if (f === "Jc_Status_id") {
          jcCreateObj[f] = newDataObj[f];
          return;
        }
        if (f === "Jc_Success_Date") {
          jcCreateObj[f] = new Date(newDataObj[f]);
          return;
        }

        if (f === "Status_id") {
          khCreateObj["Kh_Status_id"] = eval(newDataObj[f]);
          return;
        }
        if (
          [
            "BT_sendt",
            "BM_sendt",
            "Rykket",
            "Blanket_sendt",
            "Velkomstmail",
          ].includes(f)
        ) {
          khCreateObj[f] = new Date(newDataObj[f]);
          return;
        }
        khCreateObj[f] = newDataObj[f];
      });
      client.clearStore();

      khCreateObj["Hold_id"] = eval(newDataObj.Hold_id);
      khCreateObj["Kursist_id"] = eval(newDataObj.Kursist_id);
      create_kursist_til_hold({
        variables: { jcData: jcCreateObj, khData: khCreateObj },
      });
      onSubmit();
    },
    remove: () => {
      client.clearStore();
      popupHandler.remove(2);
      remove_kursist_on_hold({
        variables: { id: eval(dataObj.Kursist_Hold_id) },
      });
    },
    close: () => {
      popupHandler.close();
    },
    goToKursist: () => {
      const location = "/kursister";
      if (window.location.pathname === location) {
        popupHandler.close();
        return;
      }
      popupHandler.clear();
      navigate(location, {
        replace: true,
        state: {
          initalSearch: `Kursist_id=${newDataObj.Kursist_id}`,
          select: newDataObj.Kursist_id,
        },
      });
    },
    goToHold: () => {
      const location = "/hold";
      if (window.location.pathname === location) {
        popupHandler.close();
        return;
      }
      popupHandler.clear();
      navigate(location, {
        replace: true,
        state: {
          initalSearch: `Hold_id=${newDataObj.Hold_id}`,
          select: newDataObj.Hold_id,
        },
      });
    },
  };

  const StatusOptions = inputOptions.kh_statuser.map((s, i) => ({
    ...s,
    jsx: (
      <span className={style.colorSelect} key={i + "Color"}>
        <Color color={formatHandler.statusColor(s.kode)} size="10" onClick={function (event: React.MouseEvent<SVGSVGElement, MouseEvent>): void {
                throw new Error("Function not implemented.");
            } }></Color>
        <span>{s.title}</span>
      </span>
    ),
  }));

  return (
    <TabInterface
      scrollable={false}
      size="auto"
      tabs={["Kursist Til Hold", "Karrierecoach"]}
    >
      <div className={style.component}>
        {deleteKursistHoldQuery.loading ||
        updateKursistHoldQuery.loading ||
        updatejobcaochQuery.loading ||
        createKursistOnHoldQuery.loading ||
        deleteKursistHoldQuery.loading ? (
          <div className={style.loading}>
            <Loading />
          </div>
        ) : null}

        <div className={style.content}>
          <div className={style.content_scroll_conainer}>
            <div>
              <p className={style.title}>Hold</p>
              <p onClick={() => event.goToHold()} className={style.link}>
                {dataObj.Hold}
              </p>
            </div>
            <div>
              <p className={style.title}>Kursist</p>
              <p className={style.link} onClick={() => event.goToKursist()}>
                {dataObj.Fornavn} {dataObj.Efternavn}
              </p>
            </div>

            <CheckboxInput
              name="Online"
              label="Kursist er Online?"
              value={newDataObj.Online}
              onInput={event.input}
              disabled={
                newDataObj.Hold_Online === true ||
                newDataObj.Hold_Online === "ja"
              }
              title={
                newDataObj.Hold_Online === true ||
                newDataObj.Hold_Online === "ja"
                  ? "låst for online hold"
                  : null
              }
            />
            <CustomSelectInput
              name="Status_id"
              label="Status"
              sort={{ type: "code" }}
              search={true}
              options={StatusOptions}
              value={newDataObj.Status_id}
              onInput={event.input}
              required={true}
              error={"Status_id" in inputErrors ? inputErrors.Status_id : null}
            />
            <CustomSelectInput
              name="Ansoger_via_id"
              value={newDataObj.Ansoger_via_id}
              options={inputOptions.ansoger_vias}
              label="Ansøger via"
              onInput={event.input}
              required={true}
              error={
                "Ansoger_via_id" in inputErrors
                  ? inputErrors.Ansoger_via_id
                  : null
              }
            />
            <DateInput
              todayButton={true}
              name="Blanket_sendt"
              value={newDataObj.Blanket_sendt}
              label="Blanket sendt"
              onChange={event.input}
            />
            <DateInput
              todayButton={true}
              name="Rykket"
              value={newDataObj.Rykket}
              label="Rykket"
              onChange={event.input}
            />
            <DateInput
              todayButton={true}
              name="BM_sendt"
              value={newDataObj.BM_sendt}
              label="Bekræftelsesmail sendt"
              onChange={event.input}
            />
            <DateInput
              todayButton={true}
              name="BT_sendt"
              value={newDataObj.BT_sendt}
              label="BT sendt"
              onChange={event.input}
            />
            <DateInput
              todayButton={true}
              name="Velkomstmail"
              value={newDataObj.Velkomstmail}
              label="Velkomstmail"
              onChange={event.input}
            />
            <div className={style.vertical_inputs}>
              <CheckboxInput
                name="Books_sendt"
                label="Bøger sendt"
                value={newDataObj.Books_sendt}
                onInput={event.input}
              />
              <CheckboxInput
                name="PC_sendt"
                label="PC sendt"
                value={newDataObj.PC_sendt}
                onInput={event.input}
              />
              <CheckboxInput
                name="Books_return"
                label="Bøger retur"
                value={newDataObj.Books_return}
                onInput={event.input}
              />
              <CheckboxInput
                name="PC_return"
                label="PC retur"
                value={newDataObj.PC_return}
                onInput={event.input}
              />
            </div>
            <Input
              name="Certificering"
              value={newDataObj.Certificering}
              label="Eksamen/Certificering"
              onInput={event.input}
            />
          </div>
        </div>
        <div className={style.footer}>
          <div>
            {mode === "add" ? null : (
              <Button
                outlined={true}
                color={"main"}
                onClick={() =>
                  popupHandler.add(
                    <div className={style.delete_card}>
                      <p>
                        Er du sikker på at du vil fjerne{" "}
                        <span>
                          {dataObj.Fornavn} {dataObj.Efternavn}
                        </span>{" "}
                        fra <span>{dataObj.Hold}</span>?
                      </p>
                      <div>
                        <Button onClick={() => event.remove()}>ja</Button>
                        <Button outlined onClick={() => popupHandler.remove(2)}>
                          nej
                        </Button>
                      </div>
                    </div>
                  )
                }
              >
                Fjern fra hold
              </Button>
            )}
          </div>
          <div>
            <Button
              onClick={() => (mode === "add" ? event.add() : event.update())}
            >
              {mode === "add" ? "Tilføj" : "Gem"}
            </Button>
          </div>
        </div>
      </div>
      <div className={style.component}>
        {deleteKursistHoldQuery.loading ||
        updateKursistHoldQuery.loading ||
        updatejobcaochQuery.loading ||
        createKursistOnHoldQuery.loading ||
        deleteKursistHoldQuery.loading ? (
          <div className={style.loading}>
            <Loading />
          </div>
        ) : null}

        <div className={style.content}>
          <div className={style.content_scroll_conainer}>
            <div>
              <p className={style.title}>Hold</p>
              <p onClick={() => event.goToHold()} className={style.link}>
                {dataObj.Hold}
              </p>
            </div>
            <div>
              <p className={style.title}>Kursist</p>
              <p className={style.link} onClick={() => event.goToKursist()}>
                {dataObj.Fornavn} {dataObj.Efternavn}
              </p>
            </div>

            <NumberInput
              name="Jc_Samtaler"
              label="Antal samtaler"
              onInput={event.input}
              value={newDataObj.Jc_Samtaler}
            />
            <CustomSelectInput
              label="Status"
              name="Jc_Status_id"
              onInput={event.input}
              options={inputOptions.jc_statuser}
              value={newDataObj.Jc_Status_id}
            />
            <DateInput
              todayButton={true}
              name="Jc_Success_Date"
              value={newDataObj.Jc_Success_Date}
              label="Dato success"
              onInput={event.input}
            />
            <AreaInput
              label="Noter fra andre hold"
              name="Jc_Combined"
              disabled={true}
              value={newDataObj.combinedNote}
              autoSize={true}
              onInput={event.input}
            />
            <AreaInput
              label="Note"
              name="Jc_Note"
              value={newDataObj.Jc_Note}
              autoSize={true}
              onInput={event.input}
            />
          </div>
        </div>
        <div className={style.footer}>
          <div>
            {mode === "add" ? null : (
              <Button
                outlined={true}
                color={"main"}
                onClick={() =>
                  popupHandler.add(
                    <div className={style.delete_card}>
                      <p>
                        Er du sikker på at du vil fjerne{" "}
                        <span>
                          {dataObj.Fornavn} {dataObj.Efternavn}
                        </span>{" "}
                        fra <span>{dataObj.Hold}</span>?
                      </p>
                      <div>
                        <Button onClick={() => event.remove()}>ja</Button>
                        <Button outlined onClick={() => popupHandler.remove(2)}>
                          nej
                        </Button>
                      </div>
                    </div>
                  )
                }
              >
                Fjern fra hold
              </Button>
            )}
          </div>
          <div>
            <Button
              onClick={() => (mode === "add" ? event.add() : event.update())}
            >
              {mode === "add" ? "Tilføj" : "Gem"}
            </Button>
          </div>
        </div>
      </div>
    </TabInterface>
  );
};

export default KursistHoldInterface;
