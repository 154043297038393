import bg from '../assets/bg.jpg'

export const content = {
    base: {
      titel: {
        value: "Course Certificate",
        style: {
          fontSize: "36",
          marginTop: "110",
          fontWeight: "600",
          width: "540"
        }
      },
      toCertify: {
        value: "This is to certify that",
        style: {
          fontSize: "12",
          marginTop: "24",
          fontWeight: "300",
          width: "540"
        }
      },
      name: {
        value: "<<Fornavn>> <<Efternavn>>",
        style: {
          fontSize: "24",
          marginTop: "8",
          fontWeight: "300",
          width: "540"
        }
      },
      date: {
        value: "in the period September 27th –November 5th 2021 has completed the course",
        style: {
          fontSize: "12",
          marginTop: "8",
          fontWeight: "300",
          width: "320"
        }
      },
      course: {
        value: "Coaching og Konflikthåndtering",
        style: {
          fontSize: "28",
          marginTop: "24",
          fontWeight: "600",
          width: "540"
        }
      },
      course_sub_1: {
        value: "Færdigheder i Praksis",
        style: {
          fontSize: "24",
          marginTop: "0",
          fontWeight: "300",
          width: "540"
        }
      },
      course_sub_2: {
        value: "",
        style: {
          fontSize: "20",
          marginTop: "0",
          fontWeight: "600",
          width: "540"
        }
      },
      course_content: {
        value: "Course Content",
        style: {
          fontSize: "14",
          marginTop: "46",
          fontWeight: "600",
          width: "450"
        }
      },
      leader_name: "Søren Metzsch",
      leader_name_sub: "Uddannelsesleder hos Itucation"
    },
    coloumn1: {
      content: [
        {
          value: "Basic communication theory",
          content: [
            {
              value: "Conversation tools & communication theories",
              content: [
                {value: "dwadwa"},
              ]
            }
          ]
        }
      ],
      style: {
        titelFontSize: "12",
        pointFontSize: "10",
        maxWidth: "235",
        minWidth: "235",
        marginLeft: "12",
        subPointFontSize: "10",
        marginTop: "14",
        spacingtitles: "12",
        spacingPoints: "4",
        spacingSubPoints: "4"
      }
    },
    coloumn2: {
      content: [
        {
          value: "Basic communication theory",
          content: [
            {
              value: "Conversation tools & communication theories",
              content: [
                {value: "dwadwa"},
              ]
            }
          ]
        }
      ],
      style: {
        titelFontSize: "12",
        pointFontSize: "10",
        maxWidth: "235",
        minWidth: "235",
        subPointFontSize: "10",
        marginTop: "14",
        marginLeft: "12",
        spacingtitles: "12",
        spacingPoints: "4",
        spacingSubPoints: "4"
      }
    },
  }

export const styling = {
    logo: {
      width: "120px",
      right: "36px",
      top: "46px",
      position: "absolute",
    },
    background: {
      position: "absolute",
      height: "100%",
      width: "100%",
      zIndex: "-1",
    },
    baseContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      fontFamily: 'Arial',
    },
    nextPageContentSection: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      fontFamily: 'Arial' 
    },
    courseBreaker: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      flexDirection: "row",
      gap: "12px",
      width: "500px"
    },
    courseBreaker_bar: {
      height: "1px",
      backgroundColor: "black",
      flex: 1,
    },
    courseContent: {
      width: "500px",
      display: "flex",
      flexDirection: "row",
      // justifyContent: "space-between"
    },
  
    courseContent_column: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "row",
      textAlign: "left",
      zIndex: 1,
      position: "relative"
    },
  
    courseContent_column_sub: {
      // maxWidth: "235px",
      // minWidth: "235px",
    },
  
    listValue: {
      display: "flex",
      flexDirection: "row",
      gap: "6px",
      // maxWidth: "235px",
      // minWidth: "235px",
    },
  
    page: {
      position: "relative",
      aspectRatio: 1/1.41, 
      backgroundImage: `url(${bg})`, 
      display: "flex", 
      flexDirection: "column", 
      backgroundSize:"contain", 
      width: "595px",
      height: "841px !important",
      alignItems: "center",
      whiteSpace: "pre-wrap",
      zIndex: 1,
      overflow: "hidden"
    },
    content: {
      margin: "14px 64px 0px 64px",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      gap: "36px",
      alignItems: "center"
    },
    title: {
      display: "flex",
      flexDirection: "column",
  
    },
    nameSection: {
      display: "flex",
      flexDirection: "column",
      gap: "14px",
      alignItems: "center"
    },
    nameSection_cert: {
      fontSize: "14px",
      margin: "0"
    },
    nameSection_name: {
      fontSize: "30px",
      margin: "0"
    },
    nameSection_time: {
      fontSize: "14px",
      width: "328px",
      margin: "0"
    },
    courseSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    courseSection_course: {
      fontSize: "38px",
      fontWeight: "bold",
      margin: "0"
    },
    courseSection_course_sub: {
      fontSize: "30px",
      margin: "0"
    },
    courseSection_course_more_sub: {
      fontSize: "24px",
      fontWeight: "bold",
      margin: "0"
    },
    breakSections: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "16px",
      width: "100%"
    },
    breakSections_bar: {
      flex: 1,
      height: "1px",
      backgroundColor: "black"
    },
    breakSections_text: {
      fontWeight: "bold"
    },
    contentSection: {
      margin: "32px 100px 46px 100px",
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      flex: 1
    },
    contentSection_content: {
      display: "flex",
      flex: 1,
      position: "relative"
    },
    contentSection_content_flex: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      flexWrap: "wrap",
      height: "100%",
    },
    contentSection_content_title: {
      fontWeight: "bold",
      fontSize: "14px",
      paddingBottom: "8px",
      margin: 0
    },
    contentSection_content_list: {
      margin: 0,
      padding: "0 0 0 15px",
    },
    contentSection_content_list_item: {
      fontSize: "12px",
      margin: 0,
    },
    secondPageUnderskrift: {
        flex: 1,
        display: "flex",
        position: "relative",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Arial"
    },
    underskrift: {
      display: "flex",
      position: "absolute",
      bottom: "54px",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Arial",
      zIndex: -1,
    },
    underskrift_underskrift: {
      width: "162px",
    },
    underskrift_line: {
      height: "1px",
      width: "148px",
      backgroundColor: "black",
      transform: "translateY(-8px)"
    },
    underskrift_navn: {
      fontWeight: "600",
      fontSize: "9px",
      transform: "translateY(-6px)"
    },
    underskrift_titel: {
      fontSize: "9px",
      transform: "translateY(-6px)"
    },
    nextPageUnderskrift: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: 1,
      justifyContent: "center"
    },
    pageNr: {
      fontSize: "9px",
      position: "absolute",
      bottom: "46px",
      letterSpacing: "1px",
      right: "46px",
      opacity: 0.75,
    },
    subContent_dash_ul: {
  
    }
  }