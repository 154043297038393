import { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

// QUERIES
import { CREATE_KURSIST, KURSISTER } from '../../../../queries/kursister.gql'

// CONTEXT
import { AppContext } from "../../../../context/AppContext";

// TEMPLATE
import { KursistInterfaceTemplate } from "../KursistInterfaceTemplate/KursistInterfaceTemplate";

// COMPONENTS
import KursistInterface from "../KursistInterface";
import Button from "../../../Button/Button";

// STYLE
import style from './KursistInferfaceCreate.module.scss'


export default function KursistInterfaceCreate() {
    const {popupHandler, userData, msg, formatHandler} = useContext(AppContext)
    // const autoSelectMedarbejder = inputOptions.medarbejdere.find(({title}) => title.toLowerCase() === userData.Initialer.toLowerCase())
    const [inputErrors, setInputErrors] = useState({})
    const [templateID, setTemplateID] = useState(0)
    const [kursist, setKursist] = useState({
        Fornavn: "",
        Efternavn: "",
        Tlf: "",
        Mail: "",
        Gender: null,
        Birthday: "",
        Note: "",
        Uddannelsesniveau: "",
        A_kasse: null,
        Jobcenter: null,
        Kontaktperson: "",
        Indgang: null,
        Medarbejder: userData.Initialer,
        Udb: false,
        Rkv: false,
    })

    const [create_kursist, {data, loading, error}] = useMutation(CREATE_KURSIST, {
        refetchQueries: [
            KURSISTER
        ]
    });

    useEffect(() => {
        if (error) {
            const errorObjs = formatHandler.apiError(error)
            let inputErrorKeys = {}
            errorObjs.forEach(err => {
                if ("key" in err) {
                    inputErrorKeys[err.key] = err.message
                }
            })
            setInputErrors(inputErrorKeys)
            return
        }
        if (loading)
            return
        if (!data || !data.create_kursist)
            return

        if (templateID) {
            msgHandler.add(`Sender info mail til ${data.create_kursist.Mail}...`, 10)
            const name = `${data.create_kursist.Fornavn} ${data.create_kursist.Efternavn}`
            const subject = "info mail itucation"
            sendMailTemplate(templateID, data.create_kursist.Mail, name, subject, msg)
        }

        popupHandler.replace(0, <KursistInterface kursist={data.create_kursist} scrollable={true} />)
    },[data, error, loading])

    const event = {
        input: (name, val) => {
            let updateObj = {...kursist}
            updateObj[name] = val
            setKursist(updateObj)
        },
        onAutoCompleteInput: (name, val) => {

        },
        validate: () => {
            let validateObj = KursistInterfaceTemplate.functions.validate(kursist)
            if (!validateObj.valid) {
                setInputErrors(validateObj.errors)
                return;
            }
            event.create_kursist();
        },
        create_kursist: (activeMailTemplate) => {
            popupHandler.remove(2)
            let CreateKursistInput = {
                ...kursist,
                Oprettet: new Date(),
                Note: !kursist.Note ? "" : (() => {
                    const date = new Date()
                    return `${kursist.Note} - ${userData.Initialer} - ${formatHandler.date(date)} ${formatHandler.time(date)} \r\n`
                })()
            }

            let createInput = {}
            Object.keys(CreateKursistInput).forEach(key => {
                if (key === "Birthday") {
                    createInput["Birthday"] = new Date(CreateKursistInput[key])
                    return
                }
                if (key === "Postnummer") {
                    createInput[key] = eval(CreateKursistInput[key])
                    return
                }
                createInput[key] = CreateKursistInput[key]
            })

            // setTemplateID(activeMailTemplate)
            create_kursist({variables: {input: createInput}})
        },
        close: () => {
            popupHandler.close()
        }
    }

    return (
        <KursistInterfaceTemplate.component error={error} loading={loading}>
            <KursistInterfaceTemplate.content>
                <KursistInterfaceTemplate.baseInputs 
                    kursist={kursist} 
                    onInput={(name, val) => event.input(name, val)}
                    disabledFields={false}
                    inputErrors={inputErrors}
                />
                <KursistInterfaceTemplate.detailInputs 
                    kursist={kursist}
                    setKursist={setKursist}
                    onInput={(name, val) => event.input(name, val)}
                    disabledFields={false}
                    inputErrors={inputErrors}
                />
            </KursistInterfaceTemplate.content>
            <KursistInterfaceTemplate.footer>
                <Button outlined onClick={() => event.close()}>Luk</Button>
                <Button onClick={(() => event.validate())}>Opret</Button>
            </KursistInterfaceTemplate.footer>
        </KursistInterfaceTemplate.component>
    )
}